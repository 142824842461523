import * as React from 'react'
import { Switch, Typography, Flex, Button, Progress, Alert } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useExportOptions } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'
import styles from './styles/export-settings.module.scss'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'
import { useExportProgressStore } from '@app/stores/export-progress'
import { LoadingIcon } from '@components/loading-icon'
import { useBindExportProjectShortcut } from '@app/pages/editor-page/hooks/editor-shortcut'

const { Text, Paragraph } = Typography

interface ExportSettingsProps {
  projectId: string
}
export const ExportSettings: React.FC<ExportSettingsProps> = ({ projectId }) => {
  const { data: exportOptions, mutateExportOptions } = useExportOptions(projectId)
  const [isExporting, exportProgress] = useExportProgressStore((state) => [state.isExporting, state.progress])
  const [isExportError, setExportError] = React.useState<boolean>(false)
  const intl = useIntl()

  // Reset error state when projectId changes
  React.useEffect(() => {
    setExportError(false)
  }, [projectId])

  const mailToContactSupport = (): string => {
    const mailSubject = intl.formatMessage({
      id: 'export-settings.error.mail.template.subject',
      defaultMessage: 'Project Export error in Clemex Studio',
    })
    const mailBody = intl.formatMessage({
      id: 'export-settings.error.mail.template.body',
      defaultMessage: 'Hi,\n\nI could not export my project in Clemex Studio.\n\nCan you assist me with this? ',
    })
    return `mailto:support@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
  }

  useBindExportProjectShortcut(setExportError, projectId)

  return <Flex vertical className={styles.root}>
    <SettingsSection
      title={<FormattedMessage id='options.label' defaultMessage='Export Options' />}
    >
      <SettingRow
        title={
          <FormattedMessage id='export.options.combine-masks.label' defaultMessage={'Combine masks in a single colorized file'} />
        }
      >
        <Switch defaultChecked={exportOptions?.includeCombinedMasks}
          onChange={(checked: boolean) => {
            if (exportOptions === undefined) {
              return
            }
            void mutateExportOptions({
              ...exportOptions,
              includeCombinedMasks: checked,
              includeIndividualMasks: !checked,
            })
          }}
        />
      </SettingRow>
      <SettingRow className={styles.exportButtonRow}>
        {
          isExporting && <Progress
            showInfo={false}
            status='active'
            percent={exportProgress * 100}
            className={styles.progress}
          />
        }
        <Button
          type='primary'
          onClick={() => {
            window.dispatchEvent(new CustomEvent(EVENTS_ID.EXPORT_PROJECT))
          }}
          className={styles.exportButton}
          disabled={isExporting}
        >
          <LoadingIcon isLoading={isExporting} className={styles.exporting} />
          <FormattedMessage id='project.export.label' defaultMessage={'Export'} />
        </Button>
      </SettingRow>
    </SettingsSection>
    {
      isExportError &&
      <Typography.Text type="danger">
        <FormattedMessage id="export-settings.notification.error.title" defaultMessage='An unknown error occurred while exporting the project. Please try again or contact us at: {salesEmail}' values={{
          salesEmail: <><br /><a href={mailToContactSupport()}>support@clemex.com</a></>,
        }} />
      </Typography.Text>
    }
  </Flex >
}

export const ExportFeatureExplanation: React.FC = () => {
  return <Flex className={styles.root} vertical>
    <SettingsSection
      title={<FormattedMessage id='project.export.explanation.modal.title' defaultMessage='What is the project export feature?' />}
    >
      <SettingRow>
        <Alert
          message={<Text>
            <Paragraph>
              <FormattedMessage id="project.export.explanation.modal.description.2" defaultMessage="Project exportation allows to export all images, annotation masks and prediction masks of a project." />
            </Paragraph>
            <RequestLicenceFeature featureText='Project export' licenceFeatureRequest={UserAssistanceLicenceFeature.ExportProject} />
          </Text>}
          type="warning"
        />
      </SettingRow>
    </SettingsSection>
  </Flex>
}
