/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PageName } from './PageName';
import {
    PageNameFromJSON,
    PageNameFromJSONTyped,
    PageNameToJSON,
    PageNameToJSONTyped,
} from './PageName';

/**
 * 
 * @export
 * @interface IngestAccessPageRequest
 */
export interface IngestAccessPageRequest {
    /**
     * 
     * @type {PageName}
     * @memberof IngestAccessPageRequest
     */
    pageName: PageName;
}



/**
 * Check if a given object implements the IngestAccessPageRequest interface.
 */
export function instanceOfIngestAccessPageRequest(value: object): value is IngestAccessPageRequest {
    if (!('pageName' in value) || value['pageName'] === undefined) return false;
    return true;
}

export function IngestAccessPageRequestFromJSON(json: any): IngestAccessPageRequest {
    return IngestAccessPageRequestFromJSONTyped(json, false);
}

export function IngestAccessPageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngestAccessPageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'pageName': PageNameFromJSON(json['page_name']),
    };
}

export function IngestAccessPageRequestToJSON(json: any): IngestAccessPageRequest {
    return IngestAccessPageRequestToJSONTyped(json, false);
}

export function IngestAccessPageRequestToJSONTyped(value?: IngestAccessPageRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'page_name': PageNameToJSON(value['pageName']),
    };
}

