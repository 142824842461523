/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSharingDetailsRequest
 */
export interface ProjectSharingDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectSharingDetailsRequest
     */
    sharingToken: string;
}

/**
 * Check if a given object implements the ProjectSharingDetailsRequest interface.
 */
export function instanceOfProjectSharingDetailsRequest(value: object): value is ProjectSharingDetailsRequest {
    if (!('sharingToken' in value) || value['sharingToken'] === undefined) return false;
    return true;
}

export function ProjectSharingDetailsRequestFromJSON(json: any): ProjectSharingDetailsRequest {
    return ProjectSharingDetailsRequestFromJSONTyped(json, false);
}

export function ProjectSharingDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSharingDetailsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sharingToken': json['sharing_token'],
    };
}

export function ProjectSharingDetailsRequestToJSON(json: any): ProjectSharingDetailsRequest {
    return ProjectSharingDetailsRequestToJSONTyped(json, false);
}

export function ProjectSharingDetailsRequestToJSONTyped(value?: ProjectSharingDetailsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sharing_token': value['sharingToken'],
    };
}

