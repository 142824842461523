/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Canvas settings for an user.
 * @export
 * @interface UserSettingsCanvasValueObject
 */
export interface UserSettingsCanvasValueObject {
    /**
     * 
     * @type {Date}
     * @memberof UserSettingsCanvasValueObject
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserSettingsCanvasValueObject
     */
    modificationDate?: Date;
    /**
     * Zoom factor to apply when zooming in or out.
     * @type {number}
     * @memberof UserSettingsCanvasValueObject
     */
    zoomFactor?: number;
}

/**
 * Check if a given object implements the UserSettingsCanvasValueObject interface.
 */
export function instanceOfUserSettingsCanvasValueObject(value: object): value is UserSettingsCanvasValueObject {
    return true;
}

export function UserSettingsCanvasValueObjectFromJSON(json: any): UserSettingsCanvasValueObject {
    return UserSettingsCanvasValueObjectFromJSONTyped(json, false);
}

export function UserSettingsCanvasValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsCanvasValueObject {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDate': json['creation_date'] == null ? undefined : (new Date(json['creation_date'])),
        'modificationDate': json['modification_date'] == null ? undefined : (new Date(json['modification_date'])),
        'zoomFactor': json['zoom_factor'] == null ? undefined : json['zoom_factor'],
    };
}

export function UserSettingsCanvasValueObjectToJSON(json: any): UserSettingsCanvasValueObject {
    return UserSettingsCanvasValueObjectToJSONTyped(json, false);
}

export function UserSettingsCanvasValueObjectToJSONTyped(value?: UserSettingsCanvasValueObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'creation_date': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'modification_date': value['modificationDate'] == null ? undefined : ((value['modificationDate']).toISOString()),
        'zoom_factor': value['zoomFactor'],
    };
}

