/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PluginStatus } from './PluginStatus';
import {
    PluginStatusFromJSON,
    PluginStatusFromJSONTyped,
    PluginStatusToJSON,
    PluginStatusToJSONTyped,
} from './PluginStatus';

/**
 * ProjectBundleStatusResponse is used to know the status of the plugin bundle of a project.
 * @export
 * @interface ProjectBundleStatusResponse
 */
export interface ProjectBundleStatusResponse {
    /**
     * 
     * @type {PluginStatus}
     * @memberof ProjectBundleStatusResponse
     */
    status: PluginStatus;
}



/**
 * Check if a given object implements the ProjectBundleStatusResponse interface.
 */
export function instanceOfProjectBundleStatusResponse(value: object): value is ProjectBundleStatusResponse {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ProjectBundleStatusResponseFromJSON(json: any): ProjectBundleStatusResponse {
    return ProjectBundleStatusResponseFromJSONTyped(json, false);
}

export function ProjectBundleStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBundleStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': PluginStatusFromJSON(json['status']),
    };
}

export function ProjectBundleStatusResponseToJSON(json: any): ProjectBundleStatusResponse {
    return ProjectBundleStatusResponseToJSONTyped(json, false);
}

export function ProjectBundleStatusResponseToJSONTyped(value?: ProjectBundleStatusResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': PluginStatusToJSON(value['status']),
    };
}

