/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * TaskStatus represent the state of a task
 * @export
 */
export const TaskStatus = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED',
    Unknown: 'UNKNOWN'
} as const;
export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


export function instanceOfTaskStatus(value: any): boolean {
    for (const key in TaskStatus) {
        if (Object.prototype.hasOwnProperty.call(TaskStatus, key)) {
            if (TaskStatus[key as keyof typeof TaskStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaskStatusFromJSON(json: any): TaskStatus {
    return TaskStatusFromJSONTyped(json, false);
}

export function TaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatus {
    return json as TaskStatus;
}

export function TaskStatusToJSON(value?: TaskStatus | null): any {
    return value as any;
}

export function TaskStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): TaskStatus {
    return value as TaskStatus;
}

