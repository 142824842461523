/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlgoClass
 */
export interface AlgoClass {
    /**
     * 
     * @type {Date}
     * @memberof AlgoClass
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AlgoClass
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AlgoClass
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AlgoClass
     */
    colorIndex: number;
}

/**
 * Check if a given object implements the AlgoClass interface.
 */
export function instanceOfAlgoClass(value: object): value is AlgoClass {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    return true;
}

export function AlgoClassFromJSON(json: any): AlgoClass {
    return AlgoClassFromJSONTyped(json, false);
}

export function AlgoClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlgoClass {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDate': json['creation_date'] == null ? undefined : (new Date(json['creation_date'])),
        'modificationDate': json['modification_date'] == null ? undefined : (new Date(json['modification_date'])),
        'name': json['name'],
        'colorIndex': json['color_index'],
    };
}

export function AlgoClassToJSON(json: any): AlgoClass {
    return AlgoClassToJSONTyped(json, false);
}

export function AlgoClassToJSONTyped(value?: AlgoClass | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'creation_date': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'modification_date': value['modificationDate'] == null ? undefined : ((value['modificationDate']).toISOString()),
        'name': value['name'],
        'color_index': value['colorIndex'],
    };
}

