/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a request for smart annotation.
 * @export
 * @interface ProjectImageSmartAnnotationRequest
 */
export interface ProjectImageSmartAnnotationRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectImageSmartAnnotationRequest
     */
    colorIndex: number;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ProjectImageSmartAnnotationRequest
     */
    points: Array<Array<string>>;
}

/**
 * Check if a given object implements the ProjectImageSmartAnnotationRequest interface.
 */
export function instanceOfProjectImageSmartAnnotationRequest(value: object): value is ProjectImageSmartAnnotationRequest {
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    return true;
}

export function ProjectImageSmartAnnotationRequestFromJSON(json: any): ProjectImageSmartAnnotationRequest {
    return ProjectImageSmartAnnotationRequestFromJSONTyped(json, false);
}

export function ProjectImageSmartAnnotationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectImageSmartAnnotationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'colorIndex': json['color_index'],
        'points': json['points'],
    };
}

export function ProjectImageSmartAnnotationRequestToJSON(json: any): ProjectImageSmartAnnotationRequest {
    return ProjectImageSmartAnnotationRequestToJSONTyped(json, false);
}

export function ProjectImageSmartAnnotationRequestToJSONTyped(value?: ProjectImageSmartAnnotationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'color_index': value['colorIndex'],
        'points': value['points'],
    };
}

