/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MetadataAnnotationType = {
    Polygon: 'METADATA_ANNOTATION_POLYGON',
    Rectangle: 'METADATA_ANNOTATION_RECTANGLE',
    Ellipse: 'METADATA_ANNOTATION_ELLIPSE',
    Circle: 'METADATA_ANNOTATION_CIRCLE',
    Arrow: 'METADATA_ANNOTATION_ARROW',
    Line: 'METADATA_ANNOTATION_LINE',
    Text: 'METADATA_ANNOTATION_TEXT'
} as const;
export type MetadataAnnotationType = typeof MetadataAnnotationType[keyof typeof MetadataAnnotationType];


export function instanceOfMetadataAnnotationType(value: any): boolean {
    for (const key in MetadataAnnotationType) {
        if (Object.prototype.hasOwnProperty.call(MetadataAnnotationType, key)) {
            if (MetadataAnnotationType[key as keyof typeof MetadataAnnotationType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MetadataAnnotationTypeFromJSON(json: any): MetadataAnnotationType {
    return MetadataAnnotationTypeFromJSONTyped(json, false);
}

export function MetadataAnnotationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAnnotationType {
    return json as MetadataAnnotationType;
}

export function MetadataAnnotationTypeToJSON(value?: MetadataAnnotationType | null): any {
    return value as any;
}

export function MetadataAnnotationTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): MetadataAnnotationType {
    return value as MetadataAnnotationType;
}

