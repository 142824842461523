/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
    GeometryToJSONTyped,
} from './Geometry';
import type { DirectMeasureType } from './DirectMeasureType';
import {
    DirectMeasureTypeFromJSON,
    DirectMeasureTypeFromJSONTyped,
    DirectMeasureTypeToJSON,
    DirectMeasureTypeToJSONTyped,
} from './DirectMeasureType';
import type { GeometryProperties } from './GeometryProperties';
import {
    GeometryPropertiesFromJSON,
    GeometryPropertiesFromJSONTyped,
    GeometryPropertiesToJSON,
    GeometryPropertiesToJSONTyped,
} from './GeometryProperties';

/**
 * Direct Measure with analytic stats
 * @export
 * @interface DirectMeasureWithStats
 */
export interface DirectMeasureWithStats {
    /**
     * 
     * @type {string}
     * @memberof DirectMeasureWithStats
     */
    directMeasureId: string;
    /**
     * 
     * @type {DirectMeasureType}
     * @memberof DirectMeasureWithStats
     */
    type: DirectMeasureType;
    /**
     * 
     * @type {Geometry}
     * @memberof DirectMeasureWithStats
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties}
     * @memberof DirectMeasureWithStats
     */
    geometryProperties: GeometryProperties;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    minX: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    maxX: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    area: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    length: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    angle: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    radius: number | null;
}



/**
 * Check if a given object implements the DirectMeasureWithStats interface.
 */
export function instanceOfDirectMeasureWithStats(value: object): value is DirectMeasureWithStats {
    if (!('directMeasureId' in value) || value['directMeasureId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    if (!('geometryProperties' in value) || value['geometryProperties'] === undefined) return false;
    if (!('minX' in value) || value['minX'] === undefined) return false;
    if (!('minY' in value) || value['minY'] === undefined) return false;
    if (!('maxX' in value) || value['maxX'] === undefined) return false;
    if (!('maxY' in value) || value['maxY'] === undefined) return false;
    if (!('area' in value) || value['area'] === undefined) return false;
    if (!('length' in value) || value['length'] === undefined) return false;
    if (!('angle' in value) || value['angle'] === undefined) return false;
    if (!('radius' in value) || value['radius'] === undefined) return false;
    return true;
}

export function DirectMeasureWithStatsFromJSON(json: any): DirectMeasureWithStats {
    return DirectMeasureWithStatsFromJSONTyped(json, false);
}

export function DirectMeasureWithStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureWithStats {
    if (json == null) {
        return json;
    }
    return {
        
        'directMeasureId': json['direct_measure_id'],
        'type': DirectMeasureTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': GeometryPropertiesFromJSON(json['geometry_properties']),
        'minX': json['min_x'],
        'minY': json['min_y'],
        'maxX': json['max_x'],
        'maxY': json['max_y'],
        'area': json['area'],
        'length': json['length'],
        'angle': json['angle'],
        'radius': json['radius'],
    };
}

export function DirectMeasureWithStatsToJSON(json: any): DirectMeasureWithStats {
    return DirectMeasureWithStatsToJSONTyped(json, false);
}

export function DirectMeasureWithStatsToJSONTyped(value?: DirectMeasureWithStats | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'direct_measure_id': value['directMeasureId'],
        'type': DirectMeasureTypeToJSON(value['type']),
        'geometry': GeometryToJSON(value['geometry']),
        'geometry_properties': GeometryPropertiesToJSON(value['geometryProperties']),
        'min_x': value['minX'],
        'min_y': value['minY'],
        'max_x': value['maxX'],
        'max_y': value['maxY'],
        'area': value['area'],
        'length': value['length'],
        'angle': value['angle'],
        'radius': value['radius'],
    };
}

