/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserFeedbackStatistics
 */
export interface UserFeedbackStatistics {
    /**
     * 
     * @type {number}
     * @memberof UserFeedbackStatistics
     */
    averageRating: number;
    /**
     * 
     * @type {number}
     * @memberof UserFeedbackStatistics
     */
    ratingCount: number;
}

/**
 * Check if a given object implements the UserFeedbackStatistics interface.
 */
export function instanceOfUserFeedbackStatistics(value: object): value is UserFeedbackStatistics {
    if (!('averageRating' in value) || value['averageRating'] === undefined) return false;
    if (!('ratingCount' in value) || value['ratingCount'] === undefined) return false;
    return true;
}

export function UserFeedbackStatisticsFromJSON(json: any): UserFeedbackStatistics {
    return UserFeedbackStatisticsFromJSONTyped(json, false);
}

export function UserFeedbackStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFeedbackStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'averageRating': json['average_rating'],
        'ratingCount': json['rating_count'],
    };
}

export function UserFeedbackStatisticsToJSON(json: any): UserFeedbackStatistics {
    return UserFeedbackStatisticsToJSONTyped(json, false);
}

export function UserFeedbackStatisticsToJSONTyped(value?: UserFeedbackStatistics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'average_rating': value['averageRating'],
        'rating_count': value['ratingCount'],
    };
}

