/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * AlgorithmCapabilities represent the capabilities of an algorithm
 * These capabilities are used to allow the user to customize the training
 * @export
 * @interface AlgorithmCapabilities
 */
export interface AlgorithmCapabilities {
    /**
     * If the algorithm uses postprocessing
     * @type {boolean}
     * @memberof AlgorithmCapabilities
     */
    hasPostprocessing?: boolean;
    /**
     * If the algorithm uses augmentation
     * @type {boolean}
     * @memberof AlgorithmCapabilities
     */
    hasAugmentation?: boolean;
    /**
     * If the algorithm has epoch progression
     * @type {boolean}
     * @memberof AlgorithmCapabilities
     */
    hasEpochProgression?: boolean;
}

/**
 * Check if a given object implements the AlgorithmCapabilities interface.
 */
export function instanceOfAlgorithmCapabilities(value: object): value is AlgorithmCapabilities {
    return true;
}

export function AlgorithmCapabilitiesFromJSON(json: any): AlgorithmCapabilities {
    return AlgorithmCapabilitiesFromJSONTyped(json, false);
}

export function AlgorithmCapabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlgorithmCapabilities {
    if (json == null) {
        return json;
    }
    return {
        
        'hasPostprocessing': json['has_postprocessing'] == null ? undefined : json['has_postprocessing'],
        'hasAugmentation': json['has_augmentation'] == null ? undefined : json['has_augmentation'],
        'hasEpochProgression': json['has_epoch_progression'] == null ? undefined : json['has_epoch_progression'],
    };
}

export function AlgorithmCapabilitiesToJSON(json: any): AlgorithmCapabilities {
    return AlgorithmCapabilitiesToJSONTyped(json, false);
}

export function AlgorithmCapabilitiesToJSONTyped(value?: AlgorithmCapabilities | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'has_postprocessing': value['hasPostprocessing'],
        'has_augmentation': value['hasAugmentation'],
        'has_epoch_progression': value['hasEpochProgression'],
    };
}

