/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ImageStatus } from './ImageStatus';
import {
    ImageStatusFromJSON,
    ImageStatusFromJSONTyped,
    ImageStatusToJSON,
    ImageStatusToJSONTyped,
} from './ImageStatus';

/**
 * Project Image Status
 * @export
 * @interface ProjectImagesStatus
 */
export interface ProjectImagesStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectImagesStatus
     */
    projectId: string;
    /**
     * 
     * @type {{ [key: string]: ImageStatus; }}
     * @memberof ProjectImagesStatus
     */
    images: { [key: string]: ImageStatus; };
}

/**
 * Check if a given object implements the ProjectImagesStatus interface.
 */
export function instanceOfProjectImagesStatus(value: object): value is ProjectImagesStatus {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('images' in value) || value['images'] === undefined) return false;
    return true;
}

export function ProjectImagesStatusFromJSON(json: any): ProjectImagesStatus {
    return ProjectImagesStatusFromJSONTyped(json, false);
}

export function ProjectImagesStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectImagesStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'images': (mapValues(json['images'], ImageStatusFromJSON)),
    };
}

export function ProjectImagesStatusToJSON(json: any): ProjectImagesStatus {
    return ProjectImagesStatusToJSONTyped(json, false);
}

export function ProjectImagesStatusToJSONTyped(value?: ProjectImagesStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_id': value['projectId'],
        'images': (mapValues(value['images'], ImageStatusToJSON)),
    };
}

