/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingTaskType } from './OnboardingTaskType';
import {
    OnboardingTaskTypeFromJSON,
    OnboardingTaskTypeFromJSONTyped,
    OnboardingTaskTypeToJSON,
    OnboardingTaskTypeToJSONTyped,
} from './OnboardingTaskType';

/**
 * 
 * @export
 * @interface CompleteUserOnboardingTaskRequest
 */
export interface CompleteUserOnboardingTaskRequest {
    /**
     * 
     * @type {OnboardingTaskType}
     * @memberof CompleteUserOnboardingTaskRequest
     */
    taskType: OnboardingTaskType;
}



/**
 * Check if a given object implements the CompleteUserOnboardingTaskRequest interface.
 */
export function instanceOfCompleteUserOnboardingTaskRequest(value: object): value is CompleteUserOnboardingTaskRequest {
    if (!('taskType' in value) || value['taskType'] === undefined) return false;
    return true;
}

export function CompleteUserOnboardingTaskRequestFromJSON(json: any): CompleteUserOnboardingTaskRequest {
    return CompleteUserOnboardingTaskRequestFromJSONTyped(json, false);
}

export function CompleteUserOnboardingTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteUserOnboardingTaskRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'taskType': OnboardingTaskTypeFromJSON(json['task_type']),
    };
}

export function CompleteUserOnboardingTaskRequestToJSON(json: any): CompleteUserOnboardingTaskRequest {
    return CompleteUserOnboardingTaskRequestToJSONTyped(json, false);
}

export function CompleteUserOnboardingTaskRequestToJSONTyped(value?: CompleteUserOnboardingTaskRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'task_type': OnboardingTaskTypeToJSON(value['taskType']),
    };
}

