/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LicenceFeaturesResponse
 */
export interface LicenceFeaturesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    exportProject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexNetLiteV1: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexNetLiteV2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexGrainsegV3: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexGrainsegV3Aluminum: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexMedcleanV1: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenceFeaturesResponse
     */
    theme: string;
}

/**
 * Check if a given object implements the LicenceFeaturesResponse interface.
 */
export function instanceOfLicenceFeaturesResponse(value: object): value is LicenceFeaturesResponse {
    if (!('exportProject' in value) || value['exportProject'] === undefined) return false;
    if (!('algoClemexNetLiteV1' in value) || value['algoClemexNetLiteV1'] === undefined) return false;
    if (!('algoClemexNetLiteV2' in value) || value['algoClemexNetLiteV2'] === undefined) return false;
    if (!('algoClemexGrainsegV3' in value) || value['algoClemexGrainsegV3'] === undefined) return false;
    if (!('algoClemexGrainsegV3Aluminum' in value) || value['algoClemexGrainsegV3Aluminum'] === undefined) return false;
    if (!('algoClemexMedcleanV1' in value) || value['algoClemexMedcleanV1'] === undefined) return false;
    if (!('theme' in value) || value['theme'] === undefined) return false;
    return true;
}

export function LicenceFeaturesResponseFromJSON(json: any): LicenceFeaturesResponse {
    return LicenceFeaturesResponseFromJSONTyped(json, false);
}

export function LicenceFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenceFeaturesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'exportProject': json['export_project'],
        'algoClemexNetLiteV1': json['algo_clemex_net_lite_v1'],
        'algoClemexNetLiteV2': json['algo_clemex_net_lite_v2'],
        'algoClemexGrainsegV3': json['algo_clemex_grainseg_v3'],
        'algoClemexGrainsegV3Aluminum': json['algo_clemex_grainseg_v3_aluminum'],
        'algoClemexMedcleanV1': json['algo_clemex_medclean_v1'],
        'theme': json['theme'],
    };
}

export function LicenceFeaturesResponseToJSON(json: any): LicenceFeaturesResponse {
    return LicenceFeaturesResponseToJSONTyped(json, false);
}

export function LicenceFeaturesResponseToJSONTyped(value?: LicenceFeaturesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'export_project': value['exportProject'],
        'algo_clemex_net_lite_v1': value['algoClemexNetLiteV1'],
        'algo_clemex_net_lite_v2': value['algoClemexNetLiteV2'],
        'algo_clemex_grainseg_v3': value['algoClemexGrainsegV3'],
        'algo_clemex_grainseg_v3_aluminum': value['algoClemexGrainsegV3Aluminum'],
        'algo_clemex_medclean_v1': value['algoClemexMedcleanV1'],
        'theme': value['theme'],
    };
}

