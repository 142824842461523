/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * DeepLearningTrainingParametersValueObject contains the parameters to train a deep learning algorithm
 * @export
 * @interface DeepLearningTrainingParametersValueObject
 */
export interface DeepLearningTrainingParametersValueObject {
    /**
     * The number of epochs to train the model.
     * @type {number}
     * @memberof DeepLearningTrainingParametersValueObject
     */
    epochs: number;
    /**
     * Whether to use data augmentation during training.
     * @type {boolean}
     * @memberof DeepLearningTrainingParametersValueObject
     */
    useAugmentation: boolean;
    /**
     * Whether to use postprocessing after a prediction.
     * @type {boolean}
     * @memberof DeepLearningTrainingParametersValueObject
     */
    usePostprocessing: boolean;
}

/**
 * Check if a given object implements the DeepLearningTrainingParametersValueObject interface.
 */
export function instanceOfDeepLearningTrainingParametersValueObject(value: object): value is DeepLearningTrainingParametersValueObject {
    if (!('epochs' in value) || value['epochs'] === undefined) return false;
    if (!('useAugmentation' in value) || value['useAugmentation'] === undefined) return false;
    if (!('usePostprocessing' in value) || value['usePostprocessing'] === undefined) return false;
    return true;
}

export function DeepLearningTrainingParametersValueObjectFromJSON(json: any): DeepLearningTrainingParametersValueObject {
    return DeepLearningTrainingParametersValueObjectFromJSONTyped(json, false);
}

export function DeepLearningTrainingParametersValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeepLearningTrainingParametersValueObject {
    if (json == null) {
        return json;
    }
    return {
        
        'epochs': json['epochs'],
        'useAugmentation': json['use_augmentation'],
        'usePostprocessing': json['use_postprocessing'],
    };
}

export function DeepLearningTrainingParametersValueObjectToJSON(json: any): DeepLearningTrainingParametersValueObject {
    return DeepLearningTrainingParametersValueObjectToJSONTyped(json, false);
}

export function DeepLearningTrainingParametersValueObjectToJSONTyped(value?: DeepLearningTrainingParametersValueObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'epochs': value['epochs'],
        'use_augmentation': value['useAugmentation'],
        'use_postprocessing': value['usePostprocessing'],
    };
}

