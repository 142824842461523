import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, notification, Input, Form, Space, message, Alert } from 'antd'
import { PROJECT_SHARING_LINK_EXPIRATION_DAYS } from '@app/constants'
import { useSharedProjectDetails } from '@app/api/hooks'
import * as RFAPI from '@app/api/api'
import styles from './styles/project-sharing-modal.module.scss'
import { CopyOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'

interface ProjectSharingModalProps {
  sharingUrl: string
  projectName: string
  isOpen: boolean
  onClose: () => void
}
// For projects that the user is sharing
export const ProjectSharingModal: React.FC<ProjectSharingModalProps> = ({ sharingUrl, projectName, isOpen, onClose }) => {
  const expirationDate = new Date()
  const [form] = Form.useForm()
  const [messageApi, messageContext] = message.useMessage()
  expirationDate.setDate(expirationDate.getDate() + PROJECT_SHARING_LINK_EXPIRATION_DAYS)
  return <Modal
    title={<FormattedMessage id='modal.project-sharing.title' defaultMessage='Project Sharing'/>}
    open={isOpen}
    onCancel={onClose}
    onOk={onClose}
    okButtonProps={ { className: styles.button }}
    cancelButtonProps={ { className: styles.hidden }}
    className={styles.modal}
  >
    { messageContext }
    <Space direction='vertical'>
      <FormattedMessage
        id='project.sharing.url.description3'
        defaultMessage={'Share your project "{projectName}" with this URL:'}
        values={{
          projectName,
        }}/>
      <Form form={form} layout='vertical' >
        <Form.Item name="sharingUrl" initialValue={sharingUrl} >
          <Input.Search type="url" enterButton={<CopyOutlined/>} onSearch={
            () => {
              navigator.clipboard.writeText(sharingUrl).then(function () {
                void messageApi.success(
                  <FormattedMessage id='project.sharing.link.copy.success' defaultMessage='Your project sharing URL was copied'/>,
                )
              }, function () {
                void messageApi.error(
                  <FormattedMessage id='project.sharing.link.copy.failure' defaultMessage='Your project sharing URL could not be copied'/>,
                )
              })
              const input: HTMLInputElement = document.getElementById('sharingUrl') as HTMLInputElement
              input.select()
            }
          }/>
        </Form.Item>

        <Alert
          description={
            <FormattedMessage
              id='project.sharing.link.validity.description'
              defaultMessage='To easily share this project, just send the URL to your recipient before: {sharingLinkExpirationDate}. This URL cannot be used by yourself.'
              values={{ sharingLinkExpirationDate: expirationDate.toDateString() }}/>
          }
          type="info"
          showIcon
        />
      </Form>

    </Space>
  </Modal>
}
interface SharedProjectModalProps {
  sharedProjectToken: string
  isOpen: boolean
  onClose: () => void
}
// For projects shared to the user
export const SharedProjectModal: React.FC<SharedProjectModalProps> = ({ sharedProjectToken, isOpen, onClose }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { data: sharedProjectDetails, isValidating: isSharedProjectDetailsValidating } = useSharedProjectDetails(sharedProjectToken)
  const [notificationApi, notificationContext] = notification.useNotification()
  const [isAcceptingSharedProject, setIsAcceptingSharedProject] = React.useState(false)

  const removeSharedProjectTokenFromUrl = (): void => {
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.delete('sharedProjectToken')
    window.history.replaceState({}, '', currentUrl)
  }
  const onCancel = (): void => {
    removeSharedProjectTokenFromUrl()
    onClose()
  }

  if (isSharedProjectDetailsValidating) {
    return <Modal
      title={<FormattedMessage id='modal.project-sharing.loading.title' defaultMessage='Project Sharing'/>}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
    >
      <FormattedMessage id='project.shared.loading.description' defaultMessage='Loading...' />
    </Modal>
  } else if (!sharedProjectDetails?.isValid && !sharedProjectDetails?.rejectAsSharedToHimself) {
    return <Modal
      title={<FormattedMessage id='modal.project-sharing.title' defaultMessage='Project Sharing'/>}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
    >
      <FormattedMessage
        id='project.shared.invalid.description'
        defaultMessage='The project you are trying to redeem is expired or does not exist. Contact the person that provided you with this URL'
      />
    </Modal>
  } else if (sharedProjectDetails.rejectAsSharedToHimself) {
    return <Modal
      title={<FormattedMessage id='modal.project-sharing.title' defaultMessage='Project Sharing'/>}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
    >
      <FormattedMessage
        id='project.shared.rejected-to-himself.description'
        defaultMessage='You cannot redeem a project shared by yourself. Please use the duplicate project option instead.'
      />
    </Modal>
  } else {
    return <Modal
      title={<FormattedMessage id='modal.project-sharing.title' defaultMessage='Project Sharing'/>}
      okText={<FormattedMessage id='modal.project.shared.accept.label' defaultMessage='Yes'/>}
      cancelText={<FormattedMessage id='modal.project.shared.refuse.label' defaultMessage='No'/>}
      cancelButtonProps={{ className: styles.button }}
      okButtonProps={{ className: styles.button }}
      onOk={async () => {
        try {
          setIsAcceptingSharedProject(true)
          void message.open({
            type: 'loading',
            content: intl.formatMessage({ id: 'project.sharing.redeem.loading', defaultMessage: 'Accepting shared project...' }),
          })
          const { slug } = await RFAPI.redeemProjectSharing(sharedProjectToken)
          void message.open({
            type: 'success',
            content: intl.formatMessage({ id: 'project.sharing.redeem.success', defaultMessage: 'Share project completed' }),
          })
          removeSharedProjectTokenFromUrl()
          onClose()
          navigate(WebUIRoutes.annotateProject(slug).path)
        } catch {
          notificationApi.error({
            message: <FormattedMessage id='project.sharing.redeem.error' defaultMessage='There was an error while redeeming the project. Please ask the person that provided you the link for a new link.'/>,
          })
        } finally {
          setIsAcceptingSharedProject(false)
          onClose()
        }
      }}
      onCancel={onCancel}
      open={isOpen && !isAcceptingSharedProject}
    >

      <FormattedMessage
        id='project.shared.valid.description'
        defaultMessage='{userEmail} wants to share project "{projectName}" with you. Click yes to accept and no to decline. If you click yes, this project will be added to your project list.'
        values={{
          projectName: sharedProjectDetails.details?.projectName,
          userEmail: <a href={`mailto:${sharedProjectDetails.details?.sharedBy ?? ''}`}>{ sharedProjectDetails.details?.sharedBy }</a>,
        }}/>
      { notificationContext }
    </Modal>
  }
}
