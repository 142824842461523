/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectResponse } from './ProjectResponse';
import {
    ProjectResponseFromJSON,
    ProjectResponseFromJSONTyped,
    ProjectResponseToJSON,
    ProjectResponseToJSONTyped,
} from './ProjectResponse';

/**
 * 
 * @export
 * @interface ListResponseProjectResponse
 */
export interface ListResponseProjectResponse {
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ListResponseProjectResponse
     */
    data: Array<ProjectResponse>;
}

/**
 * Check if a given object implements the ListResponseProjectResponse interface.
 */
export function instanceOfListResponseProjectResponse(value: object): value is ListResponseProjectResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ListResponseProjectResponseFromJSON(json: any): ListResponseProjectResponse {
    return ListResponseProjectResponseFromJSONTyped(json, false);
}

export function ListResponseProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListResponseProjectResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ProjectResponseFromJSON)),
    };
}

export function ListResponseProjectResponseToJSON(json: any): ListResponseProjectResponse {
    return ListResponseProjectResponseToJSONTyped(json, false);
}

export function ListResponseProjectResponseToJSONTyped(value?: ListResponseProjectResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ProjectResponseToJSON)),
    };
}

