/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateClassAnnotationPartRequest } from './UpdateClassAnnotationPartRequest';
import {
    UpdateClassAnnotationPartRequestFromJSON,
    UpdateClassAnnotationPartRequestFromJSONTyped,
    UpdateClassAnnotationPartRequestToJSON,
    UpdateClassAnnotationPartRequestToJSONTyped,
} from './UpdateClassAnnotationPartRequest';

/**
 * UpdateClassAnnotationsRequest is used to update the class annotations of a project.
 * 
 * The class annotations are represented as a list of UpdateProjectClassAnnotationPartRequest.
 * The project_id and image_id should be set in the path.
 * @export
 * @interface UpdateClassAnnotationsRequest
 */
export interface UpdateClassAnnotationsRequest {
    /**
     * 
     * @type {Array<UpdateClassAnnotationPartRequest>}
     * @memberof UpdateClassAnnotationsRequest
     */
    classAnnotations: Array<UpdateClassAnnotationPartRequest>;
}

/**
 * Check if a given object implements the UpdateClassAnnotationsRequest interface.
 */
export function instanceOfUpdateClassAnnotationsRequest(value: object): value is UpdateClassAnnotationsRequest {
    if (!('classAnnotations' in value) || value['classAnnotations'] === undefined) return false;
    return true;
}

export function UpdateClassAnnotationsRequestFromJSON(json: any): UpdateClassAnnotationsRequest {
    return UpdateClassAnnotationsRequestFromJSONTyped(json, false);
}

export function UpdateClassAnnotationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClassAnnotationsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'classAnnotations': ((json['class_annotations'] as Array<any>).map(UpdateClassAnnotationPartRequestFromJSON)),
    };
}

export function UpdateClassAnnotationsRequestToJSON(json: any): UpdateClassAnnotationsRequest {
    return UpdateClassAnnotationsRequestToJSONTyped(json, false);
}

export function UpdateClassAnnotationsRequestToJSONTyped(value?: UpdateClassAnnotationsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'class_annotations': ((value['classAnnotations'] as Array<any>).map(UpdateClassAnnotationPartRequestToJSON)),
    };
}

