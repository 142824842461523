/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdatePasswordRequest
 */
export interface UserUpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdatePasswordRequest
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatePasswordRequest
     */
    newPassword: string;
}

/**
 * Check if a given object implements the UserUpdatePasswordRequest interface.
 */
export function instanceOfUserUpdatePasswordRequest(value: object): value is UserUpdatePasswordRequest {
    if (!('oldPassword' in value) || value['oldPassword'] === undefined) return false;
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function UserUpdatePasswordRequestFromJSON(json: any): UserUpdatePasswordRequest {
    return UserUpdatePasswordRequestFromJSONTyped(json, false);
}

export function UserUpdatePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdatePasswordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'oldPassword': json['old_password'],
        'newPassword': json['new_password'],
    };
}

export function UserUpdatePasswordRequestToJSON(json: any): UserUpdatePasswordRequest {
    return UserUpdatePasswordRequestToJSONTyped(json, false);
}

export function UserUpdatePasswordRequestToJSONTyped(value?: UserUpdatePasswordRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'old_password': value['oldPassword'],
        'new_password': value['newPassword'],
    };
}

