/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserResetPasswordRequest
 */
export interface UserResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordRequest
     */
    newPassword: string;
}

/**
 * Check if a given object implements the UserResetPasswordRequest interface.
 */
export function instanceOfUserResetPasswordRequest(value: object): value is UserResetPasswordRequest {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function UserResetPasswordRequestFromJSON(json: any): UserResetPasswordRequest {
    return UserResetPasswordRequestFromJSONTyped(json, false);
}

export function UserResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResetPasswordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'newPassword': json['new_password'],
    };
}

export function UserResetPasswordRequestToJSON(json: any): UserResetPasswordRequest {
    return UserResetPasswordRequestToJSONTyped(json, false);
}

export function UserResetPasswordRequestToJSONTyped(value?: UserResetPasswordRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
        'new_password': value['newPassword'],
    };
}

