/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RectangleGeometryProperties
 */
export interface RectangleGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof RectangleGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {boolean}
     * @memberof RectangleGeometryProperties
     */
    filled: boolean;
    /**
     * 
     * @type {number}
     * @memberof RectangleGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the RectangleGeometryProperties interface.
 */
export function instanceOfRectangleGeometryProperties(value: object): value is RectangleGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('filled' in value) || value['filled'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function RectangleGeometryPropertiesFromJSON(json: any): RectangleGeometryProperties {
    return RectangleGeometryPropertiesFromJSONTyped(json, false);
}

export function RectangleGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RectangleGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'filled': json['filled'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function RectangleGeometryPropertiesToJSON(json: any): RectangleGeometryProperties {
    return RectangleGeometryPropertiesToJSONTyped(json, false);
}

export function RectangleGeometryPropertiesToJSONTyped(value?: RectangleGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'filled': value['filled'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

