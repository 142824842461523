import { type MessageFormatElement } from 'react-intl'

export const messagesEN:
| Record<string, string>
| Record<string, MessageFormatElement[]> = {
  "403.button": [
    {
      "type": 0,
      "value": "Back Home",
    },
  ],
  "403.title": [
    {
      "type": 0,
      "value": "Sorry, you are not authorized to access this page.",
    },
  ],
  "404.button": [
    {
      "type": 0,
      "value": "Back Home",
    },
  ],
  "404.title": [
    {
      "type": 0,
      "value": "Sorry, the page you visited does not exist.",
    },
  ],
  "500.button": [
    {
      "type": 0,
      "value": "Back Home",
    },
  ],
  "500.title": [
    {
      "type": 0,
      "value": "We're sorry! Something went wrong.",
    },
  ],
  "activate-account.form.ActivateAccount.placeholder": [
    {
      "type": 0,
      "value": "Activate account Token",
    },
  ],
  "activate-account.form.ActivateAccount.submit": [
    {
      "type": 0,
      "value": "Activate",
    },
  ],
  "activate-account.form.error.required.token": [
    {
      "type": 0,
      "value": "Please input a valid token.",
    },
  ],
  "activate-account.notification.activate-account.error.body": [
    {
      "type": 0,
      "value": "The activation link might have expired. Try reset your password again.",
    },
  ],
  "activate-account.notification.activate-account.error.title": [
    {
      "type": 0,
      "value": "Failed to activate account",
    },
  ],
  "activate-account.notification.activate-account.failed": [
    {
      "type": 0,
      "value": "Failed to activate account",
    },
  ],
  "activate-account.notification.activate-account.success.body": [
    {
      "type": 0,
      "value": "Welcome to the Clemex's Studio",
    },
  ],
  "activate-account.notification.activate-account.success.title": [
    {
      "type": 0,
      "value": "Account activation was a success!",
    },
  ],
  "activate-account.title": [
    {
      "type": 0,
      "value": "Activate account",
    },
  ],
  "algorithm-id.clemex-grainseg-v3-aluminum.label": [
    {
      "type": 0,
      "value": "GrainSeg V3 Aluminum",
    },
  ],
  "algorithm-id.clemex-grainseg-v3.label": [
    {
      "type": 0,
      "value": "Universal GrainSeg V3",
    },
  ],
  "algorithm-id.clemex-medclean-v1.label": [
    {
      "type": 0,
      "value": "Medclean V1",
    },
  ],
  "algorithm-id.clemex-net-lite-v1.label": [
    {
      "type": 0,
      "value": "ClemexNet Lite V1",
    },
  ],
  "algorithm-id.clemex-net-lite-v2.label": [
    {
      "type": 0,
      "value": "ClemexNet Lite V2",
    },
  ],
  "algorithm-id.grainseg": [
    {
      "type": 0,
      "value": "Universal GrainSeg V3",
    },
  ],
  "algorithm-id.grainseg-aluminum": [
    {
      "type": 0,
      "value": "GrainSeg V3 Aluminum",
    },
  ],
  "algorithm-id.grainseg-medclean": [
    {
      "type": 0,
      "value": "Medclean V1",
    },
  ],
  "algorithm-id.random-forest.label": [
    {
      "type": 0,
      "value": "Random Forest",
    },
  ],
  "algorithm-notification.error.button": [
    {
      "type": 0,
      "value": "Contact us",
    },
  ],
  "algorithm-notification.error.mailto.template.body": [
    {
      "type": 0,
      "value": "Could you please help me troubleshoot my issue?",
    },
  ],
  "algorithm-notification.error.mailto.template.subject": [
    {
      "type": 0,
      "value": "I need some help [Project ",
    },
    {
      "type": 1,
      "value": "projectId",
    },
    {
      "type": 0,
      "value": "]",
    },
  ],
  "algorithm.selector.modal.confirm.button.accept": [
    {
      "type": 0,
      "value": "Apply",
    },
  ],
  "algorithm.selector.modal.confirm.content.grainseg": [
    {
      "type": 0,
      "value": "First class will be \"Grain\", second class will be \"Resin\", and third class will be \"Grain boundary\".",
    },
  ],
  "algorithm.selector.modal.confirm.content.main": [
    {
      "type": 0,
      "value": "The ",
    },
    {
      "type": 1,
      "value": "number",
    },
    {
      "type": 0,
      "value": " first classes will be renamed according to the requirements of the ",
    },
    {
      "type": 1,
      "value": "algorithm",
    },
    {
      "type": 0,
      "value": ".",
    },
  ],
  "algorithm.selector.modal.confirm.content.medclean": [
    {
      "type": 0,
      "value": "First class is \"Background\" and the second class is \"Particle\".",
    },
  ],
  "algorithm.selector.modal.confirm.title": [
    {
      "type": 0,
      "value": "Changing algorithm require to perform changes",
    },
  ],
  "algorithm.selector.modal.content.question": [
    {
      "type": 0,
      "value": "Would you like to continue and apply the changes?",
    },
  ],
  "authentication.title": [
    {
      "type": 0,
      "value": "Welcome to Clemex Studio",
    },
  ],
  "breadcrumb.page.home": [
    {
      "type": 0,
      "value": "Home",
    },
  ],
  "breadcrumb.page.licence.title": [
    {
      "type": 0,
      "value": "Licence",
    },
  ],
  "breadcrumb.page.profile.title": [
    {
      "type": 0,
      "value": "Profile",
    },
  ],
  "button.renew.plan.label": [
    {
      "type": 0,
      "value": "Renew plan",
    },
  ],
  "button.upgrade.plan.label": [
    {
      "type": 0,
      "value": "Upgrade plan",
    },
  ],
  "class-annotation-object-overview.class-annotation-id": [
    {
      "type": 0,
      "value": "#",
    },
  ],
  "class-annotation-object-overview.class-name": [
    {
      "type": 0,
      "value": "Class Name",
    },
  ],
  "class-annotation-object-overview.no-selected-class-annotation": [
    {
      "type": 0,
      "value": "No selected class annotation",
    },
  ],
  "class-editor.annotation.distribution.label": [
    {
      "type": 0,
      "value": "Annotation distribution",
    },
  ],
  "class-editor.class.tooltip.hide-class": [
    {
      "type": 0,
      "value": "Hide the annotation for this class",
    },
  ],
  "class-editor.class.tooltip.hide-prediction-class": [
    {
      "type": 0,
      "value": "Hide the prediction mask of this class on the image",
    },
  ],
  "class-editor.class.tooltip.more-options": [
    {
      "type": 0,
      "value": "More options",
    },
  ],
  "class-editor.class.tooltip.show-class": [
    {
      "type": 0,
      "value": "Show the annotation for this class",
    },
  ],
  "class-editor.class.tooltip.show-prediction-class": [
    {
      "type": 0,
      "value": "Show the prediction mask of this class on the image",
    },
  ],
  "class-editor.create-class.error.description": [
    {
      "type": 0,
      "value": "This may happen if the name given to the new class is not unique. Make sure none of the existing classes have a name that look like \"new class x\", then retry.",
    },
  ],
  "class-editor.create-class.error.title": [
    {
      "type": 0,
      "value": "Class Creation Failed",
    },
  ],
  "class-editor.delete-class.error.description": [
    {
      "type": 0,
      "value": "Please retry the operation",
    },
  ],
  "class-editor.delete-class.error.title": [
    {
      "type": 0,
      "value": "Class Deletion Failed",
    },
  ],
  "class-editor.distribution-tooltip": [
    {
      "type": 1,
      "value": "distributionStringToolTip",
    },
    {
      "type": 0,
      "value": "%",
    },
  ],
  "class-editor.edit-class.error.description": [
    {
      "type": 0,
      "value": "This may happen if the updated class name is not unique. Make sure there is no other class with the same name, then retry.",
    },
  ],
  "class-editor.edit-class.error.title": [
    {
      "type": 0,
      "value": "Class Edition Failed",
    },
  ],
  "class-editor.opacity.slider.label": [
    {
      "type": 0,
      "value": "Annotation opacity",
    },
  ],
  "class-editor.title": [
    {
      "type": 0,
      "value": "Classes",
    },
  ],
  "class-editor.tooltip.add-class": [
    {
      "type": 0,
      "value": "Add annotation class",
    },
  ],
  "class-editor.tooltip.clear-all-annotations": [
    {
      "type": 0,
      "value": "Clear all annotations",
    },
  ],
  "class-editor.tooltip.clear-all-annotations.modal.confirm.content": [
    {
      "type": 0,
      "value": "Clear all annotations on all images?",
    },
  ],
  "class-editor.tooltip.clear-all-annotations.modal.confirm.ok.text": [
    {
      "type": 0,
      "value": "Yes, I am sure",
    },
  ],
  "class-editor.tooltip.hide-annotations": [
    {
      "type": 0,
      "value": "Hide all annotations in the viewer",
    },
  ],
  "class-editor.tooltip.hide-predictions": [
    {
      "type": 0,
      "value": "Hide all predictions in the viewer",
    },
  ],
  "class-editor.tooltip.show-annotations": [
    {
      "type": 0,
      "value": "Show all annotations in the viewer",
    },
  ],
  "class-editor.tooltip.show-predictions": [
    {
      "type": 0,
      "value": "Show all predictions in the viewer",
    },
  ],
  "clemex-mosaic-canvas.message.line-1": [
    {
      "type": 0,
      "value": "Loading: The image is being tilled",
    },
  ],
  "clemex-mosaic-canvas.message.line-2": [
    {
      "type": 0,
      "value": "for compatibility with the editor.",
    },
  ],
  "color-palette.preset.recommended.modern.label": [
    {
      "type": 0,
      "value": "Modern",
    },
  ],
  "color-palette.preset.recommended.vision.label": [
    {
      "type": 0,
      "value": "Vision",
    },
  ],
  "contact-sales.content": [
    {
      "type": 0,
      "value": "To enable it, please contact the sales:",
    },
  ],
  "contact-sales.title": [
    {
      "type": 0,
      "value": "Downloading a plugin is a premium feature",
    },
  ],
  "contact_sales.mail.template.body": [
    {
      "type": 0,
      "value": "Hi, I'd like to activate the plugin download to the following project: ",
    },
    {
      "type": 1,
      "value": "url",
    },
    {
      "type": 0,
      "value": " Can you assist me with that?",
    },
  ],
  "contact_sales.mail.template.subject": [
    {
      "type": 0,
      "value": "Request access to a plugin download on Clemex Studio",
    },
  ],
  "create-password.form.error.passwordMustMatch": [
    {
      "type": 0,
      "value": "Passwords does not match",
    },
  ],
  "create-password.form.error.required.password": [
    {
      "type": 0,
      "value": "Please input a valid password.",
    },
  ],
  "create-password.form.error.required.token": [
    {
      "type": 0,
      "value": "Please input a valid token.",
    },
  ],
  "create-password.form.placeholder.password": [
    {
      "type": 0,
      "value": "New Password",
    },
  ],
  "create-password.form.placeholder.password.confirm": [
    {
      "type": 0,
      "value": "Confirm password",
    },
  ],
  "create-password.form.resetPassword.placeholder": [
    {
      "type": 0,
      "value": "Create Password Token",
    },
  ],
  "create-password.form.resetPassword.submit": [
    {
      "type": 0,
      "value": "Submit",
    },
  ],
  "create-password.form.tooltip.password": [
    {
      "type": 0,
      "value": "Password must contain at least 8 characters.",
    },
  ],
  "create-password.title": [
    {
      "type": 0,
      "value": "Create Password",
    },
  ],
  "databrowser.action.export.csv": [
    {
      "type": 0,
      "value": "Export to CSV",
    },
  ],
  "databrowser.cell.area.value": [
    {
      "type": 1,
      "value": "value",
    },
  ],
  "databrowser.cell.not-available": [
    {
      "type": 0,
      "value": "NA",
    },
  ],
  "databrowser.class-annotation.column.action-show-detected-object.cell.title": [
    {
      "type": 0,
      "value": "Thickness Measure",
    },
  ],
  "databrowser.class-annotation.column.action-show-detected-object.header.title": [
    {
      "type": 0,
      "value": "Actions",
    },
  ],
  "databrowser.class-annotation.column.area-hull.header.title.px": [
    {
      "type": 0,
      "value": "Hull Area (px²)",
    },
  ],
  "databrowser.class-annotation.column.area-hull.header.title.um": [
    {
      "type": 0,
      "value": "Hull Area (μm²)",
    },
  ],
  "databrowser.class-annotation.column.area-shell.header.title.px": [
    {
      "type": 0,
      "value": "Shell Area (px²)",
    },
  ],
  "databrowser.class-annotation.column.area-shell.header.title.um": [
    {
      "type": 0,
      "value": "Shell Area (μm²)",
    },
  ],
  "databrowser.class-annotation.column.area.header.title.px": [
    {
      "type": 0,
      "value": "Area (px²)",
    },
  ],
  "databrowser.class-annotation.column.area.header.title.um": [
    {
      "type": 0,
      "value": "Area (μm²)",
    },
  ],
  "databrowser.class-annotation.column.centroid-x.header.title.um": [
    {
      "type": 0,
      "value": "Centroid X",
    },
  ],
  "databrowser.class-annotation.column.centroid-y.header.title.um": [
    {
      "type": 0,
      "value": "Centroid Y",
    },
  ],
  "databrowser.class-annotation.column.className.header.title": [
    {
      "type": 0,
      "value": "Class Name",
    },
  ],
  "databrowser.class-annotation.column.height.header.title.px": [
    {
      "type": 0,
      "value": "Height (px)",
    },
  ],
  "databrowser.class-annotation.column.height.header.title.um": [
    {
      "type": 0,
      "value": "Height (μm)",
    },
  ],
  "databrowser.class-annotation.column.index.header.title": [
    {
      "type": 0,
      "value": "#",
    },
  ],
  "databrowser.class-annotation.column.max-Y.header.title.um": [
    {
      "type": 0,
      "value": "Max Y",
    },
  ],
  "databrowser.class-annotation.column.max-x.header.title.um": [
    {
      "type": 0,
      "value": "Max X",
    },
  ],
  "databrowser.class-annotation.column.min-x.header.title.um": [
    {
      "type": 0,
      "value": "Min X",
    },
  ],
  "databrowser.class-annotation.column.min-y.header.title.um": [
    {
      "type": 0,
      "value": "Min Y",
    },
  ],
  "databrowser.class-annotation.column.thumbnail.header.title": [
    {
      "type": 0,
      "value": "Thumbnail",
    },
  ],
  "databrowser.class-annotation.column.width.header.title.px": [
    {
      "type": 0,
      "value": "Width (px)",
    },
  ],
  "databrowser.class-annotation.column.width.header.title.um": [
    {
      "type": 0,
      "value": "Width (μm)",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.angle.value": [
    {
      "type": 0,
      "value": "Angle",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.arc.value": [
    {
      "type": 0,
      "value": "Arc",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.area.value": [
    {
      "type": 0,
      "value": "Area",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.circle.value": [
    {
      "type": 0,
      "value": "Radius",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.distance.value": [
    {
      "type": 0,
      "value": "Distance",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.ellipse.value": [
    {
      "type": 0,
      "value": "Ellipse",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.perimeter.value": [
    {
      "type": 0,
      "value": "Perimeter",
    },
  ],
  "databrowser.direct-measure.cell.direct-measure-type.rectangle.value": [
    {
      "type": 0,
      "value": "Rectangle",
    },
  ],
  "databrowser.direct-measure.column.angle.header.title.degree": [
    {
      "type": 0,
      "value": "Angle (°)",
    },
  ],
  "databrowser.direct-measure.column.angle.header.title.rad": [
    {
      "type": 0,
      "value": "Angle (rad)",
    },
  ],
  "databrowser.direct-measure.column.area.header.title.px": [
    {
      "type": 0,
      "value": "Area (px²)",
    },
  ],
  "databrowser.direct-measure.column.area.header.title.um": [
    {
      "type": 0,
      "value": "Area (μm²)",
    },
  ],
  "databrowser.direct-measure.column.direct-measure-type.header.title": [
    {
      "type": 0,
      "value": "Type",
    },
  ],
  "databrowser.direct-measure.column.id.header.title": [
    {
      "type": 0,
      "value": "#",
    },
  ],
  "databrowser.direct-measure.column.length.header.title.px": [
    {
      "type": 0,
      "value": "Length (px)",
    },
  ],
  "databrowser.direct-measure.column.length.header.title.um": [
    {
      "type": 0,
      "value": "Length (μm)",
    },
  ],
  "databrowser.direct-measure.column.radius.header.title.px": [
    {
      "type": 0,
      "value": "Radius (px)",
    },
  ],
  "databrowser.direct-measure.column.radius.header.title.um": [
    {
      "type": 0,
      "value": "Radius (μm)",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.arrow.value": [
    {
      "type": 0,
      "value": "Arrow",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.circle.value": [
    {
      "type": 0,
      "value": "Circle",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.ellipse.value": [
    {
      "type": 0,
      "value": "Ellipse",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.line.value": [
    {
      "type": 0,
      "value": "Line",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.polygon.value": [
    {
      "type": 0,
      "value": "Polygon",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.rectangle.value": [
    {
      "type": 0,
      "value": "Rectangle",
    },
  ],
  "databrowser.metadata-annotation.cell.metadata-annotation-type.text.value": [
    {
      "type": 0,
      "value": "Text",
    },
  ],
  "databrowser.metadata-annotation.column.metadata-annotation-type.header.title": [
    {
      "type": 0,
      "value": "Type",
    },
  ],
  "databrowser.metadata-annotation.column.text.header.title": [
    {
      "type": 0,
      "value": "Text",
    },
  ],
  "databrowser.prediction-detected-object.cell.action-focus.tooltip": [
    {
      "type": 0,
      "value": "Click to focus",
    },
  ],
  "databrowser.prediction-detected-object.column.action-show-detected-object.cell.title": [
    {
      "type": 0,
      "value": "Thickness Measure",
    },
  ],
  "databrowser.prediction-detected-object.column.action-show-detected-object.header.title": [
    {
      "type": 0,
      "value": "Actions",
    },
  ],
  "databrowser.prediction-detected-object.column.action.use-as-annotation.cell.title": [
    {
      "type": 0,
      "value": "Use as annotation",
    },
  ],
  "databrowser.prediction-detected-object.column.area-hull.header.title.px": [
    {
      "type": 0,
      "value": "Hull Area (px²)",
    },
  ],
  "databrowser.prediction-detected-object.column.area-hull.header.title.um": [
    {
      "type": 0,
      "value": "Hull Area (μm²)",
    },
  ],
  "databrowser.prediction-detected-object.column.area-shell.header.title.px": [
    {
      "type": 0,
      "value": "Shell Area (px²)",
    },
  ],
  "databrowser.prediction-detected-object.column.area-shell.header.title.um": [
    {
      "type": 0,
      "value": "Shell Area (μm²)",
    },
  ],
  "databrowser.prediction-detected-object.column.area.header.title.px": [
    {
      "type": 0,
      "value": "Area (px²)",
    },
  ],
  "databrowser.prediction-detected-object.column.area.header.title.um": [
    {
      "type": 0,
      "value": "Area (μm²)",
    },
  ],
  "databrowser.prediction-detected-object.column.centroid-x.header.title.um": [
    {
      "type": 0,
      "value": "Centroid X",
    },
  ],
  "databrowser.prediction-detected-object.column.centroid-y.header.title.um": [
    {
      "type": 0,
      "value": "Centroid Y",
    },
  ],
  "databrowser.prediction-detected-object.column.className.header.title": [
    {
      "type": 0,
      "value": "Class Name",
    },
  ],
  "databrowser.prediction-detected-object.column.height.header.title.px": [
    {
      "type": 0,
      "value": "Height (px)",
    },
  ],
  "databrowser.prediction-detected-object.column.height.header.title.um": [
    {
      "type": 0,
      "value": "Height (μm)",
    },
  ],
  "databrowser.prediction-detected-object.column.index.header.title": [
    {
      "type": 0,
      "value": "#",
    },
  ],
  "databrowser.prediction-detected-object.column.max-Y.header.title.um": [
    {
      "type": 0,
      "value": "Max Y",
    },
  ],
  "databrowser.prediction-detected-object.column.max-x.header.title.um": [
    {
      "type": 0,
      "value": "Max X",
    },
  ],
  "databrowser.prediction-detected-object.column.min-x.header.title.um": [
    {
      "type": 0,
      "value": "Min X",
    },
  ],
  "databrowser.prediction-detected-object.column.min-y.header.title.um": [
    {
      "type": 0,
      "value": "Min Y",
    },
  ],
  "databrowser.prediction-detected-object.column.thumbnail.header.title": [
    {
      "type": 0,
      "value": "Thumbnail",
    },
  ],
  "databrowser.prediction-detected-object.column.width.header.title.px": [
    {
      "type": 0,
      "value": "Width (px)",
    },
  ],
  "databrowser.prediction-detected-object.column.width.header.title.um": [
    {
      "type": 0,
      "value": "Width (μm)",
    },
  ],
  "databrowser.table.columns.visibility": [
    {
      "type": 0,
      "value": "Columns",
    },
  ],
  "databrowser.table.loading": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "databrowser.table.no-data": [
    {
      "type": 0,
      "value": "No data",
    },
  ],
  "databrowser.table.search": [
    {
      "type": 0,
      "value": "Search into all fields:",
    },
  ],
  "databrowser.table.summary.total-item": [
    {
      "type": 1,
      "value": "count",
    },
    {
      "type": 0,
      "value": " objects found",
    },
  ],
  "databrowser.table.type": [
    {
      "type": 0,
      "value": "Data Type:",
    },
  ],
  "databrowser.table.type.selection.class-annotation": [
    {
      "type": 0,
      "value": "Annotation",
    },
  ],
  "databrowser.table.type.selection.detected-object": [
    {
      "type": 0,
      "value": "Detected Object",
    },
  ],
  "databrowser.table.type.selection.direct-measure": [
    {
      "type": 0,
      "value": "Direct Measure",
    },
  ],
  "databrowser.table.type.selection.metadata-annotation": [
    {
      "type": 0,
      "value": "Documentation",
    },
  ],
  "desktop-disconnected.modal.content.licence.action": [
    {
      "type": 0,
      "value": "Please reopen the application to retry.",
    },
  ],
  "desktop-disconnected.modal.content.licence.failed": [
    {
      "type": 0,
      "value": "There was an unexpected issue and therefore Clemex Studio cannot be used.",
    },
  ],
  "detected-object-overview.class-annotation-id": [
    {
      "type": 0,
      "value": "#",
    },
  ],
  "detected-object-overview.class-name": [
    {
      "type": 0,
      "value": "Class Name",
    },
  ],
  "detected-object-overview.no-selected-class-annotation": [
    {
      "type": 0,
      "value": "No selected class annotation",
    },
  ],
  "disconnected.modal..mail.template.body": [
    {
      "type": 0,
      "value": "Could you help me with that ?",
    },
  ],
  "disconnected.modal.mail.template.subject": [
    {
      "type": 0,
      "value": "Clemex Studio local ",
    },
    {
      "type": 1,
      "value": "service",
    },
    {
      "type": 0,
      "value": " connection failed",
    },
  ],
  "download_plugin.error.mail.template.body": [
    {
      "type": 0,
      "value": "Could you please help me troubleshoot my issue?",
    },
  ],
  "download_plugin.error.mail.template.subject": [
    {
      "type": 0,
      "value": "I need some help [User name ",
    },
    {
      "type": 1,
      "value": "userName",
    },
    {
      "type": 0,
      "value": "]",
    },
  ],
  "download_plugin.isNotAvailable.title": [
    {
      "type": 0,
      "value": "Download Plugin",
    },
  ],
  "download_plugin.mail.template.subject": [
    {
      "type": 0,
      "value": "Request ",
    },
    {
      "type": 1,
      "value": "site",
    },
    {
      "type": 0,
      "value": " access to the plugin download feature for ",
    },
    {
      "type": 1,
      "value": "user",
    },
  ],
  "download_plugin.notification.download.fail": [
    {
      "type": 0,
      "value": "Plugin download failed",
    },
  ],
  "download_plugin.notification.download.info": [
    {
      "type": 0,
      "value": "Please contact clemex sales to unlock this feature",
    },
  ],
  "download_plugin.title": [
    {
      "type": 0,
      "value": "Download Plugin",
    },
  ],
  "download_plugin.tooltip.algorithmIsNotAvailable.description1": [
    {
      "type": 0,
      "value": "The selected algorithm is not available as a plugin yet.",
    },
  ],
  "download_plugin.tooltip.algorithmIsNotAvailable.description2": [
    {
      "type": 0,
      "value": "Please contact us if you have any questions:",
    },
  ],
  "download_plugin.tooltip.isNotAvailable.message": [
    {
      "type": 0,
      "value": "Please train your project before downloading the plugin.",
    },
  ],
  "drawing-parameters.brush.size.option.size1": [
    {
      "type": 0,
      "value": "XS",
    },
  ],
  "drawing-parameters.brush.size.option.size2": [
    {
      "type": 0,
      "value": "S",
    },
  ],
  "drawing-parameters.brush.size.option.size3": [
    {
      "type": 0,
      "value": "M",
    },
  ],
  "drawing-parameters.brush.size.option.size4": [
    {
      "type": 0,
      "value": "L",
    },
  ],
  "drawing-parameters.brush.size.option.size5": [
    {
      "type": 0,
      "value": "XL",
    },
  ],
  "drawing-parameters.brush.size.option.size6": [
    {
      "type": 0,
      "value": "XXL",
    },
  ],
  "drawing-parameters.brush.size.slider.label": [
    {
      "type": 0,
      "value": "Brush size",
    },
  ],
  "edit=.menu.help.shortcuts.shortcut": [
    {
      "type": 0,
      "value": "(?)",
    },
  ],
  "edit=.menu.projects.create.shortcut": [
    {
      "type": 0,
      "value": "(Shift+N)",
    },
  ],
  "edit=.menu.projects.upload-image.shortcut": [
    {
      "type": 0,
      "value": "(Shift+I)",
    },
  ],
  "edit=.menu.zoom.fit": [
    {
      "type": 0,
      "value": "Set the zoom level to fit the image in the available space.",
    },
  ],
  "edit=.menu.zoom.fit.shortcuts": [
    {
      "type": 0,
      "value": "(",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+0)",
    },
  ],
  "edit=.menu.zoom.in": [
    {
      "type": 0,
      "value": "Zoom in",
    },
  ],
  "edit=.menu.zoom.in.shortcuts": [
    {
      "type": 0,
      "value": "(",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+=)",
    },
  ],
  "edit=.menu.zoom.out": [
    {
      "type": 0,
      "value": "Zoom out",
    },
  ],
  "edit=.menu.zoom.out.shortcuts": [
    {
      "type": 0,
      "value": "(",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+-)",
    },
  ],
  "editor-page.draggable-panel.databrowser.title": [
    {
      "type": 0,
      "value": "Data Browser",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.area.px": [
    {
      "type": 0,
      "value": "Area (px²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.area.um": [
    {
      "type": 0,
      "value": "Area (μm²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.bbox.max_x": [
    {
      "type": 0,
      "value": "Max X",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.bbox.max_y": [
    {
      "type": 0,
      "value": "Max Y",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.bbox.min_x": [
    {
      "type": 0,
      "value": "Min X",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.bbox.min_y": [
    {
      "type": 0,
      "value": "Min Y",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.centroid.x": [
    {
      "type": 0,
      "value": "Centroid X",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.centroid.y": [
    {
      "type": 0,
      "value": "Centroid Y",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.height.px": [
    {
      "type": 0,
      "value": "Height (px)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.height.um": [
    {
      "type": 0,
      "value": "Height (μm)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.hull-area.px": [
    {
      "type": 0,
      "value": "Hull Area (px²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.hull-area.um": [
    {
      "type": 0,
      "value": "Hull Area (μm²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.measure.edit": [
    {
      "type": 0,
      "value": "Thickness measure",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.measure.loading": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.measure.title": [
    {
      "type": 0,
      "value": "Measure",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.perimeter.px": [
    {
      "type": 0,
      "value": "Perimeter (px)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.perimeter.um": [
    {
      "type": 0,
      "value": "Perimeter (μm)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.shell-area.px": [
    {
      "type": 0,
      "value": "Shell Area (px²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.shell-area.um": [
    {
      "type": 0,
      "value": "Shell Area (μm²)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.thickness_measure.max": [
    {
      "type": 0,
      "value": "Thickness max",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.thickness_measure.mean": [
    {
      "type": 0,
      "value": "Thickness mean",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.thickness_measure.median": [
    {
      "type": 0,
      "value": "Thickness median",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.thickness_measure.min": [
    {
      "type": 0,
      "value": "Thickness min",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.width.px": [
    {
      "type": 0,
      "value": "Width (px)",
    },
  ],
  "editor-page.object-overview.class-annotation-object-overview.width.um": [
    {
      "type": 0,
      "value": "Width (μm)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.area.px": [
    {
      "type": 0,
      "value": "Area (px²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.area.um": [
    {
      "type": 0,
      "value": "Area (μm²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.bbox.max_x": [
    {
      "type": 0,
      "value": "Max X",
    },
  ],
  "editor-page.object-overview.detected-object-overview.bbox.max_y": [
    {
      "type": 0,
      "value": "Max Y",
    },
  ],
  "editor-page.object-overview.detected-object-overview.bbox.min_x": [
    {
      "type": 0,
      "value": "Min X",
    },
  ],
  "editor-page.object-overview.detected-object-overview.bbox.min_y": [
    {
      "type": 0,
      "value": "Min Y",
    },
  ],
  "editor-page.object-overview.detected-object-overview.centroid.x": [
    {
      "type": 0,
      "value": "Centroid X",
    },
  ],
  "editor-page.object-overview.detected-object-overview.centroid.y": [
    {
      "type": 0,
      "value": "Centroid Y",
    },
  ],
  "editor-page.object-overview.detected-object-overview.height.px": [
    {
      "type": 0,
      "value": "Height (px)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.height.um": [
    {
      "type": 0,
      "value": "Height (μm)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.hull-area.px": [
    {
      "type": 0,
      "value": "Hull Area (px²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.hull-area.um": [
    {
      "type": 0,
      "value": "Hull Area (μm²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.measure.edit": [
    {
      "type": 0,
      "value": "Thickness measure",
    },
  ],
  "editor-page.object-overview.detected-object-overview.measure.title": [
    {
      "type": 0,
      "value": "Measure",
    },
  ],
  "editor-page.object-overview.detected-object-overview.shell-area.px": [
    {
      "type": 0,
      "value": "Shell Area (px²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.shell-area.um": [
    {
      "type": 0,
      "value": "Shell Area (μm²)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.width.px": [
    {
      "type": 0,
      "value": "Width (px)",
    },
  ],
  "editor-page.object-overview.detected-object-overview.width.um": [
    {
      "type": 0,
      "value": "Width (μm)",
    },
  ],
  "editor.add-image.dimensions.notice": [
    {
      "type": 0,
      "value": "The minimal dimension supported is ",
    },
    {
      "type": 1,
      "value": "minDimension",
    },
    {
      "type": 0,
      "value": "x",
    },
    {
      "type": 1,
      "value": "minDimension",
    },
    {
      "type": 0,
      "value": " pixels",
    },
  ],
  "editor.add-image.dropzone.label": [
    {
      "type": 0,
      "value": "Release here to upload images",
    },
  ],
  "editor.algorithm.button.cancel": [
    {
      "type": 0,
      "value": "Cancel",
    },
  ],
  "editor.algorithm.button.cancelling": [
    {
      "type": 0,
      "value": "Cancelling",
    },
  ],
  "editor.algorithm.button.predicting.title": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "Predicting image",
            },
          ],
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Predicting images",
            },
          ],
        },
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "totalImages",
    },
    {
      "type": 0,
      "value": ": ",
    },
    {
      "type": 1,
      "value": "numberOfPendingPrediction",
    },
    {
      "type": 0,
      "value": "/",
    },
    {
      "type": 1,
      "value": "totalImages",
    },
  ],
  "editor.algorithm.button.predicting.tooltip": [
    {
      "type": 0,
      "value": "Predicting the images by using the model on each project image.",
    },
  ],
  "editor.algorithm.button.train": [
    {
      "type": 0,
      "value": "Train",
    },
  ],
  "editor.algorithm.button.train.tooltip.disable": [
    {
      "type": 0,
      "value": "You need at least 2 annotations of different class to start training.",
    },
  ],
  "editor.algorithm.button.train.tooltip.isProjectTrainingSnapshotPristine": [
    {
      "type": 0,
      "value": "You're all set! The system is already trained with your latest annotations. To train again, add or remove annotation and click on the Train button.",
    },
  ],
  "editor.algorithm.button.train.tooltip.training.pending": [
    {
      "type": 0,
      "value": "Your train request has been accepted. Waiting for the algorithm to be available.",
    },
  ],
  "editor.algorithm.button.train.tooltip.training.running": [
    {
      "type": 0,
      "value": "The training is running, waiting for the model to be created.",
    },
  ],
  "editor.algorithm.button.training": [
    {
      "type": 0,
      "value": "Training",
    },
  ],
  "editor.algorithm.button.training-epochs": [
    {
      "type": 0,
      "value": "Epochs ",
    },
    {
      "type": 1,
      "value": "currentEpoch",
    },
    {
      "type": 0,
      "value": "/",
    },
    {
      "type": 1,
      "value": "totalEpochs",
    },
  ],
  "editor.classes.item.clear.annotations.confirm.content": [
    {
      "type": 0,
      "value": "Yes, I am sure",
    },
  ],
  "editor.classes.item.clear.annotations.confirm.title": [
    {
      "type": 0,
      "value": "Clear \"",
    },
    {
      "type": 1,
      "value": "className",
    },
    {
      "type": 0,
      "value": "\" annotations on all images?",
    },
  ],
  "editor.classes.item.delete": [
    {
      "type": 0,
      "value": "Delete class",
    },
  ],
  "editor.classes.item.delete.confirm.content": [
    {
      "type": 0,
      "value": "Yes, I am sure",
    },
  ],
  "editor.classes.item.delete.confirm.title": [
    {
      "type": 0,
      "value": "Delete \"",
    },
    {
      "type": 1,
      "value": "className",
    },
    {
      "type": 0,
      "value": "\" class?",
    },
  ],
  "editor.classes.item.edit.mandatory-class": [
    {
      "type": 0,
      "value": "This class is required by the algorithm",
    },
  ],
  "editor.classes.item.edit.name": [
    {
      "type": 0,
      "value": "Edit class name",
    },
  ],
  "editor.classes.item.items.clear.annotations": [
    {
      "type": 0,
      "value": "Clear annotations",
    },
  ],
  "editor.edit.select.class.1.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.1.shortcut": [
    {
      "type": 0,
      "value": "(1)",
    },
  ],
  "editor.edit.select.class.2.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.2.shortcut": [
    {
      "type": 0,
      "value": "(2)",
    },
  ],
  "editor.edit.select.class.3.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.3.shortcut": [
    {
      "type": 0,
      "value": "(3)",
    },
  ],
  "editor.edit.select.class.4.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.4.shortcut": [
    {
      "type": 0,
      "value": "(4)",
    },
  ],
  "editor.edit.select.class.5.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.5.shortcut": [
    {
      "type": 0,
      "value": "(5)",
    },
  ],
  "editor.edit.select.class.6.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.6.shortcut": [
    {
      "type": 0,
      "value": "(6)",
    },
  ],
  "editor.edit.select.class.7.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.7.shortcut": [
    {
      "type": 0,
      "value": "(7)",
    },
  ],
  "editor.edit.select.class.8.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.8.shortcut": [
    {
      "type": 0,
      "value": "(8)",
    },
  ],
  "editor.edit.select.class.9.description": [
    {
      "type": 0,
      "value": "Select the first class",
    },
  ],
  "editor.edit.select.class.9.shortcut": [
    {
      "type": 0,
      "value": "(9)",
    },
  ],
  "editor.edit.select.tool.brush.description": [
    {
      "type": 0,
      "value": "Select the brush tool",
    },
  ],
  "editor.edit.select.tool.direct-measure.angle.description": [
    {
      "type": 0,
      "value": "Select the angle direct measure tool",
    },
  ],
  "editor.edit.select.tool.direct-measure.angle.shortcut": [
    {
      "type": 0,
      "value": "(v)",
    },
  ],
  "editor.edit.select.tool.direct-measure.distance.description": [
    {
      "type": 0,
      "value": "Select the distance direct measure tool",
    },
  ],
  "editor.edit.select.tool.direct-measure.distance.shortcut": [
    {
      "type": 0,
      "value": "(m)",
    },
  ],
  "editor.edit.select.tool.eraser.description": [
    {
      "type": 0,
      "value": "Select the eraser tool",
    },
  ],
  "editor.edit.select.tool.selection.delete.description": [
    {
      "type": 0,
      "value": "Delete the selected annotations",
    },
  ],
  "editor.edit.select.tool.selection.delete.shortcut": [
    {
      "type": 0,
      "value": "(suppr.)",
    },
  ],
  "editor.edit.select.tool.selection.select.description": [
    {
      "type": 0,
      "value": "Select the selection tool",
    },
  ],
  "editor.edit.select.tool.selection.select.shortcut": [
    {
      "type": 0,
      "value": "(s)",
    },
  ],
  "editor.edit.tool.brush.shortcut": [
    {
      "type": 0,
      "value": "(b)",
    },
  ],
  "editor.edit.tool.eraser.shortcut": [
    {
      "type": 0,
      "value": "(Shift and Drag)",
    },
  ],
  "editor.edit.tool.pan.shortcut": [
    {
      "type": 0,
      "value": "(Space Bar and Drag)",
    },
  ],
  "editor.edit.tool.redo.shortcut": [
    {
      "type": 0,
      "value": "(",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+Shift+Z)",
    },
  ],
  "editor.edit.tool.select.eraser-drag.shortcut": [
    {
      "type": 0,
      "value": "(e)",
    },
  ],
  "editor.edit.tool.select.pan.description": [
    {
      "type": 0,
      "value": "Select the panning tool",
    },
  ],
  "editor.edit.tool.select.pan.shortcut": [
    {
      "type": 0,
      "value": "(p)",
    },
  ],
  "editor.edit.tool.select.smart-annotation.magic-wand.on.description": [
    {
      "type": 0,
      "value": "Select the Magic Wand",
    },
  ],
  "editor.edit.tool.select.smart-annotation.magic-wand.on.shortcut": [
    {
      "type": 0,
      "value": "(w)",
    },
  ],
  "editor.edit.tool.undo.shortcut": [
    {
      "type": 0,
      "value": "(",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+Z)",
    },
  ],
  "editor.left-panel.title": [
    {
      "type": 0,
      "value": "Images",
    },
  ],
  "editor.menu.projects.export.shortcut": [
    {
      "type": 0,
      "value": "(Shift+E)",
    },
  ],
  "editor.notification.left.panel.display.error.body": [
    {
      "type": 0,
      "value": "Please reload the page.",
    },
  ],
  "editor.notification.left.panel.upload.error.title": [
    {
      "type": 0,
      "value": "Something went wrong.",
    },
  ],
  "editor.right-panel-plugin-title": [
    {
      "type": 0,
      "value": "What is a plugin ?",
    },
  ],
  "editor.status.pixelSize": [
    {
      "type": 0,
      "value": "Pixel Size",
    },
  ],
  "editor.status.pixelSize.error": [
    {
      "type": 0,
      "value": "Please input a valid pixel size. No text allowed. The pixel size must be bigger than 0.",
    },
  ],
  "editor.status.pixelSize.input.placeholder_empty": [
    {
      "type": 0,
      "value": "N/A",
    },
  ],
  "editor.status.pixelSize.unit.um-per-px": [
    {
      "type": 0,
      "value": "µm/px",
    },
  ],
  "editor.status.zoom": [
    {
      "type": 0,
      "value": "Zoom",
    },
  ],
  "editor.uploading.label": [
    {
      "type": 1,
      "value": "nbImage",
    },
    {
      "type": 0,
      "value": " image(s) are uploading ...",
    },
  ],
  "export-settings.error.mail.template.body": [
    {
      "type": 0,
      "value": "Hi, I could not export my project in Clemex Studio. Can you assist me with this?",
    },
  ],
  "export-settings.error.mail.template.subject": [
    {
      "type": 0,
      "value": "Project Export error in Clemex Studio",
    },
  ],
  "export-settings.notification.error.title": [
    {
      "type": 0,
      "value": "An unknown error occurred while exporting the project. Please try again or contact us at: ",
    },
    {
      "type": 1,
      "value": "salesEmail",
    },
  ],
  "export.options.combine-masks.label": [
    {
      "type": 0,
      "value": "Combine masks in a single colorized file",
    },
  ],
  "footer.signature.text": [
    {
      "type": 0,
      "value": "Clemex Technologies ©2021 Created by ClemexAI!",
    },
  ],
  "global-settings.modal.section.editor.canvas.reset": [
    {
      "type": 0,
      "value": "Reset to default canvas settings",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar": [
    {
      "type": 0,
      "value": "Scale bar",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.anchor": [
    {
      "type": 0,
      "value": "Anchor",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.anchor.bottom.left": [
    {
      "type": 0,
      "value": "Bottom Left",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.anchor.bottom.right": [
    {
      "type": 0,
      "value": "Bottom Right",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.anchor.top.left": [
    {
      "type": 0,
      "value": "Top Left",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.anchor.top.right": [
    {
      "type": 0,
      "value": "Top Right",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.color": [
    {
      "type": 0,
      "value": "Bar color",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.size": [
    {
      "type": 0,
      "value": "Bar Size",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.text.position": [
    {
      "type": 0,
      "value": "Text Position",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.text.position.bottom": [
    {
      "type": 0,
      "value": "Bottom",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.text.position.middle": [
    {
      "type": 0,
      "value": "Middle",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.text.position.top": [
    {
      "type": 0,
      "value": "Top",
    },
  ],
  "global-settings.modal.section.editor.canvas.scale-bar.thickness": [
    {
      "type": 0,
      "value": "Bar Thickness",
    },
  ],
  "global-settings.modal.section.editor.canvas.zoom-factor": [
    {
      "type": 0,
      "value": "Zoom Speed",
    },
  ],
  "global-settings.modal.section.editor.canvas.zoom-factor.slider.max": [
    {
      "type": 0,
      "value": "Fast",
    },
  ],
  "global-settings.modal.section.editor.canvas.zoom-factor.slider.min": [
    {
      "type": 0,
      "value": "Slow",
    },
  ],
  "global-settings.modal.section.editor.section.canvas": [
    {
      "type": 0,
      "value": "Canvas",
    },
  ],
  "global-settings.modal.tab.editor.loading": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "global-settings.modal.tab.editor.title": [
    {
      "type": 0,
      "value": "Editor",
    },
  ],
  "global-settings.modal.title": [
    {
      "type": 0,
      "value": "Global Settings",
    },
  ],
  "header.logo.clemex": [
    {
      "type": 0,
      "value": "Clemex Studio",
    },
  ],
  "header.profile.logout": [
    {
      "type": 0,
      "value": "Logout",
    },
  ],
  "header.rf.workflow.steps.step.annotate": [
    {
      "type": 0,
      "value": "Annotate",
    },
  ],
  "header.rf.workflow.steps.step.validate": [
    {
      "type": 0,
      "value": "Validate",
    },
  ],
  "header.tour.logout.content": [
    {
      "type": 0,
      "value": "The logout button has moved to the bottom of the navigation menu.",
    },
  ],
  "header.tour.logout.title": [
    {
      "type": 0,
      "value": "Logout",
    },
  ],
  "header.tour.new-menu.content": [
    {
      "type": 0,
      "value": "The menu will change based on the options available on each page.",
    },
  ],
  "header.tour.new-menu.title": [
    {
      "type": 0,
      "value": "Studio Menu",
    },
  ],
  "header.user-menu.sub-menu.onboarding.pop-confirm-title": [
    {
      "type": 0,
      "value": "You can replay the tutorial using this button",
    },
  ],
  "help.shortcuts.list.description": [
    {
      "type": 0,
      "value": "List shortcuts",
    },
  ],
  "image-filter.contrast.title": [
    {
      "type": 0,
      "value": "Contrast",
    },
  ],
  "image-filter.exposure.title": [
    {
      "type": 0,
      "value": "Exposure",
    },
  ],
  "image-filter.gamma.title": [
    {
      "type": 0,
      "value": "Gamma",
    },
  ],
  "image-filter.reset": [
    {
      "type": 0,
      "value": "Reset",
    },
  ],
  "image-filter.saturation.title": [
    {
      "type": 0,
      "value": "Saturation",
    },
  ],
  "image-filter.title": [
    {
      "type": 0,
      "value": "Image filters",
    },
  ],
  "image-prediction-status.tooltip.status.is-failed": [
    {
      "type": 0,
      "value": "Prediction failed",
    },
  ],
  "image-prediction-status.tooltip.status.is-prediction": [
    {
      "type": 0,
      "value": "Predicting...",
    },
  ],
  "image-thumbnail.no-image.text": [
    {
      "type": 0,
      "value": "No image",
    },
  ],
  "image.navigation.left.shortcut": [
    {
      "type": 0,
      "value": "(Left Arrow)",
    },
  ],
  "image.navigation.right.shortcut": [
    {
      "type": 0,
      "value": "(Right Arrow)",
    },
  ],
  "label.authentication.provider.choice": [
    {
      "type": 0,
      "value": "or",
    },
  ],
  "label.registration.top": [
    {
      "type": 0,
      "value": "Automate your workflow in minutes!",
    },
  ],
  "left-panel.images.button.add": [
    {
      "type": 0,
      "value": "Upload images",
    },
  ],
  "left-panel.images.image-info.image-name": [
    {
      "type": 1,
      "value": "imageName",
    },
  ],
  "left-panel.images.image-info.image-size": [
    {
      "type": 1,
      "value": "width",
    },
    {
      "type": 0,
      "value": "x",
    },
    {
      "type": 1,
      "value": "height",
    },
    {
      "type": 0,
      "value": "px",
    },
  ],
  "left-panel.images.image-info.title": [
    {
      "type": 0,
      "value": "Image #",
    },
    {
      "type": 1,
      "value": "imageIndex",
    },
  ],
  "licence.acquire.description": [
    {
      "type": 0,
      "value": "In order to acquire a licence please contact Clemex sales department at",
    },
  ],
  "licence.expiration.on": [
    {
      "type": 0,
      "value": "Expires on ",
    },
    {
      "type": 1,
      "value": "expirationDate",
    },
  ],
  "licence.expired.since": [
    {
      "type": 0,
      "value": "Expired since ",
    },
    {
      "type": 1,
      "value": "expirationDate",
    },
  ],
  "licence.free.description": [
    {
      "type": 0,
      "value": "A free licence means you do not have a licence. You can use the platform, but you cannot download a plugin.",
    },
  ],
  "licence.key.title": [
    {
      "type": 0,
      "value": "Licence Key:",
    },
  ],
  "licence.premium.description": [
    {
      "type": 0,
      "value": "A premium licence allows to download a plugin that never expires.",
    },
  ],
  "licence.trial.description": [
    {
      "type": 0,
      "value": "A trial licence means you can download a plugin, but it will expire at the same time than the licence.",
    },
  ],
  "licensing-tag.free.expiration-text": [
    {
      "type": 0,
      "value": "No Licence",
    },
  ],
  "licensing-tag.free.title": [
    {
      "type": 0,
      "value": "Free",
    },
  ],
  "licensing-tag.premium.title": [
    {
      "type": 0,
      "value": "Premium",
    },
  ],
  "licensing-tag.trial.title": [
    {
      "type": 0,
      "value": "Trial",
    },
  ],
  "link.page.download-logs.title": [
    {
      "type": 0,
      "value": "Application logs",
    },
  ],
  "link.page.global-settings.title": [
    {
      "type": 0,
      "value": "Global Settings",
    },
  ],
  "link.page.help.title": [
    {
      "type": 0,
      "value": "Help",
    },
  ],
  "link.page.home.title": [
    {
      "type": 0,
      "value": "Home",
    },
  ],
  "link.page.licence.title": [
    {
      "type": 0,
      "value": "Licence",
    },
  ],
  "link.page.profile.title": [
    {
      "type": 0,
      "value": "My Profile",
    },
  ],
  "link.privacy-policy.url": [
    {
      "type": 0,
      "value": "Privacy Policy",
    },
  ],
  "link.term-use.url": [
    {
      "type": 0,
      "value": "Terms and Conditions",
    },
  ],
  "loading-page.message": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "login.clemexAccount": [
    {
      "type": 0,
      "value": "Clemex Account",
    },
  ],
  "login.form.ActivateAccount.login": [
    {
      "type": 0,
      "value": "Go to login",
    },
  ],
  "login.form.button.sign-in": [
    {
      "type": 0,
      "value": "Sign in",
    },
  ],
  "login.form.createPassword.login": [
    {
      "type": 0,
      "value": "Go to login",
    },
  ],
  "login.form.email.placeholder": [
    {
      "type": 0,
      "value": "Email",
    },
  ],
  "login.form.error.required.email": [
    {
      "type": 0,
      "value": "Please input a valid email.",
    },
  ],
  "login.form.error.required.password": [
    {
      "type": 0,
      "value": "Please input a valid password.",
    },
  ],
  "login.form.link.forgot-password.label": [
    {
      "type": 0,
      "value": "Forgot your password? ",
    },
    {
      "type": 1,
      "value": "resetYourPassword",
    },
  ],
  "login.form.link.forgot-password.url": [
    {
      "type": 0,
      "value": "Reset your password",
    },
  ],
  "login.form.link.privacy-policy.url": [
    {
      "type": 0,
      "value": "Privacy Policy",
    },
  ],
  "login.form.link.term-use.label": [
    {
      "type": 1,
      "value": "termAndConditions",
    },
    {
      "type": 0,
      "value": " and ",
    },
    {
      "type": 1,
      "value": "privacyPolicy",
    },
  ],
  "login.form.link.term-use.url": [
    {
      "type": 0,
      "value": "Terms and Conditions",
    },
  ],
  "login.form.links.sign-up.label": [
    {
      "type": 0,
      "value": "Don't have an account? ",
    },
    {
      "type": 1,
      "value": "signUp",
    },
  ],
  "login.form.links.sign-up.url": [
    {
      "type": 0,
      "value": "Sign Up",
    },
  ],
  "login.form.placeholder.password": [
    {
      "type": 0,
      "value": "Password",
    },
  ],
  "login.form.resetPassword.back": [
    {
      "type": 0,
      "value": "Go Back",
    },
  ],
  "login.form.resetPassword.login": [
    {
      "type": 0,
      "value": "Go to login",
    },
  ],
  "login.form.resetPassword.submit": [
    {
      "type": 0,
      "value": "Submit",
    },
  ],
  "login.form.tooltip.password": [
    {
      "type": 0,
      "value": "Password must contain at least 8 characters.",
    },
  ],
  "login.notification.createPassword.error.body": [
    {
      "type": 0,
      "value": "Invalid token. Please try again soon.",
    },
  ],
  "login.notification.createPassword.error.title": [
    {
      "type": 0,
      "value": "Password creation failed!",
    },
  ],
  "login.notification.login.error.body": [
    {
      "type": 0,
      "value": "Invalid login or not active account. Please try again soon or use registration email link. If it persists please contact Clemex support (support@clemex.com).",
    },
  ],
  "login.notification.login.error.title": [
    {
      "type": 0,
      "value": "Login failed!",
    },
  ],
  "login.notification.login.success.body": [
    {
      "type": 0,
      "value": "Welcome to the Clemex's Studio",
    },
  ],
  "login.notification.login.success.title": [
    {
      "type": 0,
      "value": "Login is a Success!",
    },
  ],
  "login.notification.login.user.invalid": [
    {
      "type": 0,
      "value": "Invalid user login",
    },
  ],
  "login.notification.logout.body": [
    {
      "type": 0,
      "value": "Good bye. See you soon.",
    },
  ],
  "login.notification.logout.title": [
    {
      "type": 0,
      "value": "Successful Logout",
    },
  ],
  "login.notification.passwordReset.invalid": [
    {
      "type": 0,
      "value": "Invalid request",
    },
  ],
  "login.notification.passwordReset.success.body": [
    {
      "type": 0,
      "value": "Please login to continue.",
    },
  ],
  "login.notification.passwordReset.success.title": [
    {
      "type": 0,
      "value": "Password Reset is a Success!",
    },
  ],
  "login.notification.requestResetPassword.error.body": [
    {
      "type": 0,
      "value": "Please try again soon.",
    },
  ],
  "login.notification.requestResetPassword.error.title": [
    {
      "type": 0,
      "value": "Invalid password reset request!",
    },
  ],
  "login.notification.requestResetPassword.invalid": [
    {
      "type": 0,
      "value": "Invalid request reset password",
    },
  ],
  "login.notification.requestResetPassword.success.body": [
    {
      "type": 0,
      "value": "Go check your Email (",
    },
    {
      "type": 1,
      "value": "email",
    },
    {
      "type": 0,
      "value": ") for the password reset link.",
    },
  ],
  "login.notification.requestResetPassword.success.title": [
    {
      "type": 0,
      "value": "Request Password Reset is a Success!",
    },
  ],
  "login.notification.resetPassword.error.body": [
    {
      "type": 0,
      "value": "Invalid email token. Please request reset password again. If it persists please contact Clemex support (support@clemex.com).",
    },
  ],
  "login.notification.resetPassword.error.title": [
    {
      "type": 0,
      "value": "Reset Password Failed!",
    },
  ],
  "login.withMicrosoft": [
    {
      "type": 0,
      "value": "Sign in with your Microsoft account",
    },
  ],
  "made-with-love.logo.alt": [
    {
      "type": 0,
      "value": "Made with ❤️ by Clemex",
    },
  ],
  "maintenance.page.message.part1": [
    {
      "type": 0,
      "value": "Sorry about this, but Studio is under maintenance and will be back online soon.",
    },
  ],
  "maintenance.page.message.part2": [
    {
      "type": 0,
      "value": "Once ready, you will be redirected back to your work at:",
    },
  ],
  "maintenance.title": [
    {
      "type": 0,
      "value": "Ongoing maintenance",
    },
  ],
  "message.algorithm-connected.content": [
    {
      "type": 0,
      "value": "Successfully connected to Clemex Studio algorithm engine.",
    },
  ],
  "message.waiting-algorithm-available.content": [
    {
      "type": 0,
      "value": "Connecting to Clemex Studio algorithm engine...",
    },
  ],
  "modal.confirm.continue.label": [
    {
      "type": 0,
      "value": "Continue",
    },
  ],
  "modal.project-sharing.loading.title": [
    {
      "type": 0,
      "value": "Project Sharing",
    },
  ],
  "modal.project-sharing.title": [
    {
      "type": 0,
      "value": "Project Sharing",
    },
  ],
  "modal.project.shared.accept.label": [
    {
      "type": 0,
      "value": "Yes",
    },
  ],
  "modal.project.shared.refuse.label": [
    {
      "type": 0,
      "value": "No",
    },
  ],
  "navigation.submenu.project.item.create.title": [
    {
      "type": 0,
      "value": "Create Project",
    },
  ],
  "navigation.submenu.project.item.edit.title": [
    {
      "type": 0,
      "value": "Project Settings",
    },
  ],
  "navigation.submenu.project.item.export.title": [
    {
      "type": 0,
      "value": "Export Project",
    },
  ],
  "navigation.submenu.project.item.reset.title": [
    {
      "type": 0,
      "value": "Reset Project",
    },
  ],
  "navigation.submenu.project.item.share.title": [
    {
      "type": 0,
      "value": "Share Project",
    },
  ],
  "navigation.submenu.project.item.upload.title": [
    {
      "type": 0,
      "value": "Add Images",
    },
  ],
  "notification.algorithm-cancel-train-error.description.part-1": [
    {
      "type": 0,
      "value": "Please wait a moment and try again.",
    },
  ],
  "notification.algorithm-cancel-train-error.message": [
    {
      "type": 0,
      "value": "Whoops! Failed to cancel the training",
    },
  ],
  "notification.algorithm-connection-failed.description.part-1": [
    {
      "type": 0,
      "value": "We expect it would come back soon, please retry again later...",
    },
  ],
  "notification.algorithm-connection-failed.description.part-2": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-connection-failed.message": [
    {
      "type": 0,
      "value": "Whoops! We have difficulties connecting to Clemex algorithm backend.",
    },
  ],
  "notification.algorithm-connection-lost-during-prediction.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-connection-lost-during-prediction.description.retry": [
    {
      "type": 0,
      "value": "It will retry when available...",
    },
  ],
  "notification.algorithm-connection-lost-during-prediction.message": [
    {
      "type": 0,
      "value": "Whoops! Clemex Algorithm connection lost during predictions",
    },
  ],
  "notification.algorithm-connection-lost-during-training.description.retry": [
    {
      "type": 0,
      "value": "Please retry again later when available...",
    },
  ],
  "notification.algorithm-connection-lost-during-training.error.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-connection-lost-during-training.message": [
    {
      "type": 0,
      "value": "Whoops! Clemex Algorithm connection lost during the training",
    },
  ],
  "notification.algorithm-create-train-error.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-create-train-error.description.part-1": [
    {
      "type": 0,
      "value": "Lots of magic happens during the training. We try our best to control its dark side!",
    },
  ],
  "notification.algorithm-create-train-error.description.part-2": [
    {
      "type": 0,
      "value": "The rumour says retrying your operation works sometime. You can try to train again the algorithm.",
    },
  ],
  "notification.algorithm-create-train-error.message": [
    {
      "type": 0,
      "value": "Whoops! An unexpected feature broke the training",
    },
  ],
  "notification.algorithm-predict-unknown-error.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-predict-unknown-error.description.part-1": [
    {
      "type": 0,
      "value": "Lots of magic happens during the prediction. We try our best to control its dark side!",
    },
  ],
  "notification.algorithm-predict-unknown-error.description.part-2": [
    {
      "type": 0,
      "value": "The rumour says retrying your operation works sometime. Try reloading the page or try training again the algorithm.",
    },
  ],
  "notification.algorithm-predict-unknown-error.message": [
    {
      "type": 0,
      "value": "Whoops! An unexpected feature broke the predictions",
    },
  ],
  "notification.algorithm-train-error.copy-error-message": [
    {
      "type": 0,
      "value": "Copy error message",
    },
  ],
  "notification.algorithm-train-error.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-train-error.description.part-1": [
    {
      "type": 0,
      "value": "Error message:",
    },
  ],
  "notification.algorithm-train-error.description.part-2": [
    {
      "type": 0,
      "value": "Lots of magic happens during the training. We try our best to control its dark side!",
    },
  ],
  "notification.algorithm-train-error.description.part-3": [
    {
      "type": 0,
      "value": "The rumour says retrying your operation works sometime. You can try to train again the algorithm.",
    },
  ],
  "notification.algorithm-train-error.message": [
    {
      "type": 0,
      "value": "Whoops! An unexpected feature broke the training",
    },
  ],
  "notification.algorithm-train-unknown-error.description.contact": [
    {
      "type": 0,
      "value": "If you keep having troubles, click on the contact us button.",
    },
  ],
  "notification.algorithm-train-unknown-error.description.part-1": [
    {
      "type": 0,
      "value": "Lots of magic happens during the training. We try our best to control its dark side!",
    },
  ],
  "notification.algorithm-train-unknown-error.description.part-2": [
    {
      "type": 0,
      "value": "The rumour says retrying your operation works sometime. You can try to train again the algorithm.",
    },
  ],
  "notification.algorithm-train-unknown-error.message": [
    {
      "type": 0,
      "value": "Whoops! An unexpected feature broke the training",
    },
  ],
  "notification.algorithm-training-forbidden-error.description.part-1": [
    {
      "type": 0,
      "value": "You are missing a permissions to use the training feature. Contact us to enable it via the I need help button.",
    },
  ],
  "notification.algorithm-training-forbidden-error.message": [
    {
      "type": 0,
      "value": "You do no have access to use the training feature",
    },
  ],
  "object-overview-modal.title": [
    {
      "type": 0,
      "value": "Object Overview",
    },
  ],
  "options.label": [
    {
      "type": 0,
      "value": "Export Options",
    },
  ],
  "page.licence.mailto.subject": [
    {
      "type": 0,
      "value": "Clemex Studio Licence Request",
    },
  ],
  "page.plan.title": [
    {
      "type": 0,
      "value": "Plan",
    },
  ],
  "page.profile.form.activateAccount.reset": [
    {
      "type": 0,
      "value": "Reset",
    },
  ],
  "page.profile.form.activateAccount.submit": [
    {
      "type": 0,
      "value": "Save",
    },
  ],
  "page.profile.form.changePassword.submit": [
    {
      "type": 0,
      "value": "Change password",
    },
  ],
  "page.profile.form.confirmPassword.label": [
    {
      "type": 0,
      "value": "Confirm Password",
    },
  ],
  "page.profile.form.currentPassword.label": [
    {
      "type": 0,
      "value": "Current password",
    },
  ],
  "page.profile.form.email.label": [
    {
      "type": 0,
      "value": "Email",
    },
  ],
  "page.profile.form.error.oldPassword.NewPassword.Same": [
    {
      "type": 0,
      "value": "The current and new passwords are the same!",
    },
  ],
  "page.profile.form.error.oldPassword.NotDefined": [
    {
      "type": 0,
      "value": "The current password is empty!",
    },
  ],
  "page.profile.form.error.required.currentPassword": [
    {
      "type": 0,
      "value": "Please input your password.",
    },
  ],
  "page.profile.form.error.required.firstName": [
    {
      "type": 0,
      "value": "Please input your first name.",
    },
  ],
  "page.profile.form.error.required.lastName": [
    {
      "type": 0,
      "value": "Please input your last name.",
    },
  ],
  "page.profile.form.error.required.password": [
    {
      "type": 0,
      "value": "Please input a valid password.",
    },
  ],
  "page.profile.form.error.twoPasswords.notIdentical": [
    {
      "type": 0,
      "value": "The two passwords do not match!",
    },
  ],
  "page.profile.form.firstName.label": [
    {
      "type": 0,
      "value": "First name",
    },
  ],
  "page.profile.form.firstName.placeholder": [
    {
      "type": 0,
      "value": "Zacharias",
    },
  ],
  "page.profile.form.item.confirmPassword.rule.required.message": [
    {
      "type": 0,
      "value": "Please confirm your password!",
    },
  ],
  "page.profile.form.jobTitle.label": [
    {
      "type": 0,
      "value": "Job title",
    },
  ],
  "page.profile.form.lastName.label": [
    {
      "type": 0,
      "value": "Last name",
    },
  ],
  "page.profile.form.lastName.placeholder": [
    {
      "type": 0,
      "value": "Janssen",
    },
  ],
  "page.profile.form.link.privacy-policy.url": [
    {
      "type": 0,
      "value": "Privacy Policy",
    },
  ],
  "page.profile.form.link.term-use.label": [
    {
      "type": 1,
      "value": "termAndConditions",
    },
    {
      "type": 0,
      "value": " and ",
    },
    {
      "type": 1,
      "value": "privacyPolicy",
    },
  ],
  "page.profile.form.link.term-use.url": [
    {
      "type": 0,
      "value": "Terms and Conditions",
    },
  ],
  "page.profile.form.organization.label": [
    {
      "type": 0,
      "value": "Organization",
    },
  ],
  "page.profile.form.password.label": [
    {
      "type": 0,
      "value": "Password",
    },
  ],
  "page.profile.form.phoneNumber.label": [
    {
      "type": 0,
      "value": "Phone number",
    },
  ],
  "page.profile.form.tooltip.confirmPassword": [
    {
      "type": 0,
      "value": "Same as password.",
    },
  ],
  "page.profile.form.tooltip.password": [
    {
      "type": 0,
      "value": "Password must contain at least 8 characters.",
    },
  ],
  "page.profile.licence-plans.title": [
    {
      "type": 0,
      "value": "plans",
    },
  ],
  "page.profile.password.title": [
    {
      "type": 0,
      "value": "Password",
    },
  ],
  "page.profile.title": [
    {
      "type": 0,
      "value": "Profile",
    },
  ],
  "page.title.404": [
    {
      "type": 0,
      "value": "Sorry the page you are looking for is nowhere to be found",
    },
  ],
  "page.title.default": [
    {
      "type": 0,
      "value": "Clemex Studio",
    },
  ],
  "page.title.editor": [
    {
      "type": 1,
      "value": "project",
    },
  ],
  "page.title.licence": [
    {
      "type": 0,
      "value": "Licence",
    },
  ],
  "page.title.profile": [
    {
      "type": 0,
      "value": "My profile",
    },
  ],
  "page.title.project list": [
    {
      "type": 0,
      "value": "Projects",
    },
  ],
  "panel-bar.panel-item.databrowser.tooltip": [
    {
      "type": 0,
      "value": "Data Browser",
    },
  ],
  "panel-bar.panel-item.left-panel.tooltip": [
    {
      "type": 0,
      "value": "Left Panel",
    },
  ],
  "parameters.edges.labelplugin-installation-instruction.modal.footer.actions.switch.do-not-show-again.text": [
    {
      "type": 0,
      "value": "Do not show again",
    },
  ],
  "plugin-installation-instruction.modal.content.main": [
    {
      "type": 0,
      "value": "To use the plugin you just created for Vision, follow these steps:",
    },
  ],
  "plugin-installation-instruction.modal.content.step-1": [
    {
      "type": 0,
      "value": "Move the plugin file you just downloaded to ",
    },
    {
      "type": 1,
      "value": "vision_installation_path",
    },
  ],
  "plugin-installation-instruction.modal.content.step-2": [
    {
      "type": 0,
      "value": "Restart Vision",
    },
  ],
  "plugin-installation-instruction.modal.content.step-3": [
    {
      "type": 0,
      "value": "Your plugin will be located in the π section of the Vision TOOLBOX",
    },
  ],
  "plugin-installation-instruction.modal.footer.actions.button.close.text": [
    {
      "type": 0,
      "value": "Close",
    },
  ],
  "plugin-installation-instruction.modal.title": [
    {
      "type": 0,
      "value": "Plugin installation instructions",
    },
  ],
  "prediction-classes.class.button.visibility.hide": [
    {
      "type": 0,
      "value": "Hide the prediction mask of this class on the image",
    },
  ],
  "prediction-classes.class.button.visibility.show": [
    {
      "type": 0,
      "value": "Show the prediction mask of this class on the image",
    },
  ],
  "prediction-classes.title": [
    {
      "type": 0,
      "value": "Classes",
    },
  ],
  "prediction-parameters.disabled": [
    {
      "type": 0,
      "value": "Please train the project to change the prediction opacity.",
    },
  ],
  "prediction-parameters.opacity.slider.label": [
    {
      "type": 0,
      "value": "Prediction opacity",
    },
  ],
  "predictions-classes.tooltip.hide-predictions": [
    {
      "type": 0,
      "value": "Hide all predictions in the viewer",
    },
  ],
  "predictions-classes.tooltip.show-predictions": [
    {
      "type": 0,
      "value": "Show all predictions in the viewer",
    },
  ],
  "profile.notification.error.body": [
    {
      "type": 0,
      "value": "Something went wrong during profile update, please retry. If it persists please contact Clemex support (support@clemex.com).",
    },
  ],
  "profile.notification.error.title": [
    {
      "type": 0,
      "value": "Error",
    },
  ],
  "profile.notification.success.body": [
    {
      "type": 0,
      "value": "Profile updated successfully",
    },
  ],
  "profile.notification.success.title": [
    {
      "type": 0,
      "value": "Success!",
    },
  ],
  "profile.password.notification.error.body": [
    {
      "type": 0,
      "value": "Something went wrong during password update, please retry. If it persists please contact Clemex support (support@clemex.com).",
    },
  ],
  "profile.password.notification.error.title": [
    {
      "type": 0,
      "value": "Error",
    },
  ],
  "profile.password.notification.success.body": [
    {
      "type": 0,
      "value": "Password updated successfully",
    },
  ],
  "profile.password.notification.success.title": [
    {
      "type": 0,
      "value": "Success!",
    },
  ],
  "project-info-card.action.duplicate.tooltip": [
    {
      "type": 0,
      "value": "Duplicate project and open it",
    },
  ],
  "project-info-card.info.last-modified-date.label": [
    {
      "type": 0,
      "value": "Last modified date:",
    },
  ],
  "project-info-card.tooltip.annotate-project": [
    {
      "type": 0,
      "value": "Open project in annotation editor",
    },
  ],
  "project-info-card.tooltip.edit-project": [
    {
      "type": 0,
      "value": "Edit project",
    },
  ],
  "project-list.edit.archive-project.notification.error.body": [
    {
      "type": 0,
      "value": "An error occurred while deleting a project. Please try again.",
    },
  ],
  "project-list.edit.archive-project.notification.error.title": [
    {
      "type": 0,
      "value": "Project deletion failed",
    },
  ],
  "project-list.project.tooltip.delete-project.modal.confirm.content": [
    {
      "type": 0,
      "value": "Delete project \"",
    },
    {
      "type": 1,
      "value": "project",
    },
    {
      "type": 0,
      "value": "\"?",
    },
  ],
  "project-list.project.tooltip.delete-project.modal.confirm.ok.text": [
    {
      "type": 0,
      "value": "Yes, I am sure",
    },
  ],
  "project-settings.modal.deep-learning.clemex-net-lite-v1.training-parameters.licence-restriction.warning.model": [
    {
      "type": 0,
      "value": "This model use deep learning technology.",
    },
  ],
  "project-settings.modal.editor.measurement.settings.reset": [
    {
      "type": 0,
      "value": "Reset to default settings",
    },
  ],
  "project-settings.modal.section.algorithm.choice": [
    {
      "type": 0,
      "value": "Model",
    },
  ],
  "project-settings.modal.section.algorithm.section.training-parameters": [
    {
      "type": 0,
      "value": "Training options",
    },
  ],
  "project-settings.modal.section.deep-learning.training-parameters.epochs": [
    {
      "type": 0,
      "value": "Epochs",
    },
  ],
  "project-settings.modal.section.deep-learning.training-parameters.reset": [
    {
      "type": 0,
      "value": "Reset to default training parameters",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.edges": [
    {
      "type": 0,
      "value": "Edges",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.intensity": [
    {
      "type": 0,
      "value": "Intensity",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.reset": [
    {
      "type": 0,
      "value": "Reset to default training parameters",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.sigma": [
    {
      "type": 0,
      "value": "Sigma",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.switch.warning": [
    {
      "type": 0,
      "value": "At least one option is required. You will not be able to deactivate the last one.",
    },
  ],
  "project-settings.modal.section.rf-algorithm.training-parameters.texture": [
    {
      "type": 0,
      "value": "Texture",
    },
  ],
  "project-settings.modal.tab.algorithm.loading": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "project-settings.modal.tab.algorithm.title": [
    {
      "type": 0,
      "value": "Algorithm",
    },
  ],
  "project-settings.modal.tab.direct-measure.title": [
    {
      "type": 0,
      "value": "Direct Measure",
    },
  ],
  "project-settings.modal.tab.export.title": [
    {
      "type": 0,
      "value": "Export",
    },
  ],
  "project-settings.modal.tab.measurement.angle.title": [
    {
      "type": 0,
      "value": "Angle Tool",
    },
  ],
  "project-settings.modal.tab.measurement.angle.unit.inner": [
    {
      "type": 0,
      "value": "Degree",
    },
  ],
  "project-settings.modal.tab.measurement.angle.unit.label": [
    {
      "type": 0,
      "value": "Unit",
    },
  ],
  "project-settings.modal.tab.measurement.angle.unit.outer": [
    {
      "type": 0,
      "value": "Radian",
    },
  ],
  "project-settings.modal.tab.measurement.common": [
    {
      "type": 0,
      "value": "Common Measurement Style",
    },
  ],
  "project-settings.modal.tab.measurement.font.color.label": [
    {
      "type": 0,
      "value": "Font color",
    },
  ],
  "project-settings.modal.tab.measurement.font.label": [
    {
      "type": 0,
      "value": "Font",
    },
  ],
  "project-settings.modal.tab.measurement.font.outline-color.label": [
    {
      "type": 0,
      "value": "Font Outline Color",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.measurement-text-position.bottom": [
    {
      "type": 0,
      "value": "Bottom",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.measurement-text-position.label": [
    {
      "type": 0,
      "value": "Measurement Text Position",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.measurement-text-position.left": [
    {
      "type": 0,
      "value": "Left",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.measurement-text-position.right": [
    {
      "type": 0,
      "value": "Right",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.measurement-text-position.top": [
    {
      "type": 0,
      "value": "Top",
    },
  ],
  "project-settings.modal.tab.measurement.geometry.title": [
    {
      "type": 0,
      "value": "Geometry Tool",
    },
  ],
  "project-settings.modal.tab.measurement.loading": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "project-settings.modal.tab.measurement.main-color": [
    {
      "type": 0,
      "value": "Main Color",
    },
  ],
  "project-settings.modal.tab.measurement.measuring-dot-color": [
    {
      "type": 0,
      "value": "Measuring Dot Color",
    },
  ],
  "project-settings.modal.tab.measurement.measuring-line-color": [
    {
      "type": 0,
      "value": "Measuring Line Color",
    },
  ],
  "project-settings.modal.tab.measurement.reset": [
    {
      "type": 0,
      "value": "Reset to default direct measure settings",
    },
  ],
  "project-settings.modal.tab.measurement.title": [
    {
      "type": 0,
      "value": "Measurement",
    },
  ],
  "project-settings.modal.tab.project-metadata.title": [
    {
      "type": 0,
      "value": "General",
    },
  ],
  "project-settings.modal.title": [
    {
      "type": 0,
      "value": "Project Settings",
    },
  ],
  "project.action.duplicateAndNavigate.fail.notification.description": [
    {
      "type": 0,
      "value": "Please try again later",
    },
  ],
  "project.action.duplicateAndNavigate.fail.notification.message": [
    {
      "type": 0,
      "value": "Failed to duplicate project",
    },
  ],
  "project.export.explanation.modal.description.2": [
    {
      "type": 0,
      "value": "Project exportation allows to export all images, annotation masks and prediction masks of a project.",
    },
  ],
  "project.export.explanation.modal.title": [
    {
      "type": 0,
      "value": "What is the project export feature?",
    },
  ],
  "project.export.label": [
    {
      "type": 0,
      "value": "Export",
    },
  ],
  "project.menu.item.confirm.content": [
    {
      "type": 0,
      "value": "This action is not reversible.",
    },
  ],
  "project.menu.item.confirm.title": [
    {
      "type": 0,
      "value": "Are you sure?",
    },
  ],
  "project.menu.item.image.delete": [
    {
      "type": 0,
      "value": "Delete image",
    },
  ],
  "project.menu.item.image.duplicate": [
    {
      "type": 0,
      "value": "Duplicate project",
    },
  ],
  "project.menu.item.image.duplicateAndNavigate": [
    {
      "type": 0,
      "value": "Duplicate project and open it",
    },
  ],
  "project.menu.item.image.send.training": [
    {
      "type": 0,
      "value": "Send to training",
    },
  ],
  "project.menu.item.image.send.training.navigate": [
    {
      "type": 0,
      "value": "Send to training and go to annotation editor",
    },
  ],
  "project.menu.item.image.send.validation": [
    {
      "type": 0,
      "value": "Send to validation",
    },
  ],
  "project.menu.item.reset.confirm.p1": [
    {
      "type": 0,
      "value": "This action will delete all annotations from all images and unlink the trained model from the project.",
    },
  ],
  "project.menu.item.reset.confirm.p2": [
    {
      "type": 0,
      "value": "This action is not reversible.",
    },
  ],
  "project.menu.item.reset.confirm.p3": [
    {
      "type": 0,
      "value": "Are you sure to continue?",
    },
  ],
  "project.menu.item.reset.confirm.title": [
    {
      "type": 0,
      "value": "Confirm reset project?",
    },
  ],
  "project.shared.invalid.description": [
    {
      "type": 0,
      "value": "The project you are trying to redeem is expired or does not exist. Contact the person that provided you with this URL",
    },
  ],
  "project.shared.loading.description": [
    {
      "type": 0,
      "value": "Loading...",
    },
  ],
  "project.shared.rejected-to-himself.description": [
    {
      "type": 0,
      "value": "You cannot redeem a project shared by yourself. Please use the duplicate project option instead.",
    },
  ],
  "project.shared.valid.description": [
    {
      "type": 1,
      "value": "userEmail",
    },
    {
      "type": 0,
      "value": " wants to share project \"",
    },
    {
      "type": 1,
      "value": "projectName",
    },
    {
      "type": 0,
      "value": "\" with you. Click yes to accept and no to decline. If you click yes, this project will be added to your project list.",
    },
  ],
  "project.sharing.link.copy.failure": [
    {
      "type": 0,
      "value": "Your project sharing URL could not be copied",
    },
  ],
  "project.sharing.link.copy.success": [
    {
      "type": 0,
      "value": "Your project sharing URL was copied",
    },
  ],
  "project.sharing.link.generate.error.description": [
    {
      "type": 0,
      "value": "There was an error generating the project sharing link. Please try again",
    },
  ],
  "project.sharing.link.validity.description": [
    {
      "type": 0,
      "value": "To easily share this project, just send the URL to your recipient before: ",
    },
    {
      "type": 1,
      "value": "sharingLinkExpirationDate",
    },
    {
      "type": 0,
      "value": ". This URL cannot be used by yourself.",
    },
  ],
  "project.sharing.redeem.error": [
    {
      "type": 0,
      "value": "There was an error while redeeming the project. Please ask the person that provided you the link for a new link.",
    },
  ],
  "project.sharing.redeem.loading": [
    {
      "type": 0,
      "value": "Accepting shared project...",
    },
  ],
  "project.sharing.redeem.success": [
    {
      "type": 0,
      "value": "Share project completed",
    },
  ],
  "project.sharing.url.description3": [
    {
      "type": 0,
      "value": "Share your project \"",
    },
    {
      "type": 1,
      "value": "projectName",
    },
    {
      "type": 0,
      "value": "\" with this URL:",
    },
  ],
  "project.smart-annotation.magic-wand.error": [
    {
      "type": 0,
      "value": "There was an error with the Magic Wand. Please try again later or contact us",
    },
  ],
  "projects-list.button.new-project.label": [
    {
      "type": 0,
      "value": "new project",
    },
  ],
  "projects.modal.creation.actions.cancel": [
    {
      "type": 0,
      "value": "Cancel",
    },
  ],
  "projects.modal.creation.actions.ok": [
    {
      "type": 0,
      "value": "Create",
    },
  ],
  "projects.modal.creation.form.description.label": [
    {
      "type": 0,
      "value": "Description",
    },
  ],
  "projects.modal.creation.form.error-with-archive.unknown": [
    {
      "type": 0,
      "value": "An unknown error occurred while creating the project. Please make sure the Studio export is valid and try again.",
    },
  ],
  "projects.modal.creation.form.error.unknown": [
    {
      "type": 0,
      "value": "An unknown error occurred while creating the project",
    },
  ],
  "projects.modal.creation.form.import.upload.area.hint.line-1": [
    {
      "type": 0,
      "value": "Clemex Studio will import the content into the new project.",
    },
  ],
  "projects.modal.creation.form.import.upload.area.hint.line-2": [
    {
      "type": 0,
      "value": "Only one export file is allowed.",
    },
  ],
  "projects.modal.creation.form.import.upload.area.text": [
    {
      "type": 0,
      "value": "Click or drag a Studio export file here to upload",
    },
  ],
  "projects.modal.creation.form.name.label": [
    {
      "type": 0,
      "value": "Name",
    },
  ],
  "projects.modal.creation.form.name.rule.required": [
    {
      "type": 0,
      "value": "Please give a name to your project (at least 1 character)",
    },
  ],
  "projects.modal.creation.import.explanation.description": [
    {
      "type": 0,
      "value": "Importing a project enables you to bring in all images, annotation masks, and prediction masks from a Studio exported project.",
    },
  ],
  "projects.modal.creation.title": [
    {
      "type": 0,
      "value": "Create a new project",
    },
  ],
  "projects.modal.edit.actions.archive": [
    {
      "type": 0,
      "value": "Delete",
    },
  ],
  "projects.modal.edit.actions.ok": [
    {
      "type": 0,
      "value": "Save",
    },
  ],
  "projects.modal.edit.form.description.label": [
    {
      "type": 0,
      "value": "Description",
    },
  ],
  "projects.modal.edit.form.name.label": [
    {
      "type": 0,
      "value": "Name",
    },
  ],
  "projects.modal.edit.form.name.rule.required": [
    {
      "type": 0,
      "value": "Please give a name to your project (1-255 characters)",
    },
  ],
  "projects.modal.uploadImages.actions.cancel": [
    {
      "type": 0,
      "value": "Cancel",
    },
  ],
  "projects.modal.uploadImages.actions.confirm": [
    {
      "type": 0,
      "value": "Add images",
    },
  ],
  "projects.modal.uploadImages.form.image.upload.area.text": [
    {
      "type": 0,
      "value": "Click or drag file to this area to upload",
    },
  ],
  "projects.modal.uploadImages.title": [
    {
      "type": 0,
      "value": "Upload images",
    },
  ],
  "projects.settings.open.description": [
    {
      "type": 0,
      "value": "Open project settings",
    },
  ],
  "projects.settings.open.shortcut": [
    {
      "type": 0,
      "value": "(Shift+p)",
    },
  ],
  "projects.upload-first-images.actions.confirm": [
    {
      "type": 0,
      "value": "Continue",
    },
  ],
  "projects.upload-first-images.image-demo-selection.text": [
    {
      "type": 0,
      "value": "Or select from our images",
    },
  ],
  "projects.upload-first-images.image-demo-selection.upload": [
    {
      "type": 0,
      "value": "Use your own",
    },
  ],
  "projects.upload-first-images.message": [
    {
      "type": 0,
      "value": "Please upload image(s)",
    },
  ],
  "projects.uploadImage.form.image.upload.area.hint": [
    {
      "type": 0,
      "value": "Support for multiple images upload (png, jpeg, bmp, tiff)",
    },
  ],
  "projects.uploadImage.form.image.upload.area.text": [
    {
      "type": 0,
      "value": "Click or drag file to this area to upload",
    },
  ],
  "projects.uploadImage.form.image.upload.error": [
    {
      "type": 0,
      "value": "Image ",
    },
    {
      "type": 1,
      "value": "fileName",
    },
    {
      "type": 0,
      "value": " upload failed. The image format or dimensions could be incorrect. Please validate the image is ",
    },
    {
      "type": 1,
      "value": "minDimension",
    },
    {
      "type": 0,
      "value": "x",
    },
    {
      "type": 1,
      "value": "minDimension",
    },
    {
      "type": 0,
      "value": " and in the following formats: ",
    },
    {
      "type": 1,
      "value": "imageFormat",
    },
  ],
  "register.form.button.sign-up": [
    {
      "type": 0,
      "value": "Sign Up",
    },
  ],
  "register.form.email.placeholder": [
    {
      "type": 0,
      "value": "Email",
    },
  ],
  "register.form.error.required.email": [
    {
      "type": 0,
      "value": "Please input a valid email.",
    },
  ],
  "register.form.error.required.firstName": [
    {
      "type": 0,
      "value": "Please input a valid first name.",
    },
  ],
  "register.form.error.required.lastName": [
    {
      "type": 0,
      "value": "Please input a valid last name.",
    },
  ],
  "register.form.error.required.password": [
    {
      "type": 0,
      "value": "Please input a valid password.",
    },
  ],
  "register.form.firstname.placeholder": [
    {
      "type": 0,
      "value": "First name",
    },
  ],
  "register.form.item.confirmPassword.rule.required.message": [
    {
      "type": 0,
      "value": "Please confirm your password!",
    },
  ],
  "register.form.lastName.placeholder": [
    {
      "type": 0,
      "value": "Last name",
    },
  ],
  "register.form.link.forgot-password.label": [
    {
      "type": 0,
      "value": "Forgot your password? ",
    },
    {
      "type": 1,
      "value": "resetYourPassword",
    },
  ],
  "register.form.link.forgot-password.url": [
    {
      "type": 0,
      "value": "Reset your password",
    },
  ],
  "register.form.link.privacy-policy.url": [
    {
      "type": 0,
      "value": "Privacy Policy",
    },
  ],
  "register.form.link.term-use.label": [
    {
      "type": 0,
      "value": "I agree with the ",
    },
    {
      "type": 1,
      "value": "termAndConditions",
    },
    {
      "type": 0,
      "value": " and ",
    },
    {
      "type": 1,
      "value": "privacyPolicy",
    },
    {
      "type": 0,
      "value": ".",
    },
  ],
  "register.form.link.term-use.url": [
    {
      "type": 0,
      "value": "Terms and Conditions",
    },
  ],
  "register.form.links.sign-in.label": [
    {
      "type": 0,
      "value": "Already have an account? ",
    },
    {
      "type": 1,
      "value": "signIn",
    },
  ],
  "register.form.links.sign-in.url": [
    {
      "type": 0,
      "value": "Sign In",
    },
  ],
  "register.form.placeholder.confirmPassword": [
    {
      "type": 0,
      "value": "Confirm password",
    },
  ],
  "register.form.placeholder.password": [
    {
      "type": 0,
      "value": "Password",
    },
  ],
  "register.form.tooltip.password": [
    {
      "type": 0,
      "value": "Password must contain at least 8 characters.",
    },
  ],
  "register.form.validation.passwordMismatch": [
    {
      "type": 0,
      "value": "The two passwords that you entered do not match!",
    },
  ],
  "register.notification.register.error.body": [
    {
      "type": 0,
      "value": "Please try again soon.",
    },
  ],
  "register.notification.register.error.title": [
    {
      "type": 0,
      "value": "Failed to register!",
    },
  ],
  "register.notification.register.failed": [
    {
      "type": 0,
      "value": "Failed to register",
    },
  ],
  "register.notification.register.success.body": [
    {
      "type": 0,
      "value": "Please check your email to activate your account",
    },
  ],
  "register.notification.register.success.title": [
    {
      "type": 0,
      "value": "Successfully registered your account!",
    },
  ],
  "request-assistance.button.label": [
    {
      "type": 0,
      "value": "I need help",
    },
  ],
  "request-assistance.modal.Cancel": [
    {
      "type": 0,
      "value": "Cancel",
    },
  ],
  "request-assistance.modal.assistance.error.required": [
    {
      "type": 0,
      "value": "Please select at least one option",
    },
  ],
  "request-assistance.modal.button.download-logs": [
    {
      "type": 0,
      "value": "Get application Logs",
    },
  ],
  "request-assistance.modal.button.ok": [
    {
      "type": 0,
      "value": "Ok",
    },
  ],
  "request-assistance.modal.choice.help": [
    {
      "type": 0,
      "value": "I need help with Studio",
    },
  ],
  "request-assistance.modal.choice.licence": [
    {
      "type": 0,
      "value": "I want to buy a licence",
    },
  ],
  "request-assistance.modal.choice.problem": [
    {
      "type": 0,
      "value": "Something is not working for me",
    },
  ],
  "request-assistance.modal.choice.suggestion": [
    {
      "type": 0,
      "value": "I have a suggestion",
    },
  ],
  "request-assistance.modal.contact-me": [
    {
      "type": 0,
      "value": "Contact me",
    },
  ],
  "request-assistance.modal.offlie.description": [
    {
      "type": 0,
      "value": "To contact Clemex you can send an email at : ",
    },
    {
      "type": 1,
      "value": "salesEmail",
    },
    {
      "type": 0,
      "value": ", ",
    },
    {
      "type": 1,
      "value": "supportEmail",
    },
  ],
  "request-assistance.modal.offlie.description2": [
    {
      "type": 0,
      "value": "or by phone at: ",
    },
    {
      "type": 1,
      "value": "salesPhoneNumber",
    },
  ],
  "request-assistance.modal.response.handling.description.part1": [
    {
      "type": 0,
      "value": "Just a heads up, a real live human will be reading your comment!",
    },
  ],
  "request-assistance.modal.response.handling.description.part2": [
    {
      "type": 0,
      "value": "We're all ears and ready to dish out some genuine human-powered responses.",
    },
  ],
  "request-assistance.modal.response.handling.description.part3": [
    {
      "type": 0,
      "value": "You will be contacted at the following email address:",
    },
  ],
  "request-assistance.modal.title": [
    {
      "type": 0,
      "value": "Request Assistance",
    },
  ],
  "request-assistance.notification.error.contact-me.description": [
    {
      "type": 0,
      "value": "Your request for assistance Could not be submitted to Clemex. Please try again",
    },
  ],
  "request-assistance.notification.error.contact-me.title": [
    {
      "type": 0,
      "value": "Request failed",
    },
  ],
  "request-assistance.notification.success.contact-me.description": [
    {
      "type": 0,
      "value": "Your request for assistance has been submitted to clemex team. Expect to be contacted within two business days",
    },
  ],
  "request-assistance.notification.success.contact-me.title": [
    {
      "type": 0,
      "value": "Request submitted",
    },
  ],
  "request-licence-feature.acquire-feature.contact": [
    {
      "type": 0,
      "value": "The ",
    },
    {
      "type": 1,
      "value": "feature",
    },
    {
      "type": 0,
      "value": " is a feature available upon request.",
    },
  ],
  "request-licence-feature.acquire-feature.contacted-email": [
    {
      "type": 0,
      "value": "Click on the button to request it, you will be contacted at the following email address:",
    },
  ],
  "request-licence-feature.button.contact-me": [
    {
      "type": 0,
      "value": "Contact me",
    },
  ],
  "request-licence-feature.contact": [
    {
      "type": 0,
      "value": "To contact Clemex you can send an email at: ",
    },
    {
      "type": 1,
      "value": "salesEmail",
    },
    {
      "type": 0,
      "value": " or by phone at: ",
    },
    {
      "type": 1,
      "value": "salesPhoneNumber",
    },
  ],
  "request-licence-feature.mail.template.body2": [
    {
      "type": 0,
      "value": "Hi, I'd like to activate the ",
    },
    {
      "type": 1,
      "value": "feature",
    },
    {
      "type": 0,
      "value": " [",
    },
    {
      "type": 1,
      "value": "licenceFeatureRequest",
    },
    {
      "type": 0,
      "value": "] in Clemex Studio. Can you assist me with this?",
    },
  ],
  "request-licence-feature.mail.template.subject": [
    {
      "type": 0,
      "value": "Request access to ",
    },
    {
      "type": 1,
      "value": "feature",
    },
    {
      "type": 0,
      "value": " in Clemex Studio",
    },
  ],
  "request-licence-feature.notification.error.contact-me.description": [
    {
      "type": 0,
      "value": "Your request for the feature could not be submitted to Clemex. Please try again or contact us at: ",
    },
    {
      "type": 1,
      "value": "salesEmail",
    },
  ],
  "request-licence-feature.notification.error.contact-me.title": [
    {
      "type": 0,
      "value": "Request failed",
    },
  ],
  "request-licence-feature.notification.success.contact-me.description": [
    {
      "type": 0,
      "value": "Your request for the feature has been submitted to Clemex team. Expect to be contacted within two business days",
    },
  ],
  "request-licence-feature.notification.success.contact-me.title": [
    {
      "type": 0,
      "value": "Request submitted",
    },
  ],
  "request-reset-password.form.email.placeholder": [
    {
      "type": 0,
      "value": "Email",
    },
  ],
  "request-reset-password.title": [
    {
      "type": 0,
      "value": "Request Reset Password",
    },
  ],
  "reset-password.form.error.passwordMustMatch": [
    {
      "type": 0,
      "value": "Passwords does not match",
    },
  ],
  "reset-password.form.error.required.password": [
    {
      "type": 0,
      "value": "Please input a valid password.",
    },
  ],
  "reset-password.form.error.required.token": [
    {
      "type": 0,
      "value": "Please input a valid token.",
    },
  ],
  "reset-password.form.placeholder.password": [
    {
      "type": 0,
      "value": "New Password",
    },
  ],
  "reset-password.form.placeholder.password.confirm": [
    {
      "type": 0,
      "value": "Confirm password",
    },
  ],
  "reset-password.form.resetPassword.placeholder": [
    {
      "type": 0,
      "value": "Reset Password Token",
    },
  ],
  "reset-password.form.resetPassword.submit": [
    {
      "type": 0,
      "value": "Submit",
    },
  ],
  "reset-password.form.tooltip.password": [
    {
      "type": 0,
      "value": "Password must contain at least 8 characters.",
    },
  ],
  "reset-password.title": [
    {
      "type": 0,
      "value": "Reset Password",
    },
  ],
  "right-panel.download.modal.content-1": [
    {
      "type": 0,
      "value": "A plugin is a software that can be installed in Clemex Vision to be added in a routine and help you detect the objects you have trained on.",
    },
  ],
  "right-panel.download.modal.content-2": [
    {
      "type": 0,
      "value": "It enables you to use your trained model on a new set of images and measure the properties of the detected objects.",
    },
  ],
  "right-panel.download.modal.content-3": [
    {
      "type": 0,
      "value": "Clemex Vision is a fully integrated system for labs seeking traceable, repeatable, and accurate results.",
    },
  ],
  "right-panel.download.modal.title": [
    {
      "type": 0,
      "value": "What is a plugin ?",
    },
  ],
  "selected.annotation.arrow.parameters.showLeftArrow": [
    {
      "type": 0,
      "value": "Show Left Arrow",
    },
  ],
  "selected.annotation.arrow.parameters.showRightArrow": [
    {
      "type": 0,
      "value": "Show Right Arrow",
    },
  ],
  "selected.annotation.arrow.parameters.title": [
    {
      "type": 0,
      "value": "Selected Annotation Arrow",
    },
  ],
  "server-disconnected.modal.content.reconnecting": [
    {
      "type": 0,
      "value": "The local connection was lost: Reconnecting...",
    },
  ],
  "service-disconnected.modal.content.contact": [
    {
      "type": 0,
      "value": "If the issue persists please contact Clemex support (",
    },
  ],
  "service-disconnected.modal.content.licence.action": [
    {
      "type": 0,
      "value": "Please reopen the application to retry.",
    },
  ],
  "service-disconnected.modal.content.logs": [
    {
      "type": 0,
      "value": "- The logs from ",
    },
    {
      "type": 1,
      "value": "logs_path",
    },
  ],
  "service-disconnected.modal.content.lost": [
    {
      "type": 0,
      "value": "- The technical message is the local ",
    },
    {
      "type": 1,
      "value": "serviceName",
    },
    {
      "type": 0,
      "value": " connection was lost",
    },
  ],
  "service-disconnected.modal.content.reconnection.failed": [
    {
      "type": 0,
      "value": "There was an unexpected issue and therefore Clemex Studio cannot be used.",
    },
  ],
  "service-disconnected.modal.content.separator": [
    {
      "type": 0,
      "value": ") with:",
    },
  ],
  "service-disconnected.service.name.algorithm": [
    {
      "type": 0,
      "value": "algorithm",
    },
  ],
  "service-disconnected.service.name.deep-learning": [
    {
      "type": 0,
      "value": "deep-learning",
    },
  ],
  "service-disconnected.service.name.server": [
    {
      "type": 0,
      "value": "server",
    },
  ],
  "shortcuts.editor.eraser.description": [
    {
      "type": 0,
      "value": "Erase annotated pixels",
    },
  ],
  "shortcuts.editor.export.description": [
    {
      "type": 0,
      "value": "Export the project (images/annotations/masks/metadata)",
    },
  ],
  "shortcuts.editor.redo.description": [
    {
      "type": 0,
      "value": "Redo the last annotation edition action",
    },
  ],
  "shortcuts.editor.select-class": [
    {
      "type": 0,
      "value": "Select class",
    },
  ],
  "shortcuts.editor.start-pan.description": [
    {
      "type": 0,
      "value": "Pan the image in the viewer",
    },
  ],
  "shortcuts.editor.undo.description": [
    {
      "type": 0,
      "value": "Undo the last annotation edition action",
    },
  ],
  "shortcuts.group.general.label": [
    {
      "type": 0,
      "value": "General",
    },
  ],
  "shortcuts.groups.editor.label": [
    {
      "type": 0,
      "value": "Annotation Editor",
    },
  ],
  "shortcuts.groups.help.label": [
    {
      "type": 0,
      "value": "Help",
    },
  ],
  "shortcuts.modal.title": [
    {
      "type": 0,
      "value": "Shortcuts List",
    },
  ],
  "shortcuts.navigation.next-image.description": [
    {
      "type": 0,
      "value": "Go to next image",
    },
  ],
  "shortcuts.navigation.previous-image.description": [
    {
      "type": 0,
      "value": "Go to previous image",
    },
  ],
  "signUp.withMicrosoft": [
    {
      "type": 0,
      "value": "Sign Up using your Microsoft account",
    },
  ],
  "tool.parameters.freehand.label": [
    {
      "type": 0,
      "value": "Freehand",
    },
  ],
  "tool.parameters.title": [
    {
      "type": 0,
      "value": "Tool Parameters",
    },
  ],
  "tools.annotation.control.title": [
    {
      "type": 0,
      "value": "Control",
    },
  ],
  "tools.annotation.hide": [
    {
      "type": 0,
      "value": "Hide direct measure and metadata annotation",
    },
  ],
  "tools.annotation.show": [
    {
      "type": 0,
      "value": "Show direct measure and metadata annotation",
    },
  ],
  "tools.annotation.title": [
    {
      "type": 0,
      "value": "Annotation",
    },
  ],
  "tools.annotations.brush": [
    {
      "type": 0,
      "value": "Brush (B)",
    },
  ],
  "tools.annotations.eraser": [
    {
      "type": 0,
      "value": "Eraser (E)",
    },
  ],
  "tools.annotations.redo": [
    {
      "type": 0,
      "value": "Redo (",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+Shift+Z)",
    },
  ],
  "tools.annotations.smart-annotation.magic-wand.on": [
    {
      "type": 0,
      "value": "Magic Wand (w)",
    },
  ],
  "tools.annotations.undo": [
    {
      "type": 0,
      "value": "Undo (",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+Z)",
    },
  ],
  "tools.direct-measure.angle": [
    {
      "type": 0,
      "value": "Angle (v)",
    },
  ],
  "tools.direct-measure.arc": [
    {
      "type": 0,
      "value": "Arc",
    },
  ],
  "tools.direct-measure.area": [
    {
      "type": 0,
      "value": "Area",
    },
  ],
  "tools.direct-measure.distance": [
    {
      "type": 0,
      "value": "Distance (m)",
    },
  ],
  "tools.direct-measure.edit.title": [
    {
      "type": 0,
      "value": "Control",
    },
  ],
  "tools.direct-measure.ellipse": [
    {
      "type": 0,
      "value": "Ellipse.",
    },
    {
      "type": 1,
      "value": "newline",
    },
    {
      "type": 0,
      "value": " Hold Shift while drawing for a circle.",
    },
  ],
  "tools.direct-measure.perimeter": [
    {
      "type": 0,
      "value": "Perimeter",
    },
  ],
  "tools.direct-measure.rectangle": [
    {
      "type": 0,
      "value": "Rectangle",
    },
  ],
  "tools.direct-measure.title": [
    {
      "type": 0,
      "value": "Direct Measure",
    },
  ],
  "tools.metadata-annotation.arrow": [
    {
      "type": 0,
      "value": "Arrow",
    },
  ],
  "tools.metadata-annotation.ellipse": [
    {
      "type": 0,
      "value": "Ellipse. While drawing keep shift for circle.",
    },
  ],
  "tools.metadata-annotation.line": [
    {
      "type": 0,
      "value": "Line",
    },
  ],
  "tools.metadata-annotation.polygon": [
    {
      "type": 0,
      "value": "Polygon",
    },
  ],
  "tools.metadata-annotation.rectangle": [
    {
      "type": 0,
      "value": "Rectangle",
    },
  ],
  "tools.metadata-annotation.text": [
    {
      "type": 0,
      "value": "Text",
    },
  ],
  "tools.metadata-annotation.title": [
    {
      "type": 0,
      "value": "Documentation",
    },
  ],
  "tools.select": [
    {
      "type": 0,
      "value": "Selection tools",
    },
  ],
  "tools.selection.delete": [
    {
      "type": 0,
      "value": "Delete selection",
    },
  ],
  "tools.view.ZoomIn": [
    {
      "type": 0,
      "value": "Zoom in (",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+=)",
    },
  ],
  "tools.view.image-filter": [
    {
      "type": 0,
      "value": "Image Filter",
    },
  ],
  "tools.view.pan": [
    {
      "type": 0,
      "value": "Pan (Hold space and drag) or (P)",
    },
  ],
  "tools.view.pixel-grid-toggle.hide": [
    {
      "type": 0,
      "value": "Hide Pixel grid",
    },
  ],
  "tools.view.pixel-grid-toggle.show": [
    {
      "type": 0,
      "value": "Show Pixel grid",
    },
  ],
  "tools.view.zoomOut": [
    {
      "type": 0,
      "value": "Zoom out (",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+-)",
    },
  ],
  "tools.view.zoomReset": [
    {
      "type": 0,
      "value": "Zoom reset (",
    },
    {
      "type": 1,
      "value": "ctrlModifier",
    },
    {
      "type": 0,
      "value": "+0)",
    },
  ],
  "training-elapsed-time.last-training.label": [
    {
      "type": 0,
      "value": "Last Training:",
    },
  ],
  "training-elapsed-time.training-failed.value": [
    {
      "type": 0,
      "value": "Training Failed",
    },
  ],
  "training-elapsed-time.training-in-progress.value": [
    {
      "type": 0,
      "value": "In progress",
    },
  ],
  "training-elapsed-time.training-snapshot-unavailable.value": [
    {
      "type": 0,
      "value": "Not available",
    },
  ],
  "training-options.models.label": [
    {
      "type": 0,
      "value": "Models",
    },
  ],
  "training-section.algorithm-options.label": [
    {
      "type": 0,
      "value": "Training Options",
    },
  ],
  "tutorial-tour.annotation-page.annotate-and-train.content.1": [
    {
      "type": 0,
      "value": "In this page, you tell Studio what you expect it to find on your images.",
    },
  ],
  "tutorial-tour.annotation-page.annotate-and-train.content.2": [
    {
      "type": 0,
      "value": "Using simple painting tools fill in the areas of the image draw over objects or structure you are looking for in an image.",
    },
  ],
  "tutorial-tour.annotation-page.annotate-and-train.title": [
    {
      "type": 0,
      "value": "Welcome to Project Editor",
    },
  ],
  "tutorial-tour.annotation-page.classes.actions-1": [
    {
      "type": 0,
      "value": "Click the + sign to add classes",
    },
  ],
  "tutorial-tour.annotation-page.classes.actions-2": [
    {
      "type": 0,
      "value": "Double click on the class name to edit its name",
    },
  ],
  "tutorial-tour.annotation-page.classes.actions-3": [
    {
      "type": 0,
      "value": "Click on the class name to activate it to use with tools",
    },
  ],
  "tutorial-tour.annotation-page.classes.actions-4": [
    {
      "type": 0,
      "value": "Show or Hide annotations",
    },
  ],
  "tutorial-tour.annotation-page.classes.actions-5": [
    {
      "type": 0,
      "value": "Show or Hide predictions",
    },
  ],
  "tutorial-tour.annotation-page.classes.content-1": [
    {
      "type": 0,
      "value": "Classes are the types of object you want to detect. Let's say you wanted Studio to detect cats and dogs in images. Then you would create two classes: a cat and a dog class. In metallurgy, with a delta-ferrite micrograph, you would create an Austenite and a Ferrite class.",
    },
  ],
  "tutorial-tour.annotation-page.classes.content-2": [
    {
      "type": 0,
      "value": "Some features available:",
    },
  ],
  "tutorial-tour.annotation-page.classes.title": [
    {
      "type": 0,
      "value": "Classes",
    },
  ],
  "tutorial-tour.annotation-page.upload-image.alternative.content": [
    {
      "type": 0,
      "value": "Or, click on the + icon above",
    },
  ],
  "tutorial-tour.annotation-page.upload-image.content-1": [
    {
      "type": 0,
      "value": "Upload a few images that are representative of your sample: As few as 1 to 10 images will give adequate results.",
    },
  ],
  "tutorial-tour.annotation-page.upload-image.content-2": [
    {
      "type": 0,
      "value": "To do this, drag and drop an image in the left section.",
    },
  ],
  "tutorial-tour.annotation-page.upload-image.title": [
    {
      "type": 0,
      "value": "Upload your images",
    },
  ],
  "tutorial-tour.cancel.confirm.skip": [
    {
      "type": 0,
      "value": "Skip tutorial",
    },
  ],
  "tutorial-tour.cancel.confirm.title": [
    {
      "type": 0,
      "value": "Do you want to skip the tutorial ?",
    },
  ],
  "tutorial-tour.project-list.create-project.content": [
    {
      "type": 0,
      "value": "Click on that button to create a new empty project.",
    },
  ],
  "tutorial-tour.project-list.create-project.title": [
    {
      "type": 0,
      "value": "Create a new project",
    },
  ],
  "tutorial-tour.project-list.edit-project.content": [
    {
      "type": 0,
      "value": "Click on this gear to display the project settings.",
    },
  ],
  "tutorial-tour.project-list.edit-project.title": [
    {
      "type": 0,
      "value": "Edit a project settings",
    },
  ],
  "tutorial-tour.project-list.open-project.content": [
    {
      "type": 0,
      "value": "Click on the project miniature image to access the project annotation page.",
    },
  ],
  "tutorial-tour.project-list.open-project.title": [
    {
      "type": 0,
      "value": "Open a project",
    },
  ],
  "tutorial-tour.project-list.project.content-1": [
    {
      "type": 0,
      "value": "The project list is where you can quickly access your projects.",
    },
  ],
  "tutorial-tour.project-list.project.content-2": [
    {
      "type": 0,
      "value": "A project is where you can create a custom image segmentation algorithm that fit your needs.",
    },
  ],
  "tutorial-tour.project-list.project.content-3": [
    {
      "type": 0,
      "value": "To get you started, you can modify any of the existing projects.",
    },
  ],
  "tutorial-tour.project-list.project.title": [
    {
      "type": 0,
      "value": "Project List",
    },
  ],
  "tutorial-tour.project-list.welcome.content-1": [
    {
      "type": 0,
      "value": "Clemex Studio is a No-Code tool to produce stunning segmentation and detection of objects in images.",
    },
  ],
  "tutorial-tour.project-list.welcome.content-5.url": [
    {
      "type": 0,
      "value": "Clemex Vision",
    },
  ],
  "tutorial-tour.project-list.welcome.title": [
    {
      "type": 0,
      "value": "Welcome to Clemex Studio!",
    },
  ],
  "tutorial.annotation-page.add.smart-annotation.magic-wand.content-1": [
    {
      "type": 0,
      "value": "The Magic Wand tool allows you to add annotations to an image.",
    },
  ],
  "tutorial.annotation-page.add.smart-annotation.magic-wand.content-2": [
    {
      "type": 0,
      "value": "The Magic Wand segments the image and adds annotations with a single click.",
    },
  ],
  "tutorial.annotation-page.add.smart-annotation.magic-wand.content-3": [
    {
      "type": 0,
      "value": "Undo and redo features are available.",
    },
  ],
  "tutorial.annotation-page.add.smart-annotation.magic-wand.title": [
    {
      "type": 0,
      "value": "Magic Wand Tool",
    },
  ],
  "tutorial.annotation-page.annotate-and-tools.content-1": [
    {
      "type": 0,
      "value": "Annotations tell Studio where you expect to find the objects or structure you are looking for in an image.",
    },
  ],
  "tutorial.annotation-page.annotate-and-tools.content-2": [
    {
      "type": 0,
      "value": "To annotate, the brush stroke size and paint the object. Only a few strokes at a time are necessary.",
    },
  ],
  "tutorial.annotation-page.annotate-and-tools.content-3": [
    {
      "type": 0,
      "value": "By letting the cursor over an icon will display tool tips.",
    },
  ],
  "tutorial.annotation-page.annotate-and-tools.title": [
    {
      "type": 0,
      "value": "Tools",
    },
  ],
  "tutorial.annotation-page.next.content-1": [
    {
      "type": 0,
      "value": "Validate that Studio understood well your segmentation or detection expectations.",
    },
  ],
  "tutorial.annotation-page.next.content-2": [
    {
      "type": 0,
      "value": "Click on the \"Validate\" button above to go to the validation page.",
    },
  ],
  "tutorial.annotation-page.next.title": [
    {
      "type": 0,
      "value": "Validation page",
    },
  ],
  "tutorial.annotation-page.train.content-1": [
    {
      "type": 0,
      "value": "Hit the Train button to proceed with the automatic prediction on the whole series of imported images. In typical use cases this process takes approximately 8 to 30 seconds.",
    },
  ],
  "tutorial.annotation-page.train.content-2": [
    {
      "type": 0,
      "value": "Repeat annotations and train until you are satisfied with how Studio predicts what you are looking for.",
    },
  ],
  "tutorial.annotation-page.train.title": [
    {
      "type": 0,
      "value": "Train",
    },
  ],
  "tutorial.icon.button.reset-project.modal.cancel.text": [
    {
      "type": 0,
      "value": "No",
    },
  ],
  "tutorial.icon.button.reset-project.modal.ok.text": [
    {
      "type": 0,
      "value": "Yes",
    },
  ],
  "tutorial.validate-editor.image-annotation.content-1": [
    {
      "type": 0,
      "value": "The prediction on the image is not what you expected? You see that Studio is not recognizing a structure that you would like it to find?",
    },
  ],
  "tutorial.validate-editor.image-annotation.content-2": [
    {
      "type": 0,
      "value": "Then right click on the image available here and send it to annotation. This will enable you to make additional annotations on images where Studio has not learned properly what you expect from it.",
    },
  ],
  "tutorial.validate-editor.image-annotation.title": [
    {
      "type": 0,
      "value": "Send Image To Annotation",
    },
  ],
  "tutorial.validate-editor.plugin.content-1": [
    {
      "type": 0,
      "value": "When you're satisfied with the results, click on the download button to grab your ",
    },
    {
      "type": 1,
      "value": "visionDescriptionUrl",
    },
    {
      "type": 0,
      "value": " plugin. Use Vision paired with the plugin to produce in depth analysis.",
    },
  ],
  "tutorial.validate-editor.plugin.title": [
    {
      "type": 0,
      "value": "Download",
    },
  ],
  "tutorial.validate-editor.upload-image.alternative.content": [
    {
      "type": 0,
      "value": "Or, click the + icon above",
    },
  ],
  "tutorial.validate-editor.upload-image.content-1": [
    {
      "type": 0,
      "value": "Upload images that are representative of the objects and structures you want to detect but that were not used in the annotate and train step. For most problems, 5 to 10 images are sufficient.",
    },
  ],
  "tutorial.validate-editor.upload-image.content-2": [
    {
      "type": 0,
      "value": "To do this, drag and drop an image in the left section.",
    },
  ],
  "tutorial.validate-editor.upload.title": [
    {
      "type": 0,
      "value": "Upload images",
    },
  ],
  "tutorial.validate-editor.validate.content": [
    {
      "type": 0,
      "value": "It is standard practice to validate that a trained artificial intelligence system will perform well on images that were not used for training.",
    },
  ],
  "tutorial.validate-editor.validate.title": [
    {
      "type": 0,
      "value": "Welcome to Validation",
    },
  ],
  "tutorial.validate-editor.visualize-results-hide.content-1": [
    {
      "type": 0,
      "value": "You can hide specific classes using the associated button",
    },
  ],
  "tutorial.validate-editor.visualize-results-hide.title": [
    {
      "type": 0,
      "value": "Vizualize Results Classes",
    },
  ],
  "tutorial.validate-editor.visualize-results-opacity.content-1": [
    {
      "type": 0,
      "value": "Use the opacity slider and show/hide button to switch between the image and prediction.",
    },
  ],
  "tutorial.validate-editor.visualize-results-opacity.title": [
    {
      "type": 0,
      "value": "Vizualize Results Opacity",
    },
  ],
  "tutorial.validate-editor.visualize-results.content-1": [
    {
      "type": 0,
      "value": "Now that your images are uploaded, Studio will run class predictions on each of those images. See the result and make sure that the Studio makes prediction that looks right to you",
    },
  ],
  "validate-editor.download-button.user-feedback.thanks": [
    {
      "type": 0,
      "value": "Thanks for your feedback!",
    },
  ],
  "validate-editor.download-button.user-feedback.title": [
    {
      "type": 0,
      "value": "Please rate your experience",
    },
  ],
}
