import { requestSmartAnnotation } from '@app/api/api'
import { useImageClassAnnotations } from '@app/api/hooks'
import { ProjectDatasetContextInput as ProjectDatasetContext, type ClassAnnotationGeoJSONResponsePart, type UpdateClassAnnotationPartRequest } from '@app/api/openapi'
import { EVENTS_ID } from '@app/constants'
import { useSelectedAnnotationClassColorIndex, useDatasetContext, useProjectId, useSelectedImage, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { useEditorShortcutHook } from '@app/pages/editor-page/hooks/editor-shortcut'
import { type ChangePatch, ClemexMosaicCanvasListenersType, FEATURE_TYPE, FeatureType, type ClassAnnotationProperties } from '@clemex/mosaic-canvas'
import { swapPointCoordinates, swapPolygonCoordinates } from '@openlayer/helper'
import { notification } from 'antd'
import { type FeatureCollection, type Polygon as GeoJSONPolygon } from 'geojson'
import { Feature } from 'ol'
import { Polygon, Point } from 'ol/geom'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import * as Sentry from '@sentry/react'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import type GeoJSON from 'ol/format/GeoJSON'
import { useEditorStore } from '@app/stores/editor'

type AnnotationHistory = Record<string, { index: number, stack: ClassAnnotationGeoJSONResponsePart[][] } | null>

const classAnnotationFeaturesToGeoJSON = (features: Feature<Polygon>[], geojsonParser: GeoJSON): UpdateClassAnnotationPartRequest[] => {
  const changedAnnotationsFeature = geojsonParser.writeFeaturesObject(features) as unknown as FeatureCollection<GeoJSONPolygon, ClassAnnotationProperties>
  const changedAnnotations = changedAnnotationsFeature.features.map((feature): UpdateClassAnnotationPartRequest => {
    return {
      colorIndex: feature.properties.colorIndex,
      classAnnotationId: feature.properties.classAnnotationId,
      geometry: {
        type: 'Polygon',
        coordinates: swapPolygonCoordinates(feature.geometry.coordinates),
      },
    }
  })
  return changedAnnotations
}

const classAnnotationGeoJSONToFeature = (classAnnotation: ClassAnnotationGeoJSONResponsePart): Feature<Polygon> => {
  return new Feature({
    geometry: new Polygon(
      swapPolygonCoordinates(classAnnotation.geometry.coordinates as number[][][]),
    ),
    colorIndex: classAnnotation.colorIndex,
    classAnnotationId: classAnnotation.classAnnotationId,
  })
}

const classAnnotationGeoJSONToFeatures = (classAnnotations: ClassAnnotationGeoJSONResponsePart[]): Feature<Polygon>[] => {
  const features = classAnnotations.map((annotation) => classAnnotationGeoJSONToFeature(annotation))
  features.forEach((feature) => {
    feature.setProperties({
      [FEATURE_TYPE]: FeatureType.CLASS_ANNOTATION,
    })
  })
  return features
}

export const useCanvasClassAnnotations = (clemexMosaicCanvas: ClemexStudioMosaicCanvas, geojsonParser: GeoJSON): void => {
  const projectId = useProjectId()
  const datasetContext = useDatasetContext()
  const selectedImageId = useSelectedImageId()
  const selectedImage = useSelectedImage()
  const setAnnotationColorIndexVisibility = useEditorStore(store => store.setAnnotationColorIndexVisibility)

  const { data: projectImageAnnotations, isLoading: isLoadingProjectImageAnnotations, isValidating: isValidatingProjectImageAnnotations, saveClassAnnotations: saveImageAnnotations } = useImageClassAnnotations(projectId, selectedImageId)
  const [selectedAnnotationClassColorIndex] = useSelectedAnnotationClassColorIndex()

  const annotationsHistory = useRef<AnnotationHistory>({})
  const hasUserChangedAnnotationsRef = useRef(false)

  const [isWaitingForMagicWandSelectedImageId, setIsWaitingForMagicWandSelectedImageId] = useState<string | undefined>(undefined)
  const [notificationApi] = notification.useNotification()

  const saveAnnotations = useCallback((annotationFeatures: Feature<Polygon>[], _projectId: string, _imageId: string) => {
    const annotationsGeoJSON = classAnnotationFeaturesToGeoJSON(annotationFeatures, geojsonParser)
    if (!hasUserChangedAnnotationsRef.current) {
      return
    }
    void saveImageAnnotations({ classAnnotationsGeoJSON: annotationsGeoJSON, projectId: _projectId, imageId: _imageId })
    hasUserChangedAnnotationsRef.current = false
  }, [geojsonParser, saveImageAnnotations])

  // Debounce saveAnnotations
  // We want to debounce saveAnnotations to avoid saving the annotations too often.
  // As many events can trigger saveAnnotations, it can quickly become a performance bottleneck.
  const saveAnnotationsDebounce = useDebouncedCallback(saveAnnotations, 300)

  // Bind EDITOR_FORCE_SAVE_ANNOTATIONS
  // When the event is triggered, flush the debounced saveAnnotations.
  useEffect(() => {
    const onForceSaveAnnotations = (): void => {
      if (clemexMosaicCanvas === null || selectedImageId === undefined) {
        return
      }
      saveAnnotationsDebounce.flush()
    }
    window.addEventListener(EVENTS_ID.EDITOR_FORCE_SAVE_ANNOTATIONS, onForceSaveAnnotations, {})
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_FORCE_SAVE_ANNOTATIONS, onForceSaveAnnotations, {})
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce])

  // Update the annotations when the annotations are loaded.
  // And remove them when the annotations the context is undefined or validation.
  useEffect(() => {
    // Before the annotations context changes, we want to save the annotations in debounced saveAnnotations.
    // Otherwise, the annotations for this context will be lost.
    saveAnnotationsDebounce.flush()
    if (projectImageAnnotations === undefined || isLoadingProjectImageAnnotations || projectId === undefined || selectedImageId === undefined || datasetContext === ProjectDatasetContext.Validation) {
      clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures([]))
    } else {
      if (!isValidatingProjectImageAnnotations) {
        clemexMosaicCanvas.setAnnotationProperties({ projectId, imageId: selectedImageId })
        clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(projectImageAnnotations))
        // If history is empty, initialize it.
        if (annotationsHistory.current[selectedImageId] === undefined || annotationsHistory.current[selectedImageId] === null) {
          annotationsHistory.current[selectedImageId] = { index: 0, stack: [projectImageAnnotations] }
        }
      }
    }
  }, [clemexMosaicCanvas, datasetContext, isLoadingProjectImageAnnotations, isValidatingProjectImageAnnotations, projectId, projectImageAnnotations, saveAnnotationsDebounce, selectedImageId])

  // When annotations are changed, save annotations and set hasUserChangedAnnotations to true.
  // This is used to save the annotations when the user is changing image in case the saving is debounced.
  useEffect(() => {
    const onAnnotationChange = (annotations: ChangePatch<Feature<Polygon>>): void => {
      if (selectedImageId === undefined || projectId === undefined) {
        return
      }
      hasUserChangedAnnotationsRef.current = true
      const newAnnotations = annotations.add?.map((addPatch) => addPatch.data)
      if (newAnnotations !== undefined) {
        // Note: We need to forward mutateAnnotations as it is bound to its cache key context (projectId, imageId).
        //       We do not want that the mutation is applied to the wrong image.
        saveAnnotationsDebounce(newAnnotations, projectId, selectedImageId)
      }
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, onAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, saveAnnotationsDebounce, saveAnnotations, projectId, selectedImageId])

  // When the annotation changes, update the annotation history.
  useEffect(() => {
    const onAnnotationChange = (annotations: ChangePatch<Feature<Polygon>>): void => {
      const newAnnotations = annotations.add?.map((addPatch) => addPatch.data)
      if (newAnnotations === undefined) {
        return
      }
      if (selectedImageId === undefined) {
        return
      }

      if (annotationsHistory.current[selectedImageId] === undefined) {
        annotationsHistory.current[selectedImageId] = { index: 0, stack: [[]] }
      }
      const annotationHistory = annotationsHistory.current[selectedImageId]
      if (annotationHistory === null) {
        annotationsHistory.current[selectedImageId] = { index: 0, stack: [classAnnotationFeaturesToGeoJSON(newAnnotations, geojsonParser)] }
      } else {
        if (annotationHistory.index < annotationHistory.stack.length - 1) {
          annotationHistory.stack = annotationHistory.stack.slice(0, annotationHistory.index + 1)
        }
        annotationHistory.stack = [...annotationHistory.stack, classAnnotationFeaturesToGeoJSON(newAnnotations, geojsonParser)]
        annotationHistory.index = annotationHistory.stack.length - 1
      }
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, onAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, geojsonParser, selectedImageId])

  const undo = useCallback(() => {
    const annotationHistory = (selectedImageId !== undefined && annotationsHistory.current[selectedImageId] !== undefined)
      ? annotationsHistory.current[selectedImageId]
      : undefined
    if (annotationHistory === undefined || annotationHistory === null) {
      return
    }
    if (annotationHistory.index > 0) {
      annotationHistory.index -= 1
    }
    const annotations = annotationHistory.stack[annotationHistory.index]
    clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(annotations))
    hasUserChangedAnnotationsRef.current = true
    if (projectId !== undefined && selectedImageId !== undefined) {
      saveAnnotationsDebounce(classAnnotationGeoJSONToFeatures(annotations), projectId, selectedImageId)
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce, projectId])

  const redo = useCallback(() => {
    const annotationHistory = (selectedImageId !== undefined && annotationsHistory.current[selectedImageId] !== undefined)
      ? annotationsHistory.current[selectedImageId]
      : undefined
    if (annotationHistory === undefined || annotationHistory === null) {
      return
    }
    if (annotationHistory.index < annotationHistory.stack.length - 1) {
      annotationHistory.index += 1
    }
    const annotations = annotationHistory.stack[annotationHistory.index]
    clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(annotations))
    hasUserChangedAnnotationsRef.current = true
    if (projectId !== undefined && selectedImageId !== undefined) {
      saveAnnotationsDebounce(classAnnotationGeoJSONToFeatures(annotations), projectId, selectedImageId)
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce, projectId])

  // Bind Undo/Redo behavior
  useEditorShortcutHook({ undo, redo })

  // Bind EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET
  useEffect(() => {
    const onResetHistory = (): void => {
      annotationsHistory.current = { }
      if (selectedImageId !== undefined) {
        annotationsHistory.current[selectedImageId] = null
      }
    }
    window.addEventListener(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET, onResetHistory)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET, onResetHistory)
    }
  }, [clemexMosaicCanvas, selectedImageId])

  // When the image changes, we abort any magic wand in progress.
  useEffect(() => {
    if (selectedImageId === undefined) {
      return
    }
    if (isWaitingForMagicWandSelectedImageId === undefined) {
      return
    }

    if (isWaitingForMagicWandSelectedImageId !== selectedImageId) {
      clemexMosaicCanvas.abortAllSmartAnnotation()
      setIsWaitingForMagicWandSelectedImageId(undefined)
    }
  }, [clemexMosaicCanvas, isWaitingForMagicWandSelectedImageId, selectedImageId, setIsWaitingForMagicWandSelectedImageId])

  // When the smart annotation is selected
  useEffect(() => {
    const onSmartAnnotationAbort = (): void => {
      if (selectedImageId === undefined) {
        return
      }
      setIsWaitingForMagicWandSelectedImageId(undefined)
    }
    const onSmartAnnotationSelect = async (smartAnnotationPosition: Feature<Point>): Promise<void> => {
      if (selectedImageId === undefined || projectId === undefined) {
        return
      }
      hasUserChangedAnnotationsRef.current = true

      const newPoint = new Point(swapPointCoordinates(smartAnnotationPosition.getGeometry()?.getCoordinates() as unknown as number[]))

      // Request made inside the image
      const image = selectedImage?.data
      if (image !== undefined) {
        const coordinates = newPoint.getCoordinates()
        if (coordinates[0] < 0 || coordinates[0] >= image.width || coordinates[1] < 0 || coordinates[1] >= image.height) {
          clemexMosaicCanvas.abortSmartAnnotation(smartAnnotationPosition)
          return
        }
      }

      setIsWaitingForMagicWandSelectedImageId(selectedImageId)
      try {
        const smartAnnotations = await requestSmartAnnotation(projectId, selectedImageId,
          selectedAnnotationClassColorIndex,
          [[Math.floor(newPoint.getCoordinates()[0]), Math.floor(newPoint.getCoordinates()[1])]],
        )
        const olGeoJSONSmartAnnotations = classAnnotationGeoJSONToFeatures(smartAnnotations)
        const biggestSmartAnnotation = olGeoJSONSmartAnnotations.reduce((previous, current) => {
          const currentArea = current.getGeometry()?.getArea() ?? 0
          const previousArea = previous.getGeometry()?.getArea() ?? 0
          return currentArea > previousArea ? current : previous
        }, olGeoJSONSmartAnnotations[0])
        clemexMosaicCanvas.addClassAnnotationFromSmartAnnotation([biggestSmartAnnotation], smartAnnotationPosition)

        // Set the visibility of the annotation color index
        setAnnotationColorIndexVisibility(selectedAnnotationClassColorIndex, true)

      } catch (error) {
        notificationApi.error({
          message: <FormattedMessage
            id='project.smart-annotation.magic-wand.error'
            defaultMessage='There was an error with the Magic Wand. Please try again later or contact us' />,
        })

        Sentry.captureException(error)
        console.error('Error with the Magic Wand', error)

        clemexMosaicCanvas.abortSmartAnnotation(smartAnnotationPosition)
      }

      setIsWaitingForMagicWandSelectedImageId(undefined)
    }

    const smartAnnotationListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.START_SMART_ANNOTATION, onSmartAnnotationSelect)
    const smartAnnotationAbortedListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.STOP_SMART_ANNOTATION, onSmartAnnotationAbort)
    return () => {
      smartAnnotationListenerCB()
      smartAnnotationAbortedListenerCB()
    }
  }, [clemexMosaicCanvas, notificationApi, projectId, selectedAnnotationClassColorIndex, selectedImage, selectedImageId, setAnnotationColorIndexVisibility])

  useEffect(() => {
    if (clemexMosaicCanvas === null || selectedAnnotationClassColorIndex === undefined) {
      return
    }
    clemexMosaicCanvas.setColorIndex(selectedAnnotationClassColorIndex)

    // Cancel ongoing smart annotation when color index changes
    clemexMosaicCanvas.abortAllSmartAnnotation()
    setIsWaitingForMagicWandSelectedImageId(undefined)
  }, [clemexMosaicCanvas, selectedAnnotationClassColorIndex])

  useEffect(() => {
    const onAddClassAnnotation = (event: Event): void => {
      const customEvent = event as CustomEvent<{ geometry: GeoJSONPolygon, colorIndex: number }>
      const { colorIndex, geometry } = customEvent.detail
      const features = [
        new Feature({
          geometry: new Polygon(swapPolygonCoordinates(geometry.coordinates)),
        }),
      ]
      clemexMosaicCanvas.addClassAnnotations(features, colorIndex)
    }
    window.addEventListener(EVENTS_ID.CANVAS_ADD_ANNOTATION, onAddClassAnnotation)
    return () => {
      window.removeEventListener(EVENTS_ID.CANVAS_ADD_ANNOTATION, onAddClassAnnotation)
    }
  }, [clemexMosaicCanvas])
}
