/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlgorithmId } from './AlgorithmId';
import {
    AlgorithmIdFromJSON,
    AlgorithmIdFromJSONTyped,
    AlgorithmIdToJSON,
    AlgorithmIdToJSONTyped,
} from './AlgorithmId';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
    DeepLearningTrainingParametersValueObjectToJSONTyped,
} from './DeepLearningTrainingParametersValueObject';
import type { DirectMeasureSettingsValueObject } from './DirectMeasureSettingsValueObject';
import {
    DirectMeasureSettingsValueObjectFromJSON,
    DirectMeasureSettingsValueObjectFromJSONTyped,
    DirectMeasureSettingsValueObjectToJSON,
    DirectMeasureSettingsValueObjectToJSONTyped,
} from './DirectMeasureSettingsValueObject';
import type { ExportOptionsValueObject } from './ExportOptionsValueObject';
import {
    ExportOptionsValueObjectFromJSON,
    ExportOptionsValueObjectFromJSONTyped,
    ExportOptionsValueObjectToJSON,
    ExportOptionsValueObjectToJSONTyped,
} from './ExportOptionsValueObject';
import type { MeasurementSettingsValueObject } from './MeasurementSettingsValueObject';
import {
    MeasurementSettingsValueObjectFromJSON,
    MeasurementSettingsValueObjectFromJSONTyped,
    MeasurementSettingsValueObjectToJSON,
    MeasurementSettingsValueObjectToJSONTyped,
} from './MeasurementSettingsValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
    RfAlgorithmTrainingParametersValueObjectToJSONTyped,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * Project settings.
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettings
     */
    projectId: string;
    /**
     * 
     * @type {AlgorithmId}
     * @memberof ProjectSettings
     */
    algorithmId: AlgorithmId;
    /**
     * 
     * @type {RfAlgorithmTrainingParametersValueObject}
     * @memberof ProjectSettings
     */
    rfAlgorithmTrainingParameters?: RfAlgorithmTrainingParametersValueObject;
    /**
     * 
     * @type {DirectMeasureSettingsValueObject}
     * @memberof ProjectSettings
     */
    directMeasureSettings?: DirectMeasureSettingsValueObject;
    /**
     * 
     * @type {DeepLearningTrainingParametersValueObject}
     * @memberof ProjectSettings
     */
    deepLearningTrainingParameters?: DeepLearningTrainingParametersValueObject | null;
    /**
     * 
     * @type {ExportOptionsValueObject}
     * @memberof ProjectSettings
     */
    exportOptions?: ExportOptionsValueObject;
    /**
     * 
     * @type {MeasurementSettingsValueObject}
     * @memberof ProjectSettings
     */
    measurementSettings?: MeasurementSettingsValueObject;
}



/**
 * Check if a given object implements the ProjectSettings interface.
 */
export function instanceOfProjectSettings(value: object): value is ProjectSettings {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('algorithmId' in value) || value['algorithmId'] === undefined) return false;
    return true;
}

export function ProjectSettingsFromJSON(json: any): ProjectSettings {
    return ProjectSettingsFromJSONTyped(json, false);
}

export function ProjectSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'algorithmId': AlgorithmIdFromJSON(json['algorithm_id']),
        'rfAlgorithmTrainingParameters': json['rf_algorithm_training_parameters'] == null ? undefined : RfAlgorithmTrainingParametersValueObjectFromJSON(json['rf_algorithm_training_parameters']),
        'directMeasureSettings': json['direct_measure_settings'] == null ? undefined : DirectMeasureSettingsValueObjectFromJSON(json['direct_measure_settings']),
        'deepLearningTrainingParameters': json['deep_learning_training_parameters'] == null ? undefined : DeepLearningTrainingParametersValueObjectFromJSON(json['deep_learning_training_parameters']),
        'exportOptions': json['export_options'] == null ? undefined : ExportOptionsValueObjectFromJSON(json['export_options']),
        'measurementSettings': json['measurement_settings'] == null ? undefined : MeasurementSettingsValueObjectFromJSON(json['measurement_settings']),
    };
}

export function ProjectSettingsToJSON(json: any): ProjectSettings {
    return ProjectSettingsToJSONTyped(json, false);
}

export function ProjectSettingsToJSONTyped(value?: ProjectSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_id': value['projectId'],
        'algorithm_id': AlgorithmIdToJSON(value['algorithmId']),
        'rf_algorithm_training_parameters': RfAlgorithmTrainingParametersValueObjectToJSON(value['rfAlgorithmTrainingParameters']),
        'direct_measure_settings': DirectMeasureSettingsValueObjectToJSON(value['directMeasureSettings']),
        'deep_learning_training_parameters': DeepLearningTrainingParametersValueObjectToJSON(value['deepLearningTrainingParameters']),
        'export_options': ExportOptionsValueObjectToJSON(value['exportOptions']),
        'measurement_settings': MeasurementSettingsValueObjectToJSON(value['measurementSettings']),
    };
}

