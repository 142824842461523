/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectImageProperties
 */
export interface ProjectImageProperties {
    /**
     * 
     * @type {number}
     * @memberof ProjectImageProperties
     */
    pixelSizeUm?: number | null;
}

/**
 * Check if a given object implements the ProjectImageProperties interface.
 */
export function instanceOfProjectImageProperties(value: object): value is ProjectImageProperties {
    return true;
}

export function ProjectImagePropertiesFromJSON(json: any): ProjectImageProperties {
    return ProjectImagePropertiesFromJSONTyped(json, false);
}

export function ProjectImagePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectImageProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'pixelSizeUm': json['pixel_size_um'] == null ? undefined : json['pixel_size_um'],
    };
}

export function ProjectImagePropertiesToJSON(json: any): ProjectImageProperties {
    return ProjectImagePropertiesToJSONTyped(json, false);
}

export function ProjectImagePropertiesToJSONTyped(value?: ProjectImageProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'pixel_size_um': value['pixelSizeUm'],
    };
}

