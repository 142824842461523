/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DatasetStatistics } from './DatasetStatistics';
import {
    DatasetStatisticsFromJSON,
    DatasetStatisticsFromJSONTyped,
    DatasetStatisticsToJSON,
    DatasetStatisticsToJSONTyped,
} from './DatasetStatistics';
import type { UserStatistics } from './UserStatistics';
import {
    UserStatisticsFromJSON,
    UserStatisticsFromJSONTyped,
    UserStatisticsToJSON,
    UserStatisticsToJSONTyped,
} from './UserStatistics';
import type { ProjectStatistics } from './ProjectStatistics';
import {
    ProjectStatisticsFromJSON,
    ProjectStatisticsFromJSONTyped,
    ProjectStatisticsToJSON,
    ProjectStatisticsToJSONTyped,
} from './ProjectStatistics';

/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {UserStatistics}
     * @memberof Statistics
     */
    users: UserStatistics;
    /**
     * 
     * @type {ProjectStatistics}
     * @memberof Statistics
     */
    projects: ProjectStatistics;
    /**
     * 
     * @type {{ [key: string]: DatasetStatistics; }}
     * @memberof Statistics
     */
    datasets: { [key: string]: DatasetStatistics; };
}

/**
 * Check if a given object implements the Statistics interface.
 */
export function instanceOfStatistics(value: object): value is Statistics {
    if (!('users' in value) || value['users'] === undefined) return false;
    if (!('projects' in value) || value['projects'] === undefined) return false;
    if (!('datasets' in value) || value['datasets'] === undefined) return false;
    return true;
}

export function StatisticsFromJSON(json: any): Statistics {
    return StatisticsFromJSONTyped(json, false);
}

export function StatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Statistics {
    if (json == null) {
        return json;
    }
    return {
        
        'users': UserStatisticsFromJSON(json['users']),
        'projects': ProjectStatisticsFromJSON(json['projects']),
        'datasets': (mapValues(json['datasets'], DatasetStatisticsFromJSON)),
    };
}

export function StatisticsToJSON(json: any): Statistics {
    return StatisticsToJSONTyped(json, false);
}

export function StatisticsToJSONTyped(value?: Statistics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'users': UserStatisticsToJSON(value['users']),
        'projects': ProjectStatisticsToJSON(value['projects']),
        'datasets': (mapValues(value['datasets'], DatasetStatisticsToJSON)),
    };
}

