/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectShareDetails
 */
export interface ProjectShareDetails {
    /**
     * 
     * @type {string}
     * @memberof ProjectShareDetails
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectShareDetails
     */
    sharedBy: string;
}

/**
 * Check if a given object implements the ProjectShareDetails interface.
 */
export function instanceOfProjectShareDetails(value: object): value is ProjectShareDetails {
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('sharedBy' in value) || value['sharedBy'] === undefined) return false;
    return true;
}

export function ProjectShareDetailsFromJSON(json: any): ProjectShareDetails {
    return ProjectShareDetailsFromJSONTyped(json, false);
}

export function ProjectShareDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectShareDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'projectName': json['project_name'],
        'sharedBy': json['shared_by'],
    };
}

export function ProjectShareDetailsToJSON(json: any): ProjectShareDetails {
    return ProjectShareDetailsToJSONTyped(json, false);
}

export function ProjectShareDetailsToJSONTyped(value?: ProjectShareDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_name': value['projectName'],
        'shared_by': value['sharedBy'],
    };
}

