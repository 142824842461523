/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * RfAlgorithmTrainingParametersValueObject contains the parameters to train a random forest algorithm
 * @export
 * @interface RfAlgorithmTrainingParametersValueObject
 */
export interface RfAlgorithmTrainingParametersValueObject {
    /**
     * 
     * @type {number}
     * @memberof RfAlgorithmTrainingParametersValueObject
     */
    sigmaMin: number;
    /**
     * 
     * @type {number}
     * @memberof RfAlgorithmTrainingParametersValueObject
     */
    sigmaMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof RfAlgorithmTrainingParametersValueObject
     */
    intensity: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RfAlgorithmTrainingParametersValueObject
     */
    texture: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RfAlgorithmTrainingParametersValueObject
     */
    edges: boolean;
}

/**
 * Check if a given object implements the RfAlgorithmTrainingParametersValueObject interface.
 */
export function instanceOfRfAlgorithmTrainingParametersValueObject(value: object): value is RfAlgorithmTrainingParametersValueObject {
    if (!('sigmaMin' in value) || value['sigmaMin'] === undefined) return false;
    if (!('sigmaMax' in value) || value['sigmaMax'] === undefined) return false;
    if (!('intensity' in value) || value['intensity'] === undefined) return false;
    if (!('texture' in value) || value['texture'] === undefined) return false;
    if (!('edges' in value) || value['edges'] === undefined) return false;
    return true;
}

export function RfAlgorithmTrainingParametersValueObjectFromJSON(json: any): RfAlgorithmTrainingParametersValueObject {
    return RfAlgorithmTrainingParametersValueObjectFromJSONTyped(json, false);
}

export function RfAlgorithmTrainingParametersValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): RfAlgorithmTrainingParametersValueObject {
    if (json == null) {
        return json;
    }
    return {
        
        'sigmaMin': json['sigma_min'],
        'sigmaMax': json['sigma_max'],
        'intensity': json['intensity'],
        'texture': json['texture'],
        'edges': json['edges'],
    };
}

export function RfAlgorithmTrainingParametersValueObjectToJSON(json: any): RfAlgorithmTrainingParametersValueObject {
    return RfAlgorithmTrainingParametersValueObjectToJSONTyped(json, false);
}

export function RfAlgorithmTrainingParametersValueObjectToJSONTyped(value?: RfAlgorithmTrainingParametersValueObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sigma_min': value['sigmaMin'],
        'sigma_max': value['sigmaMax'],
        'intensity': value['intensity'],
        'texture': value['texture'],
        'edges': value['edges'],
    };
}

