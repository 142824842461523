/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GiveFeedbackRequest
 */
export interface GiveFeedbackRequest {
    /**
     * 
     * @type {number}
     * @memberof GiveFeedbackRequest
     */
    rating: number;
}

/**
 * Check if a given object implements the GiveFeedbackRequest interface.
 */
export function instanceOfGiveFeedbackRequest(value: object): value is GiveFeedbackRequest {
    if (!('rating' in value) || value['rating'] === undefined) return false;
    return true;
}

export function GiveFeedbackRequestFromJSON(json: any): GiveFeedbackRequest {
    return GiveFeedbackRequestFromJSONTyped(json, false);
}

export function GiveFeedbackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GiveFeedbackRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'rating': json['rating'],
    };
}

export function GiveFeedbackRequestToJSON(json: any): GiveFeedbackRequest {
    return GiveFeedbackRequestToJSONTyped(json, false);
}

export function GiveFeedbackRequestToJSONTyped(value?: GiveFeedbackRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'rating': value['rating'],
    };
}

