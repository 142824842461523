/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
}

/**
 * Check if a given object implements the Coordinates interface.
 */
export function instanceOfCoordinates(value: object): value is Coordinates {
    return true;
}

export function CoordinatesFromJSON(json: any): Coordinates {
    return CoordinatesFromJSONTyped(json, false);
}

export function CoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coordinates {
    return json;
}

export function CoordinatesToJSON(json: any): Coordinates {
    return CoordinatesToJSONTyped(json, false);
}

export function CoordinatesToJSONTyped(value?: Coordinates | null, ignoreDiscriminator: boolean = false): any {
    return value;
}

