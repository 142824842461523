/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectTrainingError } from './ProjectTrainingError';
import {
    ProjectTrainingErrorFromJSON,
    ProjectTrainingErrorFromJSONTyped,
    ProjectTrainingErrorToJSON,
    ProjectTrainingErrorToJSONTyped,
} from './ProjectTrainingError';
import type { InProgressTraining } from './InProgressTraining';
import {
    InProgressTrainingFromJSON,
    InProgressTrainingFromJSONTyped,
    InProgressTrainingToJSON,
    InProgressTrainingToJSONTyped,
} from './InProgressTraining';

/**
 * Project Training Status
 * @export
 * @interface ProjectTrainingStatus
 */
export interface ProjectTrainingStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    currentProjectTrainingSnapshotId: string | null;
    /**
     * 
     * @type {ProjectTrainingError}
     * @memberof ProjectTrainingStatus
     */
    trainingError: ProjectTrainingError | null;
    /**
     * 
     * @type {InProgressTraining}
     * @memberof ProjectTrainingStatus
     */
    inProgressTraining: InProgressTraining | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingStatus
     */
    isFailed: boolean;
}

/**
 * Check if a given object implements the ProjectTrainingStatus interface.
 */
export function instanceOfProjectTrainingStatus(value: object): value is ProjectTrainingStatus {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('currentProjectTrainingSnapshotId' in value) || value['currentProjectTrainingSnapshotId'] === undefined) return false;
    if (!('trainingError' in value) || value['trainingError'] === undefined) return false;
    if (!('inProgressTraining' in value) || value['inProgressTraining'] === undefined) return false;
    if (!('isFailed' in value) || value['isFailed'] === undefined) return false;
    return true;
}

export function ProjectTrainingStatusFromJSON(json: any): ProjectTrainingStatus {
    return ProjectTrainingStatusFromJSONTyped(json, false);
}

export function ProjectTrainingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'currentProjectTrainingSnapshotId': json['current_project_training_snapshot_id'],
        'trainingError': ProjectTrainingErrorFromJSON(json['training_error']),
        'inProgressTraining': InProgressTrainingFromJSON(json['in_progress_training']),
        'isFailed': json['is_failed'],
    };
}

export function ProjectTrainingStatusToJSON(json: any): ProjectTrainingStatus {
    return ProjectTrainingStatusToJSONTyped(json, false);
}

export function ProjectTrainingStatusToJSONTyped(value?: ProjectTrainingStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_id': value['projectId'],
        'current_project_training_snapshot_id': value['currentProjectTrainingSnapshotId'],
        'training_error': ProjectTrainingErrorToJSON(value['trainingError']),
        'in_progress_training': InProgressTrainingToJSON(value['inProgressTraining']),
        'is_failed': value['isFailed'],
    };
}

