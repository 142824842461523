/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThicknessMeasureVisualization } from './ThicknessMeasureVisualization';
import {
    ThicknessMeasureVisualizationFromJSON,
    ThicknessMeasureVisualizationFromJSONTyped,
    ThicknessMeasureVisualizationToJSON,
    ThicknessMeasureVisualizationToJSONTyped,
} from './ThicknessMeasureVisualization';

/**
 * 
 * @export
 * @interface ThicknessMeasureImageObject
 */
export interface ThicknessMeasureImageObject {
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMin: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMax: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMedian: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMean: number;
    /**
     * 
     * @type {ThicknessMeasureVisualization}
     * @memberof ThicknessMeasureImageObject
     */
    visualization: ThicknessMeasureVisualization;
}

/**
 * Check if a given object implements the ThicknessMeasureImageObject interface.
 */
export function instanceOfThicknessMeasureImageObject(value: object): value is ThicknessMeasureImageObject {
    if (!('thicknessMin' in value) || value['thicknessMin'] === undefined) return false;
    if (!('thicknessMax' in value) || value['thicknessMax'] === undefined) return false;
    if (!('thicknessMedian' in value) || value['thicknessMedian'] === undefined) return false;
    if (!('thicknessMean' in value) || value['thicknessMean'] === undefined) return false;
    if (!('visualization' in value) || value['visualization'] === undefined) return false;
    return true;
}

export function ThicknessMeasureImageObjectFromJSON(json: any): ThicknessMeasureImageObject {
    return ThicknessMeasureImageObjectFromJSONTyped(json, false);
}

export function ThicknessMeasureImageObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThicknessMeasureImageObject {
    if (json == null) {
        return json;
    }
    return {
        
        'thicknessMin': json['thickness_min'],
        'thicknessMax': json['thickness_max'],
        'thicknessMedian': json['thickness_median'],
        'thicknessMean': json['thickness_mean'],
        'visualization': ThicknessMeasureVisualizationFromJSON(json['visualization']),
    };
}

export function ThicknessMeasureImageObjectToJSON(json: any): ThicknessMeasureImageObject {
    return ThicknessMeasureImageObjectToJSONTyped(json, false);
}

export function ThicknessMeasureImageObjectToJSONTyped(value?: ThicknessMeasureImageObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'thickness_min': value['thicknessMin'],
        'thickness_max': value['thicknessMax'],
        'thickness_median': value['thicknessMedian'],
        'thickness_mean': value['thicknessMean'],
        'visualization': ThicknessMeasureVisualizationToJSON(value['visualization']),
    };
}

