import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, Form, Input, Typography, Button, Flex, Alert } from 'antd'
import { WebUIRoutes } from '@app/routes'
import * as RFAPI from '@app/api/api'
import { useNavigate } from 'react-router'
import { EVENTS_ID } from '@app/constants'
import { useLicence, useProjects } from '@app/api/hooks'
import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi'

const { Text, Title, Paragraph } = Typography

interface CreateProjectModalProps {
  isOpen?: boolean
  onClose?: () => void
}
export const CreateProjectModal: React.FC<CreateProjectModalProps> = (props) => {
  const { isOpen = false, onClose = () => { /* noop */ } } = props

  const intl = useIntl()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [isCreating, setIsCreating] = React.useState(false)
  const [isOpenInternal, setIsOpenInternal] = React.useState(false)
  const { mutate: mutateProjects } = useProjects()
  const { data: licence } = useLicence()
  const [errors, setErrors] = React.useState<string | undefined>(undefined)

  const onCreateProject = async (values: { name: string, description: string, projectArchive: { file: File } }): Promise<void> => {
    setIsCreating(true)
    try {
      const createdProject = await RFAPI.createProject(values.name, values.description, values.projectArchive?.file)
      await mutateProjects()
      navigate(WebUIRoutes.annotateProject(createdProject.slug).path)
      setIsOpenInternal(false)
    } catch (error) {
      try {
        const response = await (error as { response: Response }).response.json() as { errors?: { message: string }[] }
        if (response.errors !== undefined) {
          setErrors(response.errors.map((e: { message: string }) => e.message).join(', '))
        } else {
          throw error
        }
      } catch {
        if (values.projectArchive !== undefined) {
          setErrors(
            intl.formatMessage({
              id: 'projects.modal.creation.form.error-with-archive.unknown',
              defaultMessage: 'An unknown error occurred while creating the project. Please make sure the Studio export is valid and try again.',
            }))
        } else {
          setErrors(
            intl.formatMessage({
              id: 'projects.modal.creation.form.error.unknown',
              defaultMessage: 'An unknown error occurred while creating the project',
            }))
        }
      }
    } finally {
      setIsCreating(false)
    }
  }

  React.useEffect(() => {
    const createNewProjectEventHandler = (): void => {
      setIsOpenInternal(true)
    }
    setIsOpenInternal(isOpen)
    window.addEventListener(EVENTS_ID.PROJECT_CREATE_NEW, createNewProjectEventHandler, {})
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_CREATE_NEW, createNewProjectEventHandler, {})
    }
  }, [isOpen])

  const title = <Title level={3}>
    <FormattedMessage id="projects.modal.creation.title" defaultMessage={'Create a new project'} />
  </Title>
  return <Modal
    width={640}
    title={title}
    open={isOpenInternal}
    onCancel={() => {
      onClose()
      setIsOpenInternal(false)
    }}
    footer={[
      <Button key={'cancel'} onClick={() => {
        onClose()
        setIsOpenInternal(false)
      }}>
        <FormattedMessage id="projects.modal.creation.actions.cancel" defaultMessage={'Cancel'} />
      </Button>,
      <Button key={'confirm'} type="primary" loading={isCreating} onClick={async () => {
        try {
          await form.validateFields()
          form.submit()
        } catch { /* noop */ }
      }}>
        &nbsp;<FormattedMessage id="projects.modal.creation.actions.ok" defaultMessage={'Create'} />
      </Button>,
    ]}
  >
    <Form
      form={form}
      onFinish={onCreateProject}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{ name: '', description: '' }}
    >
      <Form.Item
        label={intl.formatMessage({ id: 'projects.modal.creation.form.name.label', defaultMessage: 'Name' })}
        name="name"
        required
        rules={[
          { required: true, type: 'string', min: 1, message: intl.formatMessage({ id: 'projects.modal.creation.form.name.rule.required', defaultMessage: 'Please give a name to your project (at least 1 character)' }) },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={intl.formatMessage({ id: 'projects.modal.creation.form.description.label', defaultMessage: 'Description' })}
        name="description"
      >
        <Input.TextArea rows={6} />
      </Form.Item>
      {licence?.enableExportImportProject &&
        <Form.Item
          rules={[{ required: false }]}
          name="projectArchive"
          wrapperCol={{ span: 24 }}
          valuePropName="projectArchive"
        >
          <Dragger
            beforeUpload={() => false}
            maxCount={1}
            accept='application/zip'
            listType='picture'
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <FormattedMessage
                id="projects.modal.creation.form.import.upload.area.text"
                defaultMessage={'Click or drag a Studio export file here to upload'} />
            </p>
            <p className="ant-upload-hint">
              <FormattedMessage
                id="projects.modal.creation.form.import.upload.area.hint.line-1"
                defaultMessage={'Clemex Studio will import the content into the new project.'}
              />
              <br />
              <FormattedMessage
                id="projects.modal.creation.form.import.upload.area.hint.line-2"
                defaultMessage={'Only one export file is allowed.'}
              />
            </p>
          </Dragger>
        </Form.Item>
      }
      {
        errors !== undefined &&
        <Typography.Text type="danger">
          {errors}
        </Typography.Text>
      }
    </Form>
    {!licence?.enableExportImportProject && <Flex className='flex flex-grow' vertical>
      <Alert
        message={<Text>
          <Paragraph>
            <FormattedMessage id="projects.modal.creation.import.explanation.description" defaultMessage="Importing a project enables you to bring in all images, annotation masks, and prediction masks from a Studio exported project." />
          </Paragraph>
          <RequestLicenceFeature featureText='Project import' licenceFeatureRequest={UserAssistanceLicenceFeature.ExportProject} />
        </Text>}
        type="warning"
      />
    </Flex>}
  </Modal >
}
