/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextGeometryProperties
 */
export interface TextGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof TextGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {string}
     * @memberof TextGeometryProperties
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof TextGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the TextGeometryProperties interface.
 */
export function instanceOfTextGeometryProperties(value: object): value is TextGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function TextGeometryPropertiesFromJSON(json: any): TextGeometryProperties {
    return TextGeometryPropertiesFromJSONTyped(json, false);
}

export function TextGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'text': json['text'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function TextGeometryPropertiesToJSON(json: any): TextGeometryProperties {
    return TextGeometryPropertiesToJSONTyped(json, false);
}

export function TextGeometryPropertiesToJSONTyped(value?: TextGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'text': value['text'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

