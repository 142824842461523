/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PageName = {
    Home: 'home',
    ExperimentalLogin: 'experimental::/login',
    Register: '/register',
    ActivateAccount: '/activate-account',
    CreatePassword: '/create-password',
    RequestResetPassword: '/request-reset-password',
    ResetPassword: '/reset-password',
    Projects: '/projects',
    ProjectsProjectIdAnnotate: '/projects/<project_id>/annotate',
    ProjectsProjectIdValidate: '/projects/<project_id>/validate',
    ProjectsNew: '/projects/new',
    Logout: '/logout',
    Profile: '/profile',
    _503Maintenance: '/503_maintenance',
    Licence: '/licence',
    _404NotFound: '/404_not_found',
    RobotTxt: '/robot.txt',
    Ping: '/ping'
} as const;
export type PageName = typeof PageName[keyof typeof PageName];


export function instanceOfPageName(value: any): boolean {
    for (const key in PageName) {
        if (Object.prototype.hasOwnProperty.call(PageName, key)) {
            if (PageName[key as keyof typeof PageName] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PageNameFromJSON(json: any): PageName {
    return PageNameFromJSONTyped(json, false);
}

export function PageNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageName {
    return json as PageName;
}

export function PageNameToJSON(value?: PageName | null): any {
    return value as any;
}

export function PageNameToJSONTyped(value: any, ignoreDiscriminator: boolean): PageName {
    return value as PageName;
}

