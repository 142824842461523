import * as React from 'react'
import styles from './styles/project-info-card.module.scss'
import { Card, Typography, Button, Row, Tooltip, Dropdown, DropDownProps, notification } from 'antd'
import * as RFAPI from '@app/api/api'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { LoadingOutlined } from '@ant-design/icons'
import MoreIcon from '@material-design-icons/svg/filled/more_vert.svg'
import Edit from '@material-design-icons/svg/filled/edit.svg'
import { E2E_REQUIRED_CLASSES, TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import clsx from 'clsx'
import { type PosterImage } from '@app/api/models'
import { ImageThumbnail } from '@components/image-thumbnail'
import { WebUIRoutes } from '@app/routes'
import { ProjectSharingModal } from '@components/modals/project-sharing-modal'

const { Text, Title, Paragraph } = Typography

interface CardCoverProps {
  posterImage?: PosterImage
  onOpen: () => void
  children?: React.ReactNode
}
const CardCover: React.FC<CardCoverProps> = ({ posterImage, onOpen, children }) => {
  return <div
    onClick={onOpen}
    key="editor-icon"
    className={styles.cardCover}
  >
    <ImageThumbnail
      imageThumbnailUrl={posterImage?.thumbnailUrl}
      imagePredictionMaskThumbnailUrl={posterImage?.predictionMaskThumbnailUrl}
      width={284}
      height={200}
    />
    <Tooltip
      placement="topLeft"
      title={<FormattedMessage
        id="project-info-card.tooltip.annotate-project"
        defaultMessage="Open project in annotation editor" />}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
    >
      <Button
        type="text"
        size="middle"
        icon={
          <Edit />
        }
        className={clsx(styles.cardCoverButton, styles.topRight)}
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
      >
      </Button>
    </Tooltip>
    {children}
  </div>
}

export interface ProjectInfoCardProps {
  projectName: string
  lastModifiedDate: string
  description: string
  projectId: string
  posterImage?: PosterImage
  onClickEditProject: () => void
  onDuplicateAndNavigate: () => Promise<void>
  onDuplicate: () => Promise<void>
  onOpen: () => void
  cardCoverChildren: React.ReactNode
  isDuplicating: boolean
}
export const ProjectInfoCard: React.FC<ProjectInfoCardProps> = (props) => {
  const { projectId, projectName, lastModifiedDate, description, posterImage, onClickEditProject, onDuplicate, onDuplicateAndNavigate, onOpen, cardCoverChildren, isDuplicating } = props

  const [showShareProject, setShowShareProject] = React.useState(false)
  const [projectSharingUrl, setProjectSharingUrl] = React.useState('')
  const [notificationApi] = notification.useNotification()

  const openProjectSharingModal = React.useCallback(async (): Promise<void> => {
    if (projectId !== undefined) {
      try {
        const sharingToken = (await RFAPI.requestProjectSharingToken(projectId)).sharingToken
        setProjectSharingUrl(window.origin + WebUIRoutes.projectList().path + '?sharedProjectToken=' + sharingToken)
      } catch {
        notificationApi.error({
          message: <FormattedMessage
            id='project.sharing.link.generate.error.description'
            defaultMessage='There was an error generating the project sharing link. Please try again' />,
        })
      }
      setShowShareProject(true)
    }
  }, [projectId, notificationApi])

  const menuItems: DropDownProps['menu'] = {
    items: [
      {
        key: 'duplicate',
        label: <FormattedMessage
          id="project-info-card.action.duplicate"
          defaultMessage="Duplicate project" />,
        onClick: () => void onDuplicate(),
      },
      {
        key: 'duplicate-and-open',
        label: <FormattedMessage
          id="project-info-card.action.duplicate-and-open"
          defaultMessage="Duplicate project and open it" />,
        onClick: () => void onDuplicateAndNavigate(),
      },
      {
        key: 'share',
        label: <FormattedMessage
          id="project-info-card.action.share-project"
          defaultMessage="Share project" />,
        onClick: () => void openProjectSharingModal(),
      },
      {
        key: 'edit',
        label: <FormattedMessage
          id="project-info-card.action.open-project-settings"
          defaultMessage="Open project settings" />,
        onClick: onClickEditProject,
      },
    ],
  }

  return <>
    <ProjectSharingModal isOpen={showShareProject} onClose={() => { setShowShareProject(false) }} sharingUrl={projectSharingUrl} projectName={projectName ?? ''} />

    <Dropdown
      trigger={['contextMenu']}
      menu={menuItems}
      className={E2E_REQUIRED_CLASSES.PROJECT_CARD}
    >
      <Card
        className={styles.projectInfoCard}
        cover={
          <CardCover
            posterImage={posterImage}
            onOpen={onOpen}
          >
            {cardCoverChildren}
          </CardCover>
        }
      >
        <div className={styles.projectInfoCardContent}>
          <Row className={styles.cardRow}>
            <Title level={3} className={styles.projectInfoCardTitle} ellipsis title={projectName}>
              {projectName}
            </Title>
            <div className={styles.cardActions}>
              {
                isDuplicating
                  ? <Button
                      icon={<LoadingOutlined />}
                      ghost
                      size='middle'
                      type='text'
                    />
                  : <Dropdown
                      menu={menuItems}
                      trigger={['click']}
                    >
                      <Button
                        className={styles.cardAction}
                        icon={<MoreIcon />}
                        size='middle'
                        type='text'
                      />
                    </Dropdown>
              }
            </div>
          </Row>
          <Row>
            <Text className={styles.projectInfoCardInfo} type={'secondary'} >
              <FormattedMessage id={'project-info-card.info.last-modified-date.label'} defaultMessage={'Last modified date: '} />{' '}<FormattedDate value={lastModifiedDate} />
            </Text>
          </Row>
          <Row>
            <Paragraph ellipsis={{ rows: 3 }} className={styles.projectInfoCardDescription}>
              {description}
            </Paragraph>
          </Row>
        </div>
      </Card>
    </Dropdown>
  </>
}
