/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Deep Learning algorithm training progression.
 * @export
 * @interface DeepLearningAlgorithmTrainingProgression
 */
export interface DeepLearningAlgorithmTrainingProgression {
    /**
     * 
     * @type {string}
     * @memberof DeepLearningAlgorithmTrainingProgression
     */
    algorithmId: DeepLearningAlgorithmTrainingProgressionAlgorithmIdEnum;
    /**
     * 
     * @type {number}
     * @memberof DeepLearningAlgorithmTrainingProgression
     */
    epochNumber: number;
    /**
     * 
     * @type {number}
     * @memberof DeepLearningAlgorithmTrainingProgression
     */
    totalEpochs: number;
}


/**
 * @export
 */
export const DeepLearningAlgorithmTrainingProgressionAlgorithmIdEnum = {
    NetLiteV1: 'CLEMEX_NET_LITE_V1',
    NetLiteV2: 'CLEMEX_NET_LITE_V2',
    GrainsegV3: 'CLEMEX_GRAINSEG_V3',
    GrainsegV3Aluminum: 'CLEMEX_GRAINSEG_V3_ALUMINUM',
    MedcleanV1: 'CLEMEX_MEDCLEAN_V1'
} as const;
export type DeepLearningAlgorithmTrainingProgressionAlgorithmIdEnum = typeof DeepLearningAlgorithmTrainingProgressionAlgorithmIdEnum[keyof typeof DeepLearningAlgorithmTrainingProgressionAlgorithmIdEnum];


/**
 * Check if a given object implements the DeepLearningAlgorithmTrainingProgression interface.
 */
export function instanceOfDeepLearningAlgorithmTrainingProgression(value: object): value is DeepLearningAlgorithmTrainingProgression {
    if (!('algorithmId' in value) || value['algorithmId'] === undefined) return false;
    if (!('epochNumber' in value) || value['epochNumber'] === undefined) return false;
    if (!('totalEpochs' in value) || value['totalEpochs'] === undefined) return false;
    return true;
}

export function DeepLearningAlgorithmTrainingProgressionFromJSON(json: any): DeepLearningAlgorithmTrainingProgression {
    return DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, false);
}

export function DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeepLearningAlgorithmTrainingProgression {
    if (json == null) {
        return json;
    }
    return {
        
        'algorithmId': json['algorithm_id'],
        'epochNumber': json['epoch_number'],
        'totalEpochs': json['total_epochs'],
    };
}

export function DeepLearningAlgorithmTrainingProgressionToJSON(json: any): DeepLearningAlgorithmTrainingProgression {
    return DeepLearningAlgorithmTrainingProgressionToJSONTyped(json, false);
}

export function DeepLearningAlgorithmTrainingProgressionToJSONTyped(value?: DeepLearningAlgorithmTrainingProgression | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'algorithm_id': value['algorithmId'],
        'epoch_number': value['epochNumber'],
        'total_epochs': value['totalEpochs'],
    };
}

