/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArrowGeometryProperties
 */
export interface ArrowGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof ArrowGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArrowGeometryProperties
     */
    showLeftArrow: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArrowGeometryProperties
     */
    showRightArrow: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArrowGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the ArrowGeometryProperties interface.
 */
export function instanceOfArrowGeometryProperties(value: object): value is ArrowGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('showLeftArrow' in value) || value['showLeftArrow'] === undefined) return false;
    if (!('showRightArrow' in value) || value['showRightArrow'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function ArrowGeometryPropertiesFromJSON(json: any): ArrowGeometryProperties {
    return ArrowGeometryPropertiesFromJSONTyped(json, false);
}

export function ArrowGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrowGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'showLeftArrow': json['show_left_arrow'],
        'showRightArrow': json['show_right_arrow'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function ArrowGeometryPropertiesToJSON(json: any): ArrowGeometryProperties {
    return ArrowGeometryPropertiesToJSONTyped(json, false);
}

export function ArrowGeometryPropertiesToJSONTyped(value?: ArrowGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'show_left_arrow': value['showLeftArrow'],
        'show_right_arrow': value['showRightArrow'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

