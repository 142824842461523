/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AngleGeometryProperties
 */
export interface AngleGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof AngleGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {number}
     * @memberof AngleGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the AngleGeometryProperties interface.
 */
export function instanceOfAngleGeometryProperties(value: object): value is AngleGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function AngleGeometryPropertiesFromJSON(json: any): AngleGeometryProperties {
    return AngleGeometryPropertiesFromJSONTyped(json, false);
}

export function AngleGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AngleGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function AngleGeometryPropertiesToJSON(json: any): AngleGeometryProperties {
    return AngleGeometryPropertiesToJSONTyped(json, false);
}

export function AngleGeometryPropertiesToJSONTyped(value?: AngleGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

