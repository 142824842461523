/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassAnnotationWithStats } from './ClassAnnotationWithStats';
import {
    ClassAnnotationWithStatsFromJSON,
    ClassAnnotationWithStatsFromJSONTyped,
    ClassAnnotationWithStatsToJSON,
    ClassAnnotationWithStatsToJSONTyped,
} from './ClassAnnotationWithStats';

/**
 * List of class annotation with stats for an image
 * @export
 * @interface AnalyticDataClassAnnotations
 */
export interface AnalyticDataClassAnnotations {
    /**
     * 
     * @type {string}
     * @memberof AnalyticDataClassAnnotations
     */
    imageId: string;
    /**
     * 
     * @type {Array<ClassAnnotationWithStats>}
     * @memberof AnalyticDataClassAnnotations
     */
    items: Array<ClassAnnotationWithStats>;
}

/**
 * Check if a given object implements the AnalyticDataClassAnnotations interface.
 */
export function instanceOfAnalyticDataClassAnnotations(value: object): value is AnalyticDataClassAnnotations {
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function AnalyticDataClassAnnotationsFromJSON(json: any): AnalyticDataClassAnnotations {
    return AnalyticDataClassAnnotationsFromJSONTyped(json, false);
}

export function AnalyticDataClassAnnotationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticDataClassAnnotations {
    if (json == null) {
        return json;
    }
    return {
        
        'imageId': json['image_id'],
        'items': ((json['items'] as Array<any>).map(ClassAnnotationWithStatsFromJSON)),
    };
}

export function AnalyticDataClassAnnotationsToJSON(json: any): AnalyticDataClassAnnotations {
    return AnalyticDataClassAnnotationsToJSONTyped(json, false);
}

export function AnalyticDataClassAnnotationsToJSONTyped(value?: AnalyticDataClassAnnotations | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image_id': value['imageId'],
        'items': ((value['items'] as Array<any>).map(ClassAnnotationWithStatsToJSON)),
    };
}

