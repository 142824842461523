/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
    PolygonToJSONTyped,
} from './Polygon';

/**
 * Similar to ClassAnnotationGeoJSON but without the project_id and image_id.
 * @export
 * @interface ClassAnnotationGeoJSONResponsePart
 */
export interface ClassAnnotationGeoJSONResponsePart {
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSONResponsePart
     */
    classAnnotationId: string;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationGeoJSONResponsePart
     */
    colorIndex: number;
    /**
     * 
     * @type {Polygon}
     * @memberof ClassAnnotationGeoJSONResponsePart
     */
    geometry: Polygon;
}

/**
 * Check if a given object implements the ClassAnnotationGeoJSONResponsePart interface.
 */
export function instanceOfClassAnnotationGeoJSONResponsePart(value: object): value is ClassAnnotationGeoJSONResponsePart {
    if (!('classAnnotationId' in value) || value['classAnnotationId'] === undefined) return false;
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    return true;
}

export function ClassAnnotationGeoJSONResponsePartFromJSON(json: any): ClassAnnotationGeoJSONResponsePart {
    return ClassAnnotationGeoJSONResponsePartFromJSONTyped(json, false);
}

export function ClassAnnotationGeoJSONResponsePartFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassAnnotationGeoJSONResponsePart {
    if (json == null) {
        return json;
    }
    return {
        
        'classAnnotationId': json['class_annotation_id'],
        'colorIndex': json['color_index'],
        'geometry': PolygonFromJSON(json['geometry']),
    };
}

export function ClassAnnotationGeoJSONResponsePartToJSON(json: any): ClassAnnotationGeoJSONResponsePart {
    return ClassAnnotationGeoJSONResponsePartToJSONTyped(json, false);
}

export function ClassAnnotationGeoJSONResponsePartToJSONTyped(value?: ClassAnnotationGeoJSONResponsePart | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'class_annotation_id': value['classAnnotationId'],
        'color_index': value['colorIndex'],
        'geometry': PolygonToJSON(value['geometry']),
    };
}

