/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSettingsCanvasResponse
 */
export interface UserSettingsCanvasResponse {
    /**
     * 
     * @type {number}
     * @memberof UserSettingsCanvasResponse
     */
    zoomFactor: number;
}

/**
 * Check if a given object implements the UserSettingsCanvasResponse interface.
 */
export function instanceOfUserSettingsCanvasResponse(value: object): value is UserSettingsCanvasResponse {
    if (!('zoomFactor' in value) || value['zoomFactor'] === undefined) return false;
    return true;
}

export function UserSettingsCanvasResponseFromJSON(json: any): UserSettingsCanvasResponse {
    return UserSettingsCanvasResponseFromJSONTyped(json, false);
}

export function UserSettingsCanvasResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsCanvasResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'zoomFactor': json['zoom_factor'],
    };
}

export function UserSettingsCanvasResponseToJSON(json: any): UserSettingsCanvasResponse {
    return UserSettingsCanvasResponseToJSONTyped(json, false);
}

export function UserSettingsCanvasResponseToJSONTyped(value?: UserSettingsCanvasResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'zoom_factor': value['zoomFactor'],
    };
}

