/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * RedeemShareProjectResponse is used to know if a project can be trained.
 * @export
 * @interface RedeemShareProjectResponse
 */
export interface RedeemShareProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof RedeemShareProjectResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemShareProjectResponse
     */
    slug: string;
}

/**
 * Check if a given object implements the RedeemShareProjectResponse interface.
 */
export function instanceOfRedeemShareProjectResponse(value: object): value is RedeemShareProjectResponse {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function RedeemShareProjectResponseFromJSON(json: any): RedeemShareProjectResponse {
    return RedeemShareProjectResponseFromJSONTyped(json, false);
}

export function RedeemShareProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemShareProjectResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'slug': json['slug'],
    };
}

export function RedeemShareProjectResponseToJSON(json: any): RedeemShareProjectResponse {
    return RedeemShareProjectResponseToJSONTyped(json, false);
}

export function RedeemShareProjectResponseToJSONTyped(value?: RedeemShareProjectResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_id': value['projectId'],
        'slug': value['slug'],
    };
}

