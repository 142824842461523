/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
    GeometryToJSONTyped,
} from './Geometry';
import type { MetadataAnnotationType } from './MetadataAnnotationType';
import {
    MetadataAnnotationTypeFromJSON,
    MetadataAnnotationTypeFromJSONTyped,
    MetadataAnnotationTypeToJSON,
    MetadataAnnotationTypeToJSONTyped,
} from './MetadataAnnotationType';
import type { GeometryProperties1 } from './GeometryProperties1';
import {
    GeometryProperties1FromJSON,
    GeometryProperties1FromJSONTyped,
    GeometryProperties1ToJSON,
    GeometryProperties1ToJSONTyped,
} from './GeometryProperties1';

/**
 * 
 * @export
 * @interface MetadataAnnotation
 */
export interface MetadataAnnotation {
    /**
     * 
     * @type {Date}
     * @memberof MetadataAnnotation
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MetadataAnnotation
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotation
     */
    metadataAnnotationId?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotation
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotation
     */
    imageId: string;
    /**
     * 
     * @type {MetadataAnnotationType}
     * @memberof MetadataAnnotation
     */
    type: MetadataAnnotationType;
    /**
     * 
     * @type {Geometry}
     * @memberof MetadataAnnotation
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties1}
     * @memberof MetadataAnnotation
     */
    geometryProperties: GeometryProperties1;
}



/**
 * Check if a given object implements the MetadataAnnotation interface.
 */
export function instanceOfMetadataAnnotation(value: object): value is MetadataAnnotation {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    if (!('geometryProperties' in value) || value['geometryProperties'] === undefined) return false;
    return true;
}

export function MetadataAnnotationFromJSON(json: any): MetadataAnnotation {
    return MetadataAnnotationFromJSONTyped(json, false);
}

export function MetadataAnnotationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAnnotation {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDate': json['creation_date'] == null ? undefined : (new Date(json['creation_date'])),
        'modificationDate': json['modification_date'] == null ? undefined : (new Date(json['modification_date'])),
        'metadataAnnotationId': json['metadata_annotation_id'] == null ? undefined : json['metadata_annotation_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'type': MetadataAnnotationTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': GeometryProperties1FromJSON(json['geometry_properties']),
    };
}

export function MetadataAnnotationToJSON(json: any): MetadataAnnotation {
    return MetadataAnnotationToJSONTyped(json, false);
}

export function MetadataAnnotationToJSONTyped(value?: MetadataAnnotation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'creation_date': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'modification_date': value['modificationDate'] == null ? undefined : ((value['modificationDate']).toISOString()),
        'metadata_annotation_id': value['metadataAnnotationId'],
        'project_id': value['projectId'],
        'image_id': value['imageId'],
        'type': MetadataAnnotationTypeToJSON(value['type']),
        'geometry': GeometryToJSON(value['geometry']),
        'geometry_properties': GeometryProperties1ToJSON(value['geometryProperties']),
    };
}

