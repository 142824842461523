/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    password: string;
}

/**
 * Check if a given object implements the UserLoginRequest interface.
 */
export function instanceOfUserLoginRequest(value: object): value is UserLoginRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function UserLoginRequestFromJSON(json: any): UserLoginRequest {
    return UserLoginRequestFromJSONTyped(json, false);
}

export function UserLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function UserLoginRequestToJSON(json: any): UserLoginRequest {
    return UserLoginRequestToJSONTyped(json, false);
}

export function UserLoginRequestToJSONTyped(value?: UserLoginRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

