/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRegistrationRequest
 */
export interface UserRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    email: string;
}

/**
 * Check if a given object implements the UserRegistrationRequest interface.
 */
export function instanceOfUserRegistrationRequest(value: object): value is UserRegistrationRequest {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserRegistrationRequestFromJSON(json: any): UserRegistrationRequest {
    return UserRegistrationRequestFromJSONTyped(json, false);
}

export function UserRegistrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegistrationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'password': json['password'],
        'email': json['email'],
    };
}

export function UserRegistrationRequestToJSON(json: any): UserRegistrationRequest {
    return UserRegistrationRequestToJSONTyped(json, false);
}

export function UserRegistrationRequestToJSONTyped(value?: UserRegistrationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'password': value['password'],
        'email': value['email'],
    };
}

