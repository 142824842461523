/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ArrowGeometryProperties } from './ArrowGeometryProperties';
import {
    instanceOfArrowGeometryProperties,
    ArrowGeometryPropertiesFromJSON,
    ArrowGeometryPropertiesFromJSONTyped,
    ArrowGeometryPropertiesToJSON,
} from './ArrowGeometryProperties';
import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { LineGeometryProperties } from './LineGeometryProperties';
import {
    instanceOfLineGeometryProperties,
    LineGeometryPropertiesFromJSON,
    LineGeometryPropertiesFromJSONTyped,
    LineGeometryPropertiesToJSON,
} from './LineGeometryProperties';
import type { PolygonGeometryProperties } from './PolygonGeometryProperties';
import {
    instanceOfPolygonGeometryProperties,
    PolygonGeometryPropertiesFromJSON,
    PolygonGeometryPropertiesFromJSONTyped,
    PolygonGeometryPropertiesToJSON,
} from './PolygonGeometryProperties';
import type { RectangleGeometryProperties } from './RectangleGeometryProperties';
import {
    instanceOfRectangleGeometryProperties,
    RectangleGeometryPropertiesFromJSON,
    RectangleGeometryPropertiesFromJSONTyped,
    RectangleGeometryPropertiesToJSON,
} from './RectangleGeometryProperties';
import type { TextGeometryProperties } from './TextGeometryProperties';
import {
    instanceOfTextGeometryProperties,
    TextGeometryPropertiesFromJSON,
    TextGeometryPropertiesFromJSONTyped,
    TextGeometryPropertiesToJSON,
} from './TextGeometryProperties';

/**
 * @type GeometryProperties1
 * 
 * @export
 */
export type GeometryProperties1 = { geometryType: 'ARROW' } & ArrowGeometryProperties | { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'LINE' } & LineGeometryProperties | { geometryType: 'POLYGON' } & PolygonGeometryProperties | { geometryType: 'RECTANGLE' } & RectangleGeometryProperties | { geometryType: 'TEXT' } & TextGeometryProperties;

export function GeometryProperties1FromJSON(json: any): GeometryProperties1 {
    return GeometryProperties1FromJSONTyped(json, false);
}

export function GeometryProperties1FromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties1 {
    if (json == null) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'ARROW':
            return Object.assign({}, ArrowGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'ARROW' } as const);
        case 'CIRCLE':
            return Object.assign({}, CircleGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'CIRCLE' } as const);
        case 'ELLIPSE':
            return Object.assign({}, EllipseGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'ELLIPSE' } as const);
        case 'LINE':
            return Object.assign({}, LineGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'LINE' } as const);
        case 'POLYGON':
            return Object.assign({}, PolygonGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'POLYGON' } as const);
        case 'RECTANGLE':
            return Object.assign({}, RectangleGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'RECTANGLE' } as const);
        case 'TEXT':
            return Object.assign({}, TextGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'TEXT' } as const);
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryProperties1ToJSON(json: any): any {
    return GeometryProperties1ToJSONTyped(json, false);
}

export function GeometryProperties1ToJSONTyped(value?: GeometryProperties1 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['geometryType']) {
        case 'ARROW':
            return Object.assign({}, ArrowGeometryPropertiesToJSON(value), { geometryType: 'ARROW' } as const);
        case 'CIRCLE':
            return Object.assign({}, CircleGeometryPropertiesToJSON(value), { geometryType: 'CIRCLE' } as const);
        case 'ELLIPSE':
            return Object.assign({}, EllipseGeometryPropertiesToJSON(value), { geometryType: 'ELLIPSE' } as const);
        case 'LINE':
            return Object.assign({}, LineGeometryPropertiesToJSON(value), { geometryType: 'LINE' } as const);
        case 'POLYGON':
            return Object.assign({}, PolygonGeometryPropertiesToJSON(value), { geometryType: 'POLYGON' } as const);
        case 'RECTANGLE':
            return Object.assign({}, RectangleGeometryPropertiesToJSON(value), { geometryType: 'RECTANGLE' } as const);
        case 'TEXT':
            return Object.assign({}, TextGeometryPropertiesToJSON(value), { geometryType: 'TEXT' } as const);
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${value['geometryType']}'`);
    }

}

