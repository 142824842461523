import * as React from 'react'
import {
  type AnnotationClass,
  ClassEditor,
} from '@app/pages/editor-page/panel/panel-elements/class-editor'
import { EVENTS_ID, MAX_CLASS_COUNT, Tool } from '@app/constants'
import { useProjectActions, useProjectClasses, useProjectTrainingSnapshot } from '@app/api/hooks'
import {
  useGlobalAnnotationVisibility,
  useGlobalPredictionVisibility,
  useHiddenAnnotationClassIndexes,
  useHiddenPredictionClassIndexes,
  useSelectedAnnotationClassColorIndex,
  useSelectedTool,
} from '@app/pages/editor-page/hooks/editor-page'
import { ProjectDatasetContextInput as ProjectDatasetContext } from '@app/api/openapi'
import { PredictionClasses } from '@app/pages/editor-page/panel/panel-elements/prediction-classes'

interface EditorClassEditorProps {
  projectId: string
  context: ProjectDatasetContext
}
export const EditorClassEditor: React.FC<EditorClassEditorProps> = ({
  projectId,
  context,
}) => {
  const { data: projectClasses, addClass, deleteClass, updateClassName } = useProjectClasses(projectId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes] = useHiddenAnnotationClassIndexes()
  const [selectedAnnotationClassIndex, setSelectedAnnotationClassIndex] = useSelectedAnnotationClassColorIndex()
  const [selectedTool] = useSelectedTool()
  const [globalAnnotationVisibility, setGlobalAnnotationVisibility] = useGlobalAnnotationVisibility()
  const [hiddenPredictionClassIndexes, setHiddenPredictionClassIndexes] = useHiddenPredictionClassIndexes()
  const [globalPredictionVisibility, setGlobalPredictionVisibility] = useGlobalPredictionVisibility()
  const { triggerClearAllAnnotations, triggerClearAnnotationsByColorIndex } = useProjectActions(projectId)

  const predictionClassesFromContext = React.useMemo<AnnotationClass[]>(() => {
    const _projectClasses = projectTrainingSnapshot?.classes.sort((a, b) => a.colorIndex - b.colorIndex) ?? []
    return _projectClasses.map((annotationClass) => ({
      name: annotationClass.name,
      colorIndex: annotationClass.colorIndex,
      visible: !hiddenPredictionClassIndexes.includes(annotationClass.colorIndex),
      predictionVisible: !hiddenPredictionClassIndexes.includes(annotationClass.colorIndex),
    }))
  }, [hiddenPredictionClassIndexes, projectTrainingSnapshot?.classes])

  const classesFromContext = React.useMemo<AnnotationClass[]>(() => {
    if (context === ProjectDatasetContext.Training) {
      const _projectClasses = projectClasses ?? []
      return _projectClasses.map((annotationClass) => ({
        name: annotationClass.name,
        colorIndex: annotationClass.colorIndex,
        visible: !hiddenAnnotationClassIndexes.includes(annotationClass.colorIndex),
        predictionVisible: !hiddenPredictionClassIndexes.includes(annotationClass.colorIndex),
      }))
    }
    return predictionClassesFromContext
  }, [context, hiddenAnnotationClassIndexes, projectClasses, predictionClassesFromContext, hiddenPredictionClassIndexes])

  React.useEffect(() => {
    const startAnnotating = (): void => {
      if (selectedTool === Tool.ERASER) {
        setHiddenAnnotationClassIndexes([])
      } else if (projectClasses !== undefined) {
        setHiddenAnnotationClassIndexes(
          hiddenAnnotationClassIndexes.filter((classIndex) => classIndex !== selectedAnnotationClassIndex),
        )
      }
    }
    window.addEventListener(EVENTS_ID.EDITOR_START_ANNOTATING, startAnnotating)
    return () => {
      window.removeEventListener(
        EVENTS_ID.EDITOR_START_ANNOTATING,
        startAnnotating,
      )
    }
  }, [selectedTool, selectedAnnotationClassIndex, projectClasses, setHiddenAnnotationClassIndexes, hiddenAnnotationClassIndexes])

  React.useEffect(() => {
    if (hiddenAnnotationClassIndexes.length === 0) {
      setGlobalAnnotationVisibility(true)
    }
  }, [hiddenAnnotationClassIndexes, setGlobalAnnotationVisibility])

  React.useEffect(() => {
    // Reset the index when the context change
    setHiddenAnnotationClassIndexes([])
    setGlobalAnnotationVisibility(true)
  }, [context, setHiddenAnnotationClassIndexes, setGlobalAnnotationVisibility])

  // Reset the default classIndex
  React.useEffect(() => {
    if (projectClasses === undefined) {
      return
    }
    const availableClassIndexes = projectClasses.map((class_) => class_.colorIndex)
    if (selectedAnnotationClassIndex === undefined || !availableClassIndexes.includes(selectedAnnotationClassIndex)) {
      setSelectedAnnotationClassIndex(Math.min(...availableClassIndexes))
    }
  }, [projectClasses, selectedAnnotationClassIndex, setSelectedAnnotationClassIndex])

  // When the page is unmounted, reset the selected class index
  React.useEffect(() => {
    return () => {
      setSelectedAnnotationClassIndex(0)
    }
  }, [setSelectedAnnotationClassIndex])

  const onAddClass = React.useCallback(async (): Promise<void> => {
    const newClass = await addClass()
    setSelectedAnnotationClassIndex(newClass.colorIndex)
  }, [addClass, setSelectedAnnotationClassIndex])

  const onUpdateClassName = React.useCallback(
    async (classIndex: number, name: string) => {
      await updateClassName(classIndex, name)
    }, [updateClassName])

  const onToggleGlobalAnnotationVisibility = React.useCallback(() => {
    if (globalAnnotationVisibility) {
      setHiddenAnnotationClassIndexes(classesFromContext.map((class_) => class_.colorIndex))
    } else {
      setHiddenAnnotationClassIndexes([])
    }
    setGlobalAnnotationVisibility(!globalAnnotationVisibility)
  }, [classesFromContext, globalAnnotationVisibility, setHiddenAnnotationClassIndexes, setGlobalAnnotationVisibility])

  const onClearAnnotation = React.useCallback(
    async (
      colorIndex: number,
    ): Promise<void> => {
      await triggerClearAnnotationsByColorIndex(colorIndex)
    },
    [triggerClearAnnotationsByColorIndex],
  )

  const onDeleteClass = React.useCallback(
    async (classIndex: number): Promise<void> => {
      await deleteClass(classIndex)
      // The selected class might be deleted, we need to select another one if it's the case
      const availableClassIndexes = classesFromContext.map((class_) => class_.colorIndex).filter((index) => index !== classIndex)
      const hasSelectedClass = availableClassIndexes.some((index) => index === selectedAnnotationClassIndex)
      if (!hasSelectedClass) {
        setSelectedAnnotationClassIndex(Math.min(...availableClassIndexes))
      }
    },
    [deleteClass, classesFromContext, selectedAnnotationClassIndex, setSelectedAnnotationClassIndex],
  )

  const onClearAllAnnotation = React.useCallback(
    async (): Promise<void> => {
      await triggerClearAllAnnotations()
    },
    [triggerClearAllAnnotations],
  )

  const toggleAnnotationClassVisibility = (classIndex: number): void => {
    const nextHiddenAnnotationClasses = !globalAnnotationVisibility
      ? predictionClassesFromContext.map((class_) => class_.colorIndex).filter((i) => i !== classIndex) // Case when all hidden from opacity or global and want only that one to be visible
      : hiddenAnnotationClassIndexes.includes(classIndex)
        ? hiddenAnnotationClassIndexes.filter((i) => i !== classIndex)
        : [...hiddenAnnotationClassIndexes, classIndex]

    setHiddenAnnotationClassIndexes(nextHiddenAnnotationClasses)
    setGlobalAnnotationVisibility(
      nextHiddenAnnotationClasses.length !== classesFromContext.length,
    )
  }

  const togglePredictionClassVisibility = (classIndex: number): void => {
    const nextHiddenPredictionClasses = !globalPredictionVisibility
      ? classesFromContext.map((class_) => class_.colorIndex).filter((i) => i !== classIndex) // Case when all hidden from opacity or global and want only that one to be visible
      : hiddenPredictionClassIndexes.includes(classIndex)
        ? hiddenPredictionClassIndexes.filter((i) => i !== classIndex)
        : [...hiddenPredictionClassIndexes, classIndex]

    setHiddenPredictionClassIndexes(nextHiddenPredictionClasses)
    setGlobalPredictionVisibility(
      nextHiddenPredictionClasses.length !== predictionClassesFromContext.length,
    )
  }

  const onToggleGlobalPredictionVisibility = (): void => {
    if (globalPredictionVisibility) {
      setHiddenPredictionClassIndexes(classesFromContext.map((class_) => class_.colorIndex))
    } else {
      setHiddenPredictionClassIndexes([])
    }
    setGlobalPredictionVisibility(!globalPredictionVisibility)
  }

  return (
    <>
      {context === ProjectDatasetContext.Training && (
        <ClassEditor
          classes={classesFromContext}
          onAddClass={onAddClass}
          onDeleteClass={onDeleteClass}
          onChangeClassName={onUpdateClassName}
          onTogglePredictionClassVisibility={togglePredictionClassVisibility}
          onToggleAnnotationClassVisibility={toggleAnnotationClassVisibility}
          onToggleGlobalAnnotationVisibility={onToggleGlobalAnnotationVisibility}
          globalAnnotationVisibility={globalAnnotationVisibility}
          onToggleGlobalPredictionVisibility={onToggleGlobalPredictionVisibility}
          globalPredictionVisibility={globalPredictionVisibility}
          onSetBrushColorIndex={setSelectedAnnotationClassIndex}
          selectedClassColorIndex={selectedAnnotationClassIndex}
          canAddMoreClass={classesFromContext.length < MAX_CLASS_COUNT}
          onClearAnnotations={onClearAnnotation}
          onClearAllAnnotations={onClearAllAnnotation}
        />
      )}
      {context === ProjectDatasetContext.Validation && (
        <PredictionClasses
          classes={predictionClassesFromContext}
          onTogglePredictionClassVisibility={togglePredictionClassVisibility}
          onToggleGlobalPredictionVisibility={onToggleGlobalPredictionVisibility}
          globalPredictionVisibility={globalPredictionVisibility}
        />
      )}
    </>
  )
}
