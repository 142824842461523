/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserManagementResponseStatus } from './UserManagementResponseStatus';
import {
    UserManagementResponseStatusFromJSON,
    UserManagementResponseStatusFromJSONTyped,
    UserManagementResponseStatusToJSON,
    UserManagementResponseStatusToJSONTyped,
} from './UserManagementResponseStatus';

/**
 * 
 * @export
 * @interface UserManagementResponse
 */
export interface UserManagementResponse {
    /**
     * 
     * @type {string}
     * @memberof UserManagementResponse
     */
    message: string;
    /**
     * 
     * @type {UserManagementResponseStatus}
     * @memberof UserManagementResponse
     */
    status?: UserManagementResponseStatus;
}



/**
 * Check if a given object implements the UserManagementResponse interface.
 */
export function instanceOfUserManagementResponse(value: object): value is UserManagementResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function UserManagementResponseFromJSON(json: any): UserManagementResponse {
    return UserManagementResponseFromJSONTyped(json, false);
}

export function UserManagementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserManagementResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'status': json['status'] == null ? undefined : UserManagementResponseStatusFromJSON(json['status']),
    };
}

export function UserManagementResponseToJSON(json: any): UserManagementResponse {
    return UserManagementResponseToJSONTyped(json, false);
}

export function UserManagementResponseToJSONTyped(value?: UserManagementResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
        'status': UserManagementResponseStatusToJSON(value['status']),
    };
}

