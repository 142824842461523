/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
    TaskStatusToJSONTyped,
} from './TaskStatus';

/**
 * Prediction Status
 * 
 * Holds the status of a prediction task and prediction tiling tasks
 * @export
 * @interface PredictionStatus
 */
export interface PredictionStatus {
    /**
     * 
     * @type {TaskStatus}
     * @memberof PredictionStatus
     */
    predictionTaskStatus: TaskStatus;
    /**
     * 
     * @type {TaskStatus}
     * @memberof PredictionStatus
     */
    predictionMaskTilingTaskStatus: TaskStatus;
    /**
     * 
     * @type {TaskStatus}
     * @memberof PredictionStatus
     */
    predictionInstanceSegmentationStatus: TaskStatus;
}



/**
 * Check if a given object implements the PredictionStatus interface.
 */
export function instanceOfPredictionStatus(value: object): value is PredictionStatus {
    if (!('predictionTaskStatus' in value) || value['predictionTaskStatus'] === undefined) return false;
    if (!('predictionMaskTilingTaskStatus' in value) || value['predictionMaskTilingTaskStatus'] === undefined) return false;
    if (!('predictionInstanceSegmentationStatus' in value) || value['predictionInstanceSegmentationStatus'] === undefined) return false;
    return true;
}

export function PredictionStatusFromJSON(json: any): PredictionStatus {
    return PredictionStatusFromJSONTyped(json, false);
}

export function PredictionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'predictionTaskStatus': TaskStatusFromJSON(json['prediction_task_status']),
        'predictionMaskTilingTaskStatus': TaskStatusFromJSON(json['prediction_mask_tiling_task_status']),
        'predictionInstanceSegmentationStatus': TaskStatusFromJSON(json['prediction_instance_segmentation_status']),
    };
}

export function PredictionStatusToJSON(json: any): PredictionStatus {
    return PredictionStatusToJSONTyped(json, false);
}

export function PredictionStatusToJSONTyped(value?: PredictionStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'prediction_task_status': TaskStatusToJSON(value['predictionTaskStatus']),
        'prediction_mask_tiling_task_status': TaskStatusToJSON(value['predictionMaskTilingTaskStatus']),
        'prediction_instance_segmentation_status': TaskStatusToJSON(value['predictionInstanceSegmentationStatus']),
    };
}

