/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Angle unit.
 * @export
 */
export const AngleUnit = {
    Degree: 'DEGREE',
    Radian: 'RADIAN'
} as const;
export type AngleUnit = typeof AngleUnit[keyof typeof AngleUnit];


export function instanceOfAngleUnit(value: any): boolean {
    for (const key in AngleUnit) {
        if (Object.prototype.hasOwnProperty.call(AngleUnit, key)) {
            if (AngleUnit[key as keyof typeof AngleUnit] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AngleUnitFromJSON(json: any): AngleUnit {
    return AngleUnitFromJSONTyped(json, false);
}

export function AngleUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): AngleUnit {
    return json as AngleUnit;
}

export function AngleUnitToJSON(value?: AngleUnit | null): any {
    return value as any;
}

export function AngleUnitToJSONTyped(value: any, ignoreDiscriminator: boolean): AngleUnit {
    return value as AngleUnit;
}

