/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectStatistics
 */
export interface ProjectStatistics {
    /**
     * 
     * @type {number}
     * @memberof ProjectStatistics
     */
    totalProjects: number;
}

/**
 * Check if a given object implements the ProjectStatistics interface.
 */
export function instanceOfProjectStatistics(value: object): value is ProjectStatistics {
    if (!('totalProjects' in value) || value['totalProjects'] === undefined) return false;
    return true;
}

export function ProjectStatisticsFromJSON(json: any): ProjectStatistics {
    return ProjectStatisticsFromJSONTyped(json, false);
}

export function ProjectStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'totalProjects': json['total_projects'],
    };
}

export function ProjectStatisticsToJSON(json: any): ProjectStatistics {
    return ProjectStatisticsToJSONTyped(json, false);
}

export function ProjectStatisticsToJSONTyped(value?: ProjectStatistics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'total_projects': value['totalProjects'],
    };
}

