/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAssistanceType } from './UserAssistanceType';
import {
    UserAssistanceTypeFromJSON,
    UserAssistanceTypeFromJSONTyped,
    UserAssistanceTypeToJSON,
    UserAssistanceTypeToJSONTyped,
} from './UserAssistanceType';
import type { UserAssistanceLicenceFeature } from './UserAssistanceLicenceFeature';
import {
    UserAssistanceLicenceFeatureFromJSON,
    UserAssistanceLicenceFeatureFromJSONTyped,
    UserAssistanceLicenceFeatureToJSON,
    UserAssistanceLicenceFeatureToJSONTyped,
} from './UserAssistanceLicenceFeature';

/**
 * 
 * @export
 * @interface UserAssistanceRequest
 */
export interface UserAssistanceRequest {
    /**
     * 
     * @type {Array<UserAssistanceType>}
     * @memberof UserAssistanceRequest
     */
    assistanceRequested: Array<UserAssistanceType>;
    /**
     * 
     * @type {UserAssistanceLicenceFeature}
     * @memberof UserAssistanceRequest
     */
    licenceFeature?: UserAssistanceLicenceFeature | null;
}



/**
 * Check if a given object implements the UserAssistanceRequest interface.
 */
export function instanceOfUserAssistanceRequest(value: object): value is UserAssistanceRequest {
    if (!('assistanceRequested' in value) || value['assistanceRequested'] === undefined) return false;
    return true;
}

export function UserAssistanceRequestFromJSON(json: any): UserAssistanceRequest {
    return UserAssistanceRequestFromJSONTyped(json, false);
}

export function UserAssistanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssistanceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'assistanceRequested': ((json['assistance_requested'] as Array<any>).map(UserAssistanceTypeFromJSON)),
        'licenceFeature': json['licence_feature'] == null ? undefined : UserAssistanceLicenceFeatureFromJSON(json['licence_feature']),
    };
}

export function UserAssistanceRequestToJSON(json: any): UserAssistanceRequest {
    return UserAssistanceRequestToJSONTyped(json, false);
}

export function UserAssistanceRequestToJSONTyped(value?: UserAssistanceRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'assistance_requested': ((value['assistanceRequested'] as Array<any>).map(UserAssistanceTypeToJSON)),
        'licence_feature': UserAssistanceLicenceFeatureToJSON(value['licenceFeature']),
    };
}

