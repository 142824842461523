/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LicenceFeaturesResponse } from './LicenceFeaturesResponse';
import {
    LicenceFeaturesResponseFromJSON,
    LicenceFeaturesResponseFromJSONTyped,
    LicenceFeaturesResponseToJSON,
    LicenceFeaturesResponseToJSONTyped,
} from './LicenceFeaturesResponse';
import type { LicenceType } from './LicenceType';
import {
    LicenceTypeFromJSON,
    LicenceTypeFromJSONTyped,
    LicenceTypeToJSON,
    LicenceTypeToJSONTyped,
} from './LicenceType';

/**
 * 
 * @export
 * @interface LicenceResponse
 */
export interface LicenceResponse {
    /**
     * 
     * @type {LicenceType}
     * @memberof LicenceResponse
     */
    type: LicenceType;
    /**
     * 
     * @type {Date}
     * @memberof LicenceResponse
     */
    expiration: Date;
    /**
     * 
     * @type {number}
     * @memberof LicenceResponse
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof LicenceResponse
     */
    key: string;
    /**
     * 
     * @type {LicenceFeaturesResponse}
     * @memberof LicenceResponse
     */
    features: LicenceFeaturesResponse;
}



/**
 * Check if a given object implements the LicenceResponse interface.
 */
export function instanceOfLicenceResponse(value: object): value is LicenceResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('expiration' in value) || value['expiration'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    return true;
}

export function LicenceResponseFromJSON(json: any): LicenceResponse {
    return LicenceResponseFromJSONTyped(json, false);
}

export function LicenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': LicenceTypeFromJSON(json['type']),
        'expiration': (new Date(json['expiration'])),
        'duration': json['duration'],
        'key': json['key'],
        'features': LicenceFeaturesResponseFromJSON(json['features']),
    };
}

export function LicenceResponseToJSON(json: any): LicenceResponse {
    return LicenceResponseToJSONTyped(json, false);
}

export function LicenceResponseToJSONTyped(value?: LicenceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': LicenceTypeToJSON(value['type']),
        'expiration': ((value['expiration']).toISOString()),
        'duration': value['duration'],
        'key': value['key'],
        'features': LicenceFeaturesResponseToJSON(value['features']),
    };
}

