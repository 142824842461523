import Stroke from 'ol/style/Stroke'
import Style, { type StyleFunction } from 'ol/style/Style'
import { Circle, type Point, LineString } from 'ol/geom'
import { DEFAULT_SHAPE_STYLE, FEATURE_TYPE, FeatureType, type ShapeStyle } from './common'
import { type Coordinate } from 'ol/coordinate'
import { type State, toContext } from 'ol/render'
import ColorJS from 'colorjs.io'
import { findCenterFromTriangle } from '../interactions/arc'
import Fill from 'ol/style/Fill'
import type Feature from 'ol/Feature'
import Text from 'ol/style/Text'
import CircleStyle from 'ol/style/Circle'

export interface ArcGeometryProperties {
  geometryType: 'ARC'
  pinnedResolution: number
  filled: boolean
}

export const buildArcStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  // Create RGBA color for fill alpha
  // It would be nice to inline it like: `new Color(style.mainColor).set('alpha', style.fillOpacity)`
  // But it does not work at the moment, see issue:
  // https://github.com/color-js/color.js/issues/310

  return (feature, resolution) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }
    const type = geometry.getType()
    if (type !== 'LineString') {
      return []
    }

    const { pinnedResolution, filled } = geometry.getProperties() as ArcGeometryProperties
    const selected = selection.has(feature.getProperties().id as string)
    const fillColor = filled
      ? new ColorJS(selected ? style.mainSelectedColor : style.mainColor)
      : new ColorJS('transparent')
    fillColor.alpha = filled ? style.fillOpacity : 0

    const lineStringGeom = geometry as LineString
    const geomCoordinates = lineStringGeom.getCoordinates()
    const styles: Style[] = []
    if (geomCoordinates.length === 2) {
      styles.push(
        new Style({
          stroke: new Stroke({
            color: selected ? style.mainSelectedColor : style.mainColor,
            width: style.strokeWidth,
          }),
          zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }
    if (geomCoordinates.length === 3 || geomCoordinates.length === 4) {
      const geomCenter = findCenterFromTriangle(geomCoordinates[0], geomCoordinates[1], geomCoordinates[2])
      const radius = Math.sqrt((geomCoordinates[0][0] - geomCenter[0]) ** 2 + (geomCoordinates[0][1] - geomCenter[1]) ** 2)
      // Draw measuring text
      const text = pixelSizeCalibration !== undefined
        ? `${(radius * pixelSizeCalibration).toFixed(1)} µm`
        : `${radius.toFixed(2)} px`

      styles.push(
        new Style({
          renderer: (renderCoordinates_: Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][], state: State) => {
            const context = state.context
            const renderCoordinates = renderCoordinates_ as Coordinate[]
            const A = renderCoordinates[0] as [number, number]
            let B = renderCoordinates[1] as [number, number]
            let C = renderCoordinates[2] as [number, number]
            if (geomCoordinates.length !== renderCoordinates.length) {
              // OpenLayers rendered simplified geometry
              // It usually happens when AB and BC are almost collinear, it get simplified to AC
              // We can retrieve B from geomCoordinates
              const geomABCratio = (geomCoordinates[1][0] - geomCoordinates[0][0]) / (geomCoordinates[0][0] - geomCoordinates[2][0])
              B = [
                renderCoordinates[0][0] + geomABCratio * (renderCoordinates[1][0] - renderCoordinates[0][0]),
                renderCoordinates[0][1] + geomABCratio * (renderCoordinates[1][1] - renderCoordinates[0][1]),
              ] as const
              C = renderCoordinates[1] as [number, number]
            }

            const H = findCenterFromTriangle(A, B, C)
            const distanceAH = Math.sqrt((A[0] - H[0]) ** 2 + (A[1] - H[1]) ** 2)

            // The measuring arc is outside the triangle if the angle between sin(BAC) is negative
            // The measuring arc is outside, we need to swap the start and end angle
            const ABangle = Math.atan2(B[1] - A[1], B[0] - A[0])
            const ACangle = Math.atan2(C[1] - A[1], C[0] - A[0])
            const isOutside = Math.sin(ACangle - ABangle) < 0

            const [angle1, angle2] = [Math.atan2(A[1] - H[1], A[0] - H[0]), Math.atan2(B[1] - H[1], B[0] - H[0])]
            const [startAngle, endAngle] = isOutside ? [angle1, angle2] : [angle2, angle1]
            context.save()
            const vectorContext = toContext(context)
            vectorContext.setStyle(new Style({
              stroke: new Stroke({
                color: selected ? style.mainSelectedColor : style.mainColor,
                width: style.strokeWidth,
              }),
            }))

            context.lineWidth = style.strokeWidth
            context.strokeStyle = selected ? style.mainSelectedColor : style.mainColor
            context.fillStyle = fillColor.toString()
            context.beginPath()
            context.arc(H[0], H[1], distanceAH, startAngle, endAngle)
            if (!isOutside) {
              context.lineTo(H[0], H[1])
              context.lineTo(B[0], B[1])
            } else {
              context.lineTo(H[0], H[1])
              context.lineTo(A[0], A[1])
            }
            context.fill()
            context.stroke()

            context.restore()
          },
          zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      );

      [...geomCoordinates, geomCenter].forEach((coordinate) => {
        styles.push(new Style({
          fill: new Fill({
            color: style.measuringPointColor,
          }),
          geometry: () => {
            return new Circle(coordinate, (selected ? style.selectedMeasuringPointRadius : style.measuringPointRadius) * resolution)
          },
          zIndex: style.zIndexOffSet + 2 + (selected ? style.zIndexSelectionOffset : 0),
        }))
      })
      if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_ARC) {
        styles.push(new Style({
          text: new Text({
            font: `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`,
            text,
            backgroundFill: new Fill({
              color: style.measurementTextBackgroundFillColor,
            }),
            backgroundStroke: new Stroke({
              color: style.measurementTextBackgroundStrokeColor,
              width: style.measurementTextBackgroundStrokeWidth,
            }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            scale: pinnedResolution / resolution,
          }),
          zIndex: style.zIndexOffSet + 3,
          geometry: () => {
            return new LineString([geomCenter, geomCoordinates[0]])
          },
        }))
      }
    }
    return styles
  }
}

export const buildArcModifyStyle = (style: ShapeStyle): StyleFunction => {
  return (feature) => {
    const hitPoint = feature.getGeometry() as Point
    const targetFeature = feature.get('features')[0] as Feature | undefined
    if (targetFeature === undefined) {
      return undefined
    }
    const targetModifyGeometry = targetFeature.getGeometry()

    if (targetModifyGeometry !== undefined) {
      if (targetModifyGeometry.getType() === 'LineString') {
        const targetLineCoordinates = (targetModifyGeometry as LineString).getCoordinates() as [[number, number], [number, number], [number, number]]
        const hitPointCoordinates = hitPoint.getCoordinates() as [number, number]

        if (
          targetLineCoordinates.some((coordinate) => coordinate[0] === hitPointCoordinates[0] && coordinate[1] === hitPointCoordinates[1])
        ) {
          return [
            new Style({
              image: new CircleStyle({
                radius: style.hitDetectionToleranceRadius / 2,
                fill: new Fill({
                  color: 'rgba(255, 255, 255, 0.5)',
                }),
                stroke: new Stroke({
                  color: 'rgba(255, 255, 255, 0.8)',
                  width: 2,
                }),
              }),
              zIndex: style.zIndexOffSet + 4,
            }),
          ]
        }
      }
    }
  }
}
