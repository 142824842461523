/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
    PointToJSONTyped,
} from './Point';

/**
 * 
 * @export
 * @interface VisualizationTextLabel
 */
export interface VisualizationTextLabel {
    /**
     * 
     * @type {string}
     * @memberof VisualizationTextLabel
     */
    text: string;
    /**
     * 
     * @type {Point}
     * @memberof VisualizationTextLabel
     */
    position: Point;
}

/**
 * Check if a given object implements the VisualizationTextLabel interface.
 */
export function instanceOfVisualizationTextLabel(value: object): value is VisualizationTextLabel {
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    return true;
}

export function VisualizationTextLabelFromJSON(json: any): VisualizationTextLabel {
    return VisualizationTextLabelFromJSONTyped(json, false);
}

export function VisualizationTextLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisualizationTextLabel {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
        'position': PointFromJSON(json['position']),
    };
}

export function VisualizationTextLabelToJSON(json: any): VisualizationTextLabel {
    return VisualizationTextLabelToJSONTyped(json, false);
}

export function VisualizationTextLabelToJSONTyped(value?: VisualizationTextLabel | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'text': value['text'],
        'position': PointToJSON(value['position']),
    };
}

