/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TrainingParameters } from './TrainingParameters';
import {
    TrainingParametersFromJSON,
    TrainingParametersFromJSONTyped,
    TrainingParametersToJSON,
    TrainingParametersToJSONTyped,
} from './TrainingParameters';
import type { AlgoClass } from './AlgoClass';
import {
    AlgoClassFromJSON,
    AlgoClassFromJSONTyped,
    AlgoClassToJSON,
    AlgoClassToJSONTyped,
} from './AlgoClass';
import type { ClassAnnotationGeoJSON } from './ClassAnnotationGeoJSON';
import {
    ClassAnnotationGeoJSONFromJSON,
    ClassAnnotationGeoJSONFromJSONTyped,
    ClassAnnotationGeoJSONToJSON,
    ClassAnnotationGeoJSONToJSONTyped,
} from './ClassAnnotationGeoJSON';

/**
 * 
 * @export
 * @interface ProjectTrainingSnapshotResponse
 */
export interface ProjectTrainingSnapshotResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingSnapshotResponse
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {Array<AlgoClass>}
     * @memberof ProjectTrainingSnapshotResponse
     */
    classes: Array<AlgoClass>;
    /**
     * 
     * @type {Array<ClassAnnotationGeoJSON>}
     * @memberof ProjectTrainingSnapshotResponse
     */
    classAnnotations: Array<ClassAnnotationGeoJSON>;
    /**
     * 
     * @type {TrainingParameters}
     * @memberof ProjectTrainingSnapshotResponse
     */
    trainingParameters: TrainingParameters;
    /**
     * 
     * @type {Date}
     * @memberof ProjectTrainingSnapshotResponse
     */
    creationDate: Date;
}

/**
 * Check if a given object implements the ProjectTrainingSnapshotResponse interface.
 */
export function instanceOfProjectTrainingSnapshotResponse(value: object): value is ProjectTrainingSnapshotResponse {
    if (!('projectTrainingSnapshotId' in value) || value['projectTrainingSnapshotId'] === undefined) return false;
    if (!('classes' in value) || value['classes'] === undefined) return false;
    if (!('classAnnotations' in value) || value['classAnnotations'] === undefined) return false;
    if (!('trainingParameters' in value) || value['trainingParameters'] === undefined) return false;
    if (!('creationDate' in value) || value['creationDate'] === undefined) return false;
    return true;
}

export function ProjectTrainingSnapshotResponseFromJSON(json: any): ProjectTrainingSnapshotResponse {
    return ProjectTrainingSnapshotResponseFromJSONTyped(json, false);
}

export function ProjectTrainingSnapshotResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingSnapshotResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'classes': ((json['classes'] as Array<any>).map(AlgoClassFromJSON)),
        'classAnnotations': ((json['class_annotations'] as Array<any>).map(ClassAnnotationGeoJSONFromJSON)),
        'trainingParameters': TrainingParametersFromJSON(json['training_parameters']),
        'creationDate': (new Date(json['creation_date'])),
    };
}

export function ProjectTrainingSnapshotResponseToJSON(json: any): ProjectTrainingSnapshotResponse {
    return ProjectTrainingSnapshotResponseToJSONTyped(json, false);
}

export function ProjectTrainingSnapshotResponseToJSONTyped(value?: ProjectTrainingSnapshotResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_training_snapshot_id': value['projectTrainingSnapshotId'],
        'classes': ((value['classes'] as Array<any>).map(AlgoClassToJSON)),
        'class_annotations': ((value['classAnnotations'] as Array<any>).map(ClassAnnotationGeoJSONToJSON)),
        'training_parameters': TrainingParametersToJSON(value['trainingParameters']),
        'creation_date': ((value['creationDate']).toISOString()),
    };
}

