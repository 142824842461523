/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportOptionsValueObject
 */
export interface ExportOptionsValueObject {
    /**
     * Combine masks in a single colorized file
     * @type {boolean}
     * @memberof ExportOptionsValueObject
     */
    includeCombinedMasks: boolean;
    /**
     * Export each annotation class in a different file
     * @type {boolean}
     * @memberof ExportOptionsValueObject
     */
    includeIndividualMasks: boolean;
    /**
     * Re-export original image
     * @type {boolean}
     * @memberof ExportOptionsValueObject
     */
    includeOriginalImage: boolean;
    /**
     * Include scale line on image
     * @type {boolean}
     * @memberof ExportOptionsValueObject
     */
    includeScaleLine: boolean;
    /**
     * Include the annotations which purpose are to communicate / collaborate with others
     * @type {boolean}
     * @memberof ExportOptionsValueObject
     */
    includeAnnotations: boolean;
}

/**
 * Check if a given object implements the ExportOptionsValueObject interface.
 */
export function instanceOfExportOptionsValueObject(value: object): value is ExportOptionsValueObject {
    if (!('includeCombinedMasks' in value) || value['includeCombinedMasks'] === undefined) return false;
    if (!('includeIndividualMasks' in value) || value['includeIndividualMasks'] === undefined) return false;
    if (!('includeOriginalImage' in value) || value['includeOriginalImage'] === undefined) return false;
    if (!('includeScaleLine' in value) || value['includeScaleLine'] === undefined) return false;
    if (!('includeAnnotations' in value) || value['includeAnnotations'] === undefined) return false;
    return true;
}

export function ExportOptionsValueObjectFromJSON(json: any): ExportOptionsValueObject {
    return ExportOptionsValueObjectFromJSONTyped(json, false);
}

export function ExportOptionsValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportOptionsValueObject {
    if (json == null) {
        return json;
    }
    return {
        
        'includeCombinedMasks': json['include_combined_masks'],
        'includeIndividualMasks': json['include_individual_masks'],
        'includeOriginalImage': json['include_original_image'],
        'includeScaleLine': json['include_scale_line'],
        'includeAnnotations': json['include_annotations'],
    };
}

export function ExportOptionsValueObjectToJSON(json: any): ExportOptionsValueObject {
    return ExportOptionsValueObjectToJSONTyped(json, false);
}

export function ExportOptionsValueObjectToJSONTyped(value?: ExportOptionsValueObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'include_combined_masks': value['includeCombinedMasks'],
        'include_individual_masks': value['includeIndividualMasks'],
        'include_original_image': value['includeOriginalImage'],
        'include_scale_line': value['includeScaleLine'],
        'include_annotations': value['includeAnnotations'],
    };
}

