import { useLicence } from '@app/api/hooks'
import { LicensingTagWithExpiration } from '@components/licence/licensing-tag'
import { Typography, Tag } from 'antd'
import { type FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CopyIcon from '@material-design-icons/svg/outlined/copy_all.svg'
import CopiedIcon from '@material-design-icons/svg/outlined/check.svg'
import { getLicenceTagProperties } from '@components/licence/licensing-tag-properties'
import clsx from 'clsx'
import styles from './styles/licence-plan.module.scss'
const { Title, Paragraph } = Typography

export const LicencePlan: FC = () => {
  const intl = useIntl()
  const { data: licence, isLoading: isLicenceLoading } = useLicence()
  const licenceProps = getLicenceTagProperties(intl, licence)

  return <div className='grow flex flex-col gap-[8px]'>
    <Title level={4} className='flex justify-between uppercase mb-[16px]'>
      <FormattedMessage id={'page.plan.title'} defaultMessage={'Plan'}/>
    </Title>
    <LicensingTagWithExpiration
      className={clsx(licenceProps.className, { invisible: isLicenceLoading })}
      tagIcon={licenceProps.icon}
      tagText={licenceProps.text}
      expirationText={licenceProps.expirationText}
      expirationClassName={licenceProps.expirationClassName}
    />
    {
      licence?.key !== undefined &&
      <div className="flex justify-between items-center">
        <Title level={5} className="pl-[8px] !text-[1rem] !text-clemex-offDarkGray">
          <FormattedMessage id={'licence.key.title'} defaultMessage={'Licence Key:'}/>
        </Title>
        <Tag className="mr-0 flex">
          <Paragraph
            className={clsx('p-[2px] !m-0', styles.copy)}
            copyable={{
              icon: [<CopyIcon key="copy-icon"/>, <CopiedIcon key="copied-icon" />],
            }}>
            {licence?.key}
          </Paragraph>
        </Tag>
      </div>
    }
  </div>
}
