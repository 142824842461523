/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LicenceType = {
    Trial: 'trial',
    Premium: 'premium'
} as const;
export type LicenceType = typeof LicenceType[keyof typeof LicenceType];


export function instanceOfLicenceType(value: any): boolean {
    for (const key in LicenceType) {
        if (Object.prototype.hasOwnProperty.call(LicenceType, key)) {
            if (LicenceType[key as keyof typeof LicenceType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LicenceTypeFromJSON(json: any): LicenceType {
    return LicenceTypeFromJSONTyped(json, false);
}

export function LicenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenceType {
    return json as LicenceType;
}

export function LicenceTypeToJSON(value?: LicenceType | null): any {
    return value as any;
}

export function LicenceTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): LicenceType {
    return value as LicenceType;
}

