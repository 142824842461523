/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagResponse
 */
export interface FeatureFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    enableLocalUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    enableMaintenance: boolean;
}

/**
 * Check if a given object implements the FeatureFlagResponse interface.
 */
export function instanceOfFeatureFlagResponse(value: object): value is FeatureFlagResponse {
    if (!('enableLocalUser' in value) || value['enableLocalUser'] === undefined) return false;
    if (!('enableMaintenance' in value) || value['enableMaintenance'] === undefined) return false;
    return true;
}

export function FeatureFlagResponseFromJSON(json: any): FeatureFlagResponse {
    return FeatureFlagResponseFromJSONTyped(json, false);
}

export function FeatureFlagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'enableLocalUser': json['enable_local_user'],
        'enableMaintenance': json['enable_maintenance'],
    };
}

export function FeatureFlagResponseToJSON(json: any): FeatureFlagResponse {
    return FeatureFlagResponseToJSONTyped(json, false);
}

export function FeatureFlagResponseToJSONTyped(value?: FeatureFlagResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enable_local_user': value['enableLocalUser'],
        'enable_maintenance': value['enableMaintenance'],
    };
}

