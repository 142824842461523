import styles from './styles/clemex-mosaic-canvas-viewer.module.css'

import { useEffect, useMemo, useRef } from 'react'
import { useClemexMosaicCanvasContext } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { useCanvasClassAnnotations } from '@app/pages/editor-page/canvas/hooks/use-canvas-class-annotations'
import { useCanvasDirectMeasures } from '@app/pages/editor-page/canvas/hooks/use-canvas-direct-measures'
import { useCanvasImage } from '@app/pages/editor-page/canvas/hooks/use-canvas-image'
import { useCanvasMetadataAnnotations } from '@app/pages/editor-page/canvas/hooks/use-canvas-metadata-annotations'
import { useCanvasPixelGrid } from '@app/pages/editor-page/canvas/hooks/use-canvas-pixel-grid'
import { useCanvasPixelSize } from '@app/pages/editor-page/canvas/hooks/use-canvas-pixel-size'
import { useCanvasScaleBar } from '@app/pages/editor-page/canvas/hooks/use-canvas-scale-bar'
import { useCanvasSelection } from '@app/pages/editor-page/canvas/hooks/use-canvas-selection'
import { useCanvasShapeStyle } from '@app/pages/editor-page/canvas/hooks/use-canvas-shape-style'
import { useCanvasThicknessMeasure } from '@app/pages/editor-page/canvas/hooks/use-canvas-thickness-measure'
import { useCanvasTools } from '@app/pages/editor-page/canvas/hooks/use-canvas-tools'
import { useCanvasZoom } from '@app/pages/editor-page/canvas/hooks/use-canvas-zoom'
import GeoJSON from 'ol/format/GeoJSON'
import { Flex, Skeleton, Watermark } from 'antd'
import { useIntl } from 'react-intl'
import ImageLoadingIcon from '@material-design-icons/svg/outlined/panorama.svg'
import { E2E_REQUIRED_CLASSES } from '@app/constants'
import clsx from 'clsx'
import { uniqueId } from 'lodash'

const geojsonParser = new GeoJSON()

interface ClemexMosaicCanvasViewerProps {
  imageId: string
}
export const ClemexMosaicCanvasViewer: React.FC<ClemexMosaicCanvasViewerProps> = ({ imageId }) => {
  const intl = useIntl()
  const cmcDivRef = useRef<HTMLDivElement>(null)
  const clemexMosaicCanvas = useClemexMosaicCanvasContext()

  useCanvasClassAnnotations(clemexMosaicCanvas, geojsonParser)
  useCanvasDirectMeasures(clemexMosaicCanvas, geojsonParser)
  const { isIsSelectedImageLoading } = useCanvasImage(clemexMosaicCanvas)
  useCanvasMetadataAnnotations(clemexMosaicCanvas, geojsonParser)
  useCanvasPixelGrid(clemexMosaicCanvas)
  useCanvasPixelSize(clemexMosaicCanvas)
  useCanvasScaleBar(clemexMosaicCanvas)
  useCanvasSelection(clemexMosaicCanvas)
  useCanvasShapeStyle(clemexMosaicCanvas)
  useCanvasThicknessMeasure(clemexMosaicCanvas)
  useCanvasTools(clemexMosaicCanvas)
  useCanvasZoom(clemexMosaicCanvas)

  const canvasId = useMemo(() => `clemex-mosaic-canvas-${imageId}-${uniqueId()}`, [imageId])

  useEffect(() => {
    clemexMosaicCanvas.setTarget(canvasId)
    clemexMosaicCanvas.resetView()
  }, [clemexMosaicCanvas, canvasId])

  // Remove context menu from the canvas, to allow right click pan.
  useEffect(() => {
    if (cmcDivRef.current === null) {
      return
    }
    const currentRef = cmcDivRef.current
    const onContextMenu = (event: MouseEvent) => {
      event.preventDefault()
    }
    const preventTabToSwitchFocus = (event: KeyboardEvent): boolean => {
      if (event.key === 'Tab') {
        event.preventDefault()
      }
      return false
    }
    currentRef.addEventListener('contextmenu', onContextMenu)
    currentRef.addEventListener('keydown', preventTabToSwitchFocus, {})
    return () => {
      currentRef.removeEventListener('contextmenu', onContextMenu)
      currentRef.removeEventListener('keydown', preventTabToSwitchFocus, {})
    }
  }, [])

  return <div className={styles.container}>
    {isIsSelectedImageLoading && <Watermark
      content={
        isIsSelectedImageLoading
          ? [
              intl.formatMessage({ id: 'clemex-mosaic-canvas.message.line-1', defaultMessage: 'Loading: The image is being tilled' }),
              intl.formatMessage({ id: 'clemex-mosaic-canvas.message.line-2', defaultMessage: 'for compatibility with the editor.' }),
            ]
          : []}
      className='w-full h-full z-20' offset={[200, 200]} gap={[200, 200]}
    >
      <Flex justify="center" align="center" vertical={true} className='h-full'>
        <Skeleton.Node active={true} className='min-h-[256px] min-w-[256px] opacity-100'>
          <ImageLoadingIcon className='min-h-[128px] min-w-[128px] opacity-100 fill-clemex-offDarkGray' />
        </Skeleton.Node>
      </Flex>
    </Watermark>
    }
    <div
      id={canvasId}
      ref={cmcDivRef}
      className={clsx(E2E_REQUIRED_CLASSES.OL_CANVAS, styles.olCanvas)}
      tabIndex={0} // This is required for keyboard events to work.
    >
      <div id='ol-overlay-text-edit' style={{ visibility: 'hidden' }}>
        <textarea id="story" name="story" rows={5} cols={33} />
      </div>
    </div>
  </div>
}
