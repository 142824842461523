import type React from 'react'
import { Flex } from 'antd'
import styles from '../styles/training-section.module.scss'
import { TrainButton } from '@app/pages/editor-page/training/train-button'
import { TrainingElapsedTime } from '@app/pages/editor-page/training/training-elapsed-time'
import { TrainingOptions } from '@app/pages/editor-page/training/training-options'
import { PredictingStatus } from '@app/pages/editor-page/training/predicting-status'
import { type ProjectDatasetContextInput as ProjectDatasetContext } from '@app/api/openapi'

interface TrainingSectionProps {
  projectId: string | undefined
  context: ProjectDatasetContext
}

export const TrainingSection: React.FC<TrainingSectionProps> = ({
  projectId,
  context,
}) => {
  return <Flex vertical className={styles.trainingSection}>
    <TrainingElapsedTime projectId={projectId}/>
    <Flex vertical className={styles.trainingSectionInner}>
      <TrainingOptions projectId={projectId} />
    </Flex>
    <PredictingStatus projectId={projectId} context={context}/>
    <TrainButton
      projectId={projectId}
    />
  </Flex>
}
