/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DirectMeasureInput } from './DirectMeasureInput';
import {
    DirectMeasureInputFromJSON,
    DirectMeasureInputFromJSONTyped,
    DirectMeasureInputToJSON,
    DirectMeasureInputToJSONTyped,
} from './DirectMeasureInput';

/**
 * 
 * @export
 * @interface DirectMeasurePatchRequest
 */
export interface DirectMeasurePatchRequest {
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    add?: Array<DirectMeasureInput>;
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    remove?: Array<DirectMeasureInput>;
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    update?: Array<DirectMeasureInput>;
}

/**
 * Check if a given object implements the DirectMeasurePatchRequest interface.
 */
export function instanceOfDirectMeasurePatchRequest(value: object): value is DirectMeasurePatchRequest {
    return true;
}

export function DirectMeasurePatchRequestFromJSON(json: any): DirectMeasurePatchRequest {
    return DirectMeasurePatchRequestFromJSONTyped(json, false);
}

export function DirectMeasurePatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasurePatchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'add': json['add'] == null ? undefined : ((json['add'] as Array<any>).map(DirectMeasureInputFromJSON)),
        'remove': json['remove'] == null ? undefined : ((json['remove'] as Array<any>).map(DirectMeasureInputFromJSON)),
        'update': json['update'] == null ? undefined : ((json['update'] as Array<any>).map(DirectMeasureInputFromJSON)),
    };
}

export function DirectMeasurePatchRequestToJSON(json: any): DirectMeasurePatchRequest {
    return DirectMeasurePatchRequestToJSONTyped(json, false);
}

export function DirectMeasurePatchRequestToJSONTyped(value?: DirectMeasurePatchRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'add': value['add'] == null ? undefined : ((value['add'] as Array<any>).map(DirectMeasureInputToJSON)),
        'remove': value['remove'] == null ? undefined : ((value['remove'] as Array<any>).map(DirectMeasureInputToJSON)),
        'update': value['update'] == null ? undefined : ((value['update'] as Array<any>).map(DirectMeasureInputToJSON)),
    };
}

