/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOut
 */
export interface UserOut {
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    hasPassword: boolean;
}

/**
 * Check if a given object implements the UserOut interface.
 */
export function instanceOfUserOut(value: object): value is UserOut {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('hasPassword' in value) || value['hasPassword'] === undefined) return false;
    return true;
}

export function UserOutFromJSON(json: any): UserOut {
    return UserOutFromJSONTyped(json, false);
}

export function UserOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOut {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'organization': json['organization'] == null ? undefined : json['organization'],
        'jobTitle': json['job_title'] == null ? undefined : json['job_title'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'hasPassword': json['has_password'],
    };
}

export function UserOutToJSON(json: any): UserOut {
    return UserOutToJSONTyped(json, false);
}

export function UserOutToJSONTyped(value?: UserOut | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user_id': value['userId'],
        'email': value['email'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'organization': value['organization'],
        'job_title': value['jobTitle'],
        'phone_number': value['phoneNumber'],
        'has_password': value['hasPassword'],
    };
}

