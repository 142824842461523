/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOnboardingTasksResponse
 */
export interface UserOnboardingTasksResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasCompletedOnboardingFeedback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasDiscoveredMenu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasReadPluginInstallationInstructions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasReadLogoutNotice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHasReadWelcome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHasReadPageTutorialWelcome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToCreateProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToOpenProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToEditProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadUploadTrainingImage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadClassSection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAddAnnotation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAddSmartAnnotation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAccessValidate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadTrain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadValidateIntroduction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadUploadImage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadVisualizeResultsOpacity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadVisualizeResultsClassHide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadSendImageAnnotate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadDownloadPlugin?: boolean;
}

/**
 * Check if a given object implements the UserOnboardingTasksResponse interface.
 */
export function instanceOfUserOnboardingTasksResponse(value: object): value is UserOnboardingTasksResponse {
    return true;
}

export function UserOnboardingTasksResponseFromJSON(json: any): UserOnboardingTasksResponse {
    return UserOnboardingTasksResponseFromJSONTyped(json, false);
}

export function UserOnboardingTasksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOnboardingTasksResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'irreversibleUserHasCompletedOnboardingFeedback': json['irreversible_user_has_completed_onboarding_feedback'] == null ? undefined : json['irreversible_user_has_completed_onboarding_feedback'],
        'irreversibleUserHasDiscoveredMenu': json['irreversible_user_has_discovered_menu'] == null ? undefined : json['irreversible_user_has_discovered_menu'],
        'irreversibleUserHasReadPluginInstallationInstructions': json['irreversible_user_has_read_plugin_installation_instructions'] == null ? undefined : json['irreversible_user_has_read_plugin_installation_instructions'],
        'irreversibleUserHasReadLogoutNotice': json['irreversible_user_has_read_logout_notice'] == null ? undefined : json['irreversible_user_has_read_logout_notice'],
        'projectListUserHasReadWelcome': json['project_list_user_has_read_welcome'] == null ? undefined : json['project_list_user_has_read_welcome'],
        'projectListUserHasReadPageTutorialWelcome': json['project_list_user_has_read_page_tutorial_welcome'] == null ? undefined : json['project_list_user_has_read_page_tutorial_welcome'],
        'projectListUserHowToCreateProject': json['project_list_user_how_to_create_project'] == null ? undefined : json['project_list_user_how_to_create_project'],
        'projectListUserHowToOpenProject': json['project_list_user_how_to_open_project'] == null ? undefined : json['project_list_user_how_to_open_project'],
        'projectListUserHowToEditProject': json['project_list_user_how_to_edit_project'] == null ? undefined : json['project_list_user_how_to_edit_project'],
        'annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain': json['annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train'] == null ? undefined : json['annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train'],
        'annotationPageUserHasReadUploadTrainingImage': json['annotation_page_user_has_read_upload_training_image'] == null ? undefined : json['annotation_page_user_has_read_upload_training_image'],
        'annotationPageUserHasReadClassSection': json['annotation_page_user_has_read_class_section'] == null ? undefined : json['annotation_page_user_has_read_class_section'],
        'annotationPageUserHasReadAddAnnotation': json['annotation_page_user_has_read_add_annotation'] == null ? undefined : json['annotation_page_user_has_read_add_annotation'],
        'annotationPageUserHasReadAddSmartAnnotation': json['annotation_page_user_has_read_add_smart_annotation'] == null ? undefined : json['annotation_page_user_has_read_add_smart_annotation'],
        'annotationPageUserHasReadAccessValidate': json['annotation_page_user_has_read_access_validate'] == null ? undefined : json['annotation_page_user_has_read_access_validate'],
        'annotationPageUserHasReadTrain': json['annotation_page_user_has_read_train'] == null ? undefined : json['annotation_page_user_has_read_train'],
        'validationPageUserHasReadValidateIntroduction': json['validation_page_user_has_read_validate_introduction'] == null ? undefined : json['validation_page_user_has_read_validate_introduction'],
        'validationPageUserHasReadUploadImage': json['validation_page_user_has_read_upload_image'] == null ? undefined : json['validation_page_user_has_read_upload_image'],
        'validationPageUserHasReadVisualizeResultsOpacity': json['validation_page_user_has_read_visualize_results_opacity'] == null ? undefined : json['validation_page_user_has_read_visualize_results_opacity'],
        'validationPageUserHasReadVisualizeResultsClassHide': json['validation_page_user_has_read_visualize_results_class_hide'] == null ? undefined : json['validation_page_user_has_read_visualize_results_class_hide'],
        'validationPageUserHasReadSendImageAnnotate': json['validation_page_user_has_read_send_image_annotate'] == null ? undefined : json['validation_page_user_has_read_send_image_annotate'],
        'validationPageUserHasReadDownloadPlugin': json['validation_page_user_has_read_download_plugin'] == null ? undefined : json['validation_page_user_has_read_download_plugin'],
    };
}

export function UserOnboardingTasksResponseToJSON(json: any): UserOnboardingTasksResponse {
    return UserOnboardingTasksResponseToJSONTyped(json, false);
}

export function UserOnboardingTasksResponseToJSONTyped(value?: UserOnboardingTasksResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'irreversible_user_has_completed_onboarding_feedback': value['irreversibleUserHasCompletedOnboardingFeedback'],
        'irreversible_user_has_discovered_menu': value['irreversibleUserHasDiscoveredMenu'],
        'irreversible_user_has_read_plugin_installation_instructions': value['irreversibleUserHasReadPluginInstallationInstructions'],
        'irreversible_user_has_read_logout_notice': value['irreversibleUserHasReadLogoutNotice'],
        'project_list_user_has_read_welcome': value['projectListUserHasReadWelcome'],
        'project_list_user_has_read_page_tutorial_welcome': value['projectListUserHasReadPageTutorialWelcome'],
        'project_list_user_how_to_create_project': value['projectListUserHowToCreateProject'],
        'project_list_user_how_to_open_project': value['projectListUserHowToOpenProject'],
        'project_list_user_how_to_edit_project': value['projectListUserHowToEditProject'],
        'annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train': value['annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain'],
        'annotation_page_user_has_read_upload_training_image': value['annotationPageUserHasReadUploadTrainingImage'],
        'annotation_page_user_has_read_class_section': value['annotationPageUserHasReadClassSection'],
        'annotation_page_user_has_read_add_annotation': value['annotationPageUserHasReadAddAnnotation'],
        'annotation_page_user_has_read_add_smart_annotation': value['annotationPageUserHasReadAddSmartAnnotation'],
        'annotation_page_user_has_read_access_validate': value['annotationPageUserHasReadAccessValidate'],
        'annotation_page_user_has_read_train': value['annotationPageUserHasReadTrain'],
        'validation_page_user_has_read_validate_introduction': value['validationPageUserHasReadValidateIntroduction'],
        'validation_page_user_has_read_upload_image': value['validationPageUserHasReadUploadImage'],
        'validation_page_user_has_read_visualize_results_opacity': value['validationPageUserHasReadVisualizeResultsOpacity'],
        'validation_page_user_has_read_visualize_results_class_hide': value['validationPageUserHasReadVisualizeResultsClassHide'],
        'validation_page_user_has_read_send_image_annotate': value['validationPageUserHasReadSendImageAnnotate'],
        'validation_page_user_has_read_download_plugin': value['validationPageUserHasReadDownloadPlugin'],
    };
}

