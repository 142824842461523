import * as React from 'react'
import { Layout, Menu, Typography, Popconfirm, Button, Image, Space, Breadcrumb as AntBreadcrumb, Divider, Avatar, Tag, Tooltip } from 'antd'
import styles from './styles/rf-header.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { WebUIRoutes } from '@app/routes'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { TutorialIcon } from '@components/tutorial/tutorial-icon'
import SettingIcon from '@material-design-icons/svg/round/edit.svg'

import { E2E_REQUIRED_CLASSES, EVENTS_ID, ONBOARDING_TOUR_CONTEXT, TOOLTIP_MOUSE_ENTER_DELAY, TUTORIAL_ENABLED } from '@app/constants'
import { LicensingTag } from '@components/licence/licensing-tag'
import { getLicenceTagProperties } from '@components/licence/licensing-tag-properties'
import {
  useAlgorithmId,
  useLicence,
  useOnboardingTasks,
  useProfile,
} from '@api/hooks'
import { MenuContext, Navigation } from '@components/layout/navigation'
import * as RFAPI from '@app/api/api'
import { RequestAssistanceModal } from '@components/modals/request-assistance-modal'
import { AlgorithmId } from '@app/api/openapi'
const { Header } = Layout
const { Title, Text } = Typography

interface ClemexAvatarProps {
  email: string
  firstName: string
  lastName: string
}
const ClemexAvatar: React.FC<ClemexAvatarProps> = ({ firstName, lastName, email }) => {
  return <Tooltip
    title={email}
    mouseLeaveDelay={0}
    mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
  >
    <Link to={WebUIRoutes.profile().path}>
      <Typography className={styles.clemexAvatar}>
        <Avatar className={styles.avatarIcon}>
          {firstName[0]}{lastName[0]}
        </Avatar>
      </Typography>
    </Link>
  </Tooltip>
}

export const ClemexLogo: React.FC = () => {
  return (
    <Link to={WebUIRoutes.projectList().path}>
      <Image preview={false} src='/images/login/header_logo.svg' width={'50px'} />
    </Link>
  )
}
interface BreadcrumbProps {
  childPage?: string
  projectId?: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ childPage, projectId }) => {
  const intl = useIntl()
  const { data: algorithmId } = useAlgorithmId(projectId)
  const { data: licence } = useLicence()
  const breadCrumbItems = [
    {
      title: <Link to={WebUIRoutes.projectList().path}>{intl.formatMessage({
        id: 'breadcrumb.page.home',
        defaultMessage: 'Home',
      })}</Link>,
    },
  ]

  const selectedAlgo: Record<AlgorithmId, React.ReactNode> = {
    [AlgorithmId.RfAlgorithmSklearn]: <FormattedMessage id='algorithm-id.random-forest.label' defaultMessage='Random Forest'/>,
    [AlgorithmId.RfAlgorithmCuml]: <FormattedMessage id='algorithm-id.random-forest.label' defaultMessage='Random Forest'/>,
    [AlgorithmId.ClemexNetLiteV1]: <FormattedMessage id='algorithm-id.clemex-net-lite-v1.label' defaultMessage='ClemexNet Lite V1' />,
    [AlgorithmId.ClemexNetLiteV2]: <FormattedMessage id='algorithm-id.clemex-net-lite-v2.label' defaultMessage='ClemexNet Lite V2' />,
    [AlgorithmId.ClemexGrainsegV3]: <FormattedMessage id='algorithm-id.grainseg' defaultMessage='Universal GrainSeg V3' />,
    [AlgorithmId.ClemexGrainsegV3Aluminum]: <FormattedMessage id='algorithm-id.grainseg-aluminum' defaultMessage='GrainSeg V3 Aluminum' />,
    [AlgorithmId.ClemexMedcleanV1]: <FormattedMessage id='algorithm-id.grainseg-medclean' defaultMessage='Medclean V1' />,
  }
  const algoToPremiumTag: Record<AlgorithmId, boolean> = {
    [AlgorithmId.RfAlgorithmSklearn]: licence?.isPremium ?? false,
    [AlgorithmId.RfAlgorithmCuml]: licence?.isPremium ?? false,
    [AlgorithmId.ClemexNetLiteV1]: licence?.algoClemexNetLiteV1 ?? false,
    [AlgorithmId.ClemexNetLiteV2]: licence?.algoClemexNetLiteV2 ?? false,
    [AlgorithmId.ClemexGrainsegV3]: licence?.algoClemexGrainsegV3 ?? false,
    [AlgorithmId.ClemexGrainsegV3Aluminum]: licence?.algoClemexGrainsegV3Aluminum ?? false,
    [AlgorithmId.ClemexMedcleanV1]: licence?.algoClemexMedcleanV1 ?? false,
  }
  const breadCrumbItemsWithChild = childPage !== undefined
    ? [...breadCrumbItems, {
        title: <>
          <div className={styles.breadcrumbLastItemContainer}>
            <Text title={childPage} className={styles.breadcrumbLastItem} ellipsis>
              {childPage}
            </Text>
            <SettingIcon className={styles.editProjectSettings} onClick={() => {
              window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_SETTING_OPEN))
            }}/>
          </div>
          { algorithmId !== undefined && <Tag className={clsx(styles.algoTag, { [styles.premium]: algoToPremiumTag[algorithmId] })} onClick={() => {
            window.dispatchEvent(new CustomEvent(EVENTS_ID.ALGO_SETTING_OPEN))
          }}>{selectedAlgo[algorithmId]}</Tag>
          }
        </>,
      }]
    : breadCrumbItems
  return <AntBreadcrumb className={styles.breadcrumb} items={breadCrumbItemsWithChild} />
}

interface RfHeaderProps {
  leftChildren?: React.ReactNode
  children?: React.ReactNode
  rightChildren?: React.ReactNode
  showProfileMenu?: boolean
  breadcrumbItem?: string
  navigationContext?: MenuContext
  onboardingTourContext?: ONBOARDING_TOUR_CONTEXT
  projectId?: string
}
export const RFHeader: React.FC<RfHeaderProps> = ({ leftChildren, children, rightChildren, showProfileMenu, breadcrumbItem, navigationContext, projectId, onboardingTourContext = ONBOARDING_TOUR_CONTEXT.NOT_USED }) => {
  const intl = useIntl()
  const [showTutorialPopOver, setShowTutorialPopOver] = React.useState(false)
  const [showNavigation, setShowNavigation] = React.useState(false)
  const { data: userProfile } = useProfile()
  const { data: licence, isLoading: isLicenceLoading } = useLicence()
  const showLicensingWithProfile = !isLicenceLoading
  const licenceTagProperties = getLicenceTagProperties(intl, licence)
  const [isRequestAssistanceModalOpen, setIsRequestAssistanceModalOpen] = React.useState<boolean>(false)
  const licenceTagInProfile =
    <Link to={WebUIRoutes.profile().path}>
      <LicensingTag
        className={clsx(styles.licenceTag, licenceTagProperties?.className, styles.onlineLicenceTag)}
        tagIcon={licenceTagProperties?.icon}
        tagText={licenceTagProperties.text}
      />
    </Link>
  const { mutate: mutateOnboardingTasks, isLoading: isOnboardingTasksLoading, isOnboardingInProgress } = useOnboardingTasks(onboardingTourContext)

  const closeNavigation = (): void => {
    setShowNavigation(false)
  }
  const openNavigation = (): void => {
    setShowNavigation(true)
  }

  React.useEffect(() => {
    const showTutorialPopOverEventHandler = (): void => {
      setShowTutorialPopOver(true)
      // Display the popup for 2 seconds
      setTimeout(() => {
        setShowTutorialPopOver(false)
      }, 2000)
    }
    window.addEventListener(EVENTS_ID.SHOW_TUTORIAL_POP_OVER, showTutorialPopOverEventHandler, {})
    return () => {
      window.removeEventListener(EVENTS_ID.SHOW_TUTORIAL_POP_OVER, showTutorialPopOverEventHandler, {})
    }
  }, [])

  React.useEffect(() => {
    const downloadLogsEventHandler = async (): Promise<void> => {
      // TODO Handle the error case from server
      const link = document.createElement('a')
      link.href = '/api/desktop/download_logs'
      link.download = 'true'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    window.addEventListener(EVENTS_ID.DOWNLOAD_LOGS, downloadLogsEventHandler)
    return () => {
      window.removeEventListener(EVENTS_ID.DOWNLOAD_LOGS, downloadLogsEventHandler)
    }
  }, [])

  const closeAssistanceModal = (): void => {
    setIsRequestAssistanceModalOpen(false)
  }
  const helpMeButton = <Button
    size='middle'
    type='primary'
    className={styles.helpMeButton}
    onClick={ () => { setIsRequestAssistanceModalOpen(true) }}
  >
    { intl.formatMessage({
      id: 'request-assistance.button.label',
      defaultMessage: 'I need help',
    })}
  </Button>

  const isProfileMenuVisible = (showProfileMenu ?? true)

  const onResetUserOnboardingTutorial = React.useCallback(async () => {
    await RFAPI.resetAllUserOnboardingTasks()
    await mutateOnboardingTasks()
    window.dispatchEvent(new CustomEvent(EVENTS_ID.RESET_TUTORIAL))
  }, [mutateOnboardingTasks])

  return <Header className={clsx(styles.header, `theme-${licence?.theme ?? 'clemex'}`)}>
    <Space size="middle" className={styles.leftHeader}>
      <Button className={clsx(styles.buttonMenu, E2E_REQUIRED_CLASSES.NAVIGATION_MENU)} onClick={openNavigation} >&nbsp;</Button>
      <Navigation show={showNavigation} onClose={closeNavigation} context={navigationContext} />
      <Breadcrumb childPage={breadcrumbItem} projectId={projectId}/>
      {leftChildren}
    </Space>
    <div className={styles.headerContent} >
      {children}
    </div>
    <div className={clsx(styles.rightHeader, { [styles.rightHeaderWithLicence]: showLicensingWithProfile })}>
      { rightChildren }
      { navigationContext === MenuContext.HOME ||
          navigationContext === MenuContext.EDITOR
        ? <Menu mode="horizontal" className={styles.userMenu} disabledOverflow
            items={[
              TUTORIAL_ENABLED
                ? {
                    label: <Popconfirm
                      placement='bottomRight'
                      title={
                        <FormattedMessage
                          id={'header.user-menu.sub-menu.onboarding.pop-confirm-title'}
                          defaultMessage={'You can replay the tutorial using this button'}
                        />
                      }
                      open={showTutorialPopOver}
                      onConfirm={(e) => {
                        e?.stopPropagation()
                        setShowTutorialPopOver(false)
                      }}
                      showCancel={false}
                    >
                      <TutorialIcon />
                    </Popconfirm>,
                    key: 'tutorial',
                    className: clsx({ [styles.hideSubMenu]: (isOnboardingInProgress || isOnboardingTasksLoading) }, styles.menuItem),
                    onClick: () => { void onResetUserOnboardingTutorial() },
                  }
                : null,
            ]}
          />
        : null }
      { helpMeButton }
      <RequestAssistanceModal
        isOpen={isRequestAssistanceModalOpen}
        close={closeAssistanceModal}
      />

      { isProfileMenuVisible
        ? <>
            { userProfile !== undefined && <ClemexAvatar email={userProfile.email} firstName={userProfile.firstName} lastName={userProfile.lastName} />}
            { showLicensingWithProfile && <Divider className={styles.divider} type="vertical"/> }
            { showLicensingWithProfile && licenceTagInProfile }
          </>
        : <>
            { !isLicenceLoading
              ? <Link to={WebUIRoutes.licence().path} className={styles.licenceTagLink}>
                  <LicensingTag
                    className={clsx(styles.licenceTag, licenceTagProperties?.className, styles.tagWithoutProfile)}
                    tagIcon={licenceTagProperties?.icon}
                    tagText={licenceTagProperties?.text}
                  />
                </Link>
              : null
            }
          </>
      }
    </div>
  </Header>
}

export const AuthenticationHeader: React.FC<{ className?: string, children?: React.ReactNode }> = ({ className, children }) => (
  <Header className={clsx(styles.authHeader, className)}>
    <div className={styles.leftHeader}>
      <Link to={WebUIRoutes.authentication().path}>
        <div className={styles.logoAuthentication}>
          <Title className={styles.logoAuthenticationTitle}>
            <FormattedMessage id={'header.logo.clemex'} defaultMessage={'Clemex Studio'} />
          </Title>
        </div>
      </Link>
    </div>
    <div className={styles.authContent} >
      {children}
    </div>
  </Header>
)
