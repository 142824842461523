/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectDatasetContextInput = {
    Training: 'training',
    Validation: 'validation',
    Deleted: 'deleted',
    Uploaded: 'uploaded'
} as const;
export type ProjectDatasetContextInput = typeof ProjectDatasetContextInput[keyof typeof ProjectDatasetContextInput];


export function instanceOfProjectDatasetContextInput(value: any): boolean {
    for (const key in ProjectDatasetContextInput) {
        if (Object.prototype.hasOwnProperty.call(ProjectDatasetContextInput, key)) {
            if (ProjectDatasetContextInput[key as keyof typeof ProjectDatasetContextInput] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProjectDatasetContextInputFromJSON(json: any): ProjectDatasetContextInput {
    return ProjectDatasetContextInputFromJSONTyped(json, false);
}

export function ProjectDatasetContextInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDatasetContextInput {
    return json as ProjectDatasetContextInput;
}

export function ProjectDatasetContextInputToJSON(value?: ProjectDatasetContextInput | null): any {
    return value as any;
}

export function ProjectDatasetContextInputToJSONTyped(value: any, ignoreDiscriminator: boolean): ProjectDatasetContextInput {
    return value as ProjectDatasetContextInput;
}

