/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatasetStatistics
 */
export interface DatasetStatistics {
    /**
     * 
     * @type {number}
     * @memberof DatasetStatistics
     */
    totalImages: number;
}

/**
 * Check if a given object implements the DatasetStatistics interface.
 */
export function instanceOfDatasetStatistics(value: object): value is DatasetStatistics {
    if (!('totalImages' in value) || value['totalImages'] === undefined) return false;
    return true;
}

export function DatasetStatisticsFromJSON(json: any): DatasetStatistics {
    return DatasetStatisticsFromJSONTyped(json, false);
}

export function DatasetStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'totalImages': json['total_images'],
    };
}

export function DatasetStatisticsToJSON(json: any): DatasetStatistics {
    return DatasetStatisticsToJSONTyped(json, false);
}

export function DatasetStatisticsToJSONTyped(value?: DatasetStatistics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'total_images': value['totalImages'],
    };
}

