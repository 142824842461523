/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectShareDetails } from './ProjectShareDetails';
import {
    ProjectShareDetailsFromJSON,
    ProjectShareDetailsFromJSONTyped,
    ProjectShareDetailsToJSON,
    ProjectShareDetailsToJSONTyped,
} from './ProjectShareDetails';

/**
 * 
 * @export
 * @interface ProjectShareDetailsResponse
 */
export interface ProjectShareDetailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectShareDetailsResponse
     */
    isValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectShareDetailsResponse
     */
    rejectAsSharedToHimself?: boolean;
    /**
     * 
     * @type {ProjectShareDetails}
     * @memberof ProjectShareDetailsResponse
     */
    details: ProjectShareDetails | null;
}

/**
 * Check if a given object implements the ProjectShareDetailsResponse interface.
 */
export function instanceOfProjectShareDetailsResponse(value: object): value is ProjectShareDetailsResponse {
    if (!('isValid' in value) || value['isValid'] === undefined) return false;
    if (!('details' in value) || value['details'] === undefined) return false;
    return true;
}

export function ProjectShareDetailsResponseFromJSON(json: any): ProjectShareDetailsResponse {
    return ProjectShareDetailsResponseFromJSONTyped(json, false);
}

export function ProjectShareDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectShareDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isValid': json['is_valid'],
        'rejectAsSharedToHimself': json['reject_as_shared_to_himself'] == null ? undefined : json['reject_as_shared_to_himself'],
        'details': ProjectShareDetailsFromJSON(json['details']),
    };
}

export function ProjectShareDetailsResponseToJSON(json: any): ProjectShareDetailsResponse {
    return ProjectShareDetailsResponseToJSONTyped(json, false);
}

export function ProjectShareDetailsResponseToJSONTyped(value?: ProjectShareDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'is_valid': value['isValid'],
        'reject_as_shared_to_himself': value['rejectAsSharedToHimself'],
        'details': ProjectShareDetailsToJSON(value['details']),
    };
}

