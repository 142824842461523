/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Project Training Error
 * @export
 * @interface ProjectTrainingError
 */
export interface ProjectTrainingError {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingError
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectTrainingError
     */
    errorDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingError
     */
    errorMessage: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingError
     */
    acknowledged: boolean;
}

/**
 * Check if a given object implements the ProjectTrainingError interface.
 */
export function instanceOfProjectTrainingError(value: object): value is ProjectTrainingError {
    if (!('projectTrainingSnapshotId' in value) || value['projectTrainingSnapshotId'] === undefined) return false;
    if (!('errorDatetime' in value) || value['errorDatetime'] === undefined) return false;
    if (!('errorMessage' in value) || value['errorMessage'] === undefined) return false;
    if (!('acknowledged' in value) || value['acknowledged'] === undefined) return false;
    return true;
}

export function ProjectTrainingErrorFromJSON(json: any): ProjectTrainingError {
    return ProjectTrainingErrorFromJSONTyped(json, false);
}

export function ProjectTrainingErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingError {
    if (json == null) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'errorDatetime': (new Date(json['error_datetime'])),
        'errorMessage': json['error_message'],
        'acknowledged': json['acknowledged'],
    };
}

export function ProjectTrainingErrorToJSON(json: any): ProjectTrainingError {
    return ProjectTrainingErrorToJSONTyped(json, false);
}

export function ProjectTrainingErrorToJSONTyped(value?: ProjectTrainingError | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_training_snapshot_id': value['projectTrainingSnapshotId'],
        'error_datetime': ((value['errorDatetime']).toISOString()),
        'error_message': value['errorMessage'],
        'acknowledged': value['acknowledged'],
    };
}

