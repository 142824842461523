/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
    PolygonToJSONTyped,
} from './Polygon';

/**
 * 
 * @export
 * @interface ClassAnnotationGeoJSON
 */
export interface ClassAnnotationGeoJSON {
    /**
     * 
     * @type {Date}
     * @memberof ClassAnnotationGeoJSON
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassAnnotationGeoJSON
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    classAnnotationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    imageId: string;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationGeoJSON
     */
    colorIndex: number;
    /**
     * 
     * @type {Polygon}
     * @memberof ClassAnnotationGeoJSON
     */
    geometry: Polygon;
}

/**
 * Check if a given object implements the ClassAnnotationGeoJSON interface.
 */
export function instanceOfClassAnnotationGeoJSON(value: object): value is ClassAnnotationGeoJSON {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    return true;
}

export function ClassAnnotationGeoJSONFromJSON(json: any): ClassAnnotationGeoJSON {
    return ClassAnnotationGeoJSONFromJSONTyped(json, false);
}

export function ClassAnnotationGeoJSONFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassAnnotationGeoJSON {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDate': json['creation_date'] == null ? undefined : (new Date(json['creation_date'])),
        'modificationDate': json['modification_date'] == null ? undefined : (new Date(json['modification_date'])),
        'classAnnotationId': json['class_annotation_id'] == null ? undefined : json['class_annotation_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'colorIndex': json['color_index'],
        'geometry': PolygonFromJSON(json['geometry']),
    };
}

export function ClassAnnotationGeoJSONToJSON(json: any): ClassAnnotationGeoJSON {
    return ClassAnnotationGeoJSONToJSONTyped(json, false);
}

export function ClassAnnotationGeoJSONToJSONTyped(value?: ClassAnnotationGeoJSON | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'creation_date': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'modification_date': value['modificationDate'] == null ? undefined : ((value['modificationDate']).toISOString()),
        'class_annotation_id': value['classAnnotationId'],
        'project_id': value['projectId'],
        'image_id': value['imageId'],
        'color_index': value['colorIndex'],
        'geometry': PolygonToJSON(value['geometry']),
    };
}

