import { Draw, Modify, Translate } from 'ol/interaction'
import { type Map, type Collection, type Feature, type MapBrowserEvent } from 'ol'
import { LineString } from 'ol/geom'
import EventType from 'ol/events/EventType'
import { type DrawEvent } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { FEATURE_TYPE, FeatureType, type StraightLineFeatureType } from '../shapes/common'
import { type ChangePatch } from './common'
import { v4 as uuidV4 } from 'uuid'
import { type ModifyEvent } from 'ol/interaction/Modify'
import { type StyleFunction } from 'ol/style/Style'
import { primaryAction } from 'ol/events/condition'
import { type Layer } from 'ol/layer'
import { DEFAULT_ARROW_GEOMETRY_PROPERTIES, type ArrowGeometryProperties } from '../shapes/arrow'
import { type DistanceGeometryProperties } from '../shapes/distance'
import { type Coordinate } from 'ol/coordinate'
import { type TranslateEvent } from 'ol/interaction/Translate'
import { type FeatureLike } from 'ol/Feature'

export class DrawStraightLineInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private _isFreehand: boolean
  private _snapAngle = false
  private _isInteracting = false
  private readonly _drawingFeatureType: StraightLineFeatureType
  private _arrowProperties: ArrowGeometryProperties = DEFAULT_ARROW_GEOMETRY_PROPERTIES

  constructor (
    destinationCollection: Collection<Feature<LineString>>,
    onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void,
    drawingFeatureType: StraightLineFeatureType,
    isFreehand = true,
  ) {
    super({
      type: 'LineString',
      minPoints: 2,
      maxPoints: 2,
      geometryFunction: (coordinates_, geometry) => {
        const coordinates = coordinates_ as [number, number][]
        const start = coordinates[0]
        const _end = coordinates[coordinates.length - 1]
        const angle = Math.atan2(_end[1] - start[1], _end[0] - start[0])
        // Find closest snaping angle
        const snapingAngle = Math.round(angle / (Math.PI / 16)) * (Math.PI / 16)
        // Snap end to snaping angle and keep the same distance from start
        const modifiedEnd = [start[0] + Math.cos(snapingAngle) * Math.sqrt(Math.pow(_end[0] - start[0], 2) + Math.pow(_end[1] - start[1], 2)), start[1] + Math.sin(snapingAngle) * Math.sqrt(Math.pow(_end[0] - start[0], 2) + Math.pow(_end[1] - start[1], 2))]
        const end = this._snapAngle ? modifiedEnd : _end
        if (geometry === undefined) {
          geometry = new LineString([start, end])
        } else {
          (geometry as LineString).setCoordinates([start, end])
        }
        if (this._drawingFeatureType === FeatureType.METADATA_ANNOTATION_ARROW) {
          geometry.setProperties({
            ...this._arrowProperties,
            pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
          })
        } else {
          geometry.setProperties({
            geometryType: 'DISTANCE',
            pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
          } satisfies DistanceGeometryProperties)
        }
        return geometry
      },
      condition: primaryAction,
      freehandCondition: (e) => {
        if (!this._isFreehand) {
          return false
        }
        if (e.originalEvent instanceof MouseEvent) {
          return e.originalEvent.button === -1 || e.originalEvent.button === 0
        }
        return true
      },
    })
    this._onEndInteraction = onEndInteraction
    this._drawingFeatureType = drawingFeatureType
    this._isFreehand = isFreehand
    // XXX: This is a hack to make the freehand condition work
    //      Otherwise, the number of points is not updated
    this.setFreehand(isFreehand)

    this.addEventListener('drawstart', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      const id = uuidV4()
      drawEndEvent.feature.setProperties({
        [FEATURE_TYPE]: this._drawingFeatureType,
        id,
      })
      this._isInteracting = true
    })
    this.addEventListener('drawabort', () => {
      this._isInteracting = false
    })
    this.addEventListener('drawend', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      this._isInteracting = false
      const feature = drawEndEvent.feature as Feature<LineString>
      destinationCollection.push(feature)
      this._onEndInteraction({
        add: [
          {
            id: feature.getProperties().id,
            data: feature,
          },
        ],
      })
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
      if (key === 'Tab') {
        const sketchCoords = (this as unknown as { sketchCoords_: [number, number][] }).sketchCoords_
        const lastSketchCoords = sketchCoords[sketchCoords.length - 1].slice();
        (this as unknown as { modifyDrawing_: (coord: number[]) => void }).modifyDrawing_(lastSketchCoords)
      }
      if (key === 'Shift') {
        this._snapAngle = true
        mapBrowserEvent.map.getTargetElement().addEventListener('keyup', (e) => {
          if (e instanceof KeyboardEvent && e.key === 'Shift') {
            this._snapAngle = false
          }
        }, { once: true })
        return false
      }
    }

    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setFreehand = (isFreehand: boolean): void => {
    this._isFreehand = isFreehand;
    (this as unknown as { maxPoints_: number }).maxPoints_ = isFreehand ? +Infinity : 2
    this.setProperties({ maxPoints_: isFreehand ? +Infinity : 2 })
  }

  public readonly setArrowProperties = (arrowProperties: ArrowGeometryProperties): void => {
    this._arrowProperties = arrowProperties
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }
}

interface ModifyStraightLineInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  style: StyleFunction
  onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  pixelTolerance?: number
}
export class ModifyStraightLineInteraction extends Modify {
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number
  private readonly _modifiableFeatures: Collection<Feature>

  constructor ({
    modifiableFeatures,
    layers,
    style,
    onStartInteraction,
    onEndInteraction,
    pixelTolerance = 16,
  }: ModifyStraightLineInteractionOptions) {
    super({
      features: modifiableFeatures,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      pixelTolerance,
      insertVertexCondition: () => {
        return false
      },
      style,
    })
    this._pixelTolerance = pixelTolerance
    this._modifiableFeatures = modifiableFeatures
    this._layers = layers

    this.on('modifystart', (modifyStartEvent: ModifyEvent) => {
      onStartInteraction(modifyStartEvent.features.getArray() as Feature<LineString>[])
    })

    this.on('modifyend', (modifyEndEvent: ModifyEvent) => {
      onEndInteraction({
        update: modifyEndEvent.features.getArray().map((f) => {
          return {
            id: f.getProperties().id,
            data: f as Feature<LineString>,
          }
        }),
      })
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === 'pointerdown') {
      // if the event is a pointerdown event
      // it means that the user is trying to modify the feature
      // But is should only be able to modify the feature if the cursor is over a vertex
      // Otherwise, it might be translation interaction
      // Unfornatly, the ol/interaction/Modify does not provide a way to check if the cursor is over a vertex
      // So we need to reimplement the handleEvent method
      // In the case, it is not over a vertex, the event should not be captured by this interaction
      // And should be propagated to the next interaction
      if (this.isCursorAbleToModify(mapBrowserEvent)) {
        return super.handleEvent(mapBrowserEvent)
      } else {
        return true
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    const hasFeatureAtPixel = evt.map.hasFeatureAtPixel(evt.pixel, {
      layerFilter: (layer) => this._layers.includes(layer),
      hitTolerance: this._pixelTolerance,
    })
    if (!hasFeatureAtPixel) {
      return false
    }
    // Check that the cursor is over a vertex
    const clickCoordinates = this.getMap()?.getCoordinateFromPixel(evt.pixel)
    if (clickCoordinates === undefined) {
      return false
    }
    const vertexFeature = this._modifiableFeatures.getArray().find((feature) => {
      const geometry = feature.getGeometry()
      if (geometry === undefined) {
        return false
      }
      return (geometry as LineString).getCoordinates().some((coord) => {
        const dist = Math.sqrt(Math.pow(coord[0] - clickCoordinates[0], 2) + Math.pow(coord[1] - clickCoordinates[1], 2))
        return dist < this._pixelTolerance
      })
    })
    return vertexFeature !== undefined
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }
}

interface TranslateStraightLineInteractionOptions {
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  pixelTolerance?: number
}
export class TranslateStraightLineInteraction extends Translate {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number
  private _hasTranslated = false

  constructor ({
    layers,
    onEndInteraction,
    onStartInteraction,
    pixelTolerance = 16,
  }: TranslateStraightLineInteractionOptions) {
    super({
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      hitTolerance: pixelTolerance,
    })
    this._onEndInteraction = onEndInteraction
    this._pixelTolerance = pixelTolerance
    this._layers = layers;
    (this as unknown as { featuresAtPixel_: TranslateStraightLineInteraction['_featuresAtPixel_'] }).featuresAtPixel_ = this._featuresAtPixel_

    this.on('translatestart', (translateStartEvent: TranslateEvent) => {
      onStartInteraction(translateStartEvent.features.getArray() as Feature<LineString>[])
      this._hasTranslated = false
    })
    this.on('translating', () => {
      this._hasTranslated = true
    })
    this.on('translateend', (translateEndEvent: TranslateEvent) => {
      if (!this._hasTranslated) {
        this._onEndInteraction({}) // No change
      } else {
        this._onEndInteraction({
          update: translateEndEvent.features.getArray().map((feature) => {
            return {
              id: feature.getProperties().id,
              data: feature as Feature<LineString>,
            }
          }),
        })
      }
      this._hasTranslated = false
    })
  }

  // XXX: reimplementation of the private method featuresAtPixel_ from ol/interaction/Translate
  //      The original implementation is buggy as `feature` frin the callback of forEachFeatureAtPixel
  //      can be `FeatureLike` instead of `Feature<Geometry>`.
  private readonly _featuresAtPixel_ = (pixel: Coordinate, map: Map): Feature | undefined => {
    return map.forEachFeatureAtPixel(
      pixel,
      (feature, layer) => {
        if (!(this as unknown as { filter_: (f: FeatureLike, l: Layer) => boolean }).filter_(feature, layer)) {
          return undefined
        }
        const features = (this as unknown as { features_: Collection<Feature> | null }).features_
        if ((features != null) && !features.getArray().some((f) => f.getProperties().id === feature.getProperties().id)) {
          return undefined
        }

        return feature
      },
      {
        layerFilter: (layer) => this._layers.includes(layer),
        hitTolerance: this._pixelTolerance,
      },
    ) as Feature | undefined
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }
}
