/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
    TaskStatusToJSONTyped,
} from './TaskStatus';

/**
 * Prediction Status
 * 
 * Holds the status of image tiling task
 * @export
 * @interface ImageStatus
 */
export interface ImageStatus {
    /**
     * 
     * @type {TaskStatus}
     * @memberof ImageStatus
     */
    imageTilingStatus: TaskStatus;
}



/**
 * Check if a given object implements the ImageStatus interface.
 */
export function instanceOfImageStatus(value: object): value is ImageStatus {
    if (!('imageTilingStatus' in value) || value['imageTilingStatus'] === undefined) return false;
    return true;
}

export function ImageStatusFromJSON(json: any): ImageStatus {
    return ImageStatusFromJSONTyped(json, false);
}

export function ImageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'imageTilingStatus': TaskStatusFromJSON(json['image_tiling_status']),
    };
}

export function ImageStatusToJSON(json: any): ImageStatus {
    return ImageStatusToJSONTyped(json, false);
}

export function ImageStatusToJSONTyped(value?: ImageStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image_tiling_status': TaskStatusToJSON(value['imageTilingStatus']),
    };
}

