/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PendingTraining } from './PendingTraining';
import {
    PendingTrainingFromJSON,
    PendingTrainingFromJSONTyped,
    PendingTrainingToJSON,
    PendingTrainingToJSONTyped,
} from './PendingTraining';
import type { RunningTraining } from './RunningTraining';
import {
    RunningTrainingFromJSON,
    RunningTrainingFromJSONTyped,
    RunningTrainingToJSON,
    RunningTrainingToJSONTyped,
} from './RunningTraining';

/**
 * 
 * @export
 * @interface InProgressTraining
 */
export interface InProgressTraining {
    /**
     * 
     * @type {string}
     * @memberof InProgressTraining
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {Date}
     * @memberof InProgressTraining
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof InProgressTraining
     */
    startingDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof InProgressTraining
     */
    status?: string;
}

/**
 * Check if a given object implements the InProgressTraining interface.
 */
export function instanceOfInProgressTraining(value: object): value is InProgressTraining {
    if (!('projectTrainingSnapshotId' in value) || value['projectTrainingSnapshotId'] === undefined) return false;
    if (!('creationDate' in value) || value['creationDate'] === undefined) return false;
    if (!('startingDatetime' in value) || value['startingDatetime'] === undefined) return false;
    return true;
}

export function InProgressTrainingFromJSON(json: any): InProgressTraining {
    return InProgressTrainingFromJSONTyped(json, false);
}

export function InProgressTrainingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InProgressTraining {
    if (json == null) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'creationDate': (new Date(json['creation_date'])),
        'startingDatetime': (new Date(json['starting_datetime'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function InProgressTrainingToJSON(json: any): InProgressTraining {
    return InProgressTrainingToJSONTyped(json, false);
}

export function InProgressTrainingToJSONTyped(value?: InProgressTraining | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_training_snapshot_id': value['projectTrainingSnapshotId'],
        'creation_date': ((value['creationDate']).toISOString()),
        'starting_datetime': ((value['startingDatetime']).toISOString()),
        'status': value['status'],
    };
}

