import * as React from 'react'
import styles from './styles/tutorial-tour.module.scss'
import { useOnboardingTasks } from '@app/api/hooks'
import { type UserOnboardingTasksResponse, type OnboardingTaskType } from '@app/api/openapi'
import { EVENTS_ID, type ONBOARDING_TOUR_CONTEXT } from '@app/constants'
import { Modal, Tour, type TourStepProps } from 'antd'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

interface TextProps {
  children?: React.ReactNode
}
export const Text: React.FC<TextProps> = ({ children }) => {
  return <div className={styles.tutorialTourText}>
    {children}
  </div>
}

export interface TutorialTourStepsProps {
  keyIsCompleted: keyof UserOnboardingTasksResponse
  onboardingTaskCompletion: OnboardingTaskType
  step: TourStepProps
}

interface TutorialTourProps {
  steps: TutorialTourStepsProps[]
  onboardingTourContext: ONBOARDING_TOUR_CONTEXT
}

export const TutorialTour: React.FC<TutorialTourProps> = ({ steps, onboardingTourContext }) => {
  const { isValidating: isValidatingOnboardingTasks, data: onboardingTasks, incompleteTasks, isOnboardingInProgress, finishAllOnboardingTasks, finishOnboardingTask, completeOnboardingTask } = useOnboardingTasks(onboardingTourContext)
  const [cancelTutorialModal, menuClassDetailModalContextHolder] = Modal.useModal()
  const intl = useIntl()
  const selectedSteps = React.useMemo(() => {
    return steps.filter((step) =>
      (onboardingTasks !== undefined ? incompleteTasks.includes(step.keyIsCompleted) : false))
  }, [steps, onboardingTasks, incompleteTasks])
  const tourSteps = React.useMemo(() => {
    return selectedSteps.map((step) => step.step)
  }, [selectedSteps])
  const [onboardingTasksCompleted, setOnboardingTasksCompleted] = React.useState<OnboardingTaskType[]>([])

  React.useEffect(() => {
    const resetTutorialEventHandler = (): void => {
      setOnboardingTasksCompleted([])
    }
    window.addEventListener(EVENTS_ID.RESET_TUTORIAL, resetTutorialEventHandler, {})
    return () => {
      window.removeEventListener(EVENTS_ID.RESET_TUTORIAL, resetTutorialEventHandler, {})
    }
  }, [])

  const completeTask = React.useCallback(async (onboardingTask: OnboardingTaskType): Promise<void> => {
    // Do not complete the current task in case of click to Previous/Next
    // Let complete a step if not already received
    if (!onboardingTasksCompleted.includes(onboardingTask)) {
      await completeOnboardingTask(onboardingTask)
      setOnboardingTasksCompleted((previousTaskCompleted) => {
        return [...previousTaskCompleted, onboardingTask]
      })
      // No mutation let the possibility to come back to previous page
    }
  }, [completeOnboardingTask, onboardingTasksCompleted])

  const skipTutorial = async (): Promise<void> => {
    if (onboardingTasks === undefined) {
      return
    }
    const tasksToComplete = selectedSteps.map((step) => step.onboardingTaskCompletion)
    await finishAllOnboardingTasks(tasksToComplete)

    window.dispatchEvent(new CustomEvent(EVENTS_ID.SHOW_TUTORIAL_POP_OVER))
  }

  const onClose = async (currentDisplayedStep: number): Promise<void> => {
    if (selectedSteps === undefined) {
      return
    }
    if (currentDisplayedStep !== selectedSteps.length - 1) {
      // Display to user a confirmation to skip
      const tourElement = document.getElementsByClassName('ant-tour')[0] as HTMLElement
      const initialLeft = (!isNaN(tourElement?.offsetLeft) ? tourElement?.offsetLeft : 0) + (!isNaN(tourElement?.offsetWidth) ? tourElement?.offsetWidth : 0)
      const initialTop = (!isNaN(tourElement?.offsetTop) ? tourElement?.offsetTop : 0)

      await cancelTutorialModal.confirm({
        title: intl.formatMessage({ id: 'tutorial-tour.cancel.confirm.title', defaultMessage: 'Do you want to skip the tutorial ?' }),
        okText: intl.formatMessage({ id: 'tutorial-tour.cancel.confirm.skip', defaultMessage: 'Skip tutorial' }),
        onOk: skipTutorial,
        centered: true,
        transitionName: '',
        style: {
          left: Math.max(10, initialLeft > window.screen.width / 2 ? initialLeft - 450 : initialLeft),
          top: Math.max(10, initialTop),
          position: 'absolute',
        },
        width: '450px',
      })
    } else {
      // Last step with Finish pushed button do not trigger onChange
      // State finished as last one we consider it is a complete

      // Close the last step and update the onboarding tasks
      await finishOnboardingTask(selectedSteps[currentDisplayedStep].onboardingTaskCompletion)
      window.dispatchEvent(new CustomEvent(EVENTS_ID.SHOW_TUTORIAL_POP_OVER))
    }
  }

  // Disable touring during a validation to avoid any blinking effect
  if (isValidatingOnboardingTasks) {
    return null
  }

  return <>
    <Tour
      steps={tourSteps}
      open={isOnboardingInProgress}
      onChange={(step) => {
        if (step - 1 >= 0) {
          void completeTask(selectedSteps[step - 1].onboardingTaskCompletion)
        }
      }}
      onClose={onClose}
      rootClassName={clsx('tutorial-tour', styles.tutorialTour)}
      mask={{ style: { opacity: 0.4 } }}
      disabledInteraction={true}
    />
    {menuClassDetailModalContextHolder}
  </>
}
