/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DirectMeasureType = {
    Distance: 'DIRECT_MEASURE_DISTANCE',
    Angle: 'DIRECT_MEASURE_ANGLE',
    Area: 'DIRECT_MEASURE_AREA',
    Perimeter: 'DIRECT_MEASURE_PERIMETER',
    Arc: 'DIRECT_MEASURE_ARC',
    Rectangle: 'DIRECT_MEASURE_RECTANGLE',
    Ellipse: 'DIRECT_MEASURE_ELLIPSE',
    Circle: 'DIRECT_MEASURE_CIRCLE'
} as const;
export type DirectMeasureType = typeof DirectMeasureType[keyof typeof DirectMeasureType];


export function instanceOfDirectMeasureType(value: any): boolean {
    for (const key in DirectMeasureType) {
        if (Object.prototype.hasOwnProperty.call(DirectMeasureType, key)) {
            if (DirectMeasureType[key as keyof typeof DirectMeasureType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DirectMeasureTypeFromJSON(json: any): DirectMeasureType {
    return DirectMeasureTypeFromJSONTyped(json, false);
}

export function DirectMeasureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureType {
    return json as DirectMeasureType;
}

export function DirectMeasureTypeToJSON(value?: DirectMeasureType | null): any {
    return value as any;
}

export function DirectMeasureTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): DirectMeasureType {
    return value as DirectMeasureType;
}

