/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
    PolygonToJSONTyped,
} from './Polygon';

/**
 * Class Annotation with analytic stats
 * @export
 * @interface ClassAnnotationWithStats
 */
export interface ClassAnnotationWithStats {
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationWithStats
     */
    classAnnotationId: string;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationWithStats
     */
    className: string;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    colorIndex: number;
    /**
     * 
     * @type {Polygon}
     * @memberof ClassAnnotationWithStats
     */
    geometry: Polygon;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    minX: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    maxX: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    centroidX: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    centroidY: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    area: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    shellArea: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    hullArea: number;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationWithStats
     */
    perimeter: number;
}

/**
 * Check if a given object implements the ClassAnnotationWithStats interface.
 */
export function instanceOfClassAnnotationWithStats(value: object): value is ClassAnnotationWithStats {
    if (!('classAnnotationId' in value) || value['classAnnotationId'] === undefined) return false;
    if (!('className' in value) || value['className'] === undefined) return false;
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    if (!('minX' in value) || value['minX'] === undefined) return false;
    if (!('minY' in value) || value['minY'] === undefined) return false;
    if (!('maxX' in value) || value['maxX'] === undefined) return false;
    if (!('maxY' in value) || value['maxY'] === undefined) return false;
    if (!('width' in value) || value['width'] === undefined) return false;
    if (!('height' in value) || value['height'] === undefined) return false;
    if (!('centroidX' in value) || value['centroidX'] === undefined) return false;
    if (!('centroidY' in value) || value['centroidY'] === undefined) return false;
    if (!('area' in value) || value['area'] === undefined) return false;
    if (!('shellArea' in value) || value['shellArea'] === undefined) return false;
    if (!('hullArea' in value) || value['hullArea'] === undefined) return false;
    if (!('perimeter' in value) || value['perimeter'] === undefined) return false;
    return true;
}

export function ClassAnnotationWithStatsFromJSON(json: any): ClassAnnotationWithStats {
    return ClassAnnotationWithStatsFromJSONTyped(json, false);
}

export function ClassAnnotationWithStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassAnnotationWithStats {
    if (json == null) {
        return json;
    }
    return {
        
        'classAnnotationId': json['class_annotation_id'],
        'className': json['class_name'],
        'colorIndex': json['color_index'],
        'geometry': PolygonFromJSON(json['geometry']),
        'minX': json['min_x'],
        'minY': json['min_y'],
        'maxX': json['max_x'],
        'maxY': json['max_y'],
        'width': json['width'],
        'height': json['height'],
        'centroidX': json['centroid_x'],
        'centroidY': json['centroid_y'],
        'area': json['area'],
        'shellArea': json['shell_area'],
        'hullArea': json['hull_area'],
        'perimeter': json['perimeter'],
    };
}

export function ClassAnnotationWithStatsToJSON(json: any): ClassAnnotationWithStats {
    return ClassAnnotationWithStatsToJSONTyped(json, false);
}

export function ClassAnnotationWithStatsToJSONTyped(value?: ClassAnnotationWithStats | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'class_annotation_id': value['classAnnotationId'],
        'class_name': value['className'],
        'color_index': value['colorIndex'],
        'geometry': PolygonToJSON(value['geometry']),
        'min_x': value['minX'],
        'min_y': value['minY'],
        'max_x': value['maxX'],
        'max_y': value['maxY'],
        'width': value['width'],
        'height': value['height'],
        'centroid_x': value['centroidX'],
        'centroid_y': value['centroidY'],
        'area': value['area'],
        'shell_area': value['shellArea'],
        'hull_area': value['hullArea'],
        'perimeter': value['perimeter'],
    };
}

