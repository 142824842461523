/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
    PolygonToJSONTyped,
} from './Polygon';

/**
 * UpdateClassAnnotationPartRequest is used to update the class annotations of a project.
 * 
 * The project_id and image_id are common to all the class annotations of the request.
 * The creation_date and modification_date will be set by this request.
 * @export
 * @interface UpdateClassAnnotationPartRequest
 */
export interface UpdateClassAnnotationPartRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateClassAnnotationPartRequest
     */
    classAnnotationId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateClassAnnotationPartRequest
     */
    colorIndex: number;
    /**
     * 
     * @type {Polygon}
     * @memberof UpdateClassAnnotationPartRequest
     */
    geometry: Polygon;
}

/**
 * Check if a given object implements the UpdateClassAnnotationPartRequest interface.
 */
export function instanceOfUpdateClassAnnotationPartRequest(value: object): value is UpdateClassAnnotationPartRequest {
    if (!('classAnnotationId' in value) || value['classAnnotationId'] === undefined) return false;
    if (!('colorIndex' in value) || value['colorIndex'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    return true;
}

export function UpdateClassAnnotationPartRequestFromJSON(json: any): UpdateClassAnnotationPartRequest {
    return UpdateClassAnnotationPartRequestFromJSONTyped(json, false);
}

export function UpdateClassAnnotationPartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClassAnnotationPartRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'classAnnotationId': json['class_annotation_id'],
        'colorIndex': json['color_index'],
        'geometry': PolygonFromJSON(json['geometry']),
    };
}

export function UpdateClassAnnotationPartRequestToJSON(json: any): UpdateClassAnnotationPartRequest {
    return UpdateClassAnnotationPartRequestToJSONTyped(json, false);
}

export function UpdateClassAnnotationPartRequestToJSONTyped(value?: UpdateClassAnnotationPartRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'class_annotation_id': value['classAnnotationId'],
        'color_index': value['colorIndex'],
        'geometry': PolygonToJSON(value['geometry']),
    };
}

