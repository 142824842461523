/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateProfileRequest
 */
export interface UserUpdateProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    phoneNumber?: string | null;
}

/**
 * Check if a given object implements the UserUpdateProfileRequest interface.
 */
export function instanceOfUserUpdateProfileRequest(value: object): value is UserUpdateProfileRequest {
    return true;
}

export function UserUpdateProfileRequestFromJSON(json: any): UserUpdateProfileRequest {
    return UserUpdateProfileRequestFromJSONTyped(json, false);
}

export function UserUpdateProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'organization': json['organization'] == null ? undefined : json['organization'],
        'jobTitle': json['job_title'] == null ? undefined : json['job_title'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
    };
}

export function UserUpdateProfileRequestToJSON(json: any): UserUpdateProfileRequest {
    return UserUpdateProfileRequestToJSONTyped(json, false);
}

export function UserUpdateProfileRequestToJSONTyped(value?: UserUpdateProfileRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'organization': value['organization'],
        'job_title': value['jobTitle'],
        'phone_number': value['phoneNumber'],
    };
}

