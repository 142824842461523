/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectClassesAnnotationDistributionResponse
 */
export interface ProjectClassesAnnotationDistributionResponse {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectClassesAnnotationDistributionResponse
     */
    distribution: { [key: string]: number; };
}

/**
 * Check if a given object implements the ProjectClassesAnnotationDistributionResponse interface.
 */
export function instanceOfProjectClassesAnnotationDistributionResponse(value: object): value is ProjectClassesAnnotationDistributionResponse {
    if (!('distribution' in value) || value['distribution'] === undefined) return false;
    return true;
}

export function ProjectClassesAnnotationDistributionResponseFromJSON(json: any): ProjectClassesAnnotationDistributionResponse {
    return ProjectClassesAnnotationDistributionResponseFromJSONTyped(json, false);
}

export function ProjectClassesAnnotationDistributionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectClassesAnnotationDistributionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'distribution': json['distribution'],
    };
}

export function ProjectClassesAnnotationDistributionResponseToJSON(json: any): ProjectClassesAnnotationDistributionResponse {
    return ProjectClassesAnnotationDistributionResponseToJSONTyped(json, false);
}

export function ProjectClassesAnnotationDistributionResponseToJSONTyped(value?: ProjectClassesAnnotationDistributionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'distribution': value['distribution'],
    };
}

