import { type MetadataAnnotation, MetadataAnnotationType } from '@app/api/openapi'
import { Feature } from 'ol'
import { LineString, Polygon, Point } from 'ol/geom'
import { type Polygon as GeoJSONPolygon, type Point as GeoJSONPoint, type LineString as GeoJSONLineString, type Feature as GeoJSONFeature } from 'geojson'
import { swapLineStringCoordinates, swapPointCoordinates, swapPolygonCoordinates } from '@openlayer/helper'
import { type ArrowGeometryProperties, type ChangePatch, type CircleGeometryProperties, ClemexMosaicCanvasListenersType, type EllipseGeometryProperties, FEATURE_TYPE } from '@clemex/mosaic-canvas'
import { useEffect, useRef } from 'react'
import { EVENTS_ID } from '@app/constants'
import { useDebouncedCallback } from 'use-debounce'
import { groupBy } from 'lodash'
import { useMetadataAnnotation } from '@app/api/metadata-annotation'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import type GeoJSON from 'ol/format/GeoJSON'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { useEditorStore } from '@app/stores/editor'

interface StudioAnnotationFeatureProperties<T extends MetadataAnnotationType = MetadataAnnotationType> {
  [FEATURE_TYPE]: T
  id: string
}

const metadataAnnotationFeatureToGeoJSON = (feature: Feature, geojsonParser: GeoJSON): Omit<MetadataAnnotation, 'projectId' | 'imageId'> => {
  const geoJSONFeature = geojsonParser.writeFeatureObject(feature) as unknown as GeoJSONFeature<GeoJSONLineString | GeoJSONPolygon | GeoJSONPoint, StudioAnnotationFeatureProperties>
  let coordinates = geoJSONFeature.geometry.coordinates
  const type = geoJSONFeature.geometry.type
  switch (type) {
    case 'Point':
      coordinates = swapPointCoordinates(coordinates as number[])
      break
    case 'LineString':
      coordinates = swapLineStringCoordinates(coordinates as number[][])
      break
    case 'Polygon':
      coordinates = swapPolygonCoordinates(coordinates as number[][][])
      break
    default:
      throw new Error(`Unknown type: ${type as unknown as string}`)
  }
  return {
    type: geoJSONFeature.properties[FEATURE_TYPE],
    metadataAnnotationId: geoJSONFeature.properties.id,
    geometry: {
      type,
      coordinates,
    },
    geometryProperties: feature.getGeometry()?.getProperties() as CircleGeometryProperties & EllipseGeometryProperties & ArrowGeometryProperties,
  }
}

const metadataAnnotationGeoJSONToFeature = (annotation: MetadataAnnotation): Feature<LineString | Polygon | Point> => {
  const properties = {
    [FEATURE_TYPE]: annotation.type,
    id: annotation.metadataAnnotationId,
  }
  let geometry: LineString | Polygon | Point
  switch (annotation.geometry.type) {
    case 'Point':
      geometry = new Point(swapPointCoordinates(annotation.geometry.coordinates as number[]))
      break
    case 'LineString':
      geometry = new LineString(swapLineStringCoordinates(annotation.geometry.coordinates as number[][]))
      break
    case 'Polygon':
      geometry = new Polygon(swapPolygonCoordinates(annotation.geometry.coordinates as number[][][]))
      break
    default:
      throw new Error(`Unknown type: ${annotation.geometry.type as unknown as string}`)
  }
  if (annotation.geometryProperties !== undefined) {
    geometry.setProperties(annotation.geometryProperties)
  }
  return new Feature({
    geometry,
    ...properties,
  })
}

export const useCanvasMetadataAnnotations = (clemexMosaicCanvas: ClemexStudioMosaicCanvas, geojsonParser: GeoJSON): void => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const metadataAnnotation = useMetadataAnnotation(projectId, selectedImageId)
  const setDirectMeasureAndMetadataAnnotationVisibility = useEditorStore(store => store.setDirectMeasureAndMetadataAnnotationVisibility)
  const loadedDirectMeasureId = useRef<string | undefined>(undefined)

  useEffect(() => {
    const onProjectResetCompleted = (): void => {
      loadedDirectMeasureId.current = undefined
    }
    window.addEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectResetCompleted, {})
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectResetCompleted, {})
    }
  })

  // When the annotation are loaded.
  // Load them inside the ClemexMosaicCanvas.
  useEffect(() => {
    if (clemexMosaicCanvas === null || metadataAnnotation.data === undefined) {
      return
    }
    if (metadataAnnotation.isValidating) {
      return
    }

    // Avoid reloading the metadata when editing a metadata
    // Use of canvasId to avoid reloading the metadata when changing the image with same Canvas
    const loadId = `${projectId}-${selectedImageId}-${clemexMosaicCanvas.getCanvasId()}`
    if (loadedDirectMeasureId.current === loadId) {
      return
    }

    const typeToSetter = {
      [MetadataAnnotationType.Arrow]: clemexMosaicCanvas.setMetadataAnnotationArrow as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Polygon]: clemexMosaicCanvas.setMetadataAnnotationPolygon as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Line]: clemexMosaicCanvas.setMetadataAnnotationLine as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Rectangle]: clemexMosaicCanvas.setMetadataAnnotationRectangle as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Ellipse]: clemexMosaicCanvas.setMetadataAnnotationEllipse as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Circle]: clemexMosaicCanvas.setMetadataAnnotationCircle as (annotation: Feature[]) => void,
      [MetadataAnnotationType.Text]: clemexMosaicCanvas.setMetadataAnnotationText as (annotation: Feature[]) => void,
    } as const
    const annotationGroupByType = {
      [MetadataAnnotationType.Arrow]: [],
      [MetadataAnnotationType.Polygon]: [],
      [MetadataAnnotationType.Line]: [],
      [MetadataAnnotationType.Rectangle]: [],
      [MetadataAnnotationType.Ellipse]: [],
      [MetadataAnnotationType.Circle]: [],
      [MetadataAnnotationType.Text]: [],
      ...groupBy(metadataAnnotation.data, (annotation) => annotation.type),
    }
    Object.entries(annotationGroupByType).forEach(([type, annotations]) => {
      if (typeToSetter[type as MetadataAnnotationType] === undefined) {
        console.error(`Unknown metadata annotation type: ${type}`)
      } else {
        typeToSetter[type as MetadataAnnotationType](annotations.map((annotation) => metadataAnnotationGeoJSONToFeature(annotation)))
      }
    })
    loadedDirectMeasureId.current = loadId
  }, [metadataAnnotation.data, metadataAnnotation.isValidating, clemexMosaicCanvas, projectId, selectedImageId])

  const debouncedSaveAnnotationPatches = useDebouncedCallback(metadataAnnotation.savePatches, 300)
  // When the direct measure change, save them.
  useEffect(() => {
    const onMetadataAnnotationChange = (annotationChangePatch: ChangePatch<Feature>): void => {
      if (selectedImageId === undefined || projectId === undefined) {
        return
      }
      metadataAnnotation.patch({
        add: annotationChangePatch.add?.map((addPatch): MetadataAnnotation => {
          return { ...metadataAnnotationFeatureToGeoJSON(addPatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
        remove: annotationChangePatch.remove?.map((removePatch): MetadataAnnotation => {
          return { ...metadataAnnotationFeatureToGeoJSON(removePatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
        update: annotationChangePatch.update?.map((updatePatch): MetadataAnnotation => {
          return { ...metadataAnnotationFeatureToGeoJSON(updatePatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
      })

      // Show the direct measure and annotation on the canvas.
      setDirectMeasureAndMetadataAnnotationVisibility(true)

      void debouncedSaveAnnotationPatches()
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED, onMetadataAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, projectId, selectedImageId, debouncedSaveAnnotationPatches, metadataAnnotation, geojsonParser, setDirectMeasureAndMetadataAnnotationVisibility])
}
