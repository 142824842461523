/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Random Forest algorithm training progression.
 * 
 * This type of algorithm does not provide any progression.
 * Note: in the future we might add the steps of the algorithm as progression.
 * For example: generating training data, training the model
 * @export
 * @interface RFAlgorithmTrainingProgression
 */
export interface RFAlgorithmTrainingProgression {
    /**
     * 
     * @type {string}
     * @memberof RFAlgorithmTrainingProgression
     */
    algorithmId: RFAlgorithmTrainingProgressionAlgorithmIdEnum;
}


/**
 * @export
 */
export const RFAlgorithmTrainingProgressionAlgorithmIdEnum = {
    Sklearn: 'RF_ALGORITHM_SKLEARN',
    Cuml: 'RF_ALGORITHM_CUML'
} as const;
export type RFAlgorithmTrainingProgressionAlgorithmIdEnum = typeof RFAlgorithmTrainingProgressionAlgorithmIdEnum[keyof typeof RFAlgorithmTrainingProgressionAlgorithmIdEnum];


/**
 * Check if a given object implements the RFAlgorithmTrainingProgression interface.
 */
export function instanceOfRFAlgorithmTrainingProgression(value: object): value is RFAlgorithmTrainingProgression {
    if (!('algorithmId' in value) || value['algorithmId'] === undefined) return false;
    return true;
}

export function RFAlgorithmTrainingProgressionFromJSON(json: any): RFAlgorithmTrainingProgression {
    return RFAlgorithmTrainingProgressionFromJSONTyped(json, false);
}

export function RFAlgorithmTrainingProgressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RFAlgorithmTrainingProgression {
    if (json == null) {
        return json;
    }
    return {
        
        'algorithmId': json['algorithm_id'],
    };
}

export function RFAlgorithmTrainingProgressionToJSON(json: any): RFAlgorithmTrainingProgression {
    return RFAlgorithmTrainingProgressionToJSONTyped(json, false);
}

export function RFAlgorithmTrainingProgressionToJSONTyped(value?: RFAlgorithmTrainingProgression | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'algorithm_id': value['algorithmId'],
    };
}

