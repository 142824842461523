/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VisualizationTextLabel } from './VisualizationTextLabel';
import {
    VisualizationTextLabelFromJSON,
    VisualizationTextLabelFromJSONTyped,
    VisualizationTextLabelToJSON,
    VisualizationTextLabelToJSONTyped,
} from './VisualizationTextLabel';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
    PolygonToJSONTyped,
} from './Polygon';

/**
 * 
 * @export
 * @interface ThicknessMeasureVisualization
 */
export interface ThicknessMeasureVisualization {
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof ThicknessMeasureVisualization
     */
    lines: Array<Polygon>;
    /**
     * 
     * @type {Array<VisualizationTextLabel>}
     * @memberof ThicknessMeasureVisualization
     */
    labels: Array<VisualizationTextLabel>;
}

/**
 * Check if a given object implements the ThicknessMeasureVisualization interface.
 */
export function instanceOfThicknessMeasureVisualization(value: object): value is ThicknessMeasureVisualization {
    if (!('lines' in value) || value['lines'] === undefined) return false;
    if (!('labels' in value) || value['labels'] === undefined) return false;
    return true;
}

export function ThicknessMeasureVisualizationFromJSON(json: any): ThicknessMeasureVisualization {
    return ThicknessMeasureVisualizationFromJSONTyped(json, false);
}

export function ThicknessMeasureVisualizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThicknessMeasureVisualization {
    if (json == null) {
        return json;
    }
    return {
        
        'lines': ((json['lines'] as Array<any>).map(PolygonFromJSON)),
        'labels': ((json['labels'] as Array<any>).map(VisualizationTextLabelFromJSON)),
    };
}

export function ThicknessMeasureVisualizationToJSON(json: any): ThicknessMeasureVisualization {
    return ThicknessMeasureVisualizationToJSONTyped(json, false);
}

export function ThicknessMeasureVisualizationToJSONTyped(value?: ThicknessMeasureVisualization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'lines': ((value['lines'] as Array<any>).map(PolygonToJSON)),
        'labels': ((value['labels'] as Array<any>).map(VisualizationTextLabelToJSON)),
    };
}

