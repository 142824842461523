/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlgorithmId } from './AlgorithmId';
import {
    AlgorithmIdFromJSON,
    AlgorithmIdFromJSONTyped,
    AlgorithmIdToJSON,
    AlgorithmIdToJSONTyped,
} from './AlgorithmId';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
    DeepLearningTrainingParametersValueObjectToJSONTyped,
} from './DeepLearningTrainingParametersValueObject';
import type { DirectMeasureSettingsValueObject } from './DirectMeasureSettingsValueObject';
import {
    DirectMeasureSettingsValueObjectFromJSON,
    DirectMeasureSettingsValueObjectFromJSONTyped,
    DirectMeasureSettingsValueObjectToJSON,
    DirectMeasureSettingsValueObjectToJSONTyped,
} from './DirectMeasureSettingsValueObject';
import type { ExportOptionsValueObject } from './ExportOptionsValueObject';
import {
    ExportOptionsValueObjectFromJSON,
    ExportOptionsValueObjectFromJSONTyped,
    ExportOptionsValueObjectToJSON,
    ExportOptionsValueObjectToJSONTyped,
} from './ExportOptionsValueObject';
import type { MeasurementSettingsValueObject } from './MeasurementSettingsValueObject';
import {
    MeasurementSettingsValueObjectFromJSON,
    MeasurementSettingsValueObjectFromJSONTyped,
    MeasurementSettingsValueObjectToJSON,
    MeasurementSettingsValueObjectToJSONTyped,
} from './MeasurementSettingsValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
    RfAlgorithmTrainingParametersValueObjectToJSONTyped,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * 
 * @export
 * @interface ProjectSettingsUpdateRequest
 */
export interface ProjectSettingsUpdateRequest {
    /**
     * 
     * @type {AlgorithmId}
     * @memberof ProjectSettingsUpdateRequest
     */
    algorithmId?: AlgorithmId | null;
    /**
     * 
     * @type {RfAlgorithmTrainingParametersValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    rfAlgorithmTrainingParameters?: RfAlgorithmTrainingParametersValueObject | null;
    /**
     * 
     * @type {DeepLearningTrainingParametersValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    deepLearningTrainingParameters?: DeepLearningTrainingParametersValueObject | null;
    /**
     * 
     * @type {DirectMeasureSettingsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    directMeasureSettings?: DirectMeasureSettingsValueObject | null;
    /**
     * 
     * @type {ExportOptionsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    exportOptions?: ExportOptionsValueObject | null;
    /**
     * 
     * @type {MeasurementSettingsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    measurementSettings?: MeasurementSettingsValueObject | null;
}



/**
 * Check if a given object implements the ProjectSettingsUpdateRequest interface.
 */
export function instanceOfProjectSettingsUpdateRequest(value: object): value is ProjectSettingsUpdateRequest {
    return true;
}

export function ProjectSettingsUpdateRequestFromJSON(json: any): ProjectSettingsUpdateRequest {
    return ProjectSettingsUpdateRequestFromJSONTyped(json, false);
}

export function ProjectSettingsUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'algorithmId': json['algorithm_id'] == null ? undefined : AlgorithmIdFromJSON(json['algorithm_id']),
        'rfAlgorithmTrainingParameters': json['rf_algorithm_training_parameters'] == null ? undefined : RfAlgorithmTrainingParametersValueObjectFromJSON(json['rf_algorithm_training_parameters']),
        'deepLearningTrainingParameters': json['deep_learning_training_parameters'] == null ? undefined : DeepLearningTrainingParametersValueObjectFromJSON(json['deep_learning_training_parameters']),
        'directMeasureSettings': json['direct_measure_settings'] == null ? undefined : DirectMeasureSettingsValueObjectFromJSON(json['direct_measure_settings']),
        'exportOptions': json['export_options'] == null ? undefined : ExportOptionsValueObjectFromJSON(json['export_options']),
        'measurementSettings': json['measurement_settings'] == null ? undefined : MeasurementSettingsValueObjectFromJSON(json['measurement_settings']),
    };
}

export function ProjectSettingsUpdateRequestToJSON(json: any): ProjectSettingsUpdateRequest {
    return ProjectSettingsUpdateRequestToJSONTyped(json, false);
}

export function ProjectSettingsUpdateRequestToJSONTyped(value?: ProjectSettingsUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'algorithm_id': AlgorithmIdToJSON(value['algorithmId']),
        'rf_algorithm_training_parameters': RfAlgorithmTrainingParametersValueObjectToJSON(value['rfAlgorithmTrainingParameters']),
        'deep_learning_training_parameters': DeepLearningTrainingParametersValueObjectToJSON(value['deepLearningTrainingParameters']),
        'direct_measure_settings': DirectMeasureSettingsValueObjectToJSON(value['directMeasureSettings']),
        'export_options': ExportOptionsValueObjectToJSON(value['exportOptions']),
        'measurement_settings': MeasurementSettingsValueObjectToJSON(value['measurementSettings']),
    };
}

