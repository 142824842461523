/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectImageResponse
 */
export interface ProjectImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectImageResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageResponse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectImageResponse
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectImageResponse
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectImageResponse
     */
    pixelSizeUm?: number | null;
}

/**
 * Check if a given object implements the ProjectImageResponse interface.
 */
export function instanceOfProjectImageResponse(value: object): value is ProjectImageResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('width' in value) || value['width'] === undefined) return false;
    if (!('height' in value) || value['height'] === undefined) return false;
    return true;
}

export function ProjectImageResponseFromJSON(json: any): ProjectImageResponse {
    return ProjectImageResponseFromJSONTyped(json, false);
}

export function ProjectImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectImageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'width': json['width'],
        'height': json['height'],
        'pixelSizeUm': json['pixel_size_um'] == null ? undefined : json['pixel_size_um'],
    };
}

export function ProjectImageResponseToJSON(json: any): ProjectImageResponse {
    return ProjectImageResponseToJSONTyped(json, false);
}

export function ProjectImageResponseToJSONTyped(value?: ProjectImageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'slug': value['slug'],
        'name': value['name'],
        'width': value['width'],
        'height': value['height'],
        'pixel_size_um': value['pixelSizeUm'],
    };
}

