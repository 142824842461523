/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassAnnotationGeoJSONResponsePart } from './ClassAnnotationGeoJSONResponsePart';
import {
    ClassAnnotationGeoJSONResponsePartFromJSON,
    ClassAnnotationGeoJSONResponsePartFromJSONTyped,
    ClassAnnotationGeoJSONResponsePartToJSON,
    ClassAnnotationGeoJSONResponsePartToJSONTyped,
} from './ClassAnnotationGeoJSONResponsePart';

/**
 * 
 * @export
 * @interface ProjectClassAnnotationResponse
 */
export interface ProjectClassAnnotationResponse {
    /**
     * 
     * @type {Array<ClassAnnotationGeoJSONResponsePart>}
     * @memberof ProjectClassAnnotationResponse
     */
    classAnnotations: Array<ClassAnnotationGeoJSONResponsePart>;
}

/**
 * Check if a given object implements the ProjectClassAnnotationResponse interface.
 */
export function instanceOfProjectClassAnnotationResponse(value: object): value is ProjectClassAnnotationResponse {
    if (!('classAnnotations' in value) || value['classAnnotations'] === undefined) return false;
    return true;
}

export function ProjectClassAnnotationResponseFromJSON(json: any): ProjectClassAnnotationResponse {
    return ProjectClassAnnotationResponseFromJSONTyped(json, false);
}

export function ProjectClassAnnotationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectClassAnnotationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'classAnnotations': ((json['class_annotations'] as Array<any>).map(ClassAnnotationGeoJSONResponsePartFromJSON)),
    };
}

export function ProjectClassAnnotationResponseToJSON(json: any): ProjectClassAnnotationResponse {
    return ProjectClassAnnotationResponseToJSONTyped(json, false);
}

export function ProjectClassAnnotationResponseToJSONTyped(value?: ProjectClassAnnotationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'class_annotations': ((value['classAnnotations'] as Array<any>).map(ClassAnnotationGeoJSONResponsePartToJSON)),
    };
}

