/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AngleGeometryProperties } from './AngleGeometryProperties';
import {
    instanceOfAngleGeometryProperties,
    AngleGeometryPropertiesFromJSON,
    AngleGeometryPropertiesFromJSONTyped,
    AngleGeometryPropertiesToJSON,
} from './AngleGeometryProperties';
import type { ArcGeometryProperties } from './ArcGeometryProperties';
import {
    instanceOfArcGeometryProperties,
    ArcGeometryPropertiesFromJSON,
    ArcGeometryPropertiesFromJSONTyped,
    ArcGeometryPropertiesToJSON,
} from './ArcGeometryProperties';
import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { DistanceGeometryProperties } from './DistanceGeometryProperties';
import {
    instanceOfDistanceGeometryProperties,
    DistanceGeometryPropertiesFromJSON,
    DistanceGeometryPropertiesFromJSONTyped,
    DistanceGeometryPropertiesToJSON,
} from './DistanceGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { LineGeometryProperties } from './LineGeometryProperties';
import {
    instanceOfLineGeometryProperties,
    LineGeometryPropertiesFromJSON,
    LineGeometryPropertiesFromJSONTyped,
    LineGeometryPropertiesToJSON,
} from './LineGeometryProperties';
import type { PolygonGeometryProperties } from './PolygonGeometryProperties';
import {
    instanceOfPolygonGeometryProperties,
    PolygonGeometryPropertiesFromJSON,
    PolygonGeometryPropertiesFromJSONTyped,
    PolygonGeometryPropertiesToJSON,
} from './PolygonGeometryProperties';
import type { RectangleGeometryProperties } from './RectangleGeometryProperties';
import {
    instanceOfRectangleGeometryProperties,
    RectangleGeometryPropertiesFromJSON,
    RectangleGeometryPropertiesFromJSONTyped,
    RectangleGeometryPropertiesToJSON,
} from './RectangleGeometryProperties';

/**
 * @type GeometryProperties
 * 
 * @export
 */
export type GeometryProperties = { geometryType: 'ANGLE' } & AngleGeometryProperties | { geometryType: 'ARC' } & ArcGeometryProperties | { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'DISTANCE' } & DistanceGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'LINE' } & LineGeometryProperties | { geometryType: 'POLYGON' } & PolygonGeometryProperties | { geometryType: 'RECTANGLE' } & RectangleGeometryProperties;

export function GeometryPropertiesFromJSON(json: any): GeometryProperties {
    return GeometryPropertiesFromJSONTyped(json, false);
}

export function GeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties {
    if (json == null) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'ANGLE':
            return Object.assign({}, AngleGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'ANGLE' } as const);
        case 'ARC':
            return Object.assign({}, ArcGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'ARC' } as const);
        case 'CIRCLE':
            return Object.assign({}, CircleGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'CIRCLE' } as const);
        case 'DISTANCE':
            return Object.assign({}, DistanceGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'DISTANCE' } as const);
        case 'ELLIPSE':
            return Object.assign({}, EllipseGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'ELLIPSE' } as const);
        case 'LINE':
            return Object.assign({}, LineGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'LINE' } as const);
        case 'POLYGON':
            return Object.assign({}, PolygonGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'POLYGON' } as const);
        case 'RECTANGLE':
            return Object.assign({}, RectangleGeometryPropertiesFromJSONTyped(json, true), { geometryType: 'RECTANGLE' } as const);
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryPropertiesToJSON(json: any): any {
    return GeometryPropertiesToJSONTyped(json, false);
}

export function GeometryPropertiesToJSONTyped(value?: GeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['geometryType']) {
        case 'ANGLE':
            return Object.assign({}, AngleGeometryPropertiesToJSON(value), { geometryType: 'ANGLE' } as const);
        case 'ARC':
            return Object.assign({}, ArcGeometryPropertiesToJSON(value), { geometryType: 'ARC' } as const);
        case 'CIRCLE':
            return Object.assign({}, CircleGeometryPropertiesToJSON(value), { geometryType: 'CIRCLE' } as const);
        case 'DISTANCE':
            return Object.assign({}, DistanceGeometryPropertiesToJSON(value), { geometryType: 'DISTANCE' } as const);
        case 'ELLIPSE':
            return Object.assign({}, EllipseGeometryPropertiesToJSON(value), { geometryType: 'ELLIPSE' } as const);
        case 'LINE':
            return Object.assign({}, LineGeometryPropertiesToJSON(value), { geometryType: 'LINE' } as const);
        case 'POLYGON':
            return Object.assign({}, PolygonGeometryPropertiesToJSON(value), { geometryType: 'POLYGON' } as const);
        case 'RECTANGLE':
            return Object.assign({}, RectangleGeometryPropertiesToJSON(value), { geometryType: 'RECTANGLE' } as const);
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${value['geometryType']}'`);
    }

}

