/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
    GeometryToJSONTyped,
} from './Geometry';
import type { DirectMeasureType } from './DirectMeasureType';
import {
    DirectMeasureTypeFromJSON,
    DirectMeasureTypeFromJSONTyped,
    DirectMeasureTypeToJSON,
    DirectMeasureTypeToJSONTyped,
} from './DirectMeasureType';
import type { GeometryProperties } from './GeometryProperties';
import {
    GeometryPropertiesFromJSON,
    GeometryPropertiesFromJSONTyped,
    GeometryPropertiesToJSON,
    GeometryPropertiesToJSONTyped,
} from './GeometryProperties';

/**
 * 
 * @export
 * @interface DirectMeasureOutput
 */
export interface DirectMeasureOutput {
    /**
     * 
     * @type {Date}
     * @memberof DirectMeasureOutput
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DirectMeasureOutput
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasureOutput
     */
    directMeasureId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasureOutput
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasureOutput
     */
    imageId: string;
    /**
     * 
     * @type {DirectMeasureType}
     * @memberof DirectMeasureOutput
     */
    type: DirectMeasureType;
    /**
     * 
     * @type {Geometry}
     * @memberof DirectMeasureOutput
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties}
     * @memberof DirectMeasureOutput
     */
    geometryProperties: GeometryProperties;
}



/**
 * Check if a given object implements the DirectMeasureOutput interface.
 */
export function instanceOfDirectMeasureOutput(value: object): value is DirectMeasureOutput {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('geometry' in value) || value['geometry'] === undefined) return false;
    if (!('geometryProperties' in value) || value['geometryProperties'] === undefined) return false;
    return true;
}

export function DirectMeasureOutputFromJSON(json: any): DirectMeasureOutput {
    return DirectMeasureOutputFromJSONTyped(json, false);
}

export function DirectMeasureOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDate': json['creation_date'] == null ? undefined : (new Date(json['creation_date'])),
        'modificationDate': json['modification_date'] == null ? undefined : (new Date(json['modification_date'])),
        'directMeasureId': json['direct_measure_id'] == null ? undefined : json['direct_measure_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'type': DirectMeasureTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': GeometryPropertiesFromJSON(json['geometry_properties']),
    };
}

export function DirectMeasureOutputToJSON(json: any): DirectMeasureOutput {
    return DirectMeasureOutputToJSONTyped(json, false);
}

export function DirectMeasureOutputToJSONTyped(value?: DirectMeasureOutput | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'creation_date': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'modification_date': value['modificationDate'] == null ? undefined : ((value['modificationDate']).toISOString()),
        'direct_measure_id': value['directMeasureId'],
        'project_id': value['projectId'],
        'image_id': value['imageId'],
        'type': DirectMeasureTypeToJSON(value['type']),
        'geometry': GeometryToJSON(value['geometry']),
        'geometry_properties': GeometryPropertiesToJSON(value['geometryProperties']),
    };
}

