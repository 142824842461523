/* eslint-disable no-constant-binary-expression */
import type * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { type Color } from '@clemex/mosaic-canvas'
import { AlgorithmId } from '@app/api/openapi'

const isOnMac = navigator.userAgent.includes('Mac')

export const ctrlModifier = isOnMac ? '⌘' : 'Ctrl'

export enum Tool {
  SELECT = 'SELECT',
  BRUSH = 'BRUSH',
  ERASER = 'ERASER',
  PAN = 'PAN',
  DIRECT_MEASURE_DISTANCE = 'DIRECT_MEASURE_DISTANCE',
  DIRECT_MEASURE_ANGLE = 'DIRECT_MEASURE_ANGLE',
  DIRECT_MEASURE_AREA = 'DIRECT_MEASURE_AREA',
  DIRECT_MEASURE_RECTANGLE = 'DIRECT_MEASURE_RECTANGLE',
  DIRECT_MEASURE_ELLIPSE = 'DIRECT_MEASURE_ELLIPSE',
  DIRECT_MEASURE_ARC = 'DIRECT_MEASURE_ARC',
  DIRECT_MEASURE_PERIMETER = 'DIRECT_MEASURE_PERIMETER',
  METADATA_ANNOTATION_ARROW = 'METADATA_ANNOTATION_ARROW',
  METADATA_ANNOTATION_POLYGON = 'METADATA_ANNOTATION_POLYGON',
  METADATA_ANNOTATION_RECTANGLE = 'METADATA_ANNOTATION_RECTANGLE',
  METADATA_ANNOTATION_ELLIPSE = 'METADATA_ANNOTATION_ELLIPSE',
  METADATA_ANNOTATION_TEXT = 'METADATA_ANNOTATION_TEXT',
  METADATA_ANNOTATION_LINE = 'METADATA_ANNOTATION_LINE',
  SMART_ANNOTATION_ON = 'SMART_ANNOTATION_ON',
}

export const DirectMeasureTools = [
  Tool.DIRECT_MEASURE_DISTANCE,
  Tool.DIRECT_MEASURE_ANGLE,
  Tool.DIRECT_MEASURE_AREA,
  Tool.DIRECT_MEASURE_ARC,
  Tool.DIRECT_MEASURE_PERIMETER,
  Tool.DIRECT_MEASURE_RECTANGLE,
  Tool.DIRECT_MEASURE_ELLIPSE,
]

export const DirectMeasureToolsWithParameters = [
  Tool.DIRECT_MEASURE_AREA,
  Tool.DIRECT_MEASURE_PERIMETER,
  Tool.METADATA_ANNOTATION_POLYGON,
  Tool.METADATA_ANNOTATION_LINE,
]

export const MetadataAnnotationTools = [
  Tool.METADATA_ANNOTATION_ARROW,
  Tool.METADATA_ANNOTATION_POLYGON,
  Tool.METADATA_ANNOTATION_TEXT,
  Tool.METADATA_ANNOTATION_LINE,
  Tool.METADATA_ANNOTATION_RECTANGLE,
  Tool.METADATA_ANNOTATION_ELLIPSE,
]

export const ToolsWithParameters = [
  Tool.DIRECT_MEASURE_DISTANCE,
  Tool.METADATA_ANNOTATION_ARROW,
  Tool.METADATA_ANNOTATION_POLYGON,
  Tool.DIRECT_MEASURE_AREA,
  Tool.METADATA_ANNOTATION_LINE,
  Tool.DIRECT_MEASURE_PERIMETER,
]

export const MAX_CLASS_COUNT = 16

export const SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE = 'image/png,image/jpg,image/jpeg,image/bmp,image/tiff'

export const VPE_COLORS_PALETTE = [
  '#1500FF',
  '#FF0000',
  '#00FF00',
  '#FF02FF',
  '#FEFF00',
  '#008080',
  '#800080',
  '#06FFFF',
  '#800000',
  '#008001',
  '#BF01BF',
  '#050080',
  '#FE6816',
  '#1794F6',
  '#8080FF',
  '#614715',
]

export const getColor = (colorIndex: number): string => {
  return VPE_COLORS_PALETTE[colorIndex]
}

export const colorToRGBString = (color: Color | string): string => {
  if (typeof color === 'string') {
    return color
  }
  const [r, g, b] = color
  return `rgb(${r},${g},${b})`
}

export const MODERN_COLOR_PALETTE = [
  '#1F78B4',
  '#E31A1C',
  '#33A02C',
  '#FF7F00',
  '#6A3D9A',
  '#B15928',
  '#A6CEE3',
  '#FB9A99',
  '#B2DF8A',
  '#FDBF6F',
  '#CAB2D6',
  '#FFFF99',
  '#DB32B2',
  '#44E9F2',
  '#FFF000',
  '#000FFF',
]

export const COLOR_PRESETS = [
  {
    label: <FormattedMessage id="color-palette.preset.recommended.modern.label" defaultMessage={'Modern'} />,
    colors: MODERN_COLOR_PALETTE,
  },
  {
    label: <FormattedMessage id="color-palette.preset.recommended.vision.label" defaultMessage={'Vision'} />,
    colors: VPE_COLORS_PALETTE,
  },
]

export const TOOLTIP_MOUSE_ENTER_DELAY = 0.6

export interface ShortcutDescription {
  description?: React.ReactNode
  label?: React.ReactNode
}
export interface Shortcut extends ShortcutDescription{
  key: string
  usingShift?: boolean
  usingAlt?: boolean
  usingCtrl?: boolean
  usingMeta?: boolean
}

export enum EVENTS_ID {
  NAVIGATE_NEXT_IMAGE = 'clemex.navigate.next-image',
  NAVIGATE_PREVIOUS_IMAGE = 'clemex.navigate.previous-image',
  EDITOR_BRUSH_TOOL = 'clemex.editor.brush.tool',
  EDITOR_TOOL_SELECTION_SELECT = 'clemex.editor.tool.selection.select.direct-measure', // Select the selection tool for direct measure and metadata annotation
  EDITOR_TOOL_SELECTION_DELETE = 'clemex.editor.tool.selection.delete', // Delete the selection
  EDITOR_TOOL_SHOW_DIRECT_MEASURE_AND_METADATA_ANNOTATION = 'clemex.editor.tool.direct-measure-and-metadata-annotatation.show',
  EDITOR_TOOL_HIDE_DIRECT_MEASURE_AND_METADATA_ANNOTATION = 'clemex.editor.tool.direct-measure-and-metadata-annotatation.hide',
  EDITOR_TOOL_DIRECT_MEASURE_DISTANCE_SELECT = 'clemex.editor.tool.direct-measure.distance.select',
  EDITOR_TOOL_DIRECT_MEASURE_ANGLE_SELECT = 'clemex.editor.tool.direct-measure.angle.select',
  EDITOR_TOOL_DIRECT_MEASURE_RECTANGLE_SELECT = 'clemex.editor.tool.direct-measure.rectangle.select',
  EDITOR_TOOL_DIRECT_MEASURE_ELLIPSE_SELECT = 'clemex.editor.tool.direct-measure.ellipse.select',
  EDITOR_TOOL_DIRECT_MEASURE_AREA_SELECT = 'clemex.editor.tool.direct-measure.area.select',
  EDITOR_TOOL_DIRECT_MEASURE_ARC_SELECT = 'clemex.editor.tool.direct-measure.arc.select',
  EDITOR_TOOL_DIRECT_MEASURE_PERIMETER_SELECT = 'clemex.editor.tool.direct-measure.perimeter.select',
  EDITOR_TOOL_METADATA_ANNOTATION_ARROW_SELECT = 'clemex.editor.tool.metadata_annotation.arrow.select',
  EDITOR_TOOL_METADATA_ANNOTATION_RECTANGLE_SELECT = 'clemex.editor.tool.metadata_annotation.rectangle.select',
  EDITOR_TOOL_METADATA_ANNOTATION_ELLIPSE_SELECT = 'clemex.editor.tool.metadata_annotation.ellipse.select',
  EDITOR_TOOL_METADATA_ANNOTATION_POLYGON_SELECT = 'clemex.editor.tool.metadata_annotation.polygon.select',
  EDITOR_TOOL_METADATA_ANNOTATION_TEXT_SELECT = 'clemex.editor.tool.metadata_annotation.text.select',
  EDITOR_TOOL_METADATA_ANNOTATION_LINE_SELECT = 'clemex.editor.tool.metadata_annotation.line.select',
  EDITOR_SELECT_CLASS_1 = 'clemex.editor.select.class.1',
  EDITOR_SELECT_CLASS_2 = 'clemex.editor.select.class.2',
  EDITOR_SELECT_CLASS_3 = 'clemex.editor.select.class.3',
  EDITOR_SELECT_CLASS_4 = 'clemex.editor.select.class.4',
  EDITOR_SELECT_CLASS_5 = 'clemex.editor.select.class.5',
  EDITOR_SELECT_CLASS_6 = 'clemex.editor.select.class.6',
  EDITOR_SELECT_CLASS_7 = 'clemex.editor.select.class.7',
  EDITOR_SELECT_CLASS_8 = 'clemex.editor.select.class.8',
  EDITOR_SELECT_CLASS_9 = 'clemex.editor.select.class.9',
  EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES = 'clemex.editor.update.selection.arrow.properties',
  EDITOR_CANVAS_FOCUS_OBJECT = 'clemex.editor.canvas.focus.object',
  EDITOR_DATABROWSER_CLASS_ANNOTATION_FOCUS_ROW = 'clemex.editor.databrowser.class-annotation.focus-row',
  EDITOR_DATABROWSER_DETECTED_OBJECT_FOCUS_ROW = 'clemex.editor.databrowser.detected-object.focus-row',
  EDITOR_DATABROWSER_DIRECT_MEASURE_FOCUS_ROW = 'clemex.editor.databrowser.direct-measure.focus-row',
  EDITOR_DATABROWSER_METADATA_ANNOTATION_FOCUS_ROW = 'clemex.editor.databrowser.metadata-annotation.focus-row',
  EDITOR_ERASER_DRAG = 'clemex.editor.eraser.drag',
  EDITOR_ERASER_TOOL = 'clemex.editor.eraser.tool',
  EDITOR_DISABLE_DRAWING = 'clemex.editor.draw.disable',
  EDITOR_START_DRAG = 'clemex.editor.drag.start',
  EDITOR_UNDO = 'clemex.editor.undo',
  EDITOR_REDO = 'clemex.editor.redo',
  EDITOR_FORCE_SAVE_ANNOTATIONS = 'clemex.editor.annotations.save.force',
  EDITOR_ANNOTATIONS_SAVED = 'clemex.editor.annotations.saved',
  EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET = 'clemex.editor.annotations.undo-redo-history.reset',
  EDITOR_START_ANNOTATING = 'clemex.editor.annotation.start',
  EDITOR_PAN_TOOL = 'clemex.editor.pan.tool',
  EDITOR_CLASSES_ADD = 'clemex.editor.classes.add',
  EDITOR_UPLOADED_IMG = 'clemex.editor.uploaded.images',
  EDITOR_SMART_ANNOTATION_ON = 'clemex.editor.smart-annotation.tool.on',
  EXPORT_PROJECT = 'clemex.export.project',
  SHORTCUTS_MODAL = 'clemex.help.shortcuts',
  PROJECT_CREATE_NEW = 'clemex.projects.create-new',
  PROJECT_SETTING_OPEN = 'clemex.projects.settings.open',
  PROJECT_DELETE = 'clemex.projects.delete',
  PROJECT_RESET = 'clemex.projects.reset',
  PROJECT_RESET_COMPLETED = 'clemex.projects.reseted',
  PROJECT_SHARE_MODAL = 'clemex.projects.share',
  PROJECT_SEND_IMAGE_TO_VALIDATION = 'clemex.project.send.image.validation',
  PROJECT_SEND_IMAGE_TO_TRAINING = 'clemex.project.send.image.training',
  PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE = 'clemex.project.send.image.training.navigate',
  PROJECT_DELETE_IMAGE = 'clemex.project.delete.image',
  PROJECT_EXPORT_SETTINGS = 'clemex.project.export.settings',
  PROJECT_SLUG_CHANGED = 'clemex.project.slug.changed',
  DUPLICATE_PROJECT = 'clemex.project.duplicate',
  DUPLICATE_PROJECT_AND_NAVIGATE = 'clemex.project.duplicateAndNavigate',
  UPLOAD_IMG_MODAL = 'clemex.upload.images',
  ZOOM_TO_FIT = 'clemex.editor.zoom-to-fit',
  ZOOM_IN = 'clemex.editor.zoom-in',
  ZOOM_OUT = 'clemex.editor.zoom-out',
  SERVER_DISCONNECTED = 'clemex.server.disconnected',
  SERVER_RECONNECTED = 'clemex.server.reconnected',
  SERVER_RECONNECTION_FAILED = 'clemex.server.reconnection.failed',
  SERVER_LICENCE_FAILED = 'clemex.server.licence.failed',
  RF_ALGORITHM_RECONNECTION_FAILED = 'clemex.rf-algorithm.reconnection.failed',
  DL_ALGORITHM_RECONNECTION_FAILED = 'clemex.deep-learning.reconnection.failed',
  GLOBAL_SETTING_OPEN = 'clemex.global.settings.open',
  ALGO_SETTING_OPEN = 'clemex.algo.settings.open',
  USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS = 'clemex.user.has.read.plugin.installation.instructions',
  SHOW_TUTORIAL_POP_OVER = 'clemex.show.tutorial.pop.over',
  RESET_TUTORIAL = 'clemex.reset.tutorial',
  DOWNLOAD_LOGS = 'clemex.download.logs',
  CANVAS_ADD_ANNOTATION = 'clemex.canvas.annotation.add',
}

export const SELECT_EVENT_IDS = [
  EVENTS_ID.EDITOR_BRUSH_TOOL,
  EVENTS_ID.EDITOR_ERASER_TOOL,
  EVENTS_ID.EDITOR_PAN_TOOL,
  EVENTS_ID.EDITOR_TOOL_SELECTION_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ARROW_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_RECTANGLE_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ELLIPSE_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_POLYGON_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_TEXT_SELECT,
  EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_LINE_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ANGLE_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_DISTANCE_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_RECTANGLE_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ELLIPSE_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_AREA_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ARC_SELECT,
  EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_PERIMETER_SELECT,
  EVENTS_ID.EDITOR_SMART_ANNOTATION_ON,
] as const
export const EVENT_SELECT_TOOL_TO_TOOLS: Record<typeof SELECT_EVENT_IDS[number], Tool | undefined> = {
  [EVENTS_ID.EDITOR_BRUSH_TOOL]: Tool.BRUSH,
  [EVENTS_ID.EDITOR_ERASER_TOOL]: Tool.ERASER,
  [EVENTS_ID.EDITOR_PAN_TOOL]: Tool.PAN,
  [EVENTS_ID.EDITOR_TOOL_SELECTION_SELECT]: Tool.SELECT,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ARROW_SELECT]: Tool.METADATA_ANNOTATION_ARROW,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_RECTANGLE_SELECT]: Tool.METADATA_ANNOTATION_RECTANGLE,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ELLIPSE_SELECT]: Tool.METADATA_ANNOTATION_ELLIPSE,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_POLYGON_SELECT]: Tool.METADATA_ANNOTATION_POLYGON,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_TEXT_SELECT]: Tool.METADATA_ANNOTATION_TEXT,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_LINE_SELECT]: Tool.METADATA_ANNOTATION_LINE,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ANGLE_SELECT]: Tool.DIRECT_MEASURE_ANGLE,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_DISTANCE_SELECT]: Tool.DIRECT_MEASURE_DISTANCE,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_RECTANGLE_SELECT]: Tool.DIRECT_MEASURE_RECTANGLE,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ELLIPSE_SELECT]: Tool.DIRECT_MEASURE_ELLIPSE,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_AREA_SELECT]: Tool.DIRECT_MEASURE_AREA,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ARC_SELECT]: Tool.DIRECT_MEASURE_ARC,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_PERIMETER_SELECT]: Tool.DIRECT_MEASURE_PERIMETER,
  [EVENTS_ID.EDITOR_SMART_ANNOTATION_ON]: Tool.SMART_ANNOTATION_ON,
}

export const EVENTS_SHORTCUT: Record<EVENTS_ID, Shortcut | null> = {
  [EVENTS_ID.NAVIGATE_NEXT_IMAGE]: {
    key: 'ArrowRight',
    description: <FormattedMessage id='shortcuts.navigation.next-image.description' defaultMessage='Go to next image'/>,
    label: <FormattedMessage id='image.navigation.right.shortcut' defaultMessage='(Right Arrow)'/>,
  },
  [EVENTS_ID.NAVIGATE_PREVIOUS_IMAGE]: {
    key: 'ArrowLeft',
    description: <FormattedMessage id='shortcuts.navigation.previous-image.description' defaultMessage='Go to previous image'/>,
    label: <FormattedMessage id='image.navigation.left.shortcut' defaultMessage='(Left Arrow)'/>,
  },
  [EVENTS_ID.EDITOR_BRUSH_TOOL]: {
    key: 'b',
    description: <FormattedMessage id='editor.edit.select.tool.brush.description' defaultMessage='Select the brush tool'/>,
    label: <FormattedMessage id='editor.edit.tool.brush.shortcut' defaultMessage='(b)'/>,
  },
  [EVENTS_ID.EDITOR_TOOL_SELECTION_SELECT]: {
    key: 's',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.tool.selection.select.description' defaultMessage='Select the selection tool'/>,
    label: <FormattedMessage id='editor.edit.select.tool.selection.select.shortcut' defaultMessage='(s)'/>,
  },
  [EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE]: {
    key: 'delete',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.tool.selection.delete.description' defaultMessage='Delete the selected annotations'/>,
    label: <FormattedMessage id='editor.edit.select.tool.selection.delete.shortcut' defaultMessage='(suppr.)'/>,
  },
  [EVENTS_ID.EDITOR_TOOL_SHOW_DIRECT_MEASURE_AND_METADATA_ANNOTATION]: null,
  [EVENTS_ID.EDITOR_TOOL_HIDE_DIRECT_MEASURE_AND_METADATA_ANNOTATION]: null,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_DISTANCE_SELECT]: {
    key: 'm',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.tool.direct-measure.distance.description' defaultMessage='Select the distance direct measure tool'/>,
    label: <FormattedMessage id='editor.edit.select.tool.direct-measure.distance.shortcut' defaultMessage='(m)'/>,
  },
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ANGLE_SELECT]: {
    key: 'v',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.tool.direct-measure.angle.description' defaultMessage='Select the angle direct measure tool'/>,
    label: <FormattedMessage id='editor.edit.select.tool.direct-measure.angle.shortcut' defaultMessage='(v)'/>,
  },
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_RECTANGLE_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ELLIPSE_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_AREA_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ARC_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_PERIMETER_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ARROW_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_RECTANGLE_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ELLIPSE_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_POLYGON_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_TEXT_SELECT]: null,
  [EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_LINE_SELECT]: null,
  [EVENTS_ID.EDITOR_SELECT_CLASS_1]: {
    key: '1',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.1.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.1.shortcut' defaultMessage='(1)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_2]: {
    key: '2',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.2.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.2.shortcut' defaultMessage='(2)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_3]: {
    key: '3',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.3.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.3.shortcut' defaultMessage='(3)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_4]: {
    key: '4',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.4.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.4.shortcut' defaultMessage='(4)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_5]: {
    key: '5',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.5.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.5.shortcut' defaultMessage='(5)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_6]: {
    key: '6',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.6.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.6.shortcut' defaultMessage='(6)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_7]: {
    key: '7',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.7.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.7.shortcut' defaultMessage='(7)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_8]: {
    key: '8',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.8.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.8.shortcut' defaultMessage='(8)'/>,
  },
  [EVENTS_ID.EDITOR_SELECT_CLASS_9]: {
    key: '9',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.select.class.9.description' defaultMessage='Select the first class'/>,
    label: <FormattedMessage id='editor.edit.select.class.9.shortcut' defaultMessage='(9)'/>,
  },
  [EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES]: null,
  [EVENTS_ID.EDITOR_ERASER_TOOL]: {
    key: 'e',
    usingShift: false,
    description: <FormattedMessage id='editor.edit.select.tool.eraser.description' defaultMessage='Select the eraser tool'/>,
    label: <FormattedMessage id='editor.edit.tool.select.eraser-drag.shortcut' defaultMessage='(e)'/>,
  },
  [EVENTS_ID.EDITOR_PAN_TOOL]: {
    key: 'p',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.tool.select.pan.description' defaultMessage='Select the panning tool'/>,
    label: <FormattedMessage id='editor.edit.tool.select.pan.shortcut' defaultMessage='(p)'/>,
  },
  [EVENTS_ID.EDITOR_ERASER_DRAG]: {
    key: 'shift',
    description: <FormattedMessage id='shortcuts.editor.eraser.description' defaultMessage='Erase annotated pixels'/>,
    label: <FormattedMessage id='editor.edit.tool.eraser.shortcut' defaultMessage='(Shift and Drag)'/>,
  },
  [EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT]: null,
  [EVENTS_ID.EDITOR_DATABROWSER_CLASS_ANNOTATION_FOCUS_ROW]: null,
  [EVENTS_ID.EDITOR_DATABROWSER_DETECTED_OBJECT_FOCUS_ROW]: null,
  [EVENTS_ID.EDITOR_DATABROWSER_DIRECT_MEASURE_FOCUS_ROW]: null,
  [EVENTS_ID.EDITOR_DATABROWSER_METADATA_ANNOTATION_FOCUS_ROW]: null,
  [EVENTS_ID.EDITOR_DISABLE_DRAWING]: {
    key: ' ',
    usingShift: false,
    description: <FormattedMessage id='shortcuts.editor.start-pan.description' defaultMessage='Pan the image in the viewer'/>,
    label: <FormattedMessage id='editor.edit.tool.pan.shortcut' defaultMessage='(Space Bar and Drag)'/>,
  },
  [EVENTS_ID.EDITOR_UNDO]: {
    key: 'z',
    usingShift: false,
    usingCtrl: true && !isOnMac,
    usingMeta: true && isOnMac,
    description: <FormattedMessage id='shortcuts.editor.undo.description' defaultMessage='Undo the last annotation edition action'/>,
    label: <FormattedMessage id='editor.edit.tool.undo.shortcut' defaultMessage='({ctrlModifier}+Z)' values={{ ctrlModifier }}/>,
  },
  [EVENTS_ID.EDITOR_REDO]: {
    key: 'z',
    usingShift: true,
    usingCtrl: true && !isOnMac,
    usingMeta: true && isOnMac,
    description: <FormattedMessage id='shortcuts.editor.redo.description' defaultMessage='Redo the last annotation edition action'/>,
    label: <FormattedMessage id='editor.edit.tool.redo.shortcut' defaultMessage='({ctrlModifier}+Shift+Z)' values={{ ctrlModifier }}/>,
  },
  [EVENTS_ID.EXPORT_PROJECT]: {
    key: 'e',
    usingShift: true,
    usingCtrl: false,
    description: <FormattedMessage id='shortcuts.editor.export.description' defaultMessage='Export the project (images/annotations/masks/metadata)'/>,
    label: <FormattedMessage id='editor.menu.projects.export.shortcut' defaultMessage='(Shift+E)'/>,
  },
  [EVENTS_ID.PROJECT_CREATE_NEW]: {
    key: 'n',
    usingShift: true,
    label: <FormattedMessage id='edit=.menu.projects.create.shortcut' defaultMessage='(Shift+N)'/>,
  },

  [EVENTS_ID.PROJECT_SETTING_OPEN]: {
    key: 'p',
    usingShift: true,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='projects.settings.open.description' defaultMessage='Open project settings'/>,
    label: <FormattedMessage id='projects.settings.open.shortcut' defaultMessage='(Shift+p)'/>,
  },
  [EVENTS_ID.EDITOR_START_DRAG]: null,
  [EVENTS_ID.EDITOR_ANNOTATIONS_SAVED]: null,
  [EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET]: null,
  [EVENTS_ID.EDITOR_FORCE_SAVE_ANNOTATIONS]: null,
  [EVENTS_ID.EDITOR_START_ANNOTATING]: null,
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION]: null,
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING]: null,
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE]: null,
  [EVENTS_ID.PROJECT_DELETE_IMAGE]: null,
  [EVENTS_ID.PROJECT_DELETE]: null,
  [EVENTS_ID.PROJECT_RESET]: null,
  [EVENTS_ID.PROJECT_RESET_COMPLETED]: null,
  [EVENTS_ID.PROJECT_SHARE_MODAL]: null,
  [EVENTS_ID.PROJECT_SLUG_CHANGED]: null,
  [EVENTS_ID.UPLOAD_IMG_MODAL]: {
    key: 'i',
    usingShift: true,
    usingCtrl: false,
    label: <FormattedMessage id='edit=.menu.projects.upload-image.shortcut' defaultMessage='(Shift+I)'/>,
  },
  [EVENTS_ID.SHORTCUTS_MODAL]: {
    key: '?',
    description: <FormattedMessage id='help.shortcuts.list.description' defaultMessage='List shortcuts'/>,
    label: <FormattedMessage id='edit=.menu.help.shortcuts.shortcut' defaultMessage='(?)'/>,
  },
  [EVENTS_ID.ZOOM_TO_FIT]: {
    key: '0',
    usingCtrl: true && !isOnMac,
    usingMeta: true && isOnMac,
    description: <FormattedMessage id='edit=.menu.zoom.fit' defaultMessage='Set the zoom level to fit the image in the available space.'/>,
    label: <FormattedMessage id='edit=.menu.zoom.fit.shortcuts' defaultMessage='({ctrlModifier}+0)' values={{ ctrlModifier }}/>,
  },
  [EVENTS_ID.ZOOM_IN]: {
    key: '=',
    usingCtrl: true && !isOnMac,
    usingMeta: true && isOnMac,
    description: <FormattedMessage id='edit=.menu.zoom.in' defaultMessage='Zoom in' />,
    label: <FormattedMessage id='edit=.menu.zoom.in.shortcuts' defaultMessage='({ctrlModifier}+=)' values={{ ctrlModifier }}/>,
  },
  [EVENTS_ID.ZOOM_OUT]: {
    key: '-',
    usingCtrl: true && !isOnMac,
    usingMeta: true && isOnMac,
    description: <FormattedMessage id='edit=.menu.zoom.out' defaultMessage='Zoom out'/>,
    label: <FormattedMessage id='edit=.menu.zoom.out.shortcuts' defaultMessage='({ctrlModifier}+-)' values={{ ctrlModifier }}/>,
  },
  [EVENTS_ID.DUPLICATE_PROJECT]: null,
  [EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE]: null,
  [EVENTS_ID.SERVER_DISCONNECTED]: null,
  [EVENTS_ID.SERVER_RECONNECTED]: null,
  [EVENTS_ID.SERVER_RECONNECTION_FAILED]: null,
  [EVENTS_ID.EDITOR_CLASSES_ADD]: null,
  [EVENTS_ID.EDITOR_UPLOADED_IMG]: null,
  [EVENTS_ID.USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS]: null,
  [EVENTS_ID.SERVER_LICENCE_FAILED]: null,
  [EVENTS_ID.RF_ALGORITHM_RECONNECTION_FAILED]: null,
  [EVENTS_ID.DL_ALGORITHM_RECONNECTION_FAILED]: null,
  [EVENTS_ID.GLOBAL_SETTING_OPEN]: null,
  [EVENTS_ID.ALGO_SETTING_OPEN]: null,
  [EVENTS_ID.SHOW_TUTORIAL_POP_OVER]: null,
  [EVENTS_ID.PROJECT_EXPORT_SETTINGS]: null,
  [EVENTS_ID.DOWNLOAD_LOGS]: null,
  [EVENTS_ID.RESET_TUTORIAL]: null,
  [EVENTS_ID.EDITOR_SMART_ANNOTATION_ON]: {
    key: 'w',
    usingShift: false,
    usingAlt: false,
    usingCtrl: false,
    description: <FormattedMessage id='editor.edit.tool.select.smart-annotation.magic-wand.on.description' defaultMessage='Select the Magic Wand'/>,
    label: <FormattedMessage id='editor.edit.tool.select.smart-annotation.magic-wand.on.shortcut' defaultMessage='(w)'/>,
  },
  [EVENTS_ID.CANVAS_ADD_ANNOTATION]: null,
}

export enum SHORTCUT_GROUPS_ID {
  VALIDATION,
  EDITOR,
  HELP,
}

export const DEFAULT_PREDICTION_OPACITY = 0.3
export const DEFAULT_ANNOTATION_OPACITY = 1
export const DEFAULT_BRUSH_SIZE = 32

export const LICENSING_WARNING_THRESHOLD_PERCENTAGE = 0.2

export const TUTORIAL_ENABLED = true
export const MINIMAL_IMAGE_DIMENSION_PIXEL = 512
export const MESSAGE_UPLOAD_IMAGE_DIMENSION = <FormattedMessage id='editor.add-image.dimensions.notice' defaultMessage={'The minimal dimension supported is {minDimension}x{minDimension} pixels'} values={{ minDimension: MINIMAL_IMAGE_DIMENSION_PIXEL }} />
export const MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT = <FormattedMessage id="projects.uploadImage.form.image.upload.area.hint" defaultMessage={'Support for multiple images upload (png, jpeg, bmp, tiff)'} />
export const PROJECT_SHARING_LINK_EXPIRATION_DAYS = 3

export enum E2E_REQUIRED_CLASSES {
  TRAIN_BUTTON = 'e2e-train-button',
  TRAIN_BUTTON_DISABLED = 'e2e-train-button-disabled',
  UPLOAD_IMAGES = 'e2e-upload-images',
  ADD_CLASS_BUTTON = 'e2e-add-class-button',
  CLASS_DETAILS = 'e2e-class-details',
  CLASS_DETAIL_TAG = 'e2e-class-detail-tag',
  IS_TRAINING = 'e2e-is-training',
  PROJECT_CARD = 'e2e-project-card',
  NAVIGATION_MENU = 'e2e-navigation-menu',
  UPLOAD_FIRST_IMAGES_CONTINUE_BUTTON = 'e2e-upload-first-images-continue-button',
  OL_CANVAS = 'e2e-ol-canvas',
}

export enum ONBOARDING_TOUR_CONTEXT {
  PROJECT_LIST = 'project-list',
  PROJECT_LIST_LOCAL_USER = 'project-list-local-user',
  ANNOTATION = 'annotation',
  VALIDATION = 'validation',
  NOT_USED = 'not-used',
}

export const ALGORITHM_ID_TO_MANDATORY_CLASSES: Record<AlgorithmId, string[]> = {
  [AlgorithmId.ClemexGrainsegV3]: ['Grain', 'Resin', 'Grain boundary'],
  [AlgorithmId.ClemexGrainsegV3Aluminum]: ['Grain', 'Resin', 'Grain boundary'],
  [AlgorithmId.ClemexMedcleanV1]: ['Background', 'Particle'],
  [AlgorithmId.RfAlgorithmCuml]: [],
  [AlgorithmId.RfAlgorithmSklearn]: [],
  [AlgorithmId.ClemexNetLiteV1]: [],
  [AlgorithmId.ClemexNetLiteV2]: [],
}
