import { Polygon } from 'ol/geom'
import { Draw, Modify } from 'ol/interaction'
import { type MapBrowserEvent, type Feature, type Collection } from 'ol'
import EventType from 'ol/events/EventType'
import { type DrawEvent } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { FeatureType, FEATURE_TYPE, type PolygonFeatureType } from '../shapes/common'
import { type ChangePatch } from './common'
import { v4 as uuidV4 } from 'uuid'
import { type ModifyEvent } from 'ol/interaction/Modify'
import { type StyleFunction } from 'ol/style/Style'
import { primaryAction } from 'ol/events/condition'
import { type Layer } from 'ol/layer'
import { type PolygonProperties } from '../shapes/area'

export class DrawPolygonInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private _isFreehand: boolean
  private _filled = false
  private _isInteracting = false
  private readonly _drawingFeatureType: PolygonFeatureType

  constructor(
    destinationCollection: Collection<Feature<Polygon>>,
    onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void,
    drawingFeatureType: PolygonFeatureType,
    filled = false,
    freehand = false,
  ) {
    super({
      type: 'Polygon',
      minPoints: 3,
      condition: primaryAction,
      freehandCondition: (e) => {
        if (!this._isFreehand) {
          return false
        }
        if (e.originalEvent instanceof MouseEvent) {
          return e.originalEvent.button === -1 || e.originalEvent.button === 0
        }
        return true
      },
      geometryFunction: (coordinates_, geom) => {
        const coordinates = coordinates_ as [number, number][][]
        if (geom === undefined) {
          geom = new Polygon(coordinates)
        }
        if (coordinates[0].length) {
          // Add the coordinate and ensure the polygon is closed
          // Same as the geometryFunction of Draw to match the first coordinate with the last one
          geom.setCoordinates(
            [coordinates[0].concat([coordinates[0][0]])],
          )
        }
        geom?.setProperties({
          geometryType: 'POLYGON',
          filled: this._filled,
          pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
        } satisfies PolygonProperties)
        return geom
      },
    })
    this._onEndInteraction = onEndInteraction
    this._isFreehand = freehand
    this._filled = filled
    this._drawingFeatureType = drawingFeatureType

    this.addEventListener('drawstart', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      const id = uuidV4()
      drawEndEvent.feature.setProperties({
        [FEATURE_TYPE]: this._drawingFeatureType,
        id,
      })
      this._isInteracting = true
    })
    this.addEventListener('drawabort', () => {
      this._isInteracting = false
    })
    this.addEventListener('drawend', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      this._isInteracting = false
      const feature = drawEndEvent.feature as Feature<Polygon>
      // Note: The simplify method sometimes removes the properties of the geometry
      const geometryProperties = feature.getGeometry()?.getProperties()

      feature.setGeometry(feature.getGeometry()?.simplify(1) as Polygon)

      if (geometryProperties) {
        feature.getGeometry()?.setProperties(geometryProperties)
      }
      destinationCollection.push(feature)
      this._onEndInteraction({
        add: [{
          id: feature.getProperties().id,
          data: feature,
        }],
      })
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setFreehand = (isFreehand: boolean): void => {
    this._isFreehand = isFreehand
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }
}

interface ModifyPolygonInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layer: Layer
  onStartInteraction: (targetFeature: Feature<Polygon>[]) => void
  onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  style: StyleFunction
  pixelTolerance?: number
}
export class ModifyPolygonInteraction extends Modify {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Polygon>>) => void
  private readonly _layer: Layer
  private readonly _pixelTolerance: number

  constructor({
    modifiableFeatures,
    layer: layer,
    onEndInteraction,
    onStartInteraction,
    style,
    pixelTolerance = 8,
  }: ModifyPolygonInteractionOptions) {
    super({
      features: modifiableFeatures,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      pixelTolerance,
      insertVertexCondition: () => {
        return modifiableFeatures.getArray().some((feature) => {
          return feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_AREA || feature.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_POLYGON
        })
      },
      style,
    })
    this._onEndInteraction = onEndInteraction
    this._pixelTolerance = pixelTolerance
    this._layer = layer

    this.on('modifystart', (modifyStartEvent: ModifyEvent) => {
      onStartInteraction(modifyStartEvent.features.getArray() as Feature<Polygon>[])
    })

    this.on('modifyend', (modifiedEndEvent: ModifyEvent) => {
      this._onEndInteraction({
        update: modifiedEndEvent.features.getArray().map((feature) => {
          return  {
            id: feature.getProperties().id,
            data: feature as Feature<Polygon>,
          }
        }),
      })
    })
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layer === layer,
    })
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }
}
