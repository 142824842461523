/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserOnboardingTaskNamespace } from './UserOnboardingTaskNamespace';
import {
    UserOnboardingTaskNamespaceFromJSON,
    UserOnboardingTaskNamespaceFromJSONTyped,
    UserOnboardingTaskNamespaceToJSON,
    UserOnboardingTaskNamespaceToJSONTyped,
} from './UserOnboardingTaskNamespace';

/**
 * 
 * @export
 * @interface SkipUserOnboardingTaskGroupRequest
 */
export interface SkipUserOnboardingTaskGroupRequest {
    /**
     * 
     * @type {UserOnboardingTaskNamespace}
     * @memberof SkipUserOnboardingTaskGroupRequest
     */
    taskGroup: UserOnboardingTaskNamespace;
}



/**
 * Check if a given object implements the SkipUserOnboardingTaskGroupRequest interface.
 */
export function instanceOfSkipUserOnboardingTaskGroupRequest(value: object): value is SkipUserOnboardingTaskGroupRequest {
    if (!('taskGroup' in value) || value['taskGroup'] === undefined) return false;
    return true;
}

export function SkipUserOnboardingTaskGroupRequestFromJSON(json: any): SkipUserOnboardingTaskGroupRequest {
    return SkipUserOnboardingTaskGroupRequestFromJSONTyped(json, false);
}

export function SkipUserOnboardingTaskGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkipUserOnboardingTaskGroupRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'taskGroup': UserOnboardingTaskNamespaceFromJSON(json['task_group']),
    };
}

export function SkipUserOnboardingTaskGroupRequestToJSON(json: any): SkipUserOnboardingTaskGroupRequest {
    return SkipUserOnboardingTaskGroupRequestToJSONTyped(json, false);
}

export function SkipUserOnboardingTaskGroupRequestToJSONTyped(value?: SkipUserOnboardingTaskGroupRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'task_group': UserOnboardingTaskNamespaceToJSON(value['taskGroup']),
    };
}

