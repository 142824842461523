/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Bbox } from './Bbox';
import {
    BboxFromJSON,
    BboxFromJSONTyped,
    BboxToJSON,
    BboxToJSONTyped,
} from './Bbox';
import type { CoordinatesInner } from './CoordinatesInner';
import {
    CoordinatesInnerFromJSON,
    CoordinatesInnerFromJSONTyped,
    CoordinatesInnerToJSON,
    CoordinatesInnerToJSONTyped,
} from './CoordinatesInner';

/**
 * Polygon Model
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {Bbox}
     * @memberof Polygon
     */
    bbox?: Bbox | null;
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    type: string;
    /**
     * 
     * @type {Array<Array<CoordinatesInner>>}
     * @memberof Polygon
     */
    coordinates: Array<Array<CoordinatesInner>>;
}

/**
 * Check if a given object implements the Polygon interface.
 */
export function instanceOfPolygon(value: object): value is Polygon {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    return true;
}

export function PolygonFromJSON(json: any): Polygon {
    return PolygonFromJSONTyped(json, false);
}

export function PolygonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Polygon {
    if (json == null) {
        return json;
    }
    return {
        
        'bbox': json['bbox'] == null ? undefined : BboxFromJSON(json['bbox']),
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function PolygonToJSON(json: any): Polygon {
    return PolygonToJSONTyped(json, false);
}

export function PolygonToJSONTyped(value?: Polygon | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'bbox': BboxToJSON(value['bbox']),
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

