/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
    DeepLearningTrainingParametersValueObjectToJSONTyped,
} from './DeepLearningTrainingParametersValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
    RfAlgorithmTrainingParametersValueObjectToJSONTyped,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * 
 * @export
 * @interface ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
 */
export interface ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost {
    /**
     * 
     * @type {number}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    sigmaMin: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    sigmaMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    intensity: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    texture: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    edges: boolean;
    /**
     * The number of epochs to train the model.
     * @type {number}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    epochs: number;
    /**
     * Whether to use data augmentation during training.
     * @type {boolean}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    useAugmentation: boolean;
    /**
     * Whether to use postprocessing after a prediction.
     * @type {boolean}
     * @memberof ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost
     */
    usePostprocessing: boolean;
}

/**
 * Check if a given object implements the ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost interface.
 */
export function instanceOfResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost(value: object): value is ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost {
    if (!('sigmaMin' in value) || value['sigmaMin'] === undefined) return false;
    if (!('sigmaMax' in value) || value['sigmaMax'] === undefined) return false;
    if (!('intensity' in value) || value['intensity'] === undefined) return false;
    if (!('texture' in value) || value['texture'] === undefined) return false;
    if (!('edges' in value) || value['edges'] === undefined) return false;
    if (!('epochs' in value) || value['epochs'] === undefined) return false;
    if (!('useAugmentation' in value) || value['useAugmentation'] === undefined) return false;
    if (!('usePostprocessing' in value) || value['usePostprocessing'] === undefined) return false;
    return true;
}

export function ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostFromJSON(json: any): ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost {
    return ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostFromJSONTyped(json, false);
}

export function ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost {
    if (json == null) {
        return json;
    }
    return {
        
        'sigmaMin': json['sigma_min'],
        'sigmaMax': json['sigma_max'],
        'intensity': json['intensity'],
        'texture': json['texture'],
        'edges': json['edges'],
        'epochs': json['epochs'],
        'useAugmentation': json['use_augmentation'],
        'usePostprocessing': json['use_postprocessing'],
    };
}

export function ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostToJSON(json: any): ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost {
    return ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostToJSONTyped(json, false);
}

export function ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostToJSONTyped(value?: ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sigma_min': value['sigmaMin'],
        'sigma_max': value['sigmaMax'],
        'intensity': value['intensity'],
        'texture': value['texture'],
        'edges': value['edges'],
        'epochs': value['epochs'],
        'use_augmentation': value['useAugmentation'],
        'use_postprocessing': value['usePostprocessing'],
    };
}

