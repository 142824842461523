/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Direct Measure Text Position.
 * @export
 */
export const DirectMeasureTextPosition = {
    Top: 'TOP',
    Right: 'RIGHT',
    Bottom: 'BOTTOM',
    Left: 'LEFT'
} as const;
export type DirectMeasureTextPosition = typeof DirectMeasureTextPosition[keyof typeof DirectMeasureTextPosition];


export function instanceOfDirectMeasureTextPosition(value: any): boolean {
    for (const key in DirectMeasureTextPosition) {
        if (Object.prototype.hasOwnProperty.call(DirectMeasureTextPosition, key)) {
            if (DirectMeasureTextPosition[key as keyof typeof DirectMeasureTextPosition] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DirectMeasureTextPositionFromJSON(json: any): DirectMeasureTextPosition {
    return DirectMeasureTextPositionFromJSONTyped(json, false);
}

export function DirectMeasureTextPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureTextPosition {
    return json as DirectMeasureTextPosition;
}

export function DirectMeasureTextPositionToJSON(value?: DirectMeasureTextPosition | null): any {
    return value as any;
}

export function DirectMeasureTextPositionToJSONTyped(value: any, ignoreDiscriminator: boolean): DirectMeasureTextPosition {
    return value as DirectMeasureTextPosition;
}

