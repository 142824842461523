/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateImageTilesRequest
 */
export interface GenerateImageTilesRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateImageTilesRequest
     */
    imageId: string;
}

/**
 * Check if a given object implements the GenerateImageTilesRequest interface.
 */
export function instanceOfGenerateImageTilesRequest(value: object): value is GenerateImageTilesRequest {
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    return true;
}

export function GenerateImageTilesRequestFromJSON(json: any): GenerateImageTilesRequest {
    return GenerateImageTilesRequestFromJSONTyped(json, false);
}

export function GenerateImageTilesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateImageTilesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'imageId': json['image_id'],
    };
}

export function GenerateImageTilesRequestToJSON(json: any): GenerateImageTilesRequest {
    return GenerateImageTilesRequestToJSONTyped(json, false);
}

export function GenerateImageTilesRequestToJSONTyped(value?: GenerateImageTilesRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image_id': value['imageId'],
    };
}

