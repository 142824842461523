/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PredictionStatus } from './PredictionStatus';
import {
    PredictionStatusFromJSON,
    PredictionStatusFromJSONTyped,
    PredictionStatusToJSON,
    PredictionStatusToJSONTyped,
} from './PredictionStatus';

/**
 * Project Training Snapshot Predictions Status
 * @export
 * @interface ProjectTrainingSnapshotPredictionsStatus
 */
export interface ProjectTrainingSnapshotPredictionsStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingSnapshotPredictionsStatus
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {{ [key: string]: PredictionStatus; }}
     * @memberof ProjectTrainingSnapshotPredictionsStatus
     */
    images: { [key: string]: PredictionStatus; };
}

/**
 * Check if a given object implements the ProjectTrainingSnapshotPredictionsStatus interface.
 */
export function instanceOfProjectTrainingSnapshotPredictionsStatus(value: object): value is ProjectTrainingSnapshotPredictionsStatus {
    if (!('projectTrainingSnapshotId' in value) || value['projectTrainingSnapshotId'] === undefined) return false;
    if (!('images' in value) || value['images'] === undefined) return false;
    return true;
}

export function ProjectTrainingSnapshotPredictionsStatusFromJSON(json: any): ProjectTrainingSnapshotPredictionsStatus {
    return ProjectTrainingSnapshotPredictionsStatusFromJSONTyped(json, false);
}

export function ProjectTrainingSnapshotPredictionsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingSnapshotPredictionsStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'images': (mapValues(json['images'], PredictionStatusFromJSON)),
    };
}

export function ProjectTrainingSnapshotPredictionsStatusToJSON(json: any): ProjectTrainingSnapshotPredictionsStatus {
    return ProjectTrainingSnapshotPredictionsStatusToJSONTyped(json, false);
}

export function ProjectTrainingSnapshotPredictionsStatusToJSONTyped(value?: ProjectTrainingSnapshotPredictionsStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project_training_snapshot_id': value['projectTrainingSnapshotId'],
        'images': (mapValues(value['images'], PredictionStatusToJSON)),
    };
}

