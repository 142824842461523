/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingTaskType } from './OnboardingTaskType';
import {
    OnboardingTaskTypeFromJSON,
    OnboardingTaskTypeFromJSONTyped,
    OnboardingTaskTypeToJSON,
    OnboardingTaskTypeToJSONTyped,
} from './OnboardingTaskType';

/**
 * 
 * @export
 * @interface CompleteUserOnboardingTasksRequest
 */
export interface CompleteUserOnboardingTasksRequest {
    /**
     * 
     * @type {Array<OnboardingTaskType>}
     * @memberof CompleteUserOnboardingTasksRequest
     */
    tasksType: Array<OnboardingTaskType>;
}

/**
 * Check if a given object implements the CompleteUserOnboardingTasksRequest interface.
 */
export function instanceOfCompleteUserOnboardingTasksRequest(value: object): value is CompleteUserOnboardingTasksRequest {
    if (!('tasksType' in value) || value['tasksType'] === undefined) return false;
    return true;
}

export function CompleteUserOnboardingTasksRequestFromJSON(json: any): CompleteUserOnboardingTasksRequest {
    return CompleteUserOnboardingTasksRequestFromJSONTyped(json, false);
}

export function CompleteUserOnboardingTasksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteUserOnboardingTasksRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tasksType': ((json['tasks_type'] as Array<any>).map(OnboardingTaskTypeFromJSON)),
    };
}

export function CompleteUserOnboardingTasksRequestToJSON(json: any): CompleteUserOnboardingTasksRequest {
    return CompleteUserOnboardingTasksRequestToJSONTyped(json, false);
}

export function CompleteUserOnboardingTasksRequestToJSONTyped(value?: CompleteUserOnboardingTasksRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'tasks_type': ((value['tasksType'] as Array<any>).map(OnboardingTaskTypeToJSON)),
    };
}

