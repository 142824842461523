/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * ProjectTrainingState represent the state of the project.
 * 
 * can_be_train: a project can be train if it has enough annotations to train the project.
 * should_be_train: a project should be train if it is not trained or if the training snapshot is outdated.
 * @export
 * @interface ProjectTrainingState
 */
export interface ProjectTrainingState {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingState
     */
    canBeTrain: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingState
     */
    shouldBeTrain: boolean;
}

/**
 * Check if a given object implements the ProjectTrainingState interface.
 */
export function instanceOfProjectTrainingState(value: object): value is ProjectTrainingState {
    if (!('canBeTrain' in value) || value['canBeTrain'] === undefined) return false;
    if (!('shouldBeTrain' in value) || value['shouldBeTrain'] === undefined) return false;
    return true;
}

export function ProjectTrainingStateFromJSON(json: any): ProjectTrainingState {
    return ProjectTrainingStateFromJSONTyped(json, false);
}

export function ProjectTrainingStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingState {
    if (json == null) {
        return json;
    }
    return {
        
        'canBeTrain': json['can_be_train'],
        'shouldBeTrain': json['should_be_train'],
    };
}

export function ProjectTrainingStateToJSON(json: any): ProjectTrainingState {
    return ProjectTrainingStateToJSONTyped(json, false);
}

export function ProjectTrainingStateToJSONTyped(value?: ProjectTrainingState | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'can_be_train': value['canBeTrain'],
        'should_be_train': value['shouldBeTrain'],
    };
}

