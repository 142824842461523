import { type OnChangeFn } from '@tanstack/react-table'
import { type StateCreator } from 'zustand'

interface CanvasSelectionState {
  classAnnotationSelectedIds: Record<string, boolean>
  detectedObjectSelectedIds: Record<string, boolean>
  directMeasureSelectedIds: Record<string, boolean>
  metadataAnnotationSelectedIds: Record<string, boolean>
  thicknessMeasureObjectId?: string
  thicknessMeasureType: 'annotation' | 'prediction'
}

interface CanvasSelectionComputedState {
  getClassAnnotationSelectedId: () => string | undefined
  getDetectedObjectSelectedId: () => string | undefined
}

interface CanvasSelectionActions {
  setClassAnnotationSelectedIds: OnChangeFn<Record<string, boolean>>
  setDetectedObjectSelectedIds: OnChangeFn<Record<string, boolean>>
  setDirectMeasureSelectedIds: OnChangeFn<Record<string, boolean>>
  setMetadataAnnotationSelectedIds: OnChangeFn<Record<string, boolean>>
  setThicknessMeasureObject: (id: string | undefined, type?: 'annotation' | 'prediction') => void
  resetCanvasSelection: () => void
}

const initialState: CanvasSelectionState = {
  classAnnotationSelectedIds: {},
  detectedObjectSelectedIds: {},
  directMeasureSelectedIds: {},
  metadataAnnotationSelectedIds: {},
  thicknessMeasureObjectId: undefined,
  thicknessMeasureType: 'annotation',
}

export type CanvasSelectionStore = CanvasSelectionState & CanvasSelectionComputedState & CanvasSelectionActions

export const createCanvasSelectionStoreSlice: StateCreator<CanvasSelectionStore> = (set, get) => {
  return {
    ...initialState,
    getClassAnnotationSelectedId () {
      const { classAnnotationSelectedIds: selection } = get()
      return Object.keys(selection).find((id) => selection[id])
    },
    getDetectedObjectSelectedId () {
      const { detectedObjectSelectedIds: selection } = get()
      return Object.keys(selection).find((id) => selection[id])
    },
    setClassAnnotationSelectedIds: (selection) => {
      set((state) => {
        const newSelection = typeof selection === 'function'
          ? selection(state.classAnnotationSelectedIds)
          : selection
        // Reset detected object selection, direct measure and metadata annotation selection
        // And set the new class annotation selection
        return {
          classAnnotationSelectedIds: newSelection,
          detectedObjectSelectedIds: {},
          directMeasureSelectedIds: {},
          metadataAnnotationSelectedIds: {},
        }
      })
      set({ thicknessMeasureObjectId: undefined, detectedObjectSelectedIds: {} })
    },
    setDetectedObjectSelectedIds: (selection) => {
      set((state) => {
        const newSelection = typeof selection === 'function'
          ? selection(state.classAnnotationSelectedIds)
          : selection
        // Reset class annotation, direct measure and metadata annotation selection
        // And set the new detected object selection
        return {
          classAnnotationSelectedIds: {},
          detectedObjectSelectedIds: newSelection,
          directMeasureSelectedIds: {},
          metadataAnnotationSelectedIds: {},
        }
      })
      set({ thicknessMeasureObjectId: undefined, classAnnotationSelectedIds: {} })
    },
    setDirectMeasureSelectedIds: (selection) => {
      set((state) => {
        const newSelection = typeof selection === 'function'
          ? selection(state.classAnnotationSelectedIds)
          : selection
        // Reset class annotation and detected object selection
        // And set the new direct measure selection
        // Note: Metadata annotation is not reset
        return {
          classAnnotationSelectedIds: {},
          detectedObjectSelectedIds: {},
          directMeasureSelectedIds: newSelection,
        }
      })
    },
    setMetadataAnnotationSelectedIds: (selection) => {
      set((state) => {
        const newSelection = typeof selection === 'function'
          ? selection(state.classAnnotationSelectedIds)
          : selection
        // Reset class annotation and detected object selection
        // And set the new metadata annotation selection
        // Note: Direct measure selection is not reset
        return {
          classAnnotationSelectedIds: {},
          detectedObjectSelectedIds: {},
          metadataAnnotationSelectedIds: newSelection,
        }
      })
    },
    setThicknessMeasureObject: (id, type) => {
      set({ thicknessMeasureObjectId: id })
      if (type !== undefined) {
        set({ thicknessMeasureType: type })
      }
    },
    resetCanvasSelection: () => { set(initialState) },
  }
}
