/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Bbox } from './Bbox';
import {
    BboxFromJSON,
    BboxFromJSONTyped,
    BboxToJSON,
    BboxToJSONTyped,
} from './Bbox';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
    CoordinatesToJSONTyped,
} from './Coordinates';

/**
 * Point Model
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {Bbox}
     * @memberof Point
     */
    bbox?: Bbox | null;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    type: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof Point
     */
    coordinates: Coordinates;
}

/**
 * Check if a given object implements the Point interface.
 */
export function instanceOfPoint(value: object): value is Point {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    return true;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if (json == null) {
        return json;
    }
    return {
        
        'bbox': json['bbox'] == null ? undefined : BboxFromJSON(json['bbox']),
        'type': json['type'],
        'coordinates': CoordinatesFromJSON(json['coordinates']),
    };
}

export function PointToJSON(json: any): Point {
    return PointToJSONTyped(json, false);
}

export function PointToJSONTyped(value?: Point | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'bbox': BboxToJSON(value['bbox']),
        'type': value['type'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
    };
}

