/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DirectMeasureWithStats } from './DirectMeasureWithStats';
import {
    DirectMeasureWithStatsFromJSON,
    DirectMeasureWithStatsFromJSONTyped,
    DirectMeasureWithStatsToJSON,
    DirectMeasureWithStatsToJSONTyped,
} from './DirectMeasureWithStats';

/**
 * List of direct measures with stats for an image
 * @export
 * @interface AnalyticDataDirectMeasures
 */
export interface AnalyticDataDirectMeasures {
    /**
     * 
     * @type {string}
     * @memberof AnalyticDataDirectMeasures
     */
    imageId: string;
    /**
     * 
     * @type {Array<DirectMeasureWithStats>}
     * @memberof AnalyticDataDirectMeasures
     */
    items: Array<DirectMeasureWithStats>;
}

/**
 * Check if a given object implements the AnalyticDataDirectMeasures interface.
 */
export function instanceOfAnalyticDataDirectMeasures(value: object): value is AnalyticDataDirectMeasures {
    if (!('imageId' in value) || value['imageId'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function AnalyticDataDirectMeasuresFromJSON(json: any): AnalyticDataDirectMeasures {
    return AnalyticDataDirectMeasuresFromJSONTyped(json, false);
}

export function AnalyticDataDirectMeasuresFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticDataDirectMeasures {
    if (json == null) {
        return json;
    }
    return {
        
        'imageId': json['image_id'],
        'items': ((json['items'] as Array<any>).map(DirectMeasureWithStatsFromJSON)),
    };
}

export function AnalyticDataDirectMeasuresToJSON(json: any): AnalyticDataDirectMeasures {
    return AnalyticDataDirectMeasuresToJSONTyped(json, false);
}

export function AnalyticDataDirectMeasuresToJSONTyped(value?: AnalyticDataDirectMeasures | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image_id': value['imageId'],
        'items': ((value['items'] as Array<any>).map(DirectMeasureWithStatsToJSON)),
    };
}

