/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CircleGeometryProperties
 */
export interface CircleGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof CircleGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CircleGeometryProperties
     */
    center: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CircleGeometryProperties
     */
    radius: number;
    /**
     * 
     * @type {boolean}
     * @memberof CircleGeometryProperties
     */
    filled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CircleGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the CircleGeometryProperties interface.
 */
export function instanceOfCircleGeometryProperties(value: object): value is CircleGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('center' in value) || value['center'] === undefined) return false;
    if (!('radius' in value) || value['radius'] === undefined) return false;
    if (!('filled' in value) || value['filled'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function CircleGeometryPropertiesFromJSON(json: any): CircleGeometryProperties {
    return CircleGeometryPropertiesFromJSONTyped(json, false);
}

export function CircleGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CircleGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'center': json['center'],
        'radius': json['radius'],
        'filled': json['filled'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function CircleGeometryPropertiesToJSON(json: any): CircleGeometryProperties {
    return CircleGeometryPropertiesToJSONTyped(json, false);
}

export function CircleGeometryPropertiesToJSONTyped(value?: CircleGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'center': value['center'],
        'radius': value['radius'],
        'filled': value['filled'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

