/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bbox
 */
export interface Bbox {
}

/**
 * Check if a given object implements the Bbox interface.
 */
export function instanceOfBbox(value: object): value is Bbox {
    return true;
}

export function BboxFromJSON(json: any): Bbox {
    return BboxFromJSONTyped(json, false);
}

export function BboxFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bbox {
    return json;
}

export function BboxToJSON(json: any): Bbox {
    return BboxToJSONTyped(json, false);
}

export function BboxToJSONTyped(value?: Bbox | null, ignoreDiscriminator: boolean = false): any {
    return value;
}

