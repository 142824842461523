/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserManagementResponseStatus = {
    Success: 'SUCCESS',
    Fail: 'FAIL'
} as const;
export type UserManagementResponseStatus = typeof UserManagementResponseStatus[keyof typeof UserManagementResponseStatus];


export function instanceOfUserManagementResponseStatus(value: any): boolean {
    for (const key in UserManagementResponseStatus) {
        if (Object.prototype.hasOwnProperty.call(UserManagementResponseStatus, key)) {
            if (UserManagementResponseStatus[key as keyof typeof UserManagementResponseStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UserManagementResponseStatusFromJSON(json: any): UserManagementResponseStatus {
    return UserManagementResponseStatusFromJSONTyped(json, false);
}

export function UserManagementResponseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserManagementResponseStatus {
    return json as UserManagementResponseStatus;
}

export function UserManagementResponseStatusToJSON(value?: UserManagementResponseStatus | null): any {
    return value as any;
}

export function UserManagementResponseStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): UserManagementResponseStatus {
    return value as UserManagementResponseStatus;
}

