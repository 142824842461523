/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EllipseGeometryProperties
 */
export interface EllipseGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof EllipseGeometryProperties
     */
    geometryType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EllipseGeometryProperties
     */
    center: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    radiusX: number;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    radiusY: number;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    angle: number;
    /**
     * 
     * @type {boolean}
     * @memberof EllipseGeometryProperties
     */
    filled: boolean;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    pinnedResolution: number;
}

/**
 * Check if a given object implements the EllipseGeometryProperties interface.
 */
export function instanceOfEllipseGeometryProperties(value: object): value is EllipseGeometryProperties {
    if (!('geometryType' in value) || value['geometryType'] === undefined) return false;
    if (!('center' in value) || value['center'] === undefined) return false;
    if (!('radiusX' in value) || value['radiusX'] === undefined) return false;
    if (!('radiusY' in value) || value['radiusY'] === undefined) return false;
    if (!('angle' in value) || value['angle'] === undefined) return false;
    if (!('filled' in value) || value['filled'] === undefined) return false;
    if (!('pinnedResolution' in value) || value['pinnedResolution'] === undefined) return false;
    return true;
}

export function EllipseGeometryPropertiesFromJSON(json: any): EllipseGeometryProperties {
    return EllipseGeometryPropertiesFromJSONTyped(json, false);
}

export function EllipseGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EllipseGeometryProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'center': json['center'],
        'radiusX': json['radius_x'],
        'radiusY': json['radius_y'],
        'angle': json['angle'],
        'filled': json['filled'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function EllipseGeometryPropertiesToJSON(json: any): EllipseGeometryProperties {
    return EllipseGeometryPropertiesToJSONTyped(json, false);
}

export function EllipseGeometryPropertiesToJSONTyped(value?: EllipseGeometryProperties | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'geometry_type': value['geometryType'],
        'center': value['center'],
        'radius_x': value['radiusX'],
        'radius_y': value['radiusY'],
        'angle': value['angle'],
        'filled': value['filled'],
        'pinned_resolution': value['pinnedResolution'],
    };
}

