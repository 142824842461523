import * as React from 'react'
import { Button, Flex, Progress, Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/training-buttons.module.scss'
import ModelTraining from '@material-design-icons/svg/filled/model_training.svg'
import clsx from 'clsx'
import * as Sentry from '@sentry/react'
import { JumpingDots } from '@components/common/jumping-dots'
import { E2E_REQUIRED_CLASSES } from '@app/constants'
import { StopOutlined } from '@ant-design/icons'
import { AlgorithmId, type DeepLearningAlgorithmTrainingProgression, TaskStatus } from '@app/api/openapi'
import { useLicence, useProjectActions, useProjectSettings, useProjectTrainingState } from '@app/api/hooks'
import { ProjectNotificationType, useProjectsNotification } from '@components/notifications/use-notifications'
import { useProjectTrainingStatus } from '@app/api/resource-status'
import { ALGORITHM_ID_WITH_PROGRESSION, useTrainingProgression } from '@app/api/training'

interface ButtonWrapper {
  children?: React.ReactNode
}

interface TrainButtonProps {
  projectId: string | undefined
}
export const TrainButton: React.FC<TrainButtonProps> = ({ projectId }) => {
  const { triggerTrain, triggerCancelTrain } = useProjectActions(projectId)
  const { data: projectTrainingStatus } = useProjectTrainingStatus(projectId)
  const { data: projectTrainingState, isLoading: isProjectTrainingStateLoading } = useProjectTrainingState(projectId)
  const { data: licence } = useLicence()
  const { data: projectSettings } = useProjectSettings(projectId)
  const isTrainAvailablePerLicence = projectSettings === undefined
    ? true
    : {
        [AlgorithmId.ClemexNetLiteV1]: licence?.algoClemexNetLiteV1 ?? false,
        [AlgorithmId.ClemexNetLiteV2]: licence?.algoClemexNetLiteV2 ?? false,
        [AlgorithmId.ClemexGrainsegV3]: licence?.algoClemexGrainsegV3 ?? false,
        [AlgorithmId.ClemexGrainsegV3Aluminum]: licence?.algoClemexGrainsegV3Aluminum ?? false,
        [AlgorithmId.ClemexMedcleanV1]: licence?.algoClemexMedcleanV1 ?? false,
        [AlgorithmId.RfAlgorithmSklearn]: true,
        [AlgorithmId.RfAlgorithmCuml]: true,
      }[projectSettings.algorithmId]
  const [isCallingTrain, setIsCallingTrain] = React.useState(false)
  const [isCallingCancel, setIsCallingCancel] = React.useState(false)
  const { notify } = useProjectsNotification()

  const onTrain = React.useCallback(async (): Promise<void> => {
    // Ignore a train request when it is not possible
    if (projectTrainingState?.canBeTrain === false ||
      projectTrainingState?.shouldBeTrain === false) {
      return
    }

    if (projectId === undefined) {
      throw new Error('projectId is undefined')
    }
    try {
      setIsCallingTrain(true)
      await triggerTrain()
    } catch (err) {
      Sentry.captureException(err)
      notify(
        projectId,
        ProjectNotificationType.TrainingUnknown,
      )
    } finally {
      setIsCallingTrain(false)
    }
  }, [notify, projectId, projectTrainingState?.canBeTrain, projectTrainingState?.shouldBeTrain, triggerTrain])

  const onCancelTrain = React.useCallback(async (): Promise<void> => {
    if (projectId === undefined) {
      throw new Error('projectId is undefined')
    }
    try {
      setIsCallingCancel(true)
      await triggerCancelTrain()
    } catch (err) {
      Sentry.captureException(err)
      notify(
        projectId,
        ProjectNotificationType.CancelTrainingErrorUnknown,
      )
    } finally {
      setIsCallingCancel(false)
    }
  }, [notify, projectId, triggerCancelTrain])

  const isTraining = (
    isCallingTrain ||
    projectTrainingStatus?.inProgressTraining?.status === TaskStatus.Pending ||
    projectTrainingStatus?.inProgressTraining?.status === TaskStatus.Running
  )

  const trainButtonIsDisabled = (
    isProjectTrainingStateLoading ||
    projectTrainingState?.canBeTrain === false ||
    projectTrainingState?.shouldBeTrain === false ||
    !isTrainAvailablePerLicence ||
    isTraining ||
    isCallingCancel
  )

  const isCancelButtonDisabled = (
    isCallingTrain ||
    !isTraining
  )

  const { data: trainingProgression } = useTrainingProgression(projectId)
  const trainingHasEpochProgress = isTraining && ALGORITHM_ID_WITH_PROGRESSION.includes(trainingProgression?.progression?.algorithmId)

  const getTooltipTitle = React.useCallback((): React.ReactNode => {
    if (projectTrainingStatus?.inProgressTraining?.status === TaskStatus.Pending) {
      return <FormattedMessage id="editor.algorithm.button.train.tooltip.training.pending" defaultMessage={'Your train request has been accepted. Waiting for the algorithm to be available.'} />
    } else if (projectTrainingStatus?.inProgressTraining?.status === TaskStatus.Running) {
      return <FormattedMessage id="editor.algorithm.button.train.tooltip.training.running" defaultMessage={'The training is running, waiting for the model to be created.'} />
    } else if (projectTrainingState?.canBeTrain === false) {
      return <FormattedMessage id="editor.algorithm.button.train.tooltip.disable" defaultMessage={'You need at least 2 annotations of different class to start training.'} />
    } else if (!isTrainAvailablePerLicence) {
      // No popup to request licence, it is part of algorithm section visible
      return
    } else if (projectTrainingState?.shouldBeTrain === false) {
      return <FormattedMessage id="editor.algorithm.button.train.tooltip.isProjectTrainingSnapshotPristine" defaultMessage={"You're all set! The system is already trained with your latest annotations. To train again, add or remove annotation and click on the Train button."} />
    }
    return null
  }, [isTrainAvailablePerLicence, projectTrainingState, projectTrainingStatus])

  const ButtonConditionalTooltip: React.FC<ButtonWrapper> = React.useCallback(({ children }) => {
    const tooltipTitle = getTooltipTitle()
    return (tooltipTitle != null) ? <Tooltip placement="topLeft" title={tooltipTitle}>{children}</Tooltip> : <>{children}</>
  }, [getTooltipTitle])

  return <ButtonConditionalTooltip >
    <Flex vertical={false} className={styles.trainingButtonWrapper}>
      <Button
        type={
          projectTrainingStatus?.inProgressTraining?.status === TaskStatus.Pending
            ? 'dashed'
            : 'primary'
        }
        block
        size={'large'}
        onClick={onTrain}
        disabled={trainButtonIsDisabled}
        className={clsx(
          styles.button,
          styles.buttonTrain,
          E2E_REQUIRED_CLASSES.TRAIN_BUTTON,
          {
            [E2E_REQUIRED_CLASSES.TRAIN_BUTTON_DISABLED]: trainButtonIsDisabled,
            [styles.training]: isTraining,
            [styles.disabled]: trainButtonIsDisabled,
          },
        )}
      >
        <div className={clsx(styles.buttonContent, { [styles.isTrainingProgress]: trainingHasEpochProgress })}>
          {
            !trainingHasEpochProgress &&
            <>
              <div className={styles.buttonIcon}>
                <ModelTraining />
              </div>
              <div className={clsx(styles.buttonText, { [E2E_REQUIRED_CLASSES.IS_TRAINING]: isCallingTrain || isTraining })}>
                {!(isCallingTrain || isTraining) && <FormattedMessage id="editor.algorithm.button.train" defaultMessage={'Train'} />}
                {(isCallingTrain || isTraining) && <>
                  <FormattedMessage id="editor.algorithm.button.training" defaultMessage={'Training'} />
                  &nbsp;
                  <JumpingDots />
                </>}
              </div>
            </>
          }
          {
            trainingHasEpochProgress && trainingProgression !== undefined &&
            <>
              <div className={clsx(styles.buttonText, { [styles.progress]: true })}>
                <FormattedMessage
                  id="editor.algorithm.button.training-epochs"
                  defaultMessage={'Epochs {currentEpoch}/{totalEpochs}'}
                  values={{ currentEpoch: (trainingProgression.progression as DeepLearningAlgorithmTrainingProgression).epochNumber, totalEpochs: (trainingProgression.progression as DeepLearningAlgorithmTrainingProgression).totalEpochs }} />
              </div>
              <Progress
                className={styles.progressBar}
                percent={
                  100 * (trainingProgression.progression as DeepLearningAlgorithmTrainingProgression).epochNumber / (trainingProgression.progression as DeepLearningAlgorithmTrainingProgression).totalEpochs
                }
                showInfo={false}
                status='active'
              />
            </>
          }
        </div>
      </Button>
      <Button
        type={'primary'}
        block
        size={'large'}
        disabled={isCancelButtonDisabled}
        onClick={onCancelTrain}
        className={clsx(
          styles.button,
          styles.buttonCancel,
          {
            [styles.disabled]: isCancelButtonDisabled,
          },
        )}
      >
        <div className={styles.buttonContent}>
          <div className={styles.buttonIcon}>
            <StopOutlined />
          </div>
          <div className={clsx(styles.buttonText, { [styles.isCancelling]: isCallingCancel })}>
            {
              isCallingCancel
                ? <FormattedMessage id="editor.algorithm.button.cancelling" defaultMessage={'Cancelling'} />
                : <FormattedMessage id="editor.algorithm.button.cancel" defaultMessage={'Cancel'} />
            }
          </div>
        </div>
      </Button>
    </Flex>
  </ButtonConditionalTooltip>
}
