/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MetadataAnnotation } from './MetadataAnnotation';
import {
    MetadataAnnotationFromJSON,
    MetadataAnnotationFromJSONTyped,
    MetadataAnnotationToJSON,
    MetadataAnnotationToJSONTyped,
} from './MetadataAnnotation';

/**
 * 
 * @export
 * @interface AnnotationPatchRequest
 */
export interface AnnotationPatchRequest {
    /**
     * 
     * @type {Array<MetadataAnnotation>}
     * @memberof AnnotationPatchRequest
     */
    add?: Array<MetadataAnnotation>;
    /**
     * 
     * @type {Array<MetadataAnnotation>}
     * @memberof AnnotationPatchRequest
     */
    remove?: Array<MetadataAnnotation>;
    /**
     * 
     * @type {Array<MetadataAnnotation>}
     * @memberof AnnotationPatchRequest
     */
    update?: Array<MetadataAnnotation>;
}

/**
 * Check if a given object implements the AnnotationPatchRequest interface.
 */
export function instanceOfAnnotationPatchRequest(value: object): value is AnnotationPatchRequest {
    return true;
}

export function AnnotationPatchRequestFromJSON(json: any): AnnotationPatchRequest {
    return AnnotationPatchRequestFromJSONTyped(json, false);
}

export function AnnotationPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotationPatchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'add': json['add'] == null ? undefined : ((json['add'] as Array<any>).map(MetadataAnnotationFromJSON)),
        'remove': json['remove'] == null ? undefined : ((json['remove'] as Array<any>).map(MetadataAnnotationFromJSON)),
        'update': json['update'] == null ? undefined : ((json['update'] as Array<any>).map(MetadataAnnotationFromJSON)),
    };
}

export function AnnotationPatchRequestToJSON(json: any): AnnotationPatchRequest {
    return AnnotationPatchRequestToJSONTyped(json, false);
}

export function AnnotationPatchRequestToJSONTyped(value?: AnnotationPatchRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'add': value['add'] == null ? undefined : ((value['add'] as Array<any>).map(MetadataAnnotationToJSON)),
        'remove': value['remove'] == null ? undefined : ((value['remove'] as Array<any>).map(MetadataAnnotationToJSON)),
        'update': value['update'] == null ? undefined : ((value['update'] as Array<any>).map(MetadataAnnotationToJSON)),
    };
}

