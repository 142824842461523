import type * as React from 'react'
import { Steps } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/workflow-steps.module.scss'
import { ProjectDatasetContextInput as ProjectDatasetContext } from '@app/api/openapi'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'

const StepAnnotate = <FormattedMessage id="header.rf.workflow.steps.step.annotate" description="Annotation step title" defaultMessage={'Annotate'} />
const StepValidate = <FormattedMessage id="header.rf.workflow.steps.step.validate" description="Validation step title" defaultMessage={'Validate'} />

interface WorkflowStepsProps {
  datasetContext: ProjectDatasetContext | undefined
  projectSlug: string | undefined
}
export const WorkflowSteps: React.FC<WorkflowStepsProps> = ({ datasetContext, projectSlug }) => {
  const navigate = useNavigate()
  const currentStep = datasetContext === ProjectDatasetContext.Training ? 0 : 1
  const onChangeStep = (step: number): void => {
    const route = step === 0
      ? WebUIRoutes.annotateProject(projectSlug)
      : WebUIRoutes.validateProject(projectSlug)
    navigate(route.path, { replace: true })
  }

  return (
    <Steps
      onChange={onChangeStep}
      current={currentStep}
      size="small"
      labelPlacement="vertical"
      className={styles.steps}
      progressDot
      items={[
        {
          title: <div className={styles.stepText}>{StepAnnotate}</div>,
        },
        {
          title: <div id='validate-page-access' className={styles.stepText}>{StepValidate}</div>,
        },
      ]}
    />
  )
}
